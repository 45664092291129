import type { ComposerTranslation } from "vue-i18n";

/**
 * @description Function that does an "advanced translation".
 *  At the moment if we do a te() check it only checks for the key on the current locale, but this function
 *  checks the deafault language for a translation aswell, and outputs it.
 * @returns {string|undefined}
 */
export const ate = (
  t: ComposerTranslation<Record<string, string>>,
  te: <Str extends string, Key extends string = string>(
    key: Str | Key,
    locale?: string | undefined
  ) => boolean,
  str: string
) => {
  if (te(str)) return t(str);
  if (te(str, "en")) return t(str);
  return;
};
