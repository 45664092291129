import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  list() {
    return axios.get("/v1/panel");
  },
  getShareList() {
    // TODO
  },
  details(id: string) {
    return axios.get(`/v1/panel/${id}`);
  },
  getSubscriptionDetails(id: string) {
    return axios.get(`/v1/panel/${id}/subscription`);
  },
  unsubscribeAlert(id: string) {
    return axios.put(`/v1/panel/${id}/unsubscribe`);
  },
  subscribeAlert(id: string, frequency: string) {
    return axios.put(`/v1/panel/${id}/subscribe?frequency=${frequency}`);
  },
  requestUpdateJob(id: string) {
    return axios.put(`/v1/panel/${id}/update`);
  },
  archive(id: string) {
    return axios.put(`/v1/panel/${id}/archive`);
  },
  editPanel(panelId: string, params: object) {
    return axios.put(`/v1/panel/${panelId}/edit?`, null, {
      params: {
        ...params,
      },
    });
  },
  upsertDocumentInPanel(
    panelId: string,
    docId: string,
    docIndex: string,
    newState: string
  ) {
    return axios.post(`/v1/panel/${panelId}/document?state=${newState}`, {
      documentType: "default",
      defaultDocument: {
        doc_id: docId,
        index: docIndex,
      },
    });
  },
  upsertRawTextInPanel(
    panelId: string,
    rawText: string,
    language: string,
    newState: string
  ) {
    return axios.post(`/v1/panel/${panelId}/document?state=${newState}`, {
      documentType: "raw",
      rawDocument: {
        body: rawText,
        language: language,
      },
    });
  },
  updateNotes(panelId: string, notes: string) {
    return axios.post(`/v1/panel/${panelId}/notes`, notes, {
      headers: { "Content-Type": "text/plain" },
    });
  },
  createEmpty(idBackend: string, panelName: string, panelDescription: string) {
    return axios.post(`/v1/panel/empty`, {
      idBackend: idBackend,
      name: panelName,
      description: panelDescription,
    });
  },
  createFromDocument(
    idBackend: string,
    panelName: string,
    panelDescription: string,
    seedDocId: string,
    seedDocIndex: string
  ) {
    return axios.post(`/v1/panel`, {
      idBackend: idBackend,
      name: panelName,
      description: panelDescription,
      seedType: "default",
      defaultSeed: {
        doc_id: seedDocId,
        index: seedDocIndex,
      },
    });
  },
  createFromRawText(
    idBackend: string,
    panelName: string,
    panelDescription: string,
    rawText: string,
    language: string
  ) {
    return axios.post(`/v1/panel`, {
      idBackend: idBackend,
      name: panelName,
      seedType: "raw",
      description: panelDescription,
      rawSeed: {
        body: rawText,
        language: language,
      },
    });
  },
  sharePanelReport(id: string, sharelist: object) {
    return axios.post(`/v1/panel/${id}/report`, sharelist);
  },
  getSharedReport(id: string) {
    return axios.get(`/v1/panel/${id}/report`);
  },
});
