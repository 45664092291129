import type { AxiosInstance } from "axios";

function anonymousDecorator(fn: any) {
  fn._anonymous = true;
  return fn;
}

export default (axios: AxiosInstance) => ({
  login(username: string, password: string) {
    return axios.post("/v1/user/login", { username, password });
  },
  //logout() {},
  verify() {
    return axios.get("/v1/user/verify");
  },
  getDetails() {
    return axios.get("/v1/user/personalization");
  },
  getAccountWideDetails() {
    return axios.get("/v1/user/accountwide-personalization");
  },
  changeConfigFields(workspaceUserConfig: object, accountConfig: object) {
    return axios.put(`/v1/user/personalization`, {
      workspaceUserConfig,
      accountConfig,
    });
  },
  createTeam(name: string, description: string, members: any[]) {
    return axios.put(`/v1/team`, {
      name: name,
      notes: description,
      members: members,
    });
  },
  addUserToTeam(teamid: string, userid: string) {
    return axios.post(`/v1/team/${teamid}/user/${userid}`);
  },
  removeUserFromTeam(teamid: string, userid: string) {
    return axios.delete(`/v1/team/${teamid}/user/${userid}`);
  },
  addUsersToTeam(teamid: string, users: any[]) {
    return axios.post(`/v1/team/${teamid}/users/`, {
      users: users.map((u) => u.id),
    });
  },
  archiveTeam(id: string) {
    return axios.delete(`/v1/team/${id}`);
  },
  changePassword(oldPassword: string, newPassword: string) {
    return axios.put("/v1/user/password", {
      oldPassword: oldPassword,
      newPassword: newPassword,
    });
  },
  changePasswordViaToken(newPassword: string, token: string) {
    return axios.put("/v1/user/password", {
      token,
      newPassword,
    });
  },
  forgotPassword(email: string) {
    return axios.get(`/v1/user/forgotpassword?email=${email}`);
  },
  getUserRegistrationOrgFromToken(apiKey: string) {
    return axios.get(`/v1/organization/from-token?token=${apiKey}`);
  },
  activateAccount(email: string, password: string, user: any, apiKey: string) {
    return anonymousDecorator(
      axios.put(`/v1/user/activate/${apiKey}`, {
        email: email,
        password: password,
        firstName: user.name?.first,
        lastName: user.name?.last,
      })
    );
  },
  updateAccountInfo(
    accountUserDetails: object,
    pictureUrl: string,
    email: string
  ) {
    return axios.put("/v1/user/account-info", {
      accountUserDetails,
      pictureUrl,
      email,
    });
  },
  updateUserPropertyTypeConfig(propertyTypeConfig: object) {
    return axios.put("/v1/user/propertyTypeConfig", { ...propertyTypeConfig });
  },
  sendFeedback(type: string, message: string, details: object) {
    return axios.post("/v1/feedback", {
      type,
      message,
      details,
    });
  },
  acceptTerms() {
    return axios.put("/v1/user/details/terms");
  },
  takenTour(tourname: string) {
    return axios.put(`/v1/user/details/tours/${tourname}`);
  },
  getEntities(timestamp: string) {
    return axios.get(`/v1/user/entity-rules/${timestamp}`);
  },
  updateTimezone(value: object) {
    return axios.put(`/v1/user/property/timezone`, { value });
  },
  updateLocale(value: object) {
    return axios.put(`/v1/user/property/locale`, { value });
  },
  uploadPicture(picture: ArrayBuffer | null) {
    return axios.post("/v1/user/picture", picture, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  },
});
