/**
 * Handle general app state initialization.
 * Methods here should only be executed once. Either when app opens or closes.
 */

import { reactive, readonly, inject } from "vue";
import { useApi } from "@api/api";
import { defineStore } from "pinia";
import storage from "@utils/tokenStorage";

export const useAppStore = defineStore("app", {
  state: () => ({
    moduleHasInit: false,
    uiVersion: localStorage.getItem("Monitio.UI.Ver") || null,
    platformVersion: localStorage.getItem("Monitio.Ver") || null,
    openHamMenu: false,
    keepSelectorOpen: false,
    autoRead: localStorage.getItem("autoRead") != "false",
    navigation_menu_active: "",
    propertyKeysFilterMap: {},
    propertyKeysFilterIds: {},
    chat: "close",
    a11y: false,
    fullscreen: false,
    isExporting: false,
  }),

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("App module already initialized");
        return;
      }

      this.moduleHasInit = true;
    },
    async setUiVersion(value: string) {
      this.uiVersion = value;
      localStorage.setItem("Monitio.UI.Ver", value);
    },
    async setPlatformVersion(value: string) {
      this.platformVersion = value;
      localStorage.setItem("Monitio.Ver", value);
    },
    setFullscreen(value: boolean) {
      this.fullscreen = value;
    },
    setChat(value: string) {
      this.chat = value;
    },
    setA11y(value: boolean) {
      this.a11y = value;
    },
    /**
     * @description Sets up another jwt token with the person we want to impersonate, and perform a plataforms refresh, efectively loggin in as other user
     */
    async impersonate(accountId: string) {
      const { api } = useApi();

      try {
        const { data } = await api.admin.impersonate(accountId);

        //storage.reset();
        storage.refreshToken = data.token;
        storage.jwtToken = data.sessionToken; // 🚑 It's important that we send the entire object { token, expires }
        storage.workspaceId = data.workspaceId;
        location.reload();
      } catch (error) {
        console.error("Couldn't impersonate", error);
      }
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
  },
});
