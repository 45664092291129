<template>
  <div
    class="m-articles m-wrapper"
    :class="`m-articles--${layout} m-wrapper--${viewsStore.nav}`"
  >
    <div class="m-subheader">
      <m-filter
        id="articles_page_filter"
        :scoped="false"
        :modelValue="viewFilters"
        :placeholder="$t('components.search.placeholder_searchForAnything')"
      />
    </div>

    <div v-if="responseHasData" class="m-container__header">
      <m-type-skeleton
        v-if="!requestCompleted"
        type="h6"
        width="100"
        size="xsmall"
        :lines="1"
        class="mx-4"
      />
      <h6
        v-else-if="totalNumOfArticles || isMarkingAllAsRead"
        class="mx-4 type--xsmall"
      >
        {{
          $t("views.articles.header_searchCount", {
            count: totalNumOfArticles.toLocaleString().replace(/,/g, " "),
          })
        }}
      </h6>
    </div>

    <m-loading v-if="isMarkingAllAsRead || loadingArticles" overlay blured />
    <div
      ref="containerRef"
      id="scrollable"
      class="m-container"
      :class="{
        'm-container--load': isMarkingAllAsRead,
      }"
    >
      <div
        class="m-container__wrapper"
        :class="{
          'm-container__wrapper--empty':
            totalNumOfArticles == 0 && !loadingArticles,
        }"
      >
        <div
          v-if="!requestCompleted"
          ref="wrapperRef"
          class="m-articles__wrapper"
          :class="[
            `m-articles__wrapper--${layout}`,
            {
              'm-articles__wrapper--empty':
                totalNumOfArticles == 0 && !loadingArticles,
            },
          ]"
        >
          <m-article-skeleton
            v-for="i in articlesSkeleton"
            :key="i"
            :titleLines="layout == 'card' ? 2 : 1"
          />
        </div>
        <template v-else>
          <div
            ref="assistantRef"
            class="m-tooltip__assistant"
            v-if="waitingForRagResponse || ragResponse"
          >
            <img id="open_chat" :src="monitio_assistant" width="26" />
            <div
              v-if="waitingForRagResponse"
              data-is-tooltip="true"
              class="m-balloon"
            >
              <div data-is-tooltip="true" class="m-loading--bubble">
                <div
                  data-is-tooltip="true"
                  class="m-bubble m-bubble--small m-bubble--1"
                ></div>
                <div
                  data-is-tooltip="true"
                  class="m-bubble m-bubble--small m-bubble--2"
                ></div>
                <div
                  data-is-tooltip="true"
                  class="m-bubble m-bubble--small m-bubble--3"
                ></div>
              </div>
            </div>
            <span
              v-else-if="ragResponse"
              class="type--small"
              v-html="ragResponse"
            ></span>
          </div>
          <div
            ref="wrapperRef"
            class="m-articles__wrapper"
            :class="[
              `m-articles__wrapper--${layout}`,
              {
                'm-articles__wrapper--empty':
                  totalNumOfArticles == 0 && !loadingArticles,
              },
            ]"
          >
            <m-article
              v-for="(article, i) in articles"
              :key="i"
              :article="article"
              :disableActions="view.isShared && !view.isBaseView"
              hasTeaser
              snippet
              @update:article="
                (key, val) => {
                  article[key] = val;
                }
              "
              @change-read-status="
                (status) => changeReadStatus(article, status)
              "
            />

            <m-loading
              v-if="articlesLength < totalNumOfArticles || !maxArticlesReached"
              ref="loadingElement"
              :class="`m-article--${viewsStore.layout}`"
              size="small"
              overlay
            />

            <div
              v-if="totalNumOfArticles == 0 && articlesLength == 0"
              class="m-articles__empty"
            >
              <h3 class="type--small type--nowrap type--empty">
                {{ t("views.articles.noSearchResults") }}
              </h3>
              <h6 class="type--small type--nowrap type--empty">
                {{ t("views.articles.tryChanging") }}
              </h6>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  computed,
  unref,
  ref,
  onMounted,
  onUnmounted,
  watch,
  watchEffect,
} from "vue";
import { useI18n } from "vue-i18n";

import {
  useRouter,
  useRoute,
  onBeforeRouteUpdate,
  onBeforeRouteLeave,
} from "vue-router";
import { useApi } from "@api/api";
import { useSignalR } from "@root/hubs/main";
import { useViewFilters } from "@hooks/useViewFilters";
import MFilter from "@components/filter/MFilter.vue";
import MArticle from "@components/MArticle.vue";
import MLoading from "@components/MLoading.vue";
import monitio_assistant from "@assets/illustrations/monitio_assistant.svg";
import useArticle from "@hooks/useArticle";
import useWindowSize from "@hooks/useWindowSize";
import MTypeSkeleton from "@components/skeletons/MTypeSkeleton.vue";
import MArticleSkeleton from "@components/skeletons/MArticleSkeleton.vue";
import { DateTime } from "luxon";
import { debounce, delay, isEmpty, isEqual } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { eventBus } from "@root/utils/eventBus";
import { useAppStore } from "@root/store/app";
import { useAlertsStore } from "@root/store/modules/alerts";
import guid from "@root/utils/guid";

const { t } = useI18n();
const viewsStore = useViewsStore();
const alertsStore = useAlertsStore();
const signalR = useSignalR();
const appStore = useAppStore();
const router = useRouter();
const { api } = useApi();
const route = useRoute();

const viewId = computed(() => route.params.viewId);
const { changeReadStatus } = useArticle(viewId);
const {
  sortBy,
  show,
  queryObject,
  dateRestriction,
  aggregateDuplicates,
  decodeQueryObject,
} = useViewFilters(router, route);

const loadingArticles = ref(false);
const assistantRef = ref(null);
const wrapperRef = ref(null);
const containerRef = ref(null);

const layout = computed(() => {
  return viewsStore.layout;
});

const view = computed(() => viewsStore.getViewById(viewId.value));
const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },
  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
  },
});

const apiArticlesData = ref([]);
const totalNumOfArticles = ref(0);
/** Use this computed variable to pre process the articles as needed for the UI */
const articles = computed(() => {
  let articles = [...apiArticlesData.value];

  /** Check the show ref to filter out results */
  if (show.value == "read") articles = articles.filter((x) => x.isRead);
  if (show.value == "unread") articles = articles.filter((x) => !x.isRead);
  return articles;
});
/** The true articles length including duplicated documents if needed */
const articlesLength = computed(() => {
  if (aggregateDuplicates.value) {
    let n = 0;
    articles.value.forEach((article) => {
      n += article.metadata?.group?.selection_count;
    });
    return n;
  } else {
    return articles.value.length;
  }
});

const formattedTotalNumOfArticles = computed(() => {
  const numString = totalNumOfArticles.value.toString();
  const parts = [];
  let i = numString.length;

  while (i > 0) {
    parts.unshift(numString.slice(Math.max(0, i - 3), i));
    i -= 3;
  }

  return parts.join(" ");
});
/** Use this computed variable to pre process the articles as needed for the UI */

const requestCompleted = ref(false);

const responseHasData = ref(true);

const { windowHeight } = useWindowSize();

const articlesSkeleton = computed(() => {
  let x;

  if (layout.value == "list") x = 14;
  if (layout.value == "details") x = 5;
  if (layout.value == "card") x = 9;

  x = (windowHeight.value * x) / 720;

  return parseInt(x);
});

const maxArticlesReached = ref(false);
/**
 *
 * @param {string} id Its a Guid
 * @param {import("@root/types").Monitio.URLQueryObject} query
 */
const getArticles = async (
  id,
  query = {},
  first = 0,
  last = 19,
  filterChanged = false
) => {
  loadingArticles.value = true;

  const filters = query.filters ?? [];
  if (!isEmpty(query.contextualFilters))
    filters.push({ facets: query.contextualFilters });

  const response = await api.search.search(
    unref(id),
    query.dateRestriction || dateRestriction.value,
    first,
    last + 20,
    filters,
    query.sortBy || sortBy.value,
    show.value,
    query?.aggregateDuplicates ?? aggregateDuplicates.value
  );
  if (response.status != 200) {
    loadingArticles.value = false;
    return { articlesData: [], articlesCount: null };
  }
  if (!response.data.result?.documents?.length) {
    responseHasData.value = false;
    maxArticlesReached.value = true;
    loadingArticles.value = false;
    return { articlesData: [], articlesCount: 0 };
  } else {
    /** Get the total number of documents. This property represents only the scenario in context */
    let totalNumOfArticles = response.data.result.numDocuments; //If the request happens more than once in the view we need to reset this

    if (aggregateDuplicates.value && totalNumOfArticles) {
      //Remove duplicates from the counting, or else will not match
      response.data?.result?.documents.forEach((doc) => {
        if (doc.metadata?.group?.selection_count > 1) {
          totalNumOfArticles -= doc.metadata?.group?.selection_count - 1;
        }
      });
    }

    // Set the
    if (response.data.result.documents.length < last - first + 1)
      maxArticlesReached.value = true;

    // Check for duplicates
    const articlesN = last - first + 1;
    const articlesInMemory = filterChanged ? [] : apiArticlesData.value;
    const duplicates = response.data.result.documents
      .filter((x) => articlesInMemory.map((m) => m.id).includes(x.id))
      .map((x) => x.id);

    //If all the articles are read, dont let the user click the read all button
    viewsStore.allArticlesRead = response.data.result.documents.every(
      (x) => x.isRead
    );

    loadingArticles.value = false;
    return {
      articlesData:
        response.data?.result?.documents
          ?.filter((x) => !duplicates.includes(x.id))
          ?.slice(0, articlesN) ?? [],
      articlesCount: totalNumOfArticles,
    };
  }
};

const numberOfArticlesToRequest = ref(20);
let isGettingMoreArticles = false;
const getMoreArticles = async () => {
  isGettingMoreArticles = true;

  const { articlesData } = await getArticles(
    viewId.value,
    queryObject.value,
    apiArticlesData.value.length,
    apiArticlesData.value.length - 1 + numberOfArticlesToRequest.value
  );
  apiArticlesData.value = [...apiArticlesData.value, ...articlesData];
  isGettingMoreArticles = false;
  loadingArticles.value = false;
};

watch(
  () => show.value,
  async (val) => {
    const { articlesData, articlesCount } = await getArticles(
      viewId.value,
      queryObject.value
    );
    apiArticlesData.value = articlesData;
    totalNumOfArticles.value = articlesCount;
  }
);

onBeforeRouteUpdate(
  debounce(async (to, from) => {
    if (from.name != to.name) return true;
    if (isEqual(to.params, from.params) && isEqual(to.query, from.query))
      return;
    /**
     * Handle the update of the props change
     * Ideally we wouls react to changes to the computed vars (viewId, queryObject) but that causes problems
     * betweens routes. So we need to use this hook, but can't use the computed vars because they only change AFTER
     * route change, and this hook runs BEFORE route change.
     **/
    //requestCompleted.value = false;
    loadingArticles.value = true;
    totalNumOfArticles.value = 0;
    waitingForRagResponse.value = false;
    ragResponse.value = null;

    try {
      const result = await getArticles(
        to.params.viewId,
        decodeQueryObject(to.query.q),
        undefined,
        undefined,
        true
      );
      apiArticlesData.value = result.articlesData ?? [];
      totalNumOfArticles.value = result.articlesCount ?? 0;
    } catch (error) {
      apiArticlesData.value = [];
      totalNumOfArticles.value = 0;
    }

    wrapperRef.value.children[0].scrollIntoView({ behavior: "smooth" });
    loadingArticles.value = false;
  }),
  window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE
);

const openArticles = () => {
  router.push(`/views/${view.value?.id}/articles`);
};

const isMarkingAllAsRead = computed(() => viewsStore.readingAllArticles);

//#region Intersection observer code to load more articles when needed
const loadingElement = ref(null);
let observerWasInitiated = false;
const handleIntersection = (entries, observer) => {
  const info = entries[0];

  //info.isIntersecting;

  if (
    info.isIntersecting &&
    !isGettingMoreArticles &&
    articles.value.length > 0
  ) {
    getMoreArticles();
  }
};
const observer = new IntersectionObserver(handleIntersection, {
  rootMargin: "20% 0%",
  threshold: [0, 0.75],
});
watch(
  () => loadingElement.value,
  (val, old) => {
    /** Initialize an intersectionObserver for each row to fetch the data on demand */
    // eslint-disable-next-line no-unreachable
    if (!val?.$el) {
      if (observerWasInitiated) {
        observer.disconnect();
        observerWasInitiated = false;
      }
    } else {
      if (!observerWasInitiated) {
        observer.observe(val.$el);
        observerWasInitiated = true;
      }
    }
  }
);
//#endregion

//#region Code to preserve articles just in case the user comes back to this page throught the browser's back button

let scrolledBy = 0;
let scrollListener;
onBeforeRouteLeave(async (to, from) => {
  window.history.replaceState(
    {
      articles: JSON.stringify(apiArticlesData.value),
      queryObject: JSON.stringify(queryObject.value),
      total: totalNumOfArticles.value,
      scrolledBy: scrolledBy,
      lastFetch: DateTime.utc().toJSDate(),
    },
    "",
    router.resolve(from).href
  );
});

//#region User activity
// Define the inactivity timeout in minutes (e.g., 5 minutes)
const inactivityTimeout = 5;
// Define a variable to store the last user activity time
let lastActiveTime = Date.now();
let activityWatcherInterval;
const startActivityWatcher = () => {
  // Attach event listeners for user activity events
  document.addEventListener("mousemove", () => (lastActiveTime = Date.now()));
  document.addEventListener("keydown", () => (lastActiveTime = Date.now()));

  // Function to check for user inactivity
  const isUserInactive = () => {
    return Date.now() - lastActiveTime > inactivityTimeout * 60 * 1000;
  };

  // Call this function periodically (e.g., every minute) to check for inactivity
  activityWatcherInterval = setInterval(async () => {
    return; //Deactivated auto refresh as per instructions
    // eslint-disable-next-line no-unreachable
    if (isUserInactive()) {
      // Handle inactivity here (e.g., display a warning or log out the user)
      requestCompleted.value = false;
      const { articlesData, articlesCount } = await getArticles(
        viewId.value,
        queryObject.value
      );
      requestCompleted.value = true;
      apiArticlesData.value = articlesData;
      totalNumOfArticles.value = articlesCount;
    }
  }, 60 * 1000);
};
//#endregion
const waitingForRagResponse = ref(false);
const ragResponse = ref(null);
const reactToChange = (wsMessage) => {
  if (
    wsMessage.detail?.value?.eventType === "WaitingForRagResponse" ||
    wsMessage.detail?.value?.eventType === "RagResponse"
  ) {
    const payload = wsMessage.detail?.value?.payload;
    if (!payload) return;
    if (payload.viewId !== viewId.value) return;

    if (wsMessage.detail?.value?.eventType === "WaitingForRagResponse") {
      waitingForRagResponse.value = true;
      console.log("trying to show loading", assistantRef.value);
      delay(
        () => assistantRef.value?.scrollIntoView({ behavior: "smooth" }),
        150
      );
      return;
    }

    if (wsMessage.detail?.value?.eventType === "RagResponse") {
      waitingForRagResponse.value = false;
      ragResponse.value = payload.result;
      assistantRef.value?.scrollIntoView({ behavior: "smooth" });
    }
  }
};

onMounted(async () => {
  signalR.addEventListener("changed", reactToChange);

  scrollListener = containerRef.value?.addEventListener("scroll", (e) => {
    scrolledBy = e.target.scrollTop;
  });

  if (history.state.articles) {
    if (!queryObject.value.filters && history.state.queryObject)
      queryObject.value = JSON.parse(history.state.queryObject);
    apiArticlesData.value = JSON.parse(history.state.articles);
    totalNumOfArticles.value = history.state.total;
    requestCompleted.value = true;
    const interval = setInterval(() => {
      const element = document.querySelector(".m-articles .m-container");
      if (element != null) {
        element.scrollTo({
          top: history.state.scrolledBy,
          left: 0,
          behavior: "auto",
        });
        window.clearInterval(interval);
      }
    }, 500);
  } else {
    requestCompleted.value = false;
    const { articlesData, articlesCount } = await getArticles(
      viewId.value,
      queryObject.value
    );
    requestCompleted.value = true;
    apiArticlesData.value = articlesData;
    totalNumOfArticles.value = articlesCount;
  }

  startActivityWatcher();
});

const exportCSV = async () => {
  //appStore.isExporting = true;

  const id = guid.NewGuid();
  alertsStore.add({
    id: id,
    type: "toast",
    variant: "load",
    message: t("general.alerts.toast.downloadingCsv", {
      type: t("views.cluster.articles"),
    }),
  });

  const filters = queryObject.value.filters ?? [];
  if (!isEmpty(queryObject.value.contextualFilters))
    filters.push({ facets: queryObject.value.contextualFilters });

  const { data } = await api.search.searchCSV(
    viewId.value,
    dateRestriction.value,
    0,
    500, //Ask for 500 by default
    filters,
    sortBy.value,
    show.value,
    aggregateDuplicates.value
  );

  /*   const fileName = "Monitio Articles";
  const BOM = "\uFEFF";

  saveAs(
    new Blob([BOM + data], {
      type: "text/csv;charset=utf-8",
    }),
    fileName + ".csv"
  ); */

  /*   appStore.isExporting = false;
  alertsStore.remove(id);
  alertsStore.add({
    id: guid.NewGuid(),
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.downloadedCsv", {
      type: t("views.tiles.tiles"),
    }),
  }); */
};
eventBus.on("export-csv", exportCSV);

onUnmounted(() => {
  eventBus.off("export-csv", exportCSV);
  containerRef.value?.removeEventListener(scrollListener);
  signalR.removeEventListener("changed", reactToChange);
  if (activityWatcherInterval) window.clearInterval(activityWatcherInterval);
});
//#endregion
</script>

<style scoped lang="scss">
.m-tooltip__assistant {
  padding: 5px;
  margin-bottom: 5px;
  border: 1px solid color($pri, 0.5);
  background-color: color($pri-action-light, 0.2);
}

.m-articles--list {
  .m-container__wrapper {
    padding-left: $spacing-1;
    padding-right: $spacing-1;
  }

  :deep(.m-filter__pane .m-toolbox--open) {
    background-color: color($white, 0.05);
  }
}
.m-container {
  width: 100%;
  height: calc(100vh - 122px);

  &__wrapper--empty,
  .m-articles__wrapper--empty {
    height: 100%;
  }

  :deep(.m-loading) {
    min-height: $column-2;
  }

  &--load {
    overflow-y: hidden !important;
  }

  &__header--load {
    opacity: 0.6;
    filter: blur($spacing-1);
  }

  .m-articles,
  .skeleton-loader__box {
    @include flex(flex-start, flex-start, column);

    :deep(.m-loading) {
      top: $spacing-12;
    }
  }

  .m-articles__empty {
    width: 100%;
    height: 100%;
    @include flex(center, center, column);
  }

  .m-articles__allread {
    position: relative;
    right: $sidenav-width;
  }
}
</style>
