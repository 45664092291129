<template>
  <div
    id="m_header_workspace"
    class="m-header-workspace"
    @mouseleave="closeDropdown"
  >
    <label :id="`m_header_workspace_label`" class="visually-hidden">
      {{ t("navigation.header.workspaces_switch") }}
    </label>
    <h4
      ref="workspaceRef"
      id="m_header_workspace"
      :data-tooltip-content="t('navigation.header.workspaces_switch')"
      data-tooltip-position="bottom-center"
      tabindex="0"
      aria-controls="m_header_workspace_dropdown"
      :aria-expanded="openDropdown"
      aria-haspopup="listbox"
      aria-labelledby="m_header_workspace_label"
      :aria-activedescendant="ariaActivedescendant"
      role="combobox"
      class="py-1 px-2 type--small m-clickable"
      :class="{ '--a11y': appStore.a11y }"
      @click="toggleDropdown"
      @keyup.up="openDropdown = true"
      @keyup.down.exact="openDropdown = true"
      @keyup.alt.down="openDropdown = true"
      @keyup.home="openDropdown = true"
      @keyup.end="openDropdown = true"
      @keyup.space="openDropdown = true"
      @keyup.enter="openDropdown = true"
      @keyup.esc="closeDropdown"
    >
      {{ currentWorkspace?.name }}
    </h4>
    <m-dropdown
      v-if="options.length > 1"
      id="m_header_workspace_dropdown"
      labelledBy="m_header_workspace"
      v-model:visible="openDropdown"
      :options="options"
      :selected="currentWorkspace.id"
      size="small"
      :query="query"
      :keyup="keyup"
      :keydown="keydown"
      v-slot="slotProps"
      class="m-header-workspace__dropdown"
      @update:selected="workspaceClick"
      @mouseenter="keepDropdownOpen"
    >
      {{ slotProps.option.label }}
      <h6 v-if="totalNumberOfOrgs > 1" class="type--xsmall">
        {{
          t("navigation.header.workspace_organization", {
            name: slotProps.option?.organizationName,
          })
        }}
      </h6>
    </m-dropdown>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute } from "vue-router";

import useDropdown from "@hooks/useDropdown";
import MDropdown from "@components/MDropdown.vue";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({ closeOthers: { type: String } });

const { t } = useI18n();
const router = useRouter();
const route = useRoute();
const appStore = useAppStore();
const workspacesStore = useWorkspacesStore();

const { openDropdown, toggleDropdown, keepDropdownOpen, closeDropdown } =
  useDropdown();

watch(
  () => props.closeOthers,
  (val) => {
    if (val != "workspaces") closeDropdown();
  }
);

const workspaceRef = ref(null);

const currentWorkspace = computed(() => {
  return workspacesStore.currentWorkspaceConfig;
});

const ariaActivedescendant = computed(() => {
  if (openDropdown.value) {
    return `workspace_option_${currentWorkspace.value.value}`;
  } else return "";
});

const options = computed(() => {
  const activeWorkspaces = workspacesStore.user?.filter((x) => x.active);
  return activeWorkspaces
    .map((x) => ({
      value: x.id,
      label: x.name,
      organizationName: x.organization?.name,
      disabled: !x.isOnline,
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
});

/** @description Computed number of organizations between all of the user workspaces */
const totalNumberOfOrgs = computed(
  () => new Set(options.value.map((x) => x.organizationName)).size
);

const workspaceClick = async (val) => {
  router.push({
    name: "switch-workspace",
    params: { from: route.name, workspaceId: val.value },
  });
};

const query = ref("");
const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;

  if (evt.code.startsWith("Key")) {
    if (!openDropdown.value) openDropdown.value = true;
    query.value += evt.key;
    setTimeout(() => {
      query.value = "";
    }, 500);
  }
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  workspaceRef.value.addEventListener("keyup", keyUpMovement);
  workspaceRef.value.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  workspaceRef.value.removeEventListener("keyup", keyUpMovement);
  workspaceRef.value.removeEventListener("keydown", keyDownMovement);
});
</script>

<style scoped lang="scss">
.m-header-workspace {
  position: relative;

  .m-clickable:hover {
    color: color($pri-action);
  }

  &__thumbnail,
  &__thumbnail * {
    cursor: pointer;
  }

  &__dropdown {
    min-width: 100%;
    top: 40px;
    transform: translate($spacing-0, -($spacing-2));
    z-index: $z-popover;

    :deep(.m-dropdown__option) {
      @include flex(flex-start, flex-start, column);
    }
  }
}
</style>
