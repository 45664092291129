const storeAxis = { x: [], y: [] };

const truncateLabel = (value, width) => {
  const label = value?.replace(" ", "=");
  let max;
  if (width == 1) max = 14;
  else if (width == 2) max = 26;

  if (max && label?.length > max) {
    return label?.substr(0, max - 3)?.replace("=", " ") + "...";
  } else return value;
};

/* We cant store the mapchart on the database due to his size
  So we store only the metadata generated by the API and here we request the mapchart
*/
const getMapChart = async (api, params) => {
  if (!params) return [];
  // Request the chartAPI directly or load here the topojson
  // TODO: Set the dinamic api base url
  const { data } = await api.analytics.getMapChartTopoJson(params);
  return { data: data.config }; //This might seem weird but its to keep compatibilty with the structure of other charts
};

const getChartScales = (data, type, width) => {
  switch (type) {
    case "bubble":
      data.options.scales.x.ticks.callback = function (val) {
        return storeAxis.x[this.getLabelForValue(val)];
      };
      data.options.scales.y.ticks.callback = function (val) {
        const label = storeAxis.y[this.getLabelForValue(val)];
        return truncateLabel(label, width);
      };
      break;

    case "bar":
    case "line":
      data.options.scales.y.ticks.callback = function (val) {
        const label = this.getLabelForValue(val);
        return truncateLabel(label, width);
      };
      break;
    default:
      break;
  }
};

const getChartTooltipCallbacks = (type) => {
  switch (type) {
    case "bubble":
      return {
        title: (context) => {
          return context[0].raw.label;
        },
        label: (context) => {
          return context.raw.date;
        },
        footer: (context) => {
          return context[0].raw.count;
        },
      };
    default:
      return;
  }
};

const getChartLegend = (data, type, width) => {
  switch (type) {
    case "pie":
    case "doughnut":
    case "polarArea":
      data.options.plugins.legend.generateLabels = {
        generateLabels: (chart) => {
          return chart.data.labels.map((l, i) => {
            const color = chart.data.datasets[0].backgroundColor[i];
            return {
              text: truncateLabel(l, width),
              fillStyle: color,
              strokeStyle: color,
            };
          });
        },
      };
      break;
    default:
      break;
  }
};
const getChartConfig = (element, width, height, config) => {
  if (element) {
    if (element.type === "bubble") {
      storeAxis.x = config?.dates;
      storeAxis.y = config?.names;
    }

    config.options.plugins.tooltip.callbacks = getChartTooltipCallbacks(
      element.type
    );
    getChartLegend(config, element.type, width);
    getChartScales(config, element.type, width, height);

    return config;
  } else return null;
};

const resizeMapChart = async (
  ctx,
  config,
  width,
  height,
  map,
  isWorldMap = false,
  hasChildren
) => {
  const isVertical = !!(width % height); //this needs to be deteted because some maps are cutted while in vertical colluns (need to rpevent that)
  const maxWidth = {
    0: 0,
    1: 120,
    2: 240,
    3: 360,
    4: 480,
  };
  let padding = { top: 10, left: 10, right: 10, bottom: 10 };
  if (!isWorldMap) {
    if (map == "ES") {
      if (isVertical) {
        padding.left = 115 * width;
        padding.right = 30 * width;
        padding.bottom = 20 * height;
      } else {
        padding.bottom = hasChildren
          ? height > 1
            ? height * 40
            : 10
          : 30 * width;
      }
    }

    switch (map) {
      case "PT":
        padding = {
          top: 10,
          left: height === 1 ? maxWidth[0] : maxWidth[width],
          right: 10,
          bottom: 10,
        };
        break;
      case "ES":
        break;
    }
  } else {
    padding.right = 20;
  }

  config.options.scales.xy ??= {};
  config.options.scales.xy.padding = padding;
};

export { getChartConfig, getMapChart, resizeMapChart };
