<template>
  <img
    v-if="computedSrc && !unableToGetImage"
    :id="`m_image_${id}`"
    :data-tooltip-content="label"
    data-tooltip-position="dynamic"
    :src="computedSrc"
    :alt="alt"
    :height="height"
    :width="width"
    class="m-image"
    @error="imageError"
  />
  <img
    v-else
    :id="`m_image_${id}_no_image`"
    :src="fallback ?? base_image"
    :alt="t('general.errors.noImage')"
    :height="height"
    :width="width"
    class="m-image m-image__empty"
  />
</template>

<script setup>
/*
 * Monitio Image component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/720175113/Image
 */

import { ref, computed, watch } from "vue";
import { useI18n } from "vue-i18n";
import base_image from "@assets/images/monitio_empty.png";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const workspacesStore = useWorkspacesStore();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);

const props = defineProps({
  id: {
    type: String,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  height: { type: String },
  width: { type: String },
  src: { type: String },
  fallback: { type: String },
  alt: { type: String },
  tooltip: { type: [String, Object] },
});

const emit = defineEmits(["error"]);

const computedSrc = computed(() => {
  if (props.src?.includes("blob-store://")) {
    const n = props.src;
    const bucketName = props.src.includes("account")
      ? "priberam"
      : workspaceConfig.value.workspaceBucketName;
    return n.replace(
      "blob-store://",
      `${window._env_.VUE_APP_apiUrl}/v1/workspaces/picture/${bucketName}/`
    );
  }
  return props.src;
});

watch(
  () => computedSrc.value,
  (src, oldSrc) => {
    if (oldSrc != src) unableToGetImage.value = false;
  }
);

// Props validation
watch(
  () => props,
  () => {
    // tooltip validator
    if (typeof props.tooltip == "object") {
      if (
        props.tooltip?.content === undefined ||
        props.tooltip?.content === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      }
      if (
        props.tooltip?.position === undefined ||
        props.tooltip?.position === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "position" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.'
        );
      } else if (
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(props.tooltip?.position)
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "tooltip": expected attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${props.tooltip?.position}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/Selector for instructions on how to use the MSelector component.`
        );
      }
    }
  }
);

const { t } = useI18n();

const unableToGetImage = ref(false);

const label = computed(() => {
  if (props.tooltip?.content) {
    return props.tooltip?.content;
  } else if (props.tooltip) {
    return props.tooltip;
  } else return null;
});

const imageError = () => {
  unableToGetImage.value = true;
  emit("error");
};
</script>

<style scoped lang="scss">
.m-image {
  width: 100%;
  @include flex(center, center, row);

  &__error {
    width: 100%;
    @include flex(center, center, row);
    background-color: color($sec-100);

    :deep(svg) {
      fill: color($sec-400);
      color: color($sec-400);
      stroke: color($sec-400);
    }
  }

  &__empty {
    background-color: rgb(247, 248, 251); // color($pri-light, 0.2)
  }
}
</style>
