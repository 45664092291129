<template>
  <div
    id="capture_report"
    class="m-report"
    :class="{ 'm-report--editable': editor }"
  >
    <h6 v-if="modules.length == 0" class="m-report__empty type--empty">
      {{ t("views.reports.emptyReport") }}
    </h6>
    <draggable
      v-if="editor"
      v-model="modules"
      group="modules"
      item-key="id"
      ref="draggableContainer"
      class="m-report__draggable"
      data-tour="tour_m_report_draggable"
      @change="updateReport"
      :options="{ scroll: false }"
    >
      <template #item="{ element }">
        <component
          :is="element.module"
          :id="element.id"
          :settings="element.settings"
          :previewViewId="previewViewId"
          :preferredLanguage="report.template.preferredLanguage"
          @update:settings="updateSettings"
          class="m-report__module"
          :class="[
            getTypeClass(element),
            {
              'm-report__module--selected': selectedElement == element.id,
            },
          ]"
          :isSelected="selectedElement == element.id"
          @select-element="selectElement"
          @remove-element="removeElement"
          @update-module-data="(data) => updateModuleData(data, element.id)"
        />
      </template>
    </draggable>
    <div class="m-report__draggable" v-else>
      <component
        v-for="element in modules"
        :key="element.id"
        :is="element.module"
        :id="element.id"
        :settings="element.settings"
        :previewViewId="previewViewId"
        :preferredLanguage="report.template.preferredLanguage"
        @update:settings="updateSettings"
        class="m-report__module"
        :class="[
          getTypeClass(element),
          {
            'm-report__module--selected': selectedElement == element.id,
          },
        ]"
        :isSelected="selectedElement == element.id"
        @select-element="selectElement"
        @remove-element="removeElement"
        @update-module-data="(data) => updateModuleData(data, element.id)"
      />
    </div>
    <div ref="reportBottomRef"></div>
  </div>
</template>

<script setup>
import { useReportsStore } from "@root/store/modules/reports";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useViewFilters } from "@hooks/useViewFilters";
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import draggable from "vuedraggable";

const props = defineProps({
  report: { type: Object },
});

const emit = defineEmits([
  "update:report",
  "select-element",
  "remove-element",
  "update-module-data",
]);

const { t } = useI18n();
const route = useRoute();
const router = useRouter();
const reportsStore = useReportsStore();
const workspacesStore = useWorkspacesStore();
const { viewId: queryFilterViewId } = useViewFilters(router, route);

const editor = computed(() => route.name.toLowerCase().includes("builder"));
const selectedElement = ref(undefined);

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);

const previewViewId = computed(() => queryFilterViewId.value ?? viewId.value);

const draggableContainer = ref(null);
const modules = computed(() => props.report.template.modules || []);

onMounted(() => {
  // modules.value = props.report.template.modules || [];
});

const getTypeClass = (val) => {
  const type = val.module?.substr(2, val.module.length)?.toLowerCase();
  return `m-report__module--${type}`;
};

const updateSettings = (val, id) => {
  const r = { ...props.report };
  r.template?.modules?.find((f) => {
    if (f.id == id) f.settings = val;
  });

  emit("update:report", r);

  if (val?.docs?.equals(props.template.modules?.find((f) => f.id == id))) {
    emit("refresh-module");
  }
};

const reportBottomRef = ref(null);

const selectElement = (val) => {
  selectedElement.value = val;
  emit("select-element", val);
};

const removeElement = (val) => {
  emit("remove-element", val);
};

const updateModuleData = (data, id) => {
  emit("update-module-data", data, id);
};

const updateReport = (val) => {
  emit("update:report", val);
};
const scrollPosition = ref(null);
onMounted(() => {
  draggableContainer.value?.$el?.addEventListener("scroll", () => {
    scrollPosition.value = draggableContainer.value.$el.scrollTop;
  });
});

watch(
  () => modules.value,
  (val, oldVal) => {
    if (val?.length > oldVal?.length) {
      let newModule = null;
      val.forEach((fe) => {
        if (!oldVal.find((f) => f.id == fe.id)) {
          newModule = fe;
        }
      });
      if (newModule) {
        selectElement(newModule.id);
        nextTick(() => {
          const isScrolledToBottom =
            scrollPosition.value !== null &&
            draggableContainer.value.$el.scrollHeight -
              draggableContainer.value.$el.clientHeight -
              scrollPosition.value <=
              1;

          if (isScrolledToBottom) {
            reportBottomRef.value.scrollIntoView({
              block: "end",
              inline: "nearest",
            });
          }
        });
      }
    }
  },
  { immediate: true, deep: true }
);
</script>

<style lang="scss">
@import "@stylesheets/scss/components/reports";
</style>

<style scoped lang="scss">
.m-report {
  padding: $spacing-4;

  &--editable {
    padding-top: $spacing-4;

    .m-report__draggable {
      max-width: 720px;
    }

    .m-report__module {
      width: 100%;
      padding: $spacing-6;
      margin-bottom: $spacing-4;
      border: 1px solid color($pri-light);
      @include round-corners($spacing-1);
      @include flex(center, center, column);
      position: relative;
      cursor: pointer;

      :deep(*) {
        cursor: pointer;
      }

      &:hover,
      &--selected {
        border: 1px solid color($pri-action-inactive, 0.6);
        box-shadow: 0px 0px 8px color($pri-action-inactive, 0.3);
      }

      &--heading {
        padding: $spacing-6 $spacing-0 $spacing-0 $spacing-0;
      }

      &--html {
        min-height: $column;
        padding: $spacing-0;
      }
    }
  }

  &__empty {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &__draggable {
    width: 100%;
    max-width: calc($reading-width + $spacing-14 + $spacing-14);
    padding: $spacing-4;
    border: 1px solid color($pri-light);
    @include round-corners($spacing-1);
    @include flex(flex-start, center, column);
    gap: $spacing-3;
    flex-grow: 1;
    background-color: color($white);
  }

  &__module {
    width: 100%;
    padding: $spacing-2;
    border: 1px solid transparent;
    @include flex(center, center, column);

    &--articles,
    &--clusters {
      min-height: $column;
      position: relative;
    }
  }
}
</style>
