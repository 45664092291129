<template>
  <modal-regist name="m_modal_retake_tour">
    <div class="m-modal__body">
      <m-image :src="imageSrc" class="m-retake-tour__image" />
      <div class="m-retake-tour__header">
        <h2 class="mb-4">
          {{ title }}
        </h2>
        <h6>
          {{ content }}
        </h6>
      </div>
    </div>
    <div class="m-modal__footer">
      <m-button
        id="m_retake_tour_cancel"
        :label="t('tour.buttons.cancel')"
        type="text"
        variant="terciary"
        size="block"
        class="mt-3"
        @click="cancelClick"
      />
      <m-button
        id="m_retake_tour_continue"
        :label="t('tour.buttons.startTour')"
        type="contained"
        variant="primary"
        size="block"
        class="mt-3 ml-6"
        @click="continueClick"
      />
    </div>
  </modal-regist>
  <modal-regist name="m_dialog_retake_tour" />
</template>

<script setup>
import { computed } from "vue";

import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import {
  ModalRegist,
  useModal,
} from "@npm/@priberam/frontend-utils/dist/modal-factory";
import MImage from "@components/MImage.vue";
import MButton from "@components/MButton.vue";
import useTour from "@hooks/useTour/useTour.js";
import createViewImage from "@assets/illustrations/onboarding_04.svg";
import tour_report_intro_illustration from "@assets/illustrations/tours_report_intro.svg";
import { useUserStore } from "@root/store/modules/user";

const props = defineProps({
  retakeTour: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["skip-tour"]);
const { t } = useI18n();
const userStore = useUserStore();
const router = useRouter();
const { open, close, cancel } = useModal("m-modal");

//todo ctc: in future import reports + dashboards tours
const { createViewTour } = useTour();

const imageSrc = computed(() => {
  if (props.retakeTour == "createView") {
    return createViewImage;
  } else if (props.retakeTour == "createReport") {
    return tour_report_intro_illustration;
  } else return null;
});

const title = computed(() => {
  return t(`tour.retake.${props.retakeTour}.title`);
});

const content = computed(() => {
  return t(`tour.retake.${props.retakeTour}.content`);
});

const retakeTourCanceled = () => {
  // todo ctc: add report + dashboard tours here
  if (props.retakeTour == "createView") {
    userStore.completeTour("createView");
    emit("skip-tour");
    cancel();
  } else cancel();
};

const cancelClick = () => {
  retakeTourCanceled();
};

const continueClick = async () => {
  // todo ctc: add report + dashboard tours here
  if (props.retakeTour == "createView") {
    await router.push({ name: "explore" });
    close();
    createViewTour.start();
    userStore.startTour("createView");
  } else {
    close();
  }
};

const handleModal = () => {
  open("m_modal_retake_tour", {
    width: "360px",
    disabled: false,
    buttons: ["custom"],
  });
};

defineExpose({
  open: handleModal,
});
</script>

<style scoped lang="scss">
.m-retake-tour {
  &__image {
    width: 100%;
    transform: scale(1.1);
    pointer-events: none;
  }

  &__header {
    height: 150px;
  }

  &__footer {
    margin-top: $spacing-5;
    @include flex(flex-end, center, row);
  }
}
</style>
