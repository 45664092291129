<template>
  <div
    :id="`m_toolbox_${id}`"
    :data-test="`test_${id}`"
    tabindex="0"
    :data-tooltip-content="label"
    data-tooltip-position="dynamic"
    role="button"
    class="m-icon-toolbox m-icon"
    :class="[
      `m-icon-toolbox--${property}`,
      { 'm-icon-toolbox--disabled': disabled },
    ]"
    @click="$emit('click', $event)"
    @keydown.enter="$emit('click', $event)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      :height="setSvgSize"
      :viewBox="setViewBox"
      role="presentation"
      class="m-icon__content m-icon--primary"
      :class="selected ? 'm-icon--selected' : 'm-icon--inactive'"
    >
      <g v-html="content"></g>
    </svg>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";

import icon_list_toolbox from "@assets/icons/icon_list_toolbox";
import { snakeCase } from "lodash-es";

const props = defineProps({
  property: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
  selected: {
    type: Boolean,
  },
  label: {
    type: String,
  },
  disabled: {
    type: Boolean,
  },
});

const emit = defineEmits(["click"]);

const list = ref(icon_list_toolbox);

const id = ref(`${snakeCase(props.property)}_${snakeCase(props.icon)}`);

const content = computed(() => {
  return list.value[props.property][props.icon];
});

const setSvgSize = computed(() => {
  if (props.property == "article-preview") return 92;
  if (props.property == "element") return 28;
  else return 60;
});

const setViewBox = computed(() => {
  if (props.property == "article-preview") return "0 0 232 92";
  if (props.property == "element") return "0 0 31 31";
  else return "0 0 60 60";
});
</script>

<style scoped lang="scss">
.m-icon {
  fill: color($pri-action-inactive);
  @include round-corners($spacing-1);

  :deep(svg) {
    stroke-width: 0;
  }

  :deep(g) {
    transform: scale(1) !important;
  }

  :deep(.light) {
    fill: color($pri-light);
  }

  :deep(.medium) {
    fill: color($pri-light);
  }

  :deep(.dark) {
    fill: color($pri-action-inactive);
  }

  &-toolbox--disabled {
    cursor: default;
    * {
      cursor: default;
    }
  }

  &:not(.m-icon-toolbox--element):not(.m-icon-toolbox--disabled) {
    &:hover {
      border-color: color($pri-action, 0.1);
      background-color: color($pri-action, 0.05);

      .m-icon {
        @include opacity-active;
      }
    }

    &:focus,
    &:active {
      border: 1px solid color($pri-action, 0.15);
      background-color: color($pri-action, 0.1);
    }
  }

  &:not(.m-icon-toolbox--element):not(.m-icon-toolbox--disabled):hover,
  &--selected {
    fill: color($pri-action);

    :deep(.light) {
      fill: color($pri-light);
    }

    :deep(.medium) {
      fill: color($pri-light);
    }

    :deep(.dark) {
      fill: color($pri-action);
    }
  }
}
</style>
@root/utils/
