/**
 * This use ensures that libraries are only used once, no matter how many time you call the use in components!
 */
let isFacebookWidgetMounted = false;
export const useFacebookWidget = () => {
  if (!isFacebookWidgetMounted) {
    const script = document.createElement("script");
    script.setAttribute(
      "src",
      "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v20.0"
    );
    document.head.appendChild(script);

    isFacebookWidgetMounted = true;
  }
  // This interval is needed because this widget only scans the document after initialization. We need to manually tell the widget to rescan
  const interval = setInterval(() => {
    const isRendered = document
      .getElementById("fb-post")
      ?.getElementsByTagName("iframe");

    if (window.FB?.XFBML?.parse) window.FB.XFBML.parse();

    if (!isRendered) return;
    else {
      window.clearInterval(interval);
      return;
    }
  }, 100);
};

let isTwitterWidgetMounted = false;
export const useTwitterWidget = async (url) => {
  if (!isTwitterWidgetMounted) {
    const script = document.createElement("script");
    script.setAttribute("src", "https://platform.twitter.com/widgets.js");
    document.head.appendChild(script);
    isTwitterWidgetMounted = true;
    //await new Promise((resolve) => setTimeout(resolve, 300));
  }

  // This interval is needed because this widget only scans the document after initialization. We need to manually tell the widget to rescan
  const interval = setInterval(() => {
    const isRendered = document.getElementsByClassName("tweet")?.[0];
    if (!isRendered) return;
    else {
      const id = url.split("?")[0].split("/").at(-1);
      window.twttr?.widgets.createTweet(id, isRendered)?.then(() => {
        isRendered.querySelector(".twitter-tweet")?.remove();
      });
      window.clearInterval(interval);
      return;
    }
  }, 100);
  return;
};
