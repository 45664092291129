<template>
  <div class="m-facet-tag" :class="`m-facet-tag__${id}`">
    <!-- <div
      class="m-facet-tag__mark"
      :class="`m-facet-tag__mark--${id} m-facet-tag__mark--${size}`"
      :style="markColor"
    ></div> -->
    <h4 class="m-facet-tag__label" :class="{ 'type--small': size == 'small' }">
      {{ computedLabel }}
    </h4>
  </div>
</template>

<script setup>
import { computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { stringToColor } from "@utils/utils";
import { snakeCase } from "lodash-es";

const props = defineProps({
  facet: {
    type: String,
    required: false,
  },
  label: {
    type: String,
    required: false,
  },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default" or "small" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/596246552/Facet+Tag for instructions on how to use the MFacetTag component.`
        );
      }
      return true;
    },
  },
});

const { t, te } = useI18n();

onMounted(() => {
  if (!props.facet && !props.label) {
    console.error(
      `Invalid props: either "facet" or "label" need to be defined. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/596246552/Facet+Tag for instructions on how to use the MFacetTag component.`
    );
  }
});

const computedLabel = computed(() => {
  if (props.label && te(`general.facets.${props.label}`)) {
    return props.label;
  } else if (t(`general.facets.${props.facet}`)?.includes(".")) {
    return props.label ?? props.facet;
  } else return t(`general.facets.${props.facet}`);
});

const id = computed(() => snakeCase(props.label ?? props.facet));

const markColor = computed(() => {
  switch (props.facet) {
    case "sourcelanguage":
    case "iptc":
    case "IPTC":
    case "people":
    case "place":
    case "feed-country":
    case "organization":
    case "feed":
    case "type":
    case "district-province":
    case "/customMetadata/sourceItemTypedScopes/$ISO_3166_alpha-2_country_code":
    case "/customMetadata/sourceItemTypedScopes/Places":
    case "/customMetadata/sourceItemTypedScopes/Type":
    case "/customMetadata/sourceItemTypedScopes/$alpha-2_continent_code":
      return;
    default:
      return `background-color: ${stringToColor(id.value)};`;
  }
});
</script>

<style scoped lang="scss">
.m-facet-tag {
  padding: math.div($spacing-1, 2) $spacing-0;
  // border: 1px solid color($sec-200);
  @include round-corners($spacing-1);
  display: inline-flex;
  align-items: center;

  &__mark {
    border-radius: 2px;

    &--default {
      width: 6px;
      height: 20px;
      margin-right: $spacing-1;
    }

    &--small {
      width: 4px;
      height: 16px;
      margin-right: math.div($spacing-1, 2);
    }

    &--sourcelanguage {
      background-color: facet-color($source-language);
    }

    &--iptc,
    &--IPTC {
      background-color: facet-color($iptc);
    }

    &--people {
      background-color: facet-color($people);
    }

    &--places,
    &--typedscopes-places,
    &--typedscopes--alpha-2-continent-code {
      background-color: facet-color($places);
    }

    &--organization {
      background-color: facet-color($organization);
    }

    &--feed {
      background-color: facet-color($feed);
    }

    &--type,
    &--typedscopes-type {
      background-color: facet-color($type);
    }

    &--feed-country,
    &--typedscopes--iso-3166-alpha-2-country-code {
      background-color: facet-color($feed-country);
    }

    &--district-province {
      background-color: facet-color($district-province);
    }
  }

  &__label {
    white-space: nowrap;
  }
}
</style>
@root/utils/
