import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance, AxiosResponse } from "axios";

export default (axios: AxiosInstance) => ({
  getWorkspacesForAccount(): Promise<AxiosResponse<MonitioAPI.WorkspaceDTO[]>> {
    return axios.get(`/v1/workspaces/account`);
  },
  getWorkspacesForOrganization() {
    return axios.get(`/v1/workspaces/organization`);
  },
  switchWorkspace(workspaceId: string) {
    return axios.put(`/v1/workspaces/${workspaceId}/switch`);
  },
  uploadPicture(picture: string | ArrayBuffer | null) {
    return axios.post(`/v1/workspaces/picture`, picture, {
      headers: {
        "Content-Type": "application/octet-stream",
      },
    });
  },
  getWorkspaceLanguages() {
    return axios.get("/v1/workspaces/languages");
  },
  getWorkspaceDateRange(id: string) {
    return axios.get("/v1/workspaces/workspace-date-range", { params: { id } });
  },
});
