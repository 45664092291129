<template>
  <div class="m-dashboard m-wrapper" :class="`m-wrapper--${viewsStore.nav}`">
    <div id="scrollable" class="m-container">
      <div
        v-if="currentRoute.name == 'dashboards-empty'"
        class="m-dashboard__not-found"
      >
        <h2 class="type--small type--empty">
          {{ t("dashboards.empty_title") }}
        </h2>
        <h6
          v-html="t('dashboards.empty_description')"
          class="type--small type--empty type--center"
        ></h6>
        <m-button
          id="m_dashboard_create"
          :label="t('dashboards.empty_action')"
          type="contained"
          variant="primary"
          size="small"
          class="mt-3"
          @click="push({ name: 'dashboards' })"
        />
      </div>
      <div
        v-else-if="dashboard"
        class="m-container__wrapper"
        :class="`m-container__wrapper--${visualization}`"
      >
        <m-dashboard
          :key="forceUpdate"
          :type="visualization"
          :dashboard="dashboard"
          :selectedElementId="selectedElementId"
          @select-element="selectElement"
          @remove-element="removeElement"
          @add-element="addElement"
          @update-layout="updateLayout"
          @add-child-element="addChildElement"
          @resized-element="resizedElement"
          @chart-data-changed="elementChartDataChanged"
          @select-chart-item="(item, id) => selectChartItem(item, id)"
          @unselect-chart-item="(item, id) => unselectChartItem(item, id)"
          @reset-advanced-filters="(id) => resetAdvancedFilters(id)"
        />
        <m-dashboard-toolbox
          v-if="visualization == 'editor'"
          :dashboard="dashboard"
          :element="selectedElement?.element"
          :hasChanges="!compareDashboards(unchangedDashboard, dashboard)"
          @cancel="cancel"
          @save="save"
          @change="change"
        />
        <m-filter
          v-else
          id="articles_page_filter"
          type="dashboard"
          :scoped="false"
          :modelValue="viewFilters"
          :placeholder="$t('components.search.placeholder_searchForAnything')"
          :dynamicSearch="false"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, watch, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter, useRoute, onBeforeRouteLeave } from "vue-router";
import Guid from "@utils/guid";
import useDashboards from "@hooks/useDashboards";
import structuredClone from "@utils/structuredClone";
import { isEqual } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import MDashboard from "@components/dashboard/MDashboard.vue";
import MDashboardToolbox from "@components/dashboard/MDashboardToolbox.vue";
import MFilter from "@components/filter/MFilter.vue";
import MButton from "@components/MButton.vue";

const { t } = useI18n();
const viewsStore = useViewsStore();
const alertsStore = useAlertsStore();
const dashboardsStore = useDashboardsStore();
const route = useRoute();
const { currentRoute, push } = useRouter();
const { deepSearchChart } = useDashboards();
const viewId = computed(
  () => viewsStore.getAllViews.find((f) => f.isBaseView)?.id
);

const dashboardId = computed(() => route.params.dashboardId);
const dashboard = ref(null);

// ⚡⚡ Note that rceUpdate exists to force the dashboard to re-render. We need this when
// the user wants to cancel the progress and revert to old dashoard
const forceUpdate = ref(0);
const unchangedDashboard = ref(null);
const isCanceling = ref(false);
const isSaving = ref(false);

const view = computed(() => viewsStore.getViewById(viewId.value));
const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },
  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
  },
});

const visualization = computed(() => {
  if (route.path.toLowerCase().includes("builder")) return "editor";
  else return "preview";
});

const hasDashboards = computed(() => dashboardsStore?.getAllAndDrafts?.length);

watch(
  () => dashboardId.value,
  async (id) => {
    if (!id) return; //Id must be defined at all costsm if it isnt we might be changing route, so do nothing here
    if (!dashboardsStore.getById(id) && viewId.value) {
      push({
        name: "dashboard-not-found",
        params: { dashboardId: id, viewId: viewId.value },
      });
      return;
    }

    dashboard.value = dashboardsStore.getById(id);
    unchangedDashboard.value = structuredClone(dashboard.value);
    forceUpdate.value++;
  }
);

onBeforeRouteLeave(async (to, from, next) => {
  //If the user is only viewing a dashboard this checks are unecessary
  if (from.name == "dashboardBuilder") {
    await leavingDashboardCheck(next);
  } else next();
});

const leavingDashboardCheck = async (next) => {
  if (isSaving.value || route.path.includes("empty")) return next();
  let modalType = "";

  const isDraft = dashboardsStore.drafts.some(
    (f) => f.id == dashboard.value.id
  );

  const noChanges = compareDashboards(
    unchangedDashboard.value,
    dashboard.value
  );

  if (isDraft) modalType = "new";
  else {
    if (noChanges) return next();
    modalType = "unsavedChanges";
  }

  if (dashboard.value.elements?.length) {
    try {
      await alertsStore.add({
        type: "dialog",
        variant: "warning",
        heading: t(`general.alerts.dialog.dashboard.${modalType}_heading`),
        message: [
          t(`general.alerts.dialog.dashboard.${modalType}_message1`),
          t(`general.alerts.dialog.dashboard.${modalType}_message2`),
        ],
        resolve: {
          label: t("general.buttons.save"),
          action: () => {
            save(true);
            next();
          },
        },
        reject: {
          label: t("general.buttons.doNotSave"),
          action: () => {
            if (isDraft) dashboardsStore.deleteDraft(dashboard.value.id);
            else dashboard.value = unchangedDashboard.value;
            next();
          },
        },
        cancel: {
          label: t("general.buttons.back"),
          action: () => next(false),
        },
      });
    } catch (error) {
      console.error("error", error);
    }
  } else {
    try {
      await alertsStore.add({
        type: "dialog",
        variant: "error",
        heading: t("general.alerts.dialog.dashboard.empty_heading"),
        message: [
          t("general.alerts.dialog.dashboard.empty_message1"),
          t("general.alerts.dialog.dashboard.empty_message2"),
        ],
        resolve: {
          label: t("general.buttons.continue"),
          action: () => {
            if (isDraft) dashboardsStore.deleteDraft(dashboard.value.id);
            else dashboard.value = unchangedDashboard.value;
            next();
          },
        },
        cancel: {
          label: t("general.buttons.back"),
          action: () => next(false),
        },
      });
    } catch (error) {
      console.error("error", error);
    }
  }
};

/** Takes in two dashboard objects and compares them */
const compareDashboards = (d1, d2) => {
  const d1Copy = structuredClone(d1);
  const d2Copy = structuredClone(d2);

  const removeDataFromElementRecursively = (el) => {
    delete el.data;
    if (el.children) el.children.forEach(removeDataFromElementRecursively);
  };

  d1Copy.elements?.forEach(removeDataFromElementRecursively);
  d2Copy.elements?.forEach(removeDataFromElementRecursively);

  // Return if there is no changes in the configuration
  return isEqual(d1Copy, d2Copy);
};

onMounted(async () => {
  dashboard.value = await dashboardsStore.getById(dashboardId.value);

  // Wait for all the elements to have data 1st
  const interval = setInterval(() => {
    if (!dashboard.value?.elements.find((x) => x.data == undefined)) {
      unchangedDashboard.value = structuredClone(dashboard.value);
      clearInterval(interval);
    }
  }, 100);
});

const type = computed(() => {
  return currentRoute.value.meta.breadcrumbs[0] == "dashboards"
    ? "dashboard"
    : "view-dashboard";
});

const selectedElementId = ref(null);
const selectedElement = computed(() => {
  const indexesPath = deepSearchChart(
    dashboard.value?.elements,
    selectedElementId.value
  );
  let element;
  indexesPath.forEach((index, i) => {
    if (i == 0) element = dashboard.value?.elements?.[index];
    else element = element.children?.[index];
  });

  if (element) {
    return {
      element,
      indexesPath,
    };
  }
  return null;
});

const selectElement = (val) => {
  selectedElementId.value = val;
};

const resizedElement = (i, newH, newW) => {
  const element = dashboard.value.elements.find((x) => x.layout.i == i);
  if (!element) return;

  element.layout.h = newH;
  element.layout.w = newW;
};

const removeElement = (val) => {
  const clone = structuredClone(unref(dashboard));

  const indexesPath = deepSearchChart(dashboard.value?.elements, val);
  if (indexesPath.length == 1) {
    clone?.elements?.splice(indexesPath[0], 1);
  } else {
    let elements;
    for (let i = 0; i < indexesPath.length - 1; i++) {
      if (i == 0) elements = clone?.elements[indexesPath[i]].children;
      else elements = elements[indexesPath[i]].children;
    }
    elements.splice(indexesPath[indexesPath.length - 1], 1);
  }
  // Should be turn on if one day we want auto-save
  //dashboardsStore.update(clone);
  dashboard.value = clone;
};

const addElement = (val) => {
  const clone = structuredClone(unref(dashboard));
  if (clone.elements?.length > 0) {
    clone.elements.push(val);
  } else {
    clone.elements = [val];
  }
  // Should be turn on if one day we want auto-save
  //dashboardsStore.update(clone);
  dashboard.value = clone;
};

const elementChartDataChanged = (data, elementId, parentId) => {
  const indexesPath = deepSearchChart(dashboard.value?.elements, elementId);
  let element;
  indexesPath.forEach((index, i) => {
    if (i == 0) element = dashboard.value?.elements?.[index];
    else element = element.children?.[index];
  });

  element.data = data;
};

/**
 * @description Handles the user selection of an item of the chart (a property of a propertyType). Then transforms it in a array in the same format as other filter in Monitio
 * @param {{key: string, label: string}} item the selected property
 * @param {string} elementId the id of the element
 */
const selectChartItem = (item, elementId) => {
  const element = dashboard.value.elements.find((x) => x.id == elementId);
  /** @type {import("@/api").MonitioAPI.FrontendFiltersGroup} */
  let filters = element.advancedFilters;
  if (!filters)
    filters = {
      facets: [],
    };

  const facet = filters.facets.find((x) => x.value == element.filterBy);
  if (facet) {
    facet.query.push({ label: item.label, value: item.key });
    facet.query.push({ operator: "or" });
  } else {
    filters.facets.push({
      value: element.filterBy,
      query: [
        {
          label: item.label,
          value: item.key,
        },
        { operator: "or" },
      ],
    });
  }

  const elementIndex = dashboard.value.elements.findIndex(
    (x) => x.id == elementId
  );
  dashboard.value.elements[elementIndex].advancedFilters = filters;
};

/**
 * @param {{key: string, label: string}} item the selected property
 * @param {string} elementId the id of the element
 */
const unselectChartItem = (item, elementId) => {
  const element = dashboard.value.elements.find((x) => x.id == elementId);
  /** @type {import("@/api").MonitioAPI.FrontendFiltersGroup} */
  const filters = element.advancedFilters;
  const facet = filters.facets.find((x) => x.value == element.filterBy);

  const itemIdx = facet.query.findIndex((x) => x.value == item.key);
  if (itemIdx < 0) return;

  facet.query.splice(itemIdx, 2);
  if (facet.query.length == 0) {
    const idx = filters.facets.indexOf(facet);
    filters.facets.splice(idx, 1);
  }

  const elementIndex = dashboard.value.elements.findIndex(
    (x) => x.id == elementId
  );
  dashboard.value.elements[elementIndex].advancedFilters = filters;
};

const resetAdvancedFilters = (elementId) => {
  const elementIndex = dashboard.value.elements.findIndex(
    (x) => x.id == elementId
  );
  delete dashboard.value.elements[elementIndex].advancedFilters;
};

const updateLayout = (layoutRef) => {
  const layout = layoutRef.map((el) => ({
    i: el.i,
    x: el.x,
    y: el.y,
    static: el.static,
    w: el.w,
    h: el.h,
  }));

  for (let index = 0; index < layout.length; index++) {
    const elLayout = layout[index];

    const indexesPath = deepSearchChart(dashboard.value?.elements, elLayout.i);
    let element;
    indexesPath.forEach((index, i) => {
      if (i == 0) element = dashboard.value?.elements?.[index];
      else element = element.children?.[index];
    });

    if (
      element &&
      (element.layout.x != elLayout.x ||
        element.layout.y != elLayout.y ||
        element.layout.w != elLayout.w ||
        element.layout.h != elLayout.h ||
        element.layout.static != elLayout.static)
    ) {
      element.layout = elLayout;
    }
  }
};

const addChildElement = (elementId, config) => {
  const clone = structuredClone(unref(dashboard));

  const element = clone.elements.find((x) => x.id == elementId);
  if (!element) return;
  if (!element.children) element.children = [];
  element.children.push(config);
  dashboard.value = clone;
};

const cancel = () => {
  isCanceling.value = true;
  push({ name: "dashboardsTemplates" });
};

const save = async (isChangingRoute = null) => {
  isSaving.value = true;

  const clone = structuredClone(dashboard.value);
  clone.elements.forEach((element, i) => {
    // Get rid of the data, the backend doesnt need that.
    delete element.data;

    // The structuredClone function automaticly convert the luxonDatetimes to strings. So...do nothing
  });
  const result = await dashboardsStore.save(clone, viewId.value);

  if (result == null) {
    alert("dashboard couldnt be saved");
  } else {
    //Display message to user saying the reports were saved?
    unchangedDashboard.value = structuredClone(dashboard.value);
    if (!isChangingRoute) push({ name: "dashboardsTemplates" });
  }
};

const change = (type, property, val, i, layout) => {
  // Sometimes this function gets trigger with the wrong events, we need to catch them if do nothing
  if (!dashboard.value?.id) return;
  const clone = structuredClone(unref(dashboard));
  if (type == "dashboard") {
    if (clone[property] != val) {
      clone[property] = val;
      dashboard.value = clone;
    }
  } else if (type == "chart") {
    // We need to consider and find the chart of subcharts
    const indexesPath = selectedElement.value.indexesPath;
    let element;
    indexesPath.forEach((index, i) => {
      if (i == 0) element = clone?.elements?.[index];
      else element = element.children?.[index];
    });
    if (element[property] != val) {
      element[property] = val;
      dashboard.value = clone;
    }
  }
  // Should be turn on if one day we want auto-save
  //dashboardsStore.update(clone);
};

const createDashboard = async () => {
  //createDashboardModal.value.open();
  const dashboard = {
    name: "",
    details: {
      description: "",
    },
    elements: [],
  };
  const clone = structuredClone(dashboard);
  clone.id = Guid.NewGuid();
  clone.viewId = viewId.value;
  await dashboardsStore.createDraft(clone);
  await dashboardsStore.select(clone.id);
  dashboardsStore.select(clone.id);
  const dashboardName = dashboard.name;

  push({
    name: "dashboard-editor",
    params: { dashboardId: clone.id },
  });

  alertsStore.add({
    type: "toast",
    variant: "success",
    message: t("general.alerts.toast.createdDashboard", {
      name: dashboardName,
    }),
  });

  // TODO JSF: TOUR PENDING
  /*if (!userStore.checkCompletedTour("dashboard")) {
    createButtonClicked.value = true;
  }*/
};

const captureDashboard = () => {
  return new Promise((resolve, reject) => {
    const capture = document.getElementById("capture_dashboard");
  });
};
</script>

<style scoped lang="scss">
.m-dashboard {
  &:not(.m-wrapper) {
    width: 100%;
    max-width: 100% !important;
  }
  &.m-wrapper {
    padding-bottom: $spacing-0;
  }

  .m-container {
    width: 100%;
    height: 100%;
    padding: $spacing-0;
    margin: $spacing-0;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    &__wrapper {
      width: 100%;
      height: 100%;
      padding: $spacing-0;

      &--editor {
        display: grid;
        grid-template-columns: 1fr $toolbox-width;
        grid-template-rows: auto;
        grid-template-areas: "dashboard toolbox";
      }
    }
  }

  &__not-found {
    width: 100%;
    height: calc(100vh - $header-height);
    @include flex(center, center, column);
  }
}
</style>
