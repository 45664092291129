/** Basic color gradient to color the chart */
export const gradient = [
  [219, 0, 100],
  [219, 0, 98.04],
  [219, 4.76, 95.88],
  [219, 9.68, 93.92],
  [219, 9.52, 91.76],
  [219, 13.21, 89.61],
  [219, 14.29, 87.65],
  [219, 17.81, 85.69],
  [219, 20.48, 83.73],
  [219, 23.4, 81.57],
  [219, 25.71, 79.41],
  [219, 28.7, 77.45],
  [219, 31.2, 75.49],
  [219, 33.82, 73.33],
  [219, 36.05, 71.18],
  [219, 38.85, 69.22],
  [219, 41.32, 67.25],
  [219, 43.82, 65.1],
  [219, 46.03, 62.94],
  [219, 48.74, 60.98],
  [219, 51.2, 59.02],
  [219, 53.64, 56.86],
  [219, 56.52, 54.9],
  [219, 59.17, 52.94],
  [219, 61.75, 50.78],
  [219, 63.86, 48.82],
  [219, 66.39, 46.67],
  [219, 69.3, 44.71],
  [219, 71.43, 42.55],
  [219, 74.76, 40.39],
  [219, 76.65, 38.63],
  [219, 79.57, 36.47],
  [219, 81.82, 34.51],
  [219, 84.24, 32.35],
  [219, 87.01, 30.2],
  [219, 90.21, 28.04],
  [219, 92.48, 26.08],
  [219, 95.12, 24.12],
  [219, 98.21, 21.96],
];
