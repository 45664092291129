<template>
  <div class="m-toolbox" data-tour="tour_m_toolbox">
    <div class="m-toolbox__heading">
      <div class="m-toolbox__buttons">
        <m-button
          id="m_toolbox_cancel"
          :label="t('views.dashboards.toolbox.cancel')"
          type="text"
          variant="terciary"
          size="xsmall"
          class="mr-2"
          @click="cancelEdit"
        />
        <m-button
          id="m_toolbox_save"
          :label="t('views.dashboards.toolbox.save')"
          type="contained"
          variant="primary"
          size="xsmall"
          :disabled="!hasChanges || dashboard?.elements?.length == 0"
          :hint="displayHints && userStore.ongoingTour.currentStep == 9"
          @click="save"
        />
      </div>
      <div class="m-divider"></div>
    </div>
    <div class="m-toolbox__modules" ref="scroll">
      <div class="m-toolbox__module">
        <h6 class="m-toolbox__title">
          {{ t("views.dashboards.toolbox.dashboard") }}
        </h6>
        <div class="m-toolbox__tool">
          <m-input
            id="m_toolbox_dashboard_name"
            v-model="dashboardTitle"
            :label="t('views.dashboards.toolbox.name')"
            :placeholder="t('views.dashboards.toolbox.dashboardName')"
            size="small"
            :validation="dashboardTitleValidation"
          />
        </div>
        <div class="m-toolbox__tool">
          <m-selector
            id="m_toolbox_report_view"
            :tooltip="dashboardViewTooltip"
            type="multiple"
            v-model:selected="showedIn"
            :options="dashboardShowInOptions"
            :outsideLabel="t('views.dashboards.toolbox.showIn')"
            :placeholder="t('views.dashboards.toolbox.showIn_placeholder')"
            :position="['left', 'bottom']"
            size="small"
          />
        </div>
        <div class="m-divider"></div>
        <h6 class="m-toolbox__title mt-3">
          {{ t("views.dashboards.toolbox.testData") }}
        </h6>
        <div class="m-toolbox__tool">
          <m-selector
            id="m_toolbox_dashboard_view"
            :tooltip="dashboardViewTooltip"
            v-model:selected="dashboardView"
            :options="dashboardViewOptions"
            :outsideLabel="t('views.dashboards.toolbox.view')"
            :position="['left', 'bottom']"
            size="small"
          />
        </div>
        <div class="m-toolbox__tool">
          <m-simple-date-picker
            v-if="!dashboardPreviewDate.isRelative"
            v-model="dashboardPreviewDate"
            size="small"
            orientation="vertical"
            hideButton
          />
          <m-selector
            v-else
            id="m_toolbox_custom_date"
            :tooltip="elementCustomDateTooltip"
            :selected="dashboardPreviewDate.timeFrame"
            @update:selected="
              (val) =>
                (dashboardPreviewDate = {
                  isRelative: true,
                  timeFrame: val.value,
                })
            "
            :options="relativeDateOptions"
            :outsideLabel="t('views.dashboards.toolbox.elementDate')"
            :placeholder="t('views.dashboards.toolbox.elementSelectDate')"
            :position="['left', 'top']"
            size="small"
            disableSort
          />
          <!-- <m-selector
            v-else
            id="m_toolbox_custom_date"
            type="date"
            v-model:selected="elementCustomAbsoluteDate"
            :dateSpecs="elementCustomDateSpecs"
            :outsideLabel="$t('views.dashboards.toolbox.elementDate')"
            :placeholder="$t('views.dashboards.toolbox.elementSelectDate')"
            :position="['left', 'top']"
            size="small"
          /> -->
          <m-button
            id="switch"
            type="text"
            variant="primary"
            size="xsmall"
            :label="
              selectingAbsoluteDate
                ? t('views.dashboards.toolbox.switchToDatePresets')
                : t('views.dashboards.toolbox.switchToCustomDate')
            "
            class="mt-1"
            @click="
              () =>
                (dashboardPreviewDate = {
                  ...dashboardPreviewDate,
                  isRelative: !dashboardPreviewDate.isRelative,
                })
            "
          />
        </div>
        <div class="m-divider"></div>
      </div>
      <div
        v-if="openModule == 'card'"
        class="m-toolbox__module"
        data-tour="tour_m_dashboard_toolbox_module"
      >
        <h6 class="m-toolbox__title">
          {{ t("views.dashboards.toolbox.card") }}
        </h6>

        <div class="m-toolbox__tool">
          <m-input
            id="m_toolbox_element_title"
            v-model="elementTitle"
            :label="t('views.dashboards.toolbox.title')"
            :placeholder="t('views.dashboards.toolbox.elementTitle')"
            size="small"
          />
        </div>

        <div class="m-toolbox__tool">
          <label class="mb-1 type--small">
            {{ t("views.dashboards.toolbox.elementType") }}
          </label>
          <div class="m-toolbox__types">
            <div
              v-for="(type, i) in elementTypeOptions"
              :key="i"
              :id="`m_dashboard_toolbox_${type.value}`"
              :data-tooltip-content="
                t(`views.dashboards.toolbox.${type.value}_description`)
              "
              data-tooltip-position="dynamic"
              class="m-toolbox__option m-toolbox__type"
              :class="{
                'm-toolbox__option--selected': type.value == elementType,
              }"
              @click="changeElementType(type)"
              @mouseenter="hoverType = true"
              @mouseleave="hoverType = false"
            >
              <m-toolbox-icon
                property="element"
                :icon="type.value"
                variant="sidenav"
                status="active"
              />
              <component
                :is="type.value == element.type ? 'h4' : 'h5'"
                class="type--small"
              >
                {{ type.label }}
              </component>
            </div>
          </div>
        </div>

        <div class="m-toolbox__tool">
          <m-selector
            id="m_toolbox_filter"
            :tooltip="elementFilterTooltip"
            v-model:selected="elementFilter"
            :options="elementFilterOptions"
            :outsideLabel="t('views.dashboards.toolbox.elementFilter')"
            :placeholder="t('views.dashboards.toolbox.elementSelectFilter')"
            size="small"
            :position="['left', 'top']"
          />
        </div>
        <div v-if="element.type != 'choropleth'" class="m-toolbox__tool">
          <m-input
            id="m_toolbox_element_max_results"
            v-model="elementMaxResults"
            :label="t('views.dashboards.toolbox.elementMaxResults')"
            :placeholder="t('views.dashboards.toolbox.elementSelectMaxResults')"
            type="number"
            size="small"
            min="1"
            max="20"
            class="pb-2"
          />
        </div>
        <div
          v-if="
            (elementType == 'bar' ||
              elementType == 'line' ||
              elementType == 'overview') &&
            isOverview
          "
          class="m-toolbox__tool"
        >
          <m-selector
            id="m_toolbox_moving_average"
            v-model:selected="overviewShowMovingAverage"
            :options="overviewMovingAverageOptions"
            :outsideLabel="t('views.dashboards.toolbox.elementMovingAverage')"
            size="small"
          />
        </div>
        <div
          v-if="
            (elementType == 'bar' ||
              elementType == 'line' ||
              elementType == 'overview') &&
            isOverview
          "
          class="mb-6 m-toolbox__tool"
        >
          <m-input
            id="m_toolbox_element_average_days"
            v-model="overviewAverageDays"
            :label="t('views.dashboards.toolbox.elementAverageDays')"
            :placeholder="t('views.dashboards.toolbox.elementDescription')"
            size="small"
            type="number"
            :disabled="!overviewShowMovingAverage"
          />
        </div>
        <div v-if="!element.subchart" class="mb-3 m-toolbox__tool">
          <m-checkbox
            id="m_toolbox_element_custom_settings"
            v-model="elementCustomSettings"
            :label="t('views.dashboards.toolbox.elementCustomSettings')"
            size="small"
          />
        </div>
        <div
          v-if="!element.subchart && elementCustomSettings"
          class="m-toolbox__tool"
        >
          <m-selector
            id="m_toolbox_custom_view"
            :tooltip="elementCustomViewTooltip"
            v-model:selected="elementCustomView"
            :options="elementCustomViewOptions"
            :outsideLabel="t('views.dashboards.toolbox.elementView')"
            :placeholder="t('views.dashboards.toolbox.elementSelectView')"
            :position="['left', 'top']"
            size="small"
          />
        </div>
        <div
          v-if="!element.subchart && elementCustomSettings"
          class="mb-6 m-toolbox__tool"
        >
          <m-simple-date-picker
            v-if="selectingAbsoluteDate"
            :modelValue="selectedDate"
            size="small"
            orientation="vertical"
            @update:modelValue="selectCustomDate"
            hideButton
          />
          <m-selector
            v-else
            id="m_toolbox_custom_date"
            :tooltip="elementCustomDateTooltip"
            v-model:selected="elementCustomDate"
            :options="relativeDateOptions"
            :outsideLabel="t('views.dashboards.toolbox.elementDate')"
            :placeholder="t('views.dashboards.toolbox.elementSelectDate')"
            :position="['left', 'top']"
            size="small"
            disableSort
            class="mb-1"
          />
          <m-button
            id="switch"
            type="text"
            variant="primary"
            size="xsmall"
            :label="switchDateLabel()"
            @click="switchDateSelectMode"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, nextTick, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import { useRouter, useRoute } from "vue-router";
import TimeFrames from "@utils/enums/timeFrames";
import { useViewFilters } from "@hooks/useViewFilters";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

import MSelector from "@components/MSelector.vue";
import MInput from "@components/MInput.vue";
import MCheckbox from "@components/MCheckbox.vue";
import MToolboxIcon from "@root/components/MToolboxIcon.vue";
import MButton from "@components/MButton.vue";
import MSimpleDatePicker from "@components/MSimpleDatePicker.vue";
import { DateTime } from "luxon";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import DateTimeUtils from "@root/utils/dateTime";
import { ate } from "@root/utils/advancedTranslationCheck";

const props = defineProps({
  dashboard: { type: Object, required: true },
  element: { type: Object },
  hasChanges: { type: Boolean, required: true },
});

const emit = defineEmits(["cancel", "save", "change"]);

const { t, te } = useI18n();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const alertsStore = useAlertsStore();
const dashboardsStore = useDashboardsStore();
const workspacesStore = useWorkspacesStore();
const route = useRoute();
const router = useRouter();
const { open, close, cancel } = useModal("m-dialog");

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);
const workspaceId = workspacesStore.id;
const { queryObject, dateRestriction } = useViewFilters(router, route);

const scroll = ref(null);

const selectCustomDate = (val) => {
  if (val == props.element?.customDate || !selectingAbsoluteDate.value) return;
  console.debug("updating aboslute date");

  val.isRelative = false;
  val.start = DateTime.fromJSDate(val.start).startOf("day");
  val.end = DateTime.fromJSDate(val.end).endOf("day");

  const i = props.dashboard?.elements?.findIndex(
    (f) => f.id == props.element?.id
  );
  if (val != null || val != undefined)
    emit("change", "chart", "customDate", val, i);
};

const selectedDate = computed(() => {
  return (
    props.element?.customDate || {
      end: DateTime.now().endOf("day"),
      start: DateTime.now().minus({ weeks: 1 }).startOf("day"),
      isRelative: false,
    }
  );
});

const save = () => {
  if (dashboardTitleValidation.value) {
    alertsStore.add({
      type: "toast",
      variant: "error",
      message: t("general.alerts.toast.unableToSaveDashboardName", {
        name: dashboardTitle.value,
      }),
    });
  } else if (!dashboardTitle.value.trim().length) {
    alertsStore.add({
      type: "toast",
      variant: "error",
      message: t("general.alerts.toast.unableToSaveUntitled"),
    });
  } else if (props.dashboard?.elements?.length) {
    emit("save");
  }
};

const cancelEdit = () => {
  emit("cancel");
};

const openModule = ref("dashboard");
const hoverType = ref(false);

const dashboardTitle = computed({
  get() {
    return props.dashboard?.name;
  },
  set(val) {
    if (val != null) emit("change", "dashboard", "name", val);
  },
});

const dashboardTitleValidation = computed(() => {
  const all = dashboardsStore.getAll.filter((x) => x.id != props.dashboard.id);
  if (all?.find((f) => f.name == dashboardTitle.value)) {
    return {
      type: "error",
      label: t("general.errors.nameExists"),
    };
  } else return null;
});

const showedIn = computed({
  get() {
    const result = (props.dashboard?.showedIn ?? []).filter((x) =>
      dashboardShowInOptions.value.map((m) => m.id).includes(x)
    );
    if (props.dashboard?.showInAllViews) return ["all"].concat(result);
    return result;
  },
  set(val) {
    //val is the selected view
    const showInAllViews =
      Array.isArray(val) && !props.dashboard?.showInAllViews;
    let showedIn = [...(props.dashboard?.showedIn ?? [])];

    if (Array.isArray(val) && showInAllViews) {
      showedIn = dashboardShowInOptions.value.map((m) => m.id);
    }
    if (Array.isArray(val) && !showInAllViews) {
      showedIn = [];
    } else {
      if (showedIn.includes(val.id)) {
        //If  its already there remove it
        showedIn = showedIn.filter((x) => x != val.id);
      } else if (val.id) {
        showedIn.push(val.id);
      }
    }

    emit("change", "dashboard", "showInAllViews", showInAllViews);
    emit("change", "dashboard", "showedIn", showedIn);
  },
});

onMounted(() => {
  const isDraft = dashboardsStore.drafts.some(
    (x) => x.id == route.params.dashboardId
  );
  console.log(isDraft);
  if (route.name.toLowerCase().includes("builder") && isDraft) {
    //By default when creating a report all the views should be selected in showIn
    showedIn.value = dashboardShowInOptions.value;
  }
});

const dashboardDescription = computed({
  get() {
    return props.dashboard?.description;
  },
  set(val) {
    if (val != null) emit("change", "dashboard", "description", val);
  },
});

const dashboardsPage = computed(
  () => router.currentRoute.value.meta.breadcrumbs[0] == "dashboards"
);

const dashboardView = computed({
  get() {
    return props.dashboard?.viewId ?? viewId.value;
  },
  set(val) {
    if (val != null) {
      emit("change", "dashboard", "viewId", val.id);
    }
  },
});

const dashboardViewOptions = computed(() => {
  const base = viewsStore.sharedViews.filter((f) => f.isBaseView) || [];
  return (
    base.concat(viewsStore?.userViews)?.map((m) => ({
      ...m,
      label: m.name,
      value: m.id,
    })) || []
  );
});

const dashboardShowInOptions = computed(() => {
  const all = [
    {
      label: t("views.reports.toolbox.showInAllViews"),
      value: "all",
    },
  ];
  return all.concat(dashboardViewOptions.value);
});

const dashboardViewTooltip = computed(() => {
  return {
    content: t("views.dashboards.toolbox.selector_view"),
    position: "dynamic",
  };
});

const elementTitle = computed({
  get() {
    return props.element?.title;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val) emit("change", "chart", "title", val, i);
  },
});

const elementDescription = computed({
  get() {
    return props.element?.description;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val != null) emit("change", "chart", "description", val, i);
  },
});
const overviewAverageDays = computed({
  get() {
    return props.element?.averageDays;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val != null) emit("change", "chart", "averageDays", val, i);
  },
});
const overviewShowMovingAverage = computed({
  get() {
    return props.element?.showMovingAverage;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val != null) emit("change", "chart", "showMovingAverage", val.value, i);
  },
});

const overviewMovingAverageOptions = ref([
  {
    value: true,
    label: t("views.dashboards.toolbox.elementMovingAverage_show"),
  },
  {
    value: false,
    label: t("views.dashboards.toolbox.elementMovingAverage_hide"),
  },
]);

const elementType = computed(() => props.element?.type);
const changeElementType = (val) => {
  const i = props.dashboard?.elements?.findIndex(
    (f) => f.id == props.element?.id
  );
  cleanOverviewData(i);
  console.log(val);
  if (val.value == "choropleth") {
    emit("change", "chart", "maxResults", 999, i);
  } else emit("change", "chart", "maxResults", 5, i);
  if (val) emit("change", "chart", "type", val.value, i);
};

const isOverview = ref(false);

const updateOverview = (val) => {
  const i = props.dashboard?.elements?.findIndex(
    (f) => f.id == props.element?.id
  );
  emit("change", "chart", "averageDays", 7, i);
  emit("change", "chart", "showMovingAverage", true, i);
  emit("change", "chart", "isOverviewChart", val, i);
  isOverview.value = val;
};

const cleanOverviewData = (i) => {
  emit("change", "chart", "averageDays", 7, i);
  emit("change", "chart", "showMovingAverage", false, i);
  emit("change", "chart", "isOverviewChart", false, i);

  isOverview.value = false;
};

const elementFilter = computed({
  get() {
    return props.element?.filterBy;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val) {
      if (val.value === "overview") {
        isOverview.value = true;
        updateOverview(true);
      } else if (isOverview.value) {
        cleanOverviewData(i);
      }

      emit("change", "chart", "filterBy", val.value, i);
    }
  },
});

const elementFilterTooltip = computed(() => {
  return {
    content: t("views.dashboards.toolbox.selector_category"),
    position: "dynamic",
  };
});

const elementMaxResults = computed({
  get() {
    return props.element?.maxResults;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val) emit("change", "chart", "maxResults", val, i);
  },
});

const elementCustomSettings = computed({
  get() {
    return props.element?.customSettings || false;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val != null || val != undefined)
      emit("change", "chart", "customSettings", val, i);
  },
});

const elementCustomView = computed({
  get() {
    return props.element?.customViewId || viewId.value;
  },
  set(val) {
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val?.value != null || val?.value != undefined) {
      emit("change", "chart", "customViewId", val.value, i);
    }
  },
});

const elementCustomViewTooltip = computed(() => {
  return {
    content: t("views.dashboards.toolbox.selector_view"),
    position: "dynamic",
  };
});

const elementCustomDateSpecs = ref({
  mode: "range",
  timeframes: false,
  columns: 1,
});

const selectingAbsoluteDate = ref(false);

watch(
  () => props.element?.customDate,
  (val) => {
    if (val?.timeFrame) selectingAbsoluteDate.value = false;
    else selectingAbsoluteDate.value = true;
  },
  { immediate: true }
);

const switchDateLabel = () => {
  if (selectingAbsoluteDate.value) {
    return t("views.dashboards.toolbox.switchToDatePresets");
  } else return t("views.dashboards.toolbox.switchToCustomDate");
};

const switchDateSelectMode = () => {
  const i = props.dashboard?.elements?.findIndex(
    (f) => f.id == props.element?.id
  );
  if (!selectingAbsoluteDate.value) {
    emit(
      "change",
      "chart",
      "customDate",
      {
        end: DateTime.now().endOf("day"),
        start: DateTime.now().minus({ weeks: 1 }).startOf("day"),
        isRelative: false,
      },
      i
    );
  } else {
    emit(
      "change",
      "chart",
      "customDate",
      {
        isRelative: true,
        timeFrame: TimeFrames.LastMonth.value,
      },
      i
    );
  }
  selectingAbsoluteDate.value = !selectingAbsoluteDate.value;
};

/** @description A computed that mimics the header date selector and affects all the charts, unless a sepcific chart has its own date  */
const dashboardPreviewDate = computed({
  get() {
    return dateRestriction.value;
  },
  set(val) {
    if (val.isRelative) {
      if (!val.timeFrame) val.timeFrame = "last7days";
      const timeFrame = TimeFrames.TryParseFromString(val.timeFrame);
      if (timeFrame) dateRestriction.value = timeFrame;
    } else {
      if (val.timeFrame) {
        val = {
          ...val,
          ...DateTimeUtils.getTimeFrame(val.timeFrame, userStore.timeZone),
        };
      }
      dateRestriction.value = val;
    }
  },
});

/** @description A computed that gives the time to a specific chart  */
const elementCustomDate = computed({
  get() {
    if (!props.element?.customDate?.timeFrame)
      return TimeFrames.LastMonth.value;
    return TimeFrames.TryParseFromString(props.element?.customDate?.timeFrame)
      .value;
  },
  set(val) {
    if (val == props.element?.customDate || selectingAbsoluteDate.value) return;
    console.debug("updating relative date");
    const i = props.dashboard?.elements?.findIndex(
      (f) => f.id == props.element?.id
    );
    if (val != null || val != undefined)
      emit(
        "change",
        "chart",
        "customDate",
        {
          isRelative: true,
          timeFrame: val.value,
        },
        i
      );
  },
});

const elementCustomDateTooltip = computed(() => {
  return {
    content: t("views.dashboards.toolbox.selector_date"),
    position: "dynamic",
  };
});

const relativeDateOptions = ref(
  TimeFrames.ToArray()
    .filter((x) => {
      if (x.value == "sinceSnapshot") return false;
      return true;
    })
    .map((x) => ({
      value: x.value,
      label: t(`general.time.${x.value}`),
    }))
);

const workspaceSupportsMapChart = computed(() => {
  const workspace = workspacesStore.currentWorkspaceConfig;
  const userConfig = userStore.config;

  return userConfig.propertyTypeSettings.facets?.[workspace.id]
    .filter((x) => x.active)
    .some((x) => x.mapEnabled);
});

const elementTypeOptions = computed(() => {
  const items = [
    // { value: "count", label: t("views.dashboards.toolbox.count") },
    { value: "bar", label: t("views.dashboards.toolbox.bar") },
    { value: "line", label: t("views.dashboards.toolbox.line") },
    { value: "pie", label: t("views.dashboards.toolbox.pie") },
    { value: "doughnut", label: t("views.dashboards.toolbox.doughnut") },
    { value: "bubble", label: t("views.dashboards.toolbox.bubble") },
    /* { value: "overview", label: t("views.dashboards.toolbox.overview") } */
  ];

  if (workspaceSupportsMapChart.value)
    items.push({
      value: "choropleth",
      label: t("views.dashboards.toolbox.choropleth"),
    });

  return items;
});

const overviewChartTypeOptions = ref([
  { value: "bar", label: t("views.dashboards.toolbox.bar") },
  { value: "line", label: t("views.dashboards.toolbox.line") },
]);

const elementFilterOptions = computed(() => {
  const userConfig = userStore.config;
  let availablePropertyTypes = userConfig.propertyTypeSettings.facets?.[
    workspaceId
  ]
    .filter((x) => x.active)
    .sort((a, b) => a.rank - b.rank);
  if (elementType.value == "choropleth") {
    return availablePropertyTypes
      .filter((x) => x.mapEnabled)
      .map((x) => ({
        value: x.searchKey,
        label: ate(t, te, `general.facets.${x.uiKey}`) ?? x.uiKey,
      }));
  } else {
    availablePropertyTypes = availablePropertyTypes.map((x) => ({
      value: x.searchKey,
      label: ate(t, te, `general.facets.${x.uiKey}`) ?? x.uiKey,
    }));
    if (elementType.value === "bar" || elementType.value === "line")
      availablePropertyTypes.push({
        value: "overview",
        label: t("general.facets.count"),
      });
    return availablePropertyTypes;
  }
});

const elementCustomViewOptions = computed(() => {
  return viewsStore.getAllViews.map((m) => ({ value: m.id, label: m.name }));
  /* return viewsStore?.userViews.map((m) => ({
    ...m,
    label: m.name,
    value: m.id,
  })); */
});

watch(
  () => props.element,
  (val, oldVal) => {
    if (val) {
      if (val != oldVal) openModule.value = "card";
    } else {
      openModule.value = "dashboard";
    }
    if (val?.customSettings) {
      nextTick(() => {
        scroll.value.scrollTo({
          top: scroll.value.scrollHeight,
          behavior: "auto",
        });
      });
    }
  }
);

//tour hint
const displayHints = computed(() => {
  return !userStore.checkCompletedTour("dashboard");
});
</script>

<style scoped lang="scss">
:deep(.m-dropdown--date) {
  width: 100%;
}

:deep(.m-selector .m-dropdown--date) {
  min-width: calc(100% + $spacing-5);
  transform: translateX($spacing-4);
}
</style>
