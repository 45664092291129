import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance, AxiosResponse } from "axios";

export default (axios: AxiosInstance) => ({
  startConversation(
    data: MonitioAPI.StartConversationDTO
  ): Promise<AxiosResponse<MonitioAPI.LLMChatContext>> {
    return axios.post(`/v1/EnliChat/start-conversation`, data);
  },
  continueConversation(
    data: MonitioAPI.ContinueConversationDTO
  ): Promise<AxiosResponse<MonitioAPI.LLMChatContext>> {
    return axios.post(`/v1/EnliChat/continue-conversation`, data);
  },
});
