<template>
  <div class="m-headlines m-wrapper" :class="`m-wrapper--${viewsStore.nav}`">
    <div class="m-subheader">
      <m-filter
        id="headlines_filter"
        :scoped="false"
        :modelValue="viewFilters"
        :placeholder="$t('components.search.placeholder_searchForAnything')"
      />
    </div>

    <div
      ref="containerRef"
      id="scrollable"
      class="m-container"
      :class="{
        'm-container--scroll': isScrolled,
      }"
    >
      <div
        v-if="!requestCompleted || responseHasData"
        class="m-container__wrapper"
      >
        <div v-if="!requestCompleted">
          <m-headline-skeleton
            v-for="i in 2"
            :key="i"
            :id="`m_headlines_${i}`"
          />
        </div>
        <div v-else-if="responseHasData">
          <m-headline-item
            v-for="(clusterMetadata, i) in clusters"
            :key="i"
            :id="`m_headlines_${i}`"
            :clusterMetadata="clusterMetadata"
            :idx="i"
          />
        </div>
      </div>
      <div v-else class="m-container__empty">
        <h3 class="type--small type--nowrap type--empty">
          {{ t("views.tiles.noSearchResults") }}
        </h3>
        <h6 class="type--small type--nowrap type--empty">
          {{ t("views.tiles.tryChanging") }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, computed, onMounted } from "vue";
import { useRouter, useRoute, onBeforeRouteUpdate } from "vue-router";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";

import { useViewFilters } from "@hooks/useViewFilters";

import MFilter from "@components/filter/MFilter.vue";
import MHeadlineItem from "@components/MHeadlineItem.vue";
import MHeadlineSkeleton from "@components/skeletons/MHeadlineSkeleton.vue";
import { useViewsStore } from "@root/store/modules/views";
import { debounce, isEmpty } from "lodash-es";
import { useClustersStore } from "@root/store/modules/clusters";

const { t } = useI18n();
const viewsStore = useViewsStore();
const clustersStore = useClustersStore();
const { api } = useApi();
const route = useRoute();
const router = useRouter();
const { queryObject, aggregator, decodeQueryObject, dateRestriction } =
  useViewFilters(router, route);

const containerRef = ref(null);
const viewId = computed(() => route.params.viewId);

const view = computed(() => viewsStore.getViewById(viewId.value));
const viewFilters = computed({
  get() {
    return view.value?.details?.filters;
  },
  set(val) {
    const updatedView = { ...view.value };
    updatedView.details.filters = val;
  },
});

const date = ref(Date);

const initialRequestCompleted = ref(false);
const requestCompleted = ref(false);
const responseHasData = ref(true);

const apiTopClustersData = ref(null); // Populated in getTopClusters()

/**
 * @param {import("@root/types").Monitio.URLQueryObject} query
 */
const getTopClusters = async (query, filters = []) => {
  if (!isEmpty(query.contextualFilters))
    filters.push({ facets: query.contextualFilters });

  const response = await api.search.topClusters(
    unref(viewId),
    query.dateRestriction || dateRestriction.value,
    query.aggregator || aggregator.value,
    filters
  ); //start by seding view id to the backend today
  if (response.status != 200) return;
  requestCompleted.value = true;

  if (!response.data?.clusters?.length) {
    responseHasData.value = false;
    return {
      clusters: [],
      viewRestrictions: null,
    };
  } else {
    responseHasData.value = true;
    for (const cluster of response.data.clusters) {
      if (cluster.id && cluster.title)
        clustersStore.addClusterName(cluster.id, cluster.title);
    }
    return {
      clusters: response.data.clusters,
      viewRestrictions: response.data.viewRestrictions,
    };
  }
  //return (apiTopClustersData.value = []);
};
const clusters = computed(() => {
  if (apiTopClustersData.value?.clusters) {
    return apiTopClustersData.value?.clusters;
  } else return {};
});

const isScrolled = ref(false);
const runScroll = () => {
  isScrolled.value =
    containerRef.value?.getBoundingClientRect()?.top !=
    containerRef.value?.childNodes[0]?.getBoundingClientRect()?.y;
};

onMounted(async () => {
  containerRef.value.addEventListener("scroll", runScroll);
  const { clusters, viewRestrictions } = await getTopClusters(
    queryObject.value,
    queryObject.value.filters
  );

  apiTopClustersData.value = {
    clusters: clusters,
    viewRestrictions: viewRestrictions,
  };
});

onBeforeRouteUpdate(
  debounce((to, from) => {
    if (from.name != to.name) return true;
    /**
     * Handle the update of the props change
     * Ideally we wouls react to changes to the computed vars (viewId, queryObject) but that causes problems
     * betweens routes. So we need to use this hook, but can't use the computed vars because they only change AFTER
     * route change, and this hook runs BEFORE route change.
     **/
    const toQueryObject = decodeQueryObject(to.query.q);
    apiTopClustersData.value = null; // This is done so maybe a loading can take place? Else the user will view articles from another view for some milliseconds
    getTopClusters(toQueryObject, toQueryObject.filters).then(
      ({ clusters, viewRestrictions }) => {
        apiTopClustersData.value = {
          clusters: clusters,
          viewRestrictions: viewRestrictions,
        };
      }
    );
  }),
  window._env_.VUE_APP_DEFAULT_FILTER_DEBOUNCE
);
</script>

<style scoped lang="scss">
.m-headlines {
  display: flex;
  flex-direction: column;
}

.m-container {
  max-height: calc(100vh - 96px);

  &__wrapper {
    padding: $spacing-3 $spacing-2 $spacing-5;

    > div {
      &:last-child {
        padding-bottom: $spacing-0;
        margin-bottom: $spacing-3;
      }
    }
  }

  &__empty {
    height: 100%;
    @include flex(center, center, column);
  }
}

@include mq(desktopXS) {
  .m-container {
    height: calc(100vh - 96px);
  }
}
</style>
