import { DateTime } from "luxon";

const jwtName = "_jwt";
const refreshName = "_rft";
const impersonatorRefreshName = "_irft";
const workspaceIdName = "_wspid";
const redirectName = "_redirect";

class Storage {
  get jwtToken(): any {
    const item = localStorage.getItem(jwtName);
    if (item) return JSON.parse(item);
    return null;
  }
  set jwtToken(token: any) {
    /** 🔋 Manage and calculate token expiration times on the local users machine 🔋 */
    if (token)
      localStorage.setItem(
        jwtName,
        JSON.stringify({
          ...token,
          localExpires: this.calculateTokenLocalExpireTime(token).toISO(),
        })
      );
    else localStorage.removeItem(jwtName);
  }
  get refreshToken(): string | null {
    return localStorage.getItem(refreshName) || "";
  }
  set refreshToken(token) {
    if (token) localStorage.setItem(refreshName, token);
    else localStorage.removeItem(refreshName);
  }
  get impersonatorRefreshToken(): string | null {
    return sessionStorage.getItem(impersonatorRefreshName) || "";
  }
  set impersonatorRefreshToken(token) {
    if (token) sessionStorage.setItem(impersonatorRefreshName, token);
    else sessionStorage.removeItem(impersonatorRefreshName);
  }
  get workspaceId(): string | null {
    return localStorage.getItem(workspaceIdName) || "";
  }
  set workspaceId(id) {
    if (id) localStorage.setItem(workspaceIdName, id);
    else localStorage.removeItem(workspaceIdName);
  }

  get redirect(): string | null {
    return sessionStorage.getItem(redirectName) || "";
  }
  set redirect(url) {
    if (url) sessionStorage.setItem(redirectName, url);
    else sessionStorage.removeItem(redirectName);
  }

  reset() {
    this.refreshToken = null;
    this.jwtToken = null;
    this.workspaceId = null;
    this.impersonatorRefreshToken = null;
    this.redirect = null;
  }

  /**
   * Receives a token object with { created, exprires } properties
   * and return the local expiration time
   * This is to prevent users with broken ⏱ to affect expriration dates.
   * @param {Object} token
   * @returns {DateTime}
   */
  private calculateTokenLocalExpireTime(token: any) {
    /** 🔋 Manage and calculate token expiration times on the local users machine 🔋 */
    const remoteCreated = DateTime.fromISO(token.created);
    const remoteExpires = DateTime.fromISO(token.expires);
    const tokenLifetime = remoteExpires.diff(remoteCreated);
    const localExpires = DateTime.utc().plus(tokenLifetime);
    return localExpires;
  }
}

export default new Storage();
