<template>
  <SkeletonLoader
    type="text"
    :width="setWidth"
    :height="height"
    :lines="lines"
    :lineHeight="lineHeight"
    :class="`m-type-skeleton__${type}`"
  />
</template>
<script setup>
import { computed } from "vue";
import SkeletonLoader from "@priberam/skeleton-loader";

const props = defineProps({
  width: {
    type: [Number, String],
    required: false,
    default: "500px", // todo: define a default value more reasonable
  },
  type: {
    type: String,
    required: true,
    validator: function (value) {
      if (!["h1", "h2", "h3", "h4", "h5", "h6", "p", "a"].includes(value)) {
        console.error(
          `Invalid prop "type": expected a string with value "h1", "h2", "h3","h4","h5","h6", "p", "a" and got "${value}".`
        );
      }
      return true;
    },
  },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "small", "xsmall"].includes(size)) {
        console.error(
          `Invalid prop "size": expected "default", "small" or "xsmall" and got "${size}". `
        );
      }
      return true;
    },
  },
  lines: { type: Number, default: 1 },
});
// set HEIGHT
const height = computed(() => {
  if (props.size == "default") {
    switch (props.type) {
      case "h1":
        return "1.8125rem";
      case "h2":
        return "1.4375rem";

      case "h3":
        return "1.25rem";

      case "h4":
      case "h5":
      case "h6":
      case "p":
        return "1rem";

      default:
        return "";
    }
  } else if (props.size == "small") {
    switch (props.type) {
      case "h1":
        return "1.625rem";

      case "h2":
      case "h3":
        return "1.1875rem";

      case "h4":
      case "h5":
      case "h6":
      case "p":
      case "a":
        return "0.875rem";

      default:
        return "";
    }
  } else if (props.size == "xsmall") {
    switch (props.type) {
      case "h1":
        return "1.25rem";

      case "h2":
      case "h3":
        return "1.125rem";
      case "h4":
      case "h5":
      case "h6":
      case "p":
      case "a":
        return "0.75rem";
      default:
        return "";
    }
  } else {
    return "";
  }
});

//todo: define a default value more reasonable
const setWidth = computed(() => {
  if (props.width == undefined) {
    return "500px";
  } else {
    return props.width;
  }
});

const lineHeight = computed(() => {
  if (props.size == "default") {
    switch (props.type) {
      case "h1":
        return "2.25rem";

      case "h2":
      case "h3":
        return "2rem";

      case "h4":
      case "h5":
      case "h6":
      case "p":
        return "1.5rem";

      default:
        return "";
    }
  } else if (props.size == "small") {
    switch (props.type) {
      case "h1":
        return "1.625rem";

      case "h2":
      case "h3":
        return "1.1875rem";

      case "h4":
      case "h5":
      case "h6":
      case "p":
      case "a":
        return "1.375rem";

      default:
        return "1.25rem";
    }
  } else if (props.size == "xsmall") {
    switch (props.type) {
      case "h1":
        return "1.75rem";
      case "h2":
      case "h3":
        return "1.5625rem";
      case "h4":
      case "h5":
      case "h6":
      case "p":
      case "a":
        return "1rem";
      default:
        return "";
    }
  } else {
    return "";
  }
});
</script>
