<template>
  <div
    v-if="snippet?.text || snippet?.entities?.length"
    class="m-article__search"
  >
    <div v-if="snippet.text" class="m-article__search--text">
      <p v-html="snippet.text" class="type--small"></p>
    </div>
    <div
      v-else-if="snippet?.entities?.length != 0"
      class="m-article__search--entities"
    >
      <h6 class="mr-2 type--small">
        {{ t("views.articles.snippet_entities") }}
        <span
          v-for="(entity, i) in snippet.entities"
          :key="i"
          class="p type--small"
        >
          <span v-html="entity.text"></span>
          <span> ({{ t(`general.facets.${entity.type}`) }})</span>
          <span v-if="i != snippet.entities.length - 1" class="mr-1">, </span>
        </span>
      </h6>
    </div>
    <!-- <div
            v-for="(filter, idx) in searchFilters"
            :title="t(`views.articles.found`, { label: filter.label })"
          >
            <img :src="filter.image" />
          </div> -->
  </div>
</template>

<script setup>
import { useUserStore } from "@root/store/modules/user";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  search: { type: Object },
  language: { type: String, required: true },
});

const { t } = useI18n();
const userStore = useUserStore();

const style = (val) => {
  let content = val;
  const smarks = val.match(/<smark>.*?<\/smark>/g);

  if (val.length > 200 && smarks) {
    let max = 50;
    let cut;
    content = val.split(/<smark>.*?<\/smark>/g);

    for (let i = 0; i < content.length; i++) {
      if (content[i].length >= 50) {
        cut = content[i].length - max;

        if (i == 0) {
          content[i] = content[i].substring(cut, content[i].length);
          cut = content[i].indexOf(" ");
          content[i] = content[i].substring(cut, content[i].length);
          content[i] = `...${content[i]}`;
        } else if (i == content.length - 1) {
          content[i] = content[i].substring(0, max);
          cut = content[i].lastIndexOf(" ");
          content[i] = content[i].substring(0, cut);
          content[i] = `${content[i]}...`;
        } else {
          cut = cut / 2;
          content[i] = content[i].substring(cut, content[i].length - cut);
          cut = content[i].indexOf(" ");
          content[i] = content[i].substring(cut, content[i].lastIndexOf(" "));
          content[i] = ` ...${content[i]}... `;
        }
        max = 50;
      } else {
        max += 50 - content[i].length;
      }
    }
    for (let i = 0; i < smarks.length; i++) {
      content.splice(i * 2 + 1, 0, smarks[i]);
    }

    return content
      .join("")
      ?.replaceAll("<smark>", '<span class="--highlight">')
      ?.replaceAll("</smark>", "</span>");
  } else if (!smarks) {
    return content.substring(0, 200) + "...";
  } else {
    return content
      ?.replaceAll("<smark>", '<span class="--highlight">')
      ?.replaceAll("</smark>", "</span>");
  }
};

const snippet = computed(() => {
  const snippet = {};

  if (props.search) {
    const keys = Object.keys(props.search) ?? [] ?? [];

    const titleKey =
      keys.find(
        (x) => x.toLowerCase().includes("title") && x.includes(props.language)
      ) ?? keys.find((x) => x.toLowerCase().includes("title"));
    if (titleKey) snippet.title = style(props.search[titleKey][0]);

    const mainTextKey =
      keys.find(
        (x) =>
          x.toLowerCase().includes("maintext") && x.includes(props.language)
      ) ?? keys.find((x) => x.toLowerCase().includes("maintext"));
    if (mainTextKey) snippet.text = style(props.search[mainTextKey][0]);

    if (!snippet.text && Object.keys(props.search).length) {
      snippet.entities = [];
      props.search["entity.text"]?.forEach((fe) =>
        snippet.entities.push({
          text: style(fe),
          type: "entity",
        })
      );
      props.search["people.text"]?.forEach((fe) =>
        snippet.entities.push({
          text: style(fe),
          type: "people",
        })
      );
      props.search["places.text"]?.forEach((fe) =>
        snippet.entities.push({
          text: style(fe),
          type: "places",
        })
      );
      props.search["organization.text"]?.forEach((fe) =>
        snippet.entities.push({
          text: style(fe),
          type: "organization",
        })
      );
    }
  }
  return Object.keys(snippet).length != 0 ? snippet : undefined;
});
</script>
