<template>
  <m-header />
  <div class="m-home m-wrapper" :class="`m-home--${viewsStore.nav}`">
    <div
      id="scrollable"
      class="m-container"
      :class="{ 'm-container__load': loadingArticles }"
    >
      <h1 class="mt-12">
        {{ t("home.welcome", { name: account?.accountDetails?.firstName }) }}
      </h1>
      <p class="mb-10">{{ t("home.latestNews") }}</p>

      <div
        v-for="(view, i) in views"
        :key="i"
        :id="view.id"
        class="m-home__view"
        :class="{ 'd-none': articles[view?.id]?.length == 0 }"
      >
        <p class="h5">{{ view.name }}</p>

        <div
          :id="`m_home_${snakeCase(view.id)}_view_articles`"
          class="m-view__articles"
        >
          <m-icon
            v-if="canScrollLeft(view.id)"
            :id="`m_home_${snakeCase(view.id)}_scroll_left`"
            icon="arrows-down"
            variant="secondary"
            size="small"
            direction="left"
            @click="scrollLeft(view.id)"
          />
          <div
            :ref="(el) => setViewsRef(el, view.id)"
            class="m-articles__wrapper"
          >
            <div v-if="view.loading" class="m-articles__wrapper">
              <m-article-skeleton
                v-for="idx in articlesSkeleton"
                :key="idx"
                :id="`m_home_${snakeCase(view.id)}_article_${idx}`"
              />
            </div>
            <m-article
              v-for="(article, idx) in articles[view.id]"
              :key="idx"
              :id="`m_home_${snakeCase(view.id)}_article_${idx}`"
              :article="article"
              :actions="[]"
            />
          </div>
          <m-icon
            v-if="canScrollRight(view.id)"
            :id="`m_home_${snakeCase(view.id)}_scroll_right`"
            icon="arrows-down"
            variant="secondary"
            size="small"
            direction="right"
            @click="scrollRight(view.id)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, computed, watch, onBeforeUpdate, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";

import { useApi } from "@api/api";

import { useViewFilters } from "@hooks/useViewFilters";

import MHeader from "@components/navigation/MHeader.vue";

import MIcon from "@components/MIcon.vue";
import MArticle from "@components/MArticle.vue";
import MArticleSkeleton from "@components/skeletons/MArticleSkeleton.vue";

const { t } = useI18n();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const router = useRouter();
const route = useRoute();
const { api } = useApi();

import { snakeCase } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";

const { dateRestriction } = useViewFilters(router, route);

const account = computed(() => {
  return userStore.details;
});

const views = ref([]);

watch(
  () => [viewsStore.userViews, viewsStore.sharedViews],
  ([userViews, sharedViews]) => {
    if (userViews.length || sharedViews.length) {
      const baseViews = userViews
        .concat(sharedViews)
        .filter((f) => f.isBaseView)
        .map((m) => ({ ...m, readonly: true }))
        .sort((a, b) => a.name.localeCompare(b.name));

      const allViews = userViews.concat(sharedViews);

      const organizedViews = allViews
        .filter((f) => !f.isBaseView)
        .map((m) => ({ ...m, readonly: m.isShared }))
        .sort((a, b) => a.name.localeCompare(b.name));

      views.value = baseViews.concat(organizedViews);
    }
  },
  { immediate: true }
);

const articlesSkeleton = ref(10);

const articlesWrapper = ref({ start: "", end: "" });
const articlesPosition = ref({});

const viewRefs = ref([]);
const setViewsRef = (el, id) => {
  if (el) viewRefs.value.push({ element: el, id: id });
};

onMounted(() => {
  if (viewRefs.value?.length) {
    const wrapperBox = viewRefs.value[0].element?.getBoundingClientRect();
    articlesWrapper.value.start = wrapperBox.x;
    articlesWrapper.value.end = wrapperBox.x + wrapperBox.width;

    viewRefs.value.forEach((fe) => {
      const firstBox = document
        .getElementById(`m_home_${snakeCase(fe.id)}_article_0`)
        ?.getBoundingClientRect();
      const lastBox = document
        .getElementById(`m_home_${snakeCase(fe.id)}_article_9`)
        ?.getBoundingClientRect();

      articlesPosition.value[fe.id] = {
        first: firstBox?.x,
        last: lastBox?.x + lastBox?.width,
      };

      fe.element.addEventListener("scroll", () => {
        const firstBox = document
          .getElementById(`m_home_${snakeCase(fe.id)}_article_0`)
          ?.getBoundingClientRect();
        const lastBox = document
          .getElementById(`m_home_${snakeCase(fe.id)}_article_9`)
          ?.getBoundingClientRect();

        articlesPosition.value[fe.id] = {
          first: firstBox?.x,
          last: lastBox?.x + lastBox?.width,
        };
      });
    });
  }
});

// onBeforeUnmount(() => {
//   viewRefs.value.forEach((fe) => {
//     fe.element.removeEventListener("scroll", updateArticlesPosition(fe.id));
//   });
// });

const canScrollLeft = (id) => {
  const pos = articlesPosition.value[id];
  return pos?.first < articlesWrapper.value.start;
};
const canScrollRight = (id) => {
  const pos = articlesPosition.value[id];
  return pos?.last > articlesWrapper.value.end;
};

const scrollLeft = (id) => {
  viewRefs.value.find((f) => f.id == id).element.scrollLeft -=
    document.getElementsByClassName("m-article")[0].getBoundingClientRect()
      .width + 8;
};

const scrollRight = (id) => {
  viewRefs.value.find((f) => f.id == id).element.scrollLeft +=
    document.getElementsByClassName("m-article")[0].getBoundingClientRect()
      .width + 8;
};

onBeforeUpdate(() => {
  viewRefs.value = [];
});

const articles = ref({});
const loadingArticles = ref(false);
const responseHasData = ref(true);

/**
 *
 * @param {string} id Guid
 * @param {import("@root/types").Monitio.URLQueryObject} query
 */
const getArticles = async (id) => {
  const view = views.value.find((f) => f.id == id);
  view.loading = true;

  try {
    const response = await api.search.search(
      unref(id),
      dateRestriction.value,
      0,
      9,
      null,
      "date",
      "all",
      false
    );

    if (response.status == 200) {
      if (
        response.data.result.numDocuments == null ||
        response.data.result.numDocuments == undefined
      ) {
        responseHasData.value = false;
        view.loading = false;
      } else {
        articles.value[id] = response.data.result.documents;
        view.loading = false;

        return;
      }
    } else {
      responseHasData.value = false;
      view.loading = false;
    }
  } catch (error) {
    switch (error.response?.status) {
      // We can handle all the non OK status codes here
      default:
        //TODO: DGF Handle erros more elegantly for the end user, for now just say there's no results
        //const { title: message, traceId } = error.response.data;
        responseHasData.value = false;
        view.loading = false;
        break;
    }
  }
};

watch(
  () => views.value,
  (val, oldVal) => {
    if (val?.length != oldVal?.length) {
      val.forEach((fe) => {
        getArticles(fe.id);
      });
    }
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.m-home {
  .m-container {
    padding: $spacing-12 $spacing-9;
    @include flex(flex-start, flex-start, column);
  }

  &__view {
    margin-top: $spacing-7;
    align-self: stretch;
  }

  .m-view__articles {
    @include flex(flex-start, center, row);
    position: relative;

    .m-icon--left {
      position: absolute;
      left: -$spacing-6;
    }

    .m-icon--right {
      position: absolute;
      right: -$spacing-6;
    }

    .m-articles__wrapper {
      padding: $spacing-2;
      @include flex(flex-start, center, row);
      gap: $spacing-2;
      overflow-x: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none;
      }

      .m-article {
        align-self: flex-start;
      }
    }
  }
}
</style>
