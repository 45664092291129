<template>
  <div class="m-find">
    <div class="m-find__el">
      <div>
        <m-image v-if="value.image" :src="value.image" />
        <h5 class="type--small">{{ value.label }}</h5>
      </div>
      <h6 class="type--xsmall type--empty">
        {{ `${current + 1}/${found.length}` }}
      </h6>
    </div>
    <div class="m-divider" v-if="found.length > 0"></div>
    <div class="m-find__actions" v-if="found.length > 0">
      <m-icon
        id="m_find_previous"
        icon="arrow"
        direction="down"
        :status="current == 0 ? 'disabled' : 'inactive'"
        :hover="current == 0 ? '' : 'highlight'"
        @click="find('previous')"
      />
      <m-icon
        id="m_find_next"
        icon="arrow"
        :status="current + 1 == found.length ? 'disabled' : 'inactive'"
        :hover="current + 1 < found.length ? 'highlight' : ''"
        @click="find('next')"
      />
      <m-icon
        id="m_find_close"
        icon="close"
        variant="secondary"
        class="m-find__close"
        @click="close"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import MImage from "@components/MImage.vue";
import MIcon from "@components/MIcon.vue";

const { t } = useI18n();

const props = defineProps({
  value: Object,
});

const emit = defineEmits(["close"]);

const found = ref();
const previous = ref(null);
const current = ref(-1);

const find = (val) => {
  previous.value = current.value;
  if (previous.value >= 0) {
    found.value[previous.value].style.background = "rgba(39, 18, 233, 0.15)";
    found.value[previous.value].style.color = "rgba(0, 0, 0)";
  }

  if (val == "previous" && current.value != 0) {
    current.value -= 1;
  } else if (val == "next" && current.value + 1 < found.value.length) {
    current.value += 1;
  }

  found.value[current.value].scrollIntoView({ behavior: "smooth" });
  found.value[current.value].style.background =
    "linear-gradient(to bottom, transparent 10%, rgba(39, 18, 233, 0.8) 10%, rgba(39, 18, 233, 0.8) 90%, transparent 90%)";
  found.value[current.value].style.color = "rgba(255, 255, 255)";
};

const close = () => {
  found.value.forEach((fe) => {
    fe.style.background = "unset";
    fe.style.color = "rgb(0, 0, 0)";
  });

  emit("close");
};

watch(
  () => props.value,
  (val, oldVal) => {
    if (val != oldVal) {
      previous.value = null;
      current.value = -1;
      found.value?.forEach((fe) => {
        fe.style.background = "unset";
        fe.style.color = "rgb(0, 0, 0)";
      });
      found.value = document.querySelectorAll(`[data-entity="${val.id}"]`);
      found.value.forEach((fe) => {
        fe.style.background = "rgba(39, 18, 233, 0.15)"; // color($found)
      });

      if (found.value.length > 0) find("next");
    }
  },
  { immediate: true }
);
</script>

<style scoped lang="scss">
.m-find {
  padding: $spacing-3;
  border: 1px solid color($pri-light);
  @include round-corners($spacing-1);
  @include flex(space-between, center, row);
  gap: $spacing-4;
  position: fixed;
  top: calc($header-height + $spacing-4);
  right: $spacing-7;
  background-color: color($white);
  @include elevate-popover;

  &__el {
    min-width: $column-2;
    @include flex(space-between, center, row);
    gap: $spacing-7;

    > div {
      @include flex(flex-start, center, row);
      gap: $spacing-3;
    }

    :deep(img) {
      width: 32px;
      height: 32px;
      @include round-corners;
      object-fit: cover;
    }
  }

  .m-divider {
    height: inherit;
    width: 1px;
    align-self: stretch;
  }

  &__actions {
    @include flex(flex-start, center, row);
    gap: $spacing-1;
  }

  &__close {
    margin-left: $spacing-3;
  }
}
</style>
