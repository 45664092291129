<template>
  <button
    :id="`m_button_${id}`"
    :data-test="`test_m_button_${id}`"
    :data-tour="`tour_m_button_${id}`"
    :data-tooltip-content="tooltip?.content"
    :data-tooltip-position="tooltip?.position"
    ref="htmlElement"
    class="m-button"
    :class="[
      `m-button--${size} m-button--${type}--${variant}`,
      {
        'm-button--disabled': loading || disabled,
        '--a11y': appStore.a11y,
      },
    ]"
    :disabled="disabled"
    @click.prevent.stop="click"
    @keydown.enter.prevent.stop="click"
  >
    <m-icon
      v-if="leadingIcon && !loading"
      :icon="leadingIcon"
      status="active"
      :tooltip="tooltip"
      :variant="variant"
      :size="size == 'block' ? 'default' : size"
      @click="click"
    />
    <m-loading
      v-if="loading"
      size="xsmall"
      :base="type == 'contained' ? 'dark' : 'light'"
    />
    <span
      v-else-if="label"
      :id="`m_button_${id}_label`"
      v-html="label"
      :data-tooltip-content="tooltip?.content"
      :data-tooltip-position="tooltip?.position"
      class="h5"
      :class="[
        size ? `type--${size}` : '',
        { 'ml-2': leadingIcon, 'mr-2': traillingIcon },
      ]"
    ></span>
    <m-icon
      v-if="traillingIcon && !loading"
      :icon="traillingIcon"
      status="active"
      :tooltip="tooltip"
      :variant="variant"
      :size="size == 'block' ? 'default' : size"
      @click="click"
    />
    <m-hint v-if="hint" :id="`m_button_${id}_hint`" />
  </button>
</template>

<script setup>
/*
 * Monitio Button component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons
 */

import { watch, ref } from "vue";

import MIcon from "@components/MIcon.vue";
import icon_list_regular from "@assets/icons/icon_list_regular";
import icon_list_other from "@assets/icons/icon_list_other";
import MLoading from "@components/MLoading.vue";
import MHint from "@components/MHint.vue";
import { useAppStore } from "@root/store/app";

const emit = defineEmits(["click"]);
const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  label: { type: String },
  tooltip: {
    type: Object,
    validator(tooltip) {
      if (!tooltip.content || typeof tooltip.content !== "string") {
        console.error(
          '\n Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.'
        );
      }
      if (
        tooltip.position &&
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(tooltip.position)
      ) {
        console.error(
          `Invalid prop "tooltip": expected string attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${tooltip.position}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.`
        );
      }
      return true;
    },
  },
  leadingIcon: {
    type: String,
    validator(icon) {
      if (!icon_list_regular[icon] && !icon_list_other[icon]) {
        console.error(
          `Invalid prop "icon": "${icon}" does not exist. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
        );
      }
      return true;
    },
  },
  traillingIcon: {
    type: String,
    validator(icon) {
      if (!icon_list_regular[icon] && !icon_list_other[icon]) {
        console.error(
          `Invalid prop "icon": "${icon}" does not exist. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
        );
      }
      return true;
    },
  },
  type: {
    type: String,
    required: true,
    validator(type) {
      if (!["contained", "outlined", "text"].includes(type)) {
        console.error(
          `Invalid prop "type": expected string with value "contained", "outlined" or "text" and got "${type}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.`
        );
      }
      return true;
    },
  },
  size: {
    type: String,
    default: "default",
    validator(size) {
      if (!["default", "block", "small", "xsmall"].includes(size)) {
        console.error(
          `Invalid prop "size": expected string with value "default", "block", "small" or "xsmall" and got "${size}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.`
        );
      }
      return true;
    },
  },
  variant: {
    type: String,
    required: true,
    validator(variant) {
      if (
        ![
          "primary",
          "secondary",
          "terciary",
          "success",
          "warning",
          "error",
        ].includes(variant)
      ) {
        console.error(
          `Invalid prop "variant": expected string with value "primary", "secondary", "terciary", "success", "warning" or "error" and got "${variant}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.`
        );
      }
      return true;
    },
  },
  disabled: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
  hint: { type: Boolean, default: false },
});

const appStore = useAppStore();

watch(
  () => [props.label, props.tooltip],
  (vals) => {
    if (vals[0] && vals[1] && !props.disabled) {
      console.error(
        'Trying to use mutually exclusive props: use only "label" or "tooltip". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.'
      );
    } else if (!vals[0] && !vals[1]) {
      console.error(
        `Missing required prop "label" or "tooltip". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589726031/Buttons for instructions on how to use the MButton component.`
      );
    }
  },
  { immediate: true, deep: true }
);

const click = () => {
  if (props.disabled || props.loading) return;
  emit("click");
};

const htmlElement = ref(null);
defineExpose({ htmlElement });
</script>

<style scoped lang="scss">
.m-hint {
  top: -5px;
  right: -5px;
}
</style>
