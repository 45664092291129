<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div v-if="visibleEntries.length" ref="filterGroupRef" class="m-filter-group">
    <div class="m-filter-group__header">
      <div class="title">
        <h4 class="ma-0 m-toolbox__title" @click.stop="showSearch = true">
          {{ label }}
        </h4>
      </div>
      <m-loading v-if="isGettingMoreEntries" size="xsmall" />
      <m-icon
        v-else-if="canCollapse && expanded"
        id="expand_icon"
        :icon="icon"
        size="small"
        :tooltip="iconTooltip"
        class="m-filter-group__expand"
        :class="{
          'm-filter-group__expand--floating': floatingExpandIcon,
        }"
        @click="collapseEntries"
      />
    </div>
    <div class="m-toolbox__tool">
      <div class="m-filter-group__visible">
        <m-filter-entry
          v-for="entry in visibleEntries"
          :key="entry.label"
          :entry="entry"
          @update:entry="entryChanged"
          :highlight="search"
        />
        <m-icon
          v-if="visibleEntries.length == 6 && group.hasMore && !expanded"
          icon="arrows-down"
          variant="primary"
          size="xsmall"
          class="m-filter-group__fetch-more mt-2"
          :disabled="isGettingMoreEntries"
          @click="getMoreEntries"
        />
      </div>
      <div v-if="expanded" class="m-filter-group__hidden">
        <m-filter-entry
          v-for="entry in hideableEntries"
          :key="entry.label"
          :entry="entry"
          @update:entry="entryChanged"
          :highlight="search"
        />
        <m-filter-entry
          v-if="theRestOftheEntries.length"
          v-for="entry in theRestOftheEntries"
          :key="entry.label"
          :entry="entry"
          @update:entry="entryChanged"
          :highlight="search"
        />
        <m-icon
          v-if="canFetchMoreEntries"
          id="fetch_more_icon"
          icon="arrows-down"
          status="active"
          variant="primary"
          size="xsmall"
          class="m-filter-group__fetch-more mt-2"
          :disabled="isGettingMoreEntries"
          @click="getMoreEntries"
        />
      </div>
    </div>
    <div ref="dividerRef" class="m-divider"></div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useApi } from "@api/api";
import { useRouter, useRoute } from "vue-router";
import { useViewFilters } from "@hooks/useViewFilters";
import MFilterEntry from "@components/filter/MFilterEntry.vue";
import MLoading from "@components/MLoading.vue";
import MIcon from "@components/MIcon.vue";
import { isEmpty } from "lodash-es";
import { useViewsStore } from "@root/store/modules/views";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({
  group: { type: Object, required: true },
  search: { type: String },
});

const emit = defineEmits(["update:group", "more-entries"]);

const { t, te } = useI18n();
const viewsStore = useViewsStore();
const workspacesStore = useWorkspacesStore();
const route = useRoute();
const { api } = useApi();
const router = useRouter();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);

const { queryObject, dateRestriction } = useViewFilters(router, route);

const showSearch = ref(false);
const filterGroupRef = ref(null);
const dividerRef = ref(null);
const expanded = ref(false);

const canCollapse = computed(() => {
  return props.group?.values?.length > 6;
});

const label = computed(() => {
  if (t(`general.facets.${props.group?.label}`).includes(".")) {
    return props.group?.label;
  }
  return t(`general.facets.${props.group?.label}`);
});

const floatingExpandIcon = ref(false);

const filterPaneScrolled = () => {
  const height = filterGroupRef.value?.getBoundingClientRect()?.height;
  const y = filterGroupRef.value?.getBoundingClientRect()?.y;
  const headerY = document
    .getElementById("m_filter_pane_content")
    ?.getBoundingClientRect().top;

  floatingExpandIcon.value =
    y <= headerY && height - headerY - 40 >= Math.abs(y);
};

onMounted(() => {
  document
    .getElementById("m_filter_pane_content")
    ?.addEventListener("scroll", filterPaneScrolled);
});

onBeforeUnmount(() => {
  document
    .getElementById("m_filter_pane_content")
    ?.removeEventListener("scroll", filterPaneScrolled);
});

const icon = computed(() => {
  if (expanded.value) return "collapse-vertical";
  else return "expand-vertical";
});

const iconTooltip = computed(() => {
  const tooltip = { content: "", position: "bottom-left" };
  if (expanded.value) {
    tooltip.content = t("components.filterPane.collapseGroup");
  } else {
    tooltip.content = t("components.filterPane.expandGroup");
  }
  return tooltip;
});

const sortFilters = (a, b) => {
  return b.count - a.count;
};

const filters = computed(() => props.group.values);

const entries = computed(() => {
  if (filters.value?.length === 0) return [];

  let f;
  if (props.search?.trim()) {
    const search = props.search.trim().toLowerCase();
    f = filters.value?.filter(
      (a) =>
        a.label
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(search.normalize("NFD").replace(/[\u0300-\u036f]/g, "")) >= 0
    );
  } else f = filters.value;

  const selected = f.filter((a) => a.selected);
  selected.sort(sortFilters);

  const view = f.filter((a) => a.view && !a.selected);
  view.sort(sortFilters);

  const remain = f.filter((a) => !a.view && !a.selected);
  remain.sort(sortFilters);

  const items = selected.concat(view, remain);

  return Object.freeze(items);
});

const visibleEntries = computed(() => {
  return entries.value?.slice(0, viewsStore.filterCount);
});

const hideableEntries = computed(() => {
  return (
    entries.value?.slice(
      viewsStore.filterCount,
      viewsStore.expandedFilterCount
    ) ?? []
  );
});

const theRestOftheEntries = computed(() => {
  return entries.value?.slice(
    viewsStore.expandedFilterCount,
    numberOfEntriesToShow.value
  );
});

const numberOfEntriesToShow = ref(6);
const numberOfEntriesToFecth = ref(20);
const canFetchMoreEntries = computed(() => {
  return props.group?.values?.length > numberOfEntriesToShow.value;
});
const isGettingMoreEntries = ref(false);
const getMoreEntries = async () => {
  isGettingMoreEntries.value = true;

  const filters = structuredClone(queryObject.value.filters ?? []);
  if (!isEmpty(queryObject.value?.contextualFilters))
    filters.push({ facets: queryObject.value.contextualFilters });

  const response = await api.search.refineProperties(
    viewId.value,
    filters,
    dateRestriction.value,
    props.group.id,
    numberOfEntriesToFecth.value + 1
  );
  if (response.status != 200) return;

  const groups =
    Object.keys(response.data.groups).map((x) => response.data.groups[x]) || [];
  const group = groups.find((x) => x.id == props.group.id);
  if (Object.keys(group.filters).length > numberOfEntriesToShow.value) {
    expanded.value = true;
    emit("more-entries", group);
    numberOfEntriesToShow.value = numberOfEntriesToFecth.value;
    numberOfEntriesToFecth.value *= 2;
  }
  isGettingMoreEntries.value = false;
  //filterPaneScrolled();
};

const collapseEntries = () => {
  expanded.value = false;
  if (floatingExpandIcon.value) filterGroupRef.value.scrollIntoView();
};

let resetOnChange = false;
const entryChanged = (entry) => {
  emit("update:group", props.group, entry);
  resetOnChange = true;
};

watch(
  () => props.group,
  () => {
    if (!resetOnChange) return;
    expanded.value = false;
    numberOfEntriesToShow.value = 6;
    resetOnChange = false;
  },
  { deep: true }
);
</script>

<style scoped lang="scss">
.m-filter-group {
  position: relative;
  overflow: hidden;
}
.m-filter-group__expand {
  &--floating {
    padding: $spacing-1;
    border: 1px solid color($sec-200);
    @include round-corners;
    position: fixed;
    top: $spacing-9;
    right: $spacing-2;
    background-color: color($white);
    @include elevate-button;
    cursor: pointer;
    z-index: $z-dialog;
  }
}

:deep(.m-filter-group__fetch-more) {
  width: 100%;
  display: flex;
}
</style>
