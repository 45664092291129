import { useI18n } from "vue-i18n";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

export default () => {
  const { t } = useI18n();
  const { open, close, cancel } = useModal("m-modal");

  const handleModal = (report, template, moduleData) => {
    open("m_modal_send_report", {
      heading: t("modals.sendReport.title"),
      description: t("modals.sendReport.description"),
      width: 4,
      buttons: ["custom"],
      report,
      template,
      moduleData,
      close,
      cancel,
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
