import Shepherd from "shepherd.js";
import { debounce } from "lodash-es";
import useUtils from "@root/hooks/useTour/useUtils";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@root/store/modules/user";

export default () => {
  const { t } = useI18n();
  const userStore = useUserStore();

  const {
    cardContent,
    getCurrentStepIndex,
    clearArray,
    configTourOptions,
    waitForElementToDisplay,
    removeClass,
  } = useUtils();

  // CREATE VIEW TOUR
  const createViewTour = new Shepherd.Tour({
    useModalOverlay: true,

    canClickTarget: false,

    defaultStepOptions: {
      when: {
        show() {
          // Get index of currentStep
          const currentStepIndex = getCurrentStepIndex();

          //send current step to store
          userStore.updateStepTour(
            Shepherd.activeTour.steps.indexOf(Shepherd.activeTour.currentStep)
          );
          // click on browser back button
          const stepCurrent = Shepherd.activeTour.getCurrentStep();
          window.addEventListener("popstate", function (event) {
            stepCurrent.hide();
            document
              .querySelectorAll(".m-tour__mask")
              .forEach((e) => e.remove());
            createViewTour.show(Shepherd.activeTour.currentStep);
          });

          //click on browser refresh button
          window.onbeforeunload = function (event) {
            createViewTour.show(Shepherd.activeTour.currentStep);
          };

          // remove steps after trigger a cancel/ complete event
          [("cancel", "complete")].forEach((event) =>
            createViewTour.on(event, (param) => {
              const { tour } = param;
              tour.steps.forEach((step) => {
                step.destroy();
              });

              clearArray(createViewTour.steps);
            })
          );
          ["close", "cancel"].forEach((event) =>
            createViewTour.on(event, () => {
              removeClass("m-hint");

              const selector =
                document.getElementsByClassName("m-tour__mask").length > 0;
              if (selector) {
                selector[0].parentNode.removeChild(selector[0]);
              }
              userStore.completeTour("createView");
              clearArray(createViewTour.steps);
            })
          );
          // Block all clicks on explore page
          if (getCurrentStepIndex() == 0) {
            const screenToBlock = document.getElementsByClassName("m-main");
            screenToBlock[0].style["pointer-events"] = "none";

            const headerUnlock = document.getElementsByClassName("m-header");

            headerUnlock[0].style["pointer-events"] = "visible";

            const iconUnlock =
              document.getElementsByClassName("m-sidenav__header");
            iconUnlock[0].style["pointer-events"] = "visible";

            const createViewUnlock = document.querySelector(
              '[data-tour="tour_explore_recentCreateView_create"]'
            );
            createViewUnlock.style["pointer-events"] = "visible";
          }
          //EXPLORE PAGE - Hints implementation
          if (createViewTour.currentStep.id == "m_step_1_blue_explorer") {
            const createViewIcon =
              document.getElementById("m_create_view_icon");
            createViewIcon.onclick = () => {
              removeClass("m-hint");
              createViewTour.next();
            };

            const elChecker = setInterval(() => {
              const createViewDropdown = document.getElementById(
                "m_button_m_dropdown_option_views_menu_button"
              );
              if (createViewDropdown)
                createViewDropdown.onclick = () => {
                  removeClass("m-hint");
                  createViewTour.next();
                  clearInterval(elChecker);
                };
            }, 500);
            const createViewBox = document.getElementById(
              "m_explore_create_view"
            );

            createViewBox.onclick = () => {
              removeClass("m-hint");
            };
          }

          //create view modal
          // thumbnail
          if (getCurrentStepIndex() == 2) {
            const element = document.getElementById("m_tour_overlay");
            element.parentNode.removeChild(element);

            const uploadThumbnail = document.querySelector("#create_view");

            uploadThumbnail.onclick = () => {
              createViewTour.next();
            };

            const enterViewNameInput = document.querySelector(
              '[data-tour="tour_viewSetup_viewName"]'
            );

            const flowOnAddThumbnail = debounce(() => {
              createViewTour.next();
              enterViewNameInput.removeEventListener(
                "keydown",
                flowOnAddThumbnail
              );
            }, 600);
            enterViewNameInput.addEventListener("keydown", flowOnAddThumbnail);
            enterViewNameInput.addEventListener("focus", () => {
              if (getCurrentStepIndex() < 3) {
                createViewTour.show(3);
              }
            });
          }

          // click to create view
          if (getCurrentStepIndex() == 4) {
            /* const buttonCreateView = document.getElementById(
              "m_button_m_modal_continue"
            );
            buttonCreateView.classList.add("m-hint"); */
            /* const buttonHintId = document.getElementById(
              "m_button_m_modal_continue"
            ); */
            /* buttonHintId.onclick = () => {
              buttonCreateView.classList.remove("m-hint");
            }; */
          }

          if (getCurrentStepIndex() == 5 || getCurrentStepIndex() == 6) {
            removeClass("m-hint");
          }
          userStore.updateStepTour(
            Shepherd.activeTour.steps.indexOf(Shepherd.activeTour.currentStep)
          );
          if (getCurrentStepIndex() == 7) {
            const element = document.getElementById("m_tour_overlay");
            element.parentNode.removeChild(element);

            const searchBoxFilterBuilder = document.getElementById(
              "m_search_main_autocomplete_input"
            );
            searchBoxFilterBuilder.onblur = () => {
              removeClass("m-hint");
            };
            const button = document.querySelector(
              "#m_button_m_manage_filters_create_view"
            );
            button.style["pointer-events"] = "none";

            const articlesToBlock = document.querySelectorAll(
              '[data-tour="tour_manageFilters_preview"]'
            );

            articlesToBlock[0].style["pointer-events"] = "none";

            const headerToBlock =
              document.getElementsByClassName("m-header__left");

            headerToBlock[0].style["pointer-events"] = "none";
          } else if (getCurrentStepIndex() >= 8 && getCurrentStepIndex() <= 9) {
            removeClass("m-hint");
          } else if (getCurrentStepIndex() == 12) {
            const filterSearch = document.querySelectorAll(
              "m_hint_filter_builder_search"
            );

            if (typeof filterSearch != "undefined" && filterSearch != null) {
              removeClass("m-hint");
            }

            const button = document.querySelector(
              "#m_button_m_manage_filters_create_view"
            );
            button.style["pointer-events"] = "visible";
            const articlesToBlock = document.querySelectorAll(
              '[data-tour="tour_manageFilters_preview"]'
            );
            articlesToBlock[0].style["pointer-events"] = "visible";
            const headerToBlock =
              document.getElementsByClassName("m-header__left");

            headerToBlock[0].style["pointer-events"] = "visible";
          }
          /* ***** end of hint implementation  ******/

          //Add a mask element to block user clicks- white cards
          if (getCurrentStepIndex() != 0) {
            const screenUnlock = document.getElementsByClassName("m-main");
            screenUnlock[0].style["pointer-events"] = "visible";
            if (
              getCurrentStepIndex() == 1 ||
              getCurrentStepIndex() == 5 ||
              getCurrentStepIndex() == 6 ||
              getCurrentStepIndex() == 8 ||
              getCurrentStepIndex() == 9 ||
              getCurrentStepIndex() == 10 ||
              getCurrentStepIndex() == 11
            ) {
              const body = document.querySelector("body");
              const maskElement = document.createElement("div");
              maskElement.setAttribute("id", "m_tour_overlay");
              maskElement.className = "m-tour__mask";
              body.appendChild(maskElement);
            }
          }
          // CARDS CONFIGS
          configTourOptions(createViewTour, "create_view", "createView", t);
        },
      },
    },
  });

  // step 1 - blue - Explore page - create view
  createViewTour.addStep({
    id: "m_step_1_blue_explorer",
    title: "",
    text: cardContent("blue", t("tour.explore.recentCreateView_create")),
    attachTo: {
      element: '[data-tour="tour_explore_recentCreateView_create"]',
      on: "right",
    },
    classes: "m-tour-blue ml-3",

    advanceOn: {
      selector: '[data-tour="tour_explore_recentCreateView_create"]',

      event: "click",
    },
  });

  // step 2 - white - create view modal
  createViewTour.addStep({
    id: "m_step_2_white_createView_0",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.viewSetup_content"),
      t("tour.createView.viewSetup_title"),
      t("tour.createView.title")
    ),
    attachTo: {
      element: '[data-tour="tour_createView_viewSetup"]',

      on: "right",
    },
    classes: "m-tour-white ml-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],
  });

  //step 3 - blue - create view - add thumbnail
  createViewTour.addStep({
    id: "m_step_3_blue_createView",
    title: "",
    text: cardContent("blue", t("tour.createView.viewSetup_thumbnail")),
    attachTo: {
      element: '[data-tour="tour_createView_viewSetup_thumbnail"]',

      on: "left",
    },
    classes: "m-tour-blue mr-3",
  });
  //step 4 - blue - create view - add view name
  createViewTour.addStep({
    id: "m_step_4_blue_createView",
    title: "",
    text: cardContent("blue", t("tour.createView.viewSetup_viewName")),
    attachTo: {
      element: '[data-tour="tour_viewSetup_viewName"]',

      on: "left",
    },
    classes: "m-tour-blue mr-6",
  });

  //step 5 - blue - create view - create
  createViewTour.addStep({
    id: "m_step_5_blue_createView",
    title: "",
    text: cardContent("blue", t("tour.createView.viewSetup_create")),

    attachTo: {
      element: '[data-tour="tour_m_button_m_modal_continue"]',

      on: "bottom",
    },
    classes: "m-tour-blue mt-3",
    advanceOn: {
      selector: '[data-tour="tour_m_button_m_modal_continue"]',
      event: "click",
    },
  });

  // step 6 - WHITE - manage filters - filter builder
  createViewTour.addStep({
    id: "m_step_6_white_createView_1",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.filterBuilder_content"),
      t("tour.createView.filterBuilder_title"),
      t("tour.createView.title")
    ),
    attachTo: {
      element: '[data-tour="tour_filterBuilder"]',
      on: "right",
    },
    classes: "m-tour-white ml-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],
    //  scrollTo: true,

    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = ".m-manage-filters__builder";

        waitForElementToDisplay(selector).then(() => {
          resolve();
        });
      });
    },
  });

  // step 7 - WHITE - manage filters - articles preview
  createViewTour.addStep({
    id: "m_step_7_white_createView_2",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.articlesPreview_content"),
      t("tour.createView.articlesPreview_title"),
      t("tour.createView.title")
    ),

    attachTo: {
      element: '[data-tour="tour_manageFilters_preview"]',
      on: "left",
    },
    classes: "m-tour-white mr-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],
    //scrollTo: true,
  });

  // step 8 - blue - filter builder - search
  createViewTour.addStep({
    id: "m_step_8_blue_createView",
    title: "",
    text: cardContent("blue", t("tour.createView.searchFilters")),
    attachTo: {
      element: '[data-tour="tour_m_autocomplete"]',
      on: "right",
    },
    classes: "m-tour-blue ml-6",

    advanceOn: {
      selector: '[id="m_search_main_autocomplete_input"]',
      event: "blur",
    },

    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = document.getElementsByClassName("m-tour__mask");

        selector[0].parentNode.removeChild(selector[0]);
        resolve();
      });
    },
  });

  // step 9 - white - filter builder - negate filter
  createViewTour.addStep({
    id: "m_step_9_white_createView_3",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.toggleFilter_content"),
      t("tour.createView.toggleFilter_title"),
      t("tour.createView.title")
    ),

    attachTo: {
      element: '[data-tour="tour_m_filter_line_query"]',
      on: "bottom",
    },
    classes: "m-tour-white mt-2",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],

    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = ".m-query-element";

        waitForElementToDisplay(selector).then(() => {
          resolve();
        });
      });
    },
  });

  // step 10 - white - filter builder - and operator
  createViewTour.addStep({
    id: "m_step_10_white_createView_4",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.addFiltersToggleSearch_content"),
      t("tour.createView.addFiltersToggleSearch_title"),
      t("tour.createView.title")
    ),

    attachTo: {
      element: '[id="m_button_m_filter_advanced_operator_add"]',
      on: "bottom",
    },
    classes: "m-tour-white mt-2",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],

    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = document.getElementsByClassName("m-tour__mask");

        selector[0].parentNode.removeChild(selector[0]);
        resolve();
      });
    },
  });

  //step 11 - white - filter builder - add filter
  createViewTour.addStep({
    id: "m_step_11_white_createView_5",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.addFilters_content"),
      t("tour.createView.addFilters_title"),
      t("tour.createView.title")
    ),

    attachTo: {
      element: '[data-tour="tour_m_filter_entry_add"]',
      on: "right",
    },
    classes: "m-tour-white ml-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],

    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = document.getElementsByClassName("m-tour__mask");

        selector[0].parentNode.removeChild(selector[0]);
        resolve();
      });
    },
  });
  //step 12 white
  createViewTour.addStep({
    id: "m_step_12_white_createView_6",
    title: "",

    text: cardContent(
      "white",
      t("tour.createView.improveResults_content"),
      t("tour.createView.improveResults_title"),
      t("tour.createView.title")
    ),

    attachTo: {
      element: '[data-tour="tour_m_button_m_filter_advanced_and"]',
      on: "right",
    },
    classes: "m-tour-white ml-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: createViewTour.next,
      },
    ],
    // scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = document.getElementsByClassName("m-tour__mask");

        selector[0].parentNode.removeChild(selector[0]);
        resolve();
      });
    },
  });
  // step 13-LAST STEP-blue-filter builder-save filters
  createViewTour.addStep({
    id: "m_step_13_blue_createView",
    title: "",
    text: cardContent("blue", t("tour.createView.saveFilters")),
    attachTo: {
      element: '[id="m_button_m_manage_filters_create_view"]',

      on: "bottom",
    },
    classes: "m-tour-blue mt-3",

    advanceOn: {
      selector: '[id="m_button_m_manage_filters_create_view"]',
      event: "click",
    },
    //  scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = document.getElementsByClassName("m-tour__mask");

        selector[0].parentNode.removeChild(selector[0]);
        resolve();
      });
    },
  });

  return createViewTour;
};
