<template>
  <div
    v-if="visible"
    ref="dropdownRef"
    class="m-dropdown"
    :id="id"
    :data-test="`test_m_dropdown_${id}`"
    role="listbox"
    :aria-labelledby="labelledBy"
    :class="dropdownClassList"
    :style="dropdownStyle"
    @mouseenter="emit('update:visible', true)"
  >
    <div
      v-if="loading && options.length == 0"
      :id="`m_drodpown_${id}`"
      tabindex="-1"
      class="m-dropdown__wrapper m-dropdown__wrapper--date"
      :style="maxHeight"
    >
      <m-loading type="spinner" :size="size" />
    </div>
    <ul
      v-else-if="type == 'date' && dateSpecs?.timeframes"
      :id="`m_drodpown_${id}`"
      tabindex="-1"
      role="listbox"
      :aria-labelledby="`m_dropdown_${id}`"
      :aria-activedescendant="activeDescendant"
      class="m-dropdown__wrapper m-dropdown__wrapper--date"
      :style="maxHeight"
    >
      <li class="m-dropdown__timeframes" @mouseleave="stopPreview(false)">
        <ul class="pa-1">
          <li
            v-for="(opt, idx) in timeOptions"
            :key="idx"
            :id="opt.value"
            :data-range-key="opt.value"
            class="py-2 px-3 m-dropdown__option h6 type--small"
            :class="{
              'm-dropdown__option--has-icon': filteredOptions.find(
                (f) => f.icon
              ),
              'm-dropdown__option--delete': opt.value == 'delete',
              'm-dropdown__option--disabled': opt.disabled,
              'm-dropdown__option--readonly': opt.readonly,
              'm-dropdown__option--focused': focusedIdx == idx,
              'm-dropdown__option--selected':
                selected.timeFrame == opt.value ||
                (opt.value == 'custom' && selectingCustomDate),
            }"
            @mouseenter="focus(idx, opt.timeframe)"
            @mouseleave="focus(undefined)"
            @click.stop="select(opt)"
          >
            <span :id="`m_dropdown_option_label_${opt.value}`">
              {{ opt.label }}
            </span>
          </li>
        </ul>
      </li>
      <li ref="datePickerWrapper" class="m-dropdown__calendar">
        <div
          v-if="selectingCustomDate"
          class="m-dropdown__simple-date-picker__wrapper"
          :style="{
            width: `${selectingCustomWindowSize.width}px`,
            height: `${selectingCustomWindowSize.height}px`,
          }"
        >
          <m-simple-date-picker
            :modelValue="selectedDate"
            class="px-4"
            @update:modelValue="(val) => selectAbsoluteDate(val, true)"
          />
        </div>
        <m-date-picker
          v-else
          ref="datePicker"
          :key="key"
          :modelValue="selectedDate"
          mode="date"
          :input-debounce="0"
          :timezone="userStore.timeZone"
          transition="none"
          :is-range="dateSpecs?.mode == 'range'"
          :columns="dateSpecs?.columns"
          :max-date="new Date()"
          :initial-page="initialPage"
          :select-attribute="selectDragAttribute"
          :drag-attribute="selectDragAttribute"
          @dayclick="updateDisabledDates"
          :disabled-dates="disabledDates"
          borderless
          transparent
          class="m-date-picker"
          :class="`m-date-picker--${dateSpecs?.columns}`"
          @update:modelValue="selectAbsoluteDate"
        />
        <div v-if="maxClusterAge" class="pl-3">
          <p class="pl-2 py-2 m-max-cluster-age__text type--xsmall">
            {{
              t("navigation.header.filters.date.maxClusterAge", {
                days: maxClusterAge,
              })
            }}
          </p>
        </div>
      </li>
    </ul>
    <div
      v-else-if="type == 'date'"
      class="m-dropdown__wrapper m-dropdown__wrapper--date"
    >
      <m-date-picker
        ref="datePicker"
        :key="key"
        :modelValue="selectedDate"
        mode="date"
        :input-debounce="0"
        :timezone="userStore.timeZone"
        transition="none"
        :is-range="dateSpecs?.mode == 'range'"
        :columns="dateSpecs?.columns"
        :max-date="new Date()"
        :initial-page="initialPage"
        borderless
        transparent
        class="m-date-picker"
        :class="`m-date-picker--${dateSpecs?.columns}`"
        @update:modelValue="selectAbsoluteDate"
      />
    </div>
    <div
      v-if="search && options.length > 20"
      :data-test="`test_m_dropdown_search_${id}`"
      class="m-dropdown__search"
      :class="[
        `m-dropdown__search--${size}`,
        { 'm-dropdown__search--active': searchIsActive },
      ]"
      @click.stop.prevent="activateSearch"
    >
      <m-input
        ref="input"
        :id="`search_${id}`"
        v-model="searchQuery"
        :placeholder="$t('components.search.placeholder_search')"
        leadingIcon="search"
        traillingIcon="close"
        :size="size"
        class="m-dropdown__input"
        @keyup="inputKeyup"
        @resolve="select(options[focusedIdx ?? 0])"
      />
    </div>
    <ul
      v-if="(type != 'date' && noresults) || options.length != 0"
      :id="`m_drodpown_${id}`"
      tabindex="-1"
      role="listbox"
      :aria-labelledby="`m_dropdown_${id}`"
      :aria-activedescendant="activeDescendant"
      class="m-dropdown__wrapper"
      :class="[
        `m-dropdown__wrapper--${size}`,
        {
          'm-dropdown__wrapper--search': options.length > 20,
          'm-dropdown__wrapper--overflow': !options.find(
            (f) => f.options?.length
          ),
        },
      ]"
      :style="maxHeight"
    >
      <li
        v-if="filteredOptions.length == 0 || (noresults && options.length == 0)"
        class="m-dropdown__option m-dropdown__option--empty"
      >
        <h6 class="type--empty" :class="`type--${size}`">
          {{ noresultsMessage ?? t("components.dropdown.empty") }}
        </h6>
      </li>
      <li
        v-for="(opt, idx) in filteredOptions"
        :key="idx"
        :id="`${id}_option_${opt.value}`"
        :ref="setOptionRef"
        :data-test="`test_m_dropdown_${id}_option_${opt.value}}`"
        :data-idx="idx"
        :data-option="`m_dropdown_option_${opt.value}`"
        :data-secondary="secondary"
        :role="opt.value == 'divider' ? '' : 'option'"
        :aria-selected="isSelected(opt)"
        :aria-labelledby="
          opt.value != 'divider' ? `m_dropdown_option_label_${opt.value}` : ''
        "
        :title="opt.label"
        class="m-dropdown__option h6"
        :class="[
          {
            'm-dropdown__option--has-icon': filteredOptions.find((f) => f.icon),
            'm-dropdown__option--divider': opt.value == 'divider',
            'm-dropdown__option--disabled': opt.disabled,
            'm-dropdown__option--readonly': opt.readonly,
            'm-dropdown__option--focused': isFocused(opt),
            'm-dropdown__option--current': appStore.a11y && isFocused(opt),
            'm-dropdown__option--selected': isSelected(opt),
            'm-dropdown__option--arrow': opt.options?.length,
          },
          `type--${size}`,
        ]"
        @mouseenter="focus(idx)"
        @mouseleave="closeSecondaryDropdown"
        @click.stop.prevent="select(opt)"
        @keyup.enter.stop.prevent="select(opt)"
      >
        <m-icon
          v-if="showIcon(opt)"
          :icon="setOptIcon(opt)"
          status="active"
          :weight="iconWeight(opt)"
          :variant="focusedIdx == idx ? 'primary' : 'terciary'"
          :size="size == 'max-width' ? 'default' : size"
          class="mr-2"
          @click="select(opt)"
        />
        <div v-if="opt.value != 'divider'" class="m-option__label">
          <slot :option="opt">
            <m-user-badge v-if="opt?.type == 'user'" :user="opt" size="small" />
            <span v-else :id="`m_dropdown_option_label_${opt.value}`">
              {{ opt.label }}
            </span>
          </slot>
        </div>
        <m-icon
          v-if="options.find((f) => f.options?.length)"
          :icon="opt.options?.length ? 'arrow' : 'none'"
          status="active"
          variant="terciary"
          direction="right"
          weight="light"
          :size="size == 'max-width' ? 'default' : size"
          class="ml-2 m-option__arrow"
          @click="select(opt)"
        />
        <m-dropdown
          v-if="opt.options?.length"
          :id="`m_dropdown_${id}_${opt.value.replace(/-/g, '_')}_secondary`"
          :labelledBy="`${id}_option_${opt.value}`"
          :selected="opt.selected"
          :options="opt.options"
          :size="size"
          :visible="openSecondaryDropdown == opt.value"
          v-model:focused="focusedSecondaryOption"
          :keyup="keyup"
          :keydown="keydown"
          :parent="getElement(opt.value)"
          disableSort
          secondary
          :floating="getElement(opt.value) ? true : false"
          class="m-dropdown--secondary"
          v-slot="slotProps"
          @update:visible="toggleSecondaryDropdown(opt)"
          @update:selected="(val) => select(opt, val)"
          @mouseenter="keepDropdownOpen"
          @mouseleave="closeDropdown"
        >
          <slot
            v-if="slotProps.option.value != 'divider'"
            :option="slotProps.option"
          >
            <span>
              {{ slotProps.option.label }}
            </span>
          </slot>
        </m-dropdown>
      </li>
    </ul>
    <div v-if="button">
      <div class="m-dropdown__option m-dropdown__option--divider"></div>
      <m-button
        :id="`m_dropdown_option_${id}_button`"
        :data-test="`test_m_dropdown_${id}_option_button`"
        role="option"
        :aria-selected="focusedIdx == filteredOptions.length ?? null"
        type="contained"
        :leadingIcon="button.icon"
        :variant="button.variant ?? 'secondary'"
        size="small"
        :label="button.label"
        class="m-dropdown__button"
        :class="{
          'm-button--focused': focusedIdx == filteredOptions.length,
        }"
        @mouseenter="focus(filteredOptions.length)"
        @mouseleave="focus(undefined)"
        @click="$emit('button-click')"
      />
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Dropdown component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns
 */

import {
  ref,
  computed,
  watch,
  watchEffect,
  nextTick,
  onMounted,
  onBeforeUnmount,
} from "vue";
import { useI18n } from "vue-i18n";

import TimeFrames from "@utils/enums/timeFrames";
import DateTimeUtils from "@utils/dateTime";
import structuredClone from "@utils/structuredClone";
import { DateTime } from "luxon";

import useWindowSize from "@hooks/useWindowSize";
import useDropdown from "@hooks/useDropdown";

import "v-calendar/dist/style.css";
import icon_list_regular from "@assets/icons/icon_list_regular";

import MInput from "@components/MInput.vue";
import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MSimpleDatePicker from "@components/MSimpleDatePicker.vue";
import MLoading from "@components/MLoading.vue";
import { snakeCase, debounce } from "lodash-es";
import { useUserStore } from "@root/store/modules/user";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useRoute } from "vue-router";
import { useReportsStore } from "@root/store/modules/reports";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `%cComponent id: ${id}`,
          "font-weight:bold",
          `\n Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  visible: { type: Boolean, required: true },
  labelledBy: { type: String, required: true },
  selected: { type: [Number, String, Array, Object, Boolean] },
  size: {
    type: String,
    default: "default",
  },
  options: {
    type: Array,
    default: () => [],
  },
  type: {
    type: String,
    default: "default",
  },
  dateSpecs: {
    type: Object,
    default: () => ({ mode: "date", timeframes: false, columns: 1 }),
  },
  position: {
    type: [Array, String],
    default: () => ["right", "bottom"],
  },
  disableSort: { type: Boolean, default: false },
  canSelectAll: { type: Boolean, default: false },
  search: { type: Boolean, default: true },
  fullwidth: { type: Boolean, default: false },
  query: { type: String },
  button: { type: Object, default: () => undefined },
  timeframes: { type: Boolean, default: false },
  floating: { type: Boolean, default: false },
  focused: { type: [String, Number] },
  noresults: { type: Boolean, default: false },
  noresultsMessage: { type: String },
  keyup: { type: Object },
  keydown: { type: Object },
  parent: { type: [Node, Range, String] },
  secondary: { type: Boolean, default: false },
  loading: { type: Boolean, default: false },
});

const emit = defineEmits([
  "update:visible",
  "update:selected",
  "update:focused",
  "button-click",
  "keep-dropdown-open",
]);

// Props validation
watch(
  () => props,
  () => {
    // size validator
    if (!["default", "small"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size": expected string with value "default" or "small" and got "${props.size}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
      );
    }

    // options validator
    if (props.type != "date" && !props.options) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Missing required prop "options". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
      );
    }
    props.options?.forEach((opt) => {
      if (typeof opt != "object") {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "options": all array elements have to be objects. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.'
        );
      } else {
        if (opt.value === undefined || opt.value === null) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "value" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.'
          );
        }
        if (
          opt.value != "divider" &&
          (opt.label === undefined || opt.label === null)
        ) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "label" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.'
          );
        }
      }
    });

    // type validator
    if (!["default", "multiple", "date", "user", "team"].includes(props.type)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "contentType": expected string with value "default", "multiple", "date", "user" or "team" and got "${props.type}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
      );
    }

    // dateSpecs validator
    if (!["date", "range"].includes(props.dateSpecs?.mode)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "dateSpecs": expected attribute "mode" with value "date" or "range" and got ${props.dateSpecs?.mode}. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
      );
    }
    if (typeof props.dateSpecs?.timeframes != "boolean") {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        '\n Invalid prop "dateSpecs": expected attribute "timeframes" to be a boolean. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.'
      );
    }
    if (![1, 2].includes(props.dateSpecs?.columns)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "dateSpecs": expected attribute "columns" with value with value 1 or 2 and got ${props.dateSpecs?.columns}. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
      );
    }

    // position validator
    if (props.position?.length != 2) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "position": expected an array with two children defining the [ x, y ] positioning. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
      );
    } else {
      if (!["left", "right"].includes(props.position[0])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the first element of the array to be string with value "left" or "right" and got "${props.position[0]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
        );
      }
      if (!["top", "bottom"].includes(props.position[1])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the second element of the array to be string with value "top" or "bottom" and got "${props.position[1]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
        );
      }
    }

    // parent validator
    nextTick(() => {
      if (props.floating && !props.parent) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Missing prop "parent": expected a node or the string "pointer". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589824070/Dropdowns for instructions on how to use the MDropdown component.`
        );
      }
    });
  },
  { immediate: true }
);

const { t } = useI18n();
const userStore = useUserStore();
const appStore = useAppStore();
const route = useRoute();
const workspacesStore = useWorkspacesStore();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const reportStore = useReportsStore();

const report = computed(() =>
  route.params.reportId ? reportStore.getById(route.params.reportId) : null
);

const { windowWidth, windowHeight } = useWindowSize();

const {
  openDropdown: openSecondaryDropdown,
  keepDropdownOpen: keepSecondaryDropdownOpen,
  closeDropdown: closeSecondaryDropdown,
} = useDropdown();

const isClusterRestricted = computed(
  () => !!workspaceConfig.value.personalization?.maxClusterAge
);
const isClusterPage = computed(
  () =>
    route.name.includes("tiles") ||
    route.name.includes("headlines") ||
    route.name.includes("cluster")
);

const maxClusterAge = computed(() => {
  if (
    isClusterPage.value &&
    !!workspaceConfig.value.personalization?.maxClusterAge
  ) {
    const { duration } = DateTimeUtils.parseFromTimeSpan(
      workspaceConfig.value.personalization?.maxClusterAge,
      DateTime.now(),
      { locale: userStore.i18nLanguage }
    );
    return `${duration.as("days").toFixed(0)}`;
  } else return null;
});

const keepDropdownOpen = () => {
  emit("keep-dropdown-open");
  keepSecondaryDropdownOpen();
};

const closeDropdown = () => {
  closeSecondaryDropdown();
  emit("update:visible", false);
};

watch(
  () => props.visible,
  (val) => {
    if (val && props.type == "date") {
      // Hack to change calendar arrow icons to Priberam's icons 🔨
      const vcArrowPrevious = document.getElementsByClassName("vc-prev")[0];
      const vcArrowNext = document.getElementsByClassName("vc-next")[0];

      const arrowSVG = `<svg xmlns="http://www.w3.org/2000/svg" height="20" viewBox="0 0 20 20" role="presentation" class="m-icon__content">${icon_list_regular.arrow.regular}</svg>`;

      if (vcArrowPrevious) {
        vcArrowPrevious.setAttribute("id", "m_dropdown_date_arrow_previous");
        vcArrowPrevious.setAttribute(
          "data-tooltip-content",
          t("components.dropdown.previousMonth")
        );
        vcArrowPrevious.setAttribute("data-tooltip-position", "dynamic");
        vcArrowPrevious.classList.add(
          "m-icon",
          "m-icon--arrow",
          "m-icon--secondary",
          "m-icon--inactive",
          "m-icon--regular",
          "m-icon--highlight",
          "m-icon--left"
        );
        vcArrowPrevious.removeChild(vcArrowPrevious.firstChild);
        vcArrowPrevious.innerHTML = arrowSVG;
      }

      if (vcArrowNext) {
        vcArrowPrevious.setAttribute("id", "m_dropdown_date_arrow_next");
        vcArrowNext.setAttribute(
          "data-tooltip-content",
          t("components.dropdown.nextMonth")
        );
        vcArrowNext.setAttribute("data-tooltip-position", "dynamic");
        vcArrowNext.classList.add(
          "m-icon",
          "m-icon--arrow",
          "m-icon--secondary",
          "m-icon--inactive",
          "m-icon--regular",
          "m-icon--highlight",
          "m-icon--right"
        );
        vcArrowNext.removeChild(vcArrowNext.firstChild);
        vcArrowNext.innerHTML = arrowSVG;
      }
    }
  },
  { immediate: true }
);

const yPos = ref("bottom");

const dropdownClassList = computed(() => {
  let list = `m-dropdown--${props.size}`;

  if (props.button) list += " m-dropdown--button";

  if (finishedCalc.value) list += " m-dropdown--show";

  if (props.options.length > 20) list += " m-dropdown--search";

  if (props.type == "date") {
    list += ` m-dropdown--date m-dropdown--${props.dateSpecs?.columns}`;
  }

  if (!props.floating) {
    let x;
    if (props.secondary) {
      const dropdownBox = dropdownRef.value?.getBoundingClientRect();
      const parentBox =
        dropdownRef.value?.parentElement?.getBoundingClientRect();
      if (
        dropdownBox?.width <
        windowWidth.value - parentBox?.x - parentBox?.width - 12
      ) {
        x = "right";
      } else x = "left";
    } else x = props.position[0];

    list += ` m-dropdown--${x} m-dropdown--${yPos.value}`;
    if (props.fullwidth) list += " m-dropdown--fullwidth";
  } else list += " m-dropdown--floating";

  if (!props.options?.find((f) => f.options?.length)) {
    list += " m-dropdown--overflow";
  }

  return list;
});

const pointerCoordinates = ref({ x: 0, y: 0 });
const setPointerCoordinates = (evt) => {
  pointerCoordinates.value.x = evt.clientX;
  pointerCoordinates.value.y = evt.clientY;
};

const datePicker = ref(null);
const datePickerWrapper = ref(null);

/**
 * 🚨🚨           INFO ON THE STATE            🚨🚨
 * selectedDate transforms the incoming data and the selectedAbsoluteDate emits the
 * new values outisde the component wich in turn will update props.selected
 */

/**
 * @description This is a important flag variable, to avoid an ♾ update loop.
 * When the component is mounted we dont want the calendar to send an event because its the default or its a value that's already in the query object
 */
let isBeeingMounted = false;
onMounted(() => {
  document
    .getElementById("scrollable")
    ?.addEventListener("scroll", closeDropdown);
  if (props.parent == "pointer") {
    window.addEventListener("mousemove", setPointerCoordinates);
  }

  if (props.type != "date") return null;
  isBeeingMounted = true;

  /**
   * In the context of this dropdown beeing a date we need to transform the value
   * from this prop to a date that the m-calendar component can understand.
   * First check if the date beeing passed is relative of absolute
   *
   * 🔥🔥🔥 The type checking is for our own good. Please believe 🔥🔥🔥
   */
  if (props.selected.isRelative == undefined)
    throw new TypeError(
      "If the selected type of dropdown is *date* the *selected* props must either be of type TimeFrame or an Object with and start and end luxon date."
    );
  if (props.selected.isRelative) {
    const luxon = DateTimeUtils.getTimeFrame(
      props.selected.timeFrame,
      userStore.timeZone,
      workspaceConfig.value.documentDateRange,
      report.value
    );
    console.debug(
      "selectedDate computed property updated!",
      props.selected.timeFrame
    );
    selectedDate.value = {
      start: luxon.start.toJSDate(),
      end: luxon.end.toJSDate(),
    };
  } else {
    /** If it is an absolute date we need to make sure its all good */
    if (props.selected.start.isValid) {
      if (props.selected.end && !props.selected.end.isValid)
        throw new TypeError("The end date is not a luxon valid date");
      console.debug("selectedDate computed property updated!", props.selected);
      selectedDate.value = props.selected;
    } else {
      throw new TypeError("The start date is not a luxon valid date");
    }
  }

  isBeeingMounted = false;
});

const selectingCustomDate = ref(false);
const selectingCustomWindowSize = {
  width: 540,
  height: 295,
};
const selectCustomButton = () => {
  previewTemp = datePicker.value.modelValue;
  const calendarDivSize = datePickerWrapper.value?.getBoundingClientRect();
  selectingCustomWindowSize.width = calendarDivSize.width;
  selectingCustomWindowSize.height = calendarDivSize.height;
  stopPreview();
  selectingCustomDate.value = true;
};

const selectDragAttribute = computed(() => ({
  popover: {
    visibility: "hover",
    isInteractive: false,
  },
}));

/**
 * @description variable used to track if preview state is enabled, in this state we can update
 * the calendar value without triggering changes upward the components
 */
let isPreviewing = false;
const selectedDate = ref(null);
const key = ref(0);
const initialPage = computed(() => {
  if (!selectedDate.value) return DateTime.utc().toObject();
  let normalized = selectedDate.value;
  if (!DateTime.isDateTime(selectedDate.value?.start)) {
    normalized = {
      ...selectedDate.value,
      start: DateTime.fromJSDate(selectedDate.value.start),
      end: DateTime.fromJSDate(selectedDate.value.end),
    };
  }
  if (normalized?.start) return normalized?.start.toObject();
  return DateTime.utc().toObject();
});

const selectAbsoluteDate = (timeframe, bypassResctricitons = false) => {
  if (!bypassResctricitons) {
    /** If the user is in a preview state, or if the  dont emit anything */
    if (isPreviewing) return;
    if (isBeeingMounted) {
      isBeeingMounted = false;
      return;
    }
  }

  /** Convert the native javascript date back into luxon dates */
  const luxonTimeframe = {
    start: DateTime.fromJSDate(timeframe.start).startOf("day"),
    end: timeframe.end ? DateTime.fromJSDate(timeframe.end) : null,
  };

  if (
    !luxonTimeframe.end ||
    luxonTimeframe.start.diff(luxonTimeframe.end, "days").days == 0
  ) {
    luxonTimeframe.end = luxonTimeframe.start.endOf("day");
  } else {
    /**
     * The date picker has some weird behaviours choosing the end date, it gets stuck in the current hour of the user, event if we choose another dates in the past.
     * this fixes it. We always want the end of the day.
     */
    luxonTimeframe.end = luxonTimeframe.end.endOf("day");
  }
  luxonTimeframe.isRelative = false;
  console.debug("selecting absolute date", luxonTimeframe);
  emit("update:selected", luxonTimeframe); // This will update props.selected and in turn update selectedDate computed property
  selectedDate.value = timeframe;
  emit("update:visible", false);
};

let previewTemp = null;
let selectingCustomDateInitalValue = false;
const previewRelativeDate = debounce((timeframe) => {
  console.debug("previewing relative date");
  isPreviewing = true;
  selectingCustomDateInitalValue = selectingCustomDate.value;
  selectingCustomDate.value = false;

  if (datePicker.value?.updateValue) {
    if (!previewTemp) previewTemp = structuredClone(selectedDate.value); // Store the value for reseting back to it later
    const luxon = DateTimeUtils.getTimeFrame(
      timeframe,
      userStore.timeZone,
      workspaceConfig.value.documentDateRange,
      report.value
    );
    selectedDate.value = {
      start: luxon.start.toJSDate(),
      end: luxon.end.toJSDate(),
    };
    key.value++;
  }
}, 150);

const stopPreview = (skipRestore = false) => {
  if (!isPreviewing || !previewTemp) return;
  if (!skipRestore) {
    selectedDate.value = previewTemp;
    key.value++;
  }
  isPreviewing = false;
  previewTemp = null;
  selectingCustomDate.value = selectingCustomDateInitalValue;
};

const selectRelativeDate = (timeframe) => {
  selectingCustomDate.value = false;

  if (isBeeingMounted) {
    isBeeingMounted = false;
    return;
  }
  if (isPreviewing) stopPreview(true);
  console.info("selecting relative date");
  emit("update:selected", timeframe); // This will update props.selected and in turn update selectedDate computed property
  emit("update:visible", false);
};

/** @link https://vcalendar.io/calendar/api.html#daterangesource */
const disabledDates = ref([]);
/**  @param day https://vcalendar.io/calendar/api.html#calendarday */
const updateDisabledDates = (day) => {
  if (isClusterPage.value && isClusterRestricted.value) {
    const [days, rest] =
      // eslint-disable-next-line no-unsafe-optional-chaining
      workspaceConfig.value.personalization?.maxClusterAge?.split(".");

    const luxonDay = DateTime.fromJSDate(day.startDate);
    const result = [
      {
        start: luxonDay.plus({ days }).toJSDate(),
      },
      {
        end: luxonDay.minus({ days }).toJSDate(),
      },
    ];

    disabledDates.value = result;
  } else {
    disabledDates.value = [];
  }
};

const dropdownRef = ref(null);
const dropdownStyle = ref("");
const optionRefs = ref([]);
const focusedIdx = ref(null);
const focusIdx = ref(null);
const secondaryDropdownFocused = ref(null);
const focusedSecondaryOption = ref(null);
const lastFocusedIdx = ref(null);

const input = ref(null);
const searchQuery = ref("");
const searchIsActive = ref(false);

const maxHeight = ref(undefined);
const finishedCalc = ref(false);

const activateSearch = () => {
  searchIsActive.value = true;
  input.value?.focus();
};

const calcPosition = () => {
  let dropdownBox;
  let parentBox;

  if (props.floating) {
    if (
      props.floating &&
      props.parent != "pointer" &&
      dropdownRef.value?.parentElement.nodeName == "BODY"
    ) {
      document.body.removeChild(dropdownRef.value);
      if (props.parent.innerHTML) {
        props.parent.appendChild(dropdownRef.value);
      }
    }

    if (props.parent == "pointer") {
      parentBox = pointerCoordinates.value;
      parentBox.width = 0;
      parentBox.height = 16;
    } else parentBox = props.parent?.getBoundingClientRect();

    dropdownBox = dropdownRef.value?.getBoundingClientRect();

    if (props.secondary) {
      // 4 stands for the $spacing-1 horizontal padding inside the dropdown
      if (
        dropdownBox.width <
        windowWidth.value - parentBox.x - parentBox.width - 8 - 12
      ) {
        // Dropdown can open right freefly
        dropdownStyle.value = `left: ${parentBox.x + parentBox.width + 4}px;`;
      } else {
        // Dropdown needs to open left
        dropdownStyle.value = `right: ${
          windowWidth.value - parentBox.x + 4
        }px;`;
      }
    } else {
      if (dropdownBox.width < windowWidth.value - parentBox.x - 12) {
        // Dropdown can open right freefly
        dropdownStyle.value = `left: ${parentBox.x}px;`;
      } else {
        // Dropdown needs adjusting
        const moveleft =
          windowWidth.value - parentBox.x - dropdownBox.width - 12;
        // $spacing-2
        dropdownStyle.value = `left: ${parentBox.x}px; transform: translateX(${moveleft}px);`;
      }
    }

    if (
      windowHeight.value * 0.2 <
      windowHeight.value - (parentBox.y + parentBox.height)
    ) {
      // Dropdown can open bottom
      let movetop;
      if (props.secondary) {
        movetop = parentBox.y - 4;
      } else movetop = parentBox.y + parentBox.height;
      dropdownStyle.value += ` top: ${movetop}px;`;
    } else {
      // Dropdown needs to open top
      let movebottom;
      if (props.secondary) {
        movebottom = windowHeight.value - parentBox.y + parentBox.height - 4;
      } else movebottom = windowHeight.value - parentBox.y;
      dropdownStyle.value += ` bottom: ${movebottom}px;`;
    }

    if (props.fullwidth) {
      dropdownStyle.value += `min-width: ${parentBox.width - 8}px;`; // $spacing-2
    }

    let isChild = false;
    if (props.parent?.childNodes) {
      isChild = Array.from(props.parent.childNodes)?.find((f) =>
        f?.className?.includes("m-dropdown")
      );
    }

    if (isChild) {
      props.parent.removeChild(dropdownRef.value);
    }
    document.body.appendChild(dropdownRef.value);
    yPos.value = "";
  } else {
    dropdownBox = dropdownRef.value?.getBoundingClientRect();
    if (props.position[0] == "right") {
      if (dropdownBox.width > windowWidth.value - dropdownBox.x - 12) {
        // Dropdown opens right
        const moveleft =
          windowWidth.value - dropdownBox.x - dropdownBox.width - 12;
        // $spacing-2
        dropdownStyle.value = `transform: translateX(${moveleft}px);`;
      }
    } else if (props.position[0] == "left") {
      const parentBox =
        dropdownRef.value?.parentElement?.getBoundingClientRect();
      if (dropdownBox.width > parentBox.x + parentBox.width - 12) {
        // Dropdown opens left
        const moveright =
          dropdownBox.width - parentBox.x - parentBox.width + 12;
        // $spacing-2
        dropdownStyle.value = `transform: translateX(${moveright}px);`;
      }
    }

    if (windowHeight.value * 0.15 > windowHeight.value - dropdownBox.y) {
      // Dropdown needs to open top
      yPos.value = "top";
    }
  }

  let max;
  if (yPos.value == "top" || dropdownStyle.value.includes("bottom")) {
    // Dropdown opens top
    max = dropdownBox?.y;
  } else {
    // Dropdown opens bottom
    if (parentBox) max = windowHeight.value - (parentBox?.height + parentBox.y);
    else max = windowHeight.value - dropdownBox?.y;
  }

  if (props.button) {
    // 32 and 40 refers to the button height
    // 8 refers to padding
    // 20 no idea where it comes from but is needed
    if (props.size == "small") max -= 32 - 8;
    else max -= 40 - 8;
  }

  if (props.options.length > 20) {
    if (props.size == "small") max -= 32 - 8;
    else max -= 40 - 8;
  }

  if (dropdownBox.height >= max - 32) max -= 32;
  if (dropdownBox.height > max) {
    maxHeight.value = `max-height: ${max}px;`; // $spacing-7}
  }

  if (props.options.length > 20) activateSearch();
  if (props.secondary) focus(0);

  finishedCalc.value = true;
};

const focus = (idx, timeframe) => {
  let i;

  if (props.type == "date" && timeframe) {
    previewRelativeDate(timeframe);
  }

  if (!idx && (typeof focusIdx.value == "number" || focusIdx.value == "last")) {
    if (focusIdx.value == "last") {
      i = filteredOptions.value?.length - 1;
    } else i = focusIdx.value;
  } else if (idx === 0 || idx > 0) i = idx;

  if (typeof i == "number") {
    const opt = filteredOptions.value[i];
    focusedIdx.value = i;
    emit("update:focused", opt?.value || "button");
  } else {
    focusedIdx.value = undefined;
    emit("update:focused", undefined);
  }
};

const isSelected = (opt) => {
  if (opt?.options?.length && focusedSecondaryOption.value) {
    return appStore.a11y && opt.value == openSecondaryDropdown.value;
  } else if (props.type == "multiple") {
    if (
      props.selected.length == props.options?.length ||
      props.selected?.find((f) => f == "all")
    ) {
      return true;
    } else
      return Boolean(
        props.selected?.find((f) => f?.value == opt.value) ||
          props.selected?.find((f) => f == opt.value)
      );
  } else {
    return props.selected == opt.value;
  }
};

const isFocused = (opt) => {
  const idx = filteredOptions.value.findIndex((f) => f.value == opt.value);
  if (isSelected(opt) && opt?.options?.length) {
    return false;
  } else return focusedIdx.value == idx;
};

const toggleSecondaryDropdown = (opt) => {
  if (opt) openSecondaryDropdown.value = opt.value;
  else openSecondaryDropdown.value = null;
};

const showIcon = (opt) => {
  return (
    props.type == "multiple" ||
    opt.icon ||
    (props.options?.findIndex((f) => f.value == "divider") == -1 &&
      props.options?.findIndex((f) => f.icon) != -1)
  );
};

const setOptIcon = (opt) => {
  if (props.type == "multiple") {
    if (
      props.selected?.length == props.options?.length ||
      props.selected?.find((f) => f == "all")
    ) {
      return "check";
    } else {
      return props.selected?.find((f) => f?.value == opt.value) ||
        props.selected?.find((f) => f == opt.value)
        ? "check"
        : "none";
    }
  } else {
    return opt.icon ?? "none";
  }
};

const iconWeight = (opt) => {
  if (
    props.type == "multiple" &&
    props.selected?.find((f) => f.value == opt.value)
  ) {
    return "bold";
  } else return "light";
};

const timeOptions = computed(() => {
  const opts = TimeFrames.ToArray()
    .filter((x) => {
      let result = true;
      //Filters out values according to the context here
      if (
        Math.abs(
          DateTime.now()
            .startOf("day")
            .diff(
              workspaceConfig.value.documentDateRange.newest.startOf("day"),
              "days"
            ).days ?? 0
        ) > 1
      ) {
        const diffNowInDays = DateTime.now()
          .startOf("day")
          .diff(workspaceConfig.value.documentDateRange.newest, "days").days;
        const diffNowInMonths = DateTime.now()
          .startOf("day")
          .diff(
            workspaceConfig.value.documentDateRange.newest,
            "months"
          ).months;

        switch (x) {
          case TimeFrames.Latest:
            result = true;
            break;
          case TimeFrames.LastHour:
          case TimeFrames.Today:
          case TimeFrames.Last24Hours:
            result = false;
            break;
          case TimeFrames.Yesterday:
            result = diffNowInDays <= 1;
            break;
          case TimeFrames.Last3days:
            result = diffNowInDays <= 3;
            break;
          case TimeFrames.ThisWeek:
            result =
              DateTime.now()
                .startOf("week")
                .diff(workspaceConfig.value.documentDateRange.newest, "days")
                .days <= 0;
            break;
          case TimeFrames.Last7Days:
            result = diffNowInDays <= 7;
            break;
          case TimeFrames.Last30Days:
            result = diffNowInDays <= 30;
            break;
          case TimeFrames.Last6Months:
            result = diffNowInMonths <= 6;
            break;
          case TimeFrames.Last12Months:
            result = diffNowInMonths <= 12;
            break;
          case TimeFrames.LastWeek:
            result =
              DateTime.now()
                .minus({ week: 1 })
                .startOf("week")
                .diff(workspaceConfig.value.documentDateRange.newest, "days")
                .days <= 0;
            break;
          case TimeFrames.ThisMonth:
            result =
              DateTime.now()
                .startOf("month")
                .diff(workspaceConfig.value.documentDateRange.newest, "days")
                .days <= 0;
            break;
          case TimeFrames.LastMonth:
            result =
              DateTime.now()
                .startOf("month")
                .diff(workspaceConfig.value.documentDateRange.newest, "days")
                .days <= 0;
            break;
          case TimeFrames.ThisYear:
            result =
              DateTime.now()
                .startOf("year")
                .diff(workspaceConfig.value.documentDateRange.newest, "days")
                .days <= 0;
            break;
        }
      } else if (x == TimeFrames.Latest) result = false;

      if (isClusterPage.value && isClusterRestricted.value) {
        switch (x) {
          case TimeFrames.SinceSnapshot:
          case TimeFrames.Last6Months:
          case TimeFrames.ThisYear:
          case TimeFrames.Last12Months:
          case TimeFrames.All:
            result = false;
            break;
        }
      }

      if (x == TimeFrames.SinceSnapshot) {
        if (route.params?.reportId) result = true;
        else result = false;
      }

      return result;
    })
    .map((m) => ({
      timeframe: m,
      value: m.value,
      label: t(`general.time.${m.value}`),
    }));

  if (!isClusterPage.value || !isClusterRestricted.value)
    opts.push({ value: "custom", label: t("general.time.custom") });
  return opts;
});

const filteredOptions = computed(() => {
  let filtered = [];
  filtered = [...props.options];

  if (!props.disableSort) {
    const dividerIdx = filtered.findIndex((f) => f.value == "divider");
    if (dividerIdx > -1) {
      const arr1 = [...props.options]
        .splice(0, dividerIdx)
        .sort((a, b) => a.label.localeCompare(b.label));
      arr1.push({ value: "divider", label: "divider" });
      const arr2 = filtered
        .splice(dividerIdx + 1, filtered.length)
        .sort((a, b) => a.label.localeCompare(b.label));
      filtered = arr1.concat(arr2);
    } else {
      filtered = filtered.sort((a, b) => a.label.localeCompare(b.label));
    }
  }

  if (props.options.length > 20) {
    if (searchQuery.value?.trim()?.length == 0) {
      filtered = props.options;
    } else {
      filtered.splice(0, filtered.length);

      props.options.forEach((f) => {
        const opt = f.label.toLowerCase().split(" ");
        opt.forEach((x) => {
          x = x.replace(/[^a-zA-Z0-9]/g, "");
          if (x.startsWith(searchQuery.value.toLowerCase())) {
            filtered.push(f);
          }
        });
      });
    }
    return filtered;
  } else {
    const featured = filtered.filter((f) => f.featured);

    if (featured.length) featured.push({ value: "divider" });
    const list = featured.concat(filtered.filter((f) => !f.featured));

    if (
      props.type == "multiple" &&
      props.options?.length > 2 &&
      props.canSelectAll
    ) {
      list.unshift({ value: "all", label: t("components.dropdown.all") });
    }

    return list;
  }
});

const setOptionRef = (el) => {
  if (el) optionRefs.value.push(el);
};

const activeDescendant = computed(() => {
  if (props.type == "date") return "";

  const s = props.options?.find((f) => {
    if (props.type == "multiple") {
      return f.value == props.selected[0];
    } else return f.value == props.selected;
  });

  if (s) return `${props.id}_${snakeCase(s.value)}`;
  else return "";
});

watch(
  () => props.visible,
  (val) => {
    if (val) {
      dropdownStyle.value = "";
      maxHeight.value = "";
      nextTick(() => {
        if (
          props.type == "date" ||
          props.options.length != 0 ||
          props.noresults
        ) {
          calcPosition();
        }
      });
    } else {
      openSecondaryDropdown.value = undefined;
      maxHeight.value = "";
    }
  },
  { immediate: true }
);

watch(
  () => props.parent,
  (val, oldVal) => {
    if (val != oldVal && props.visible && props.floating) {
      dropdownStyle.value = "";
      maxHeight.value = "";
      nextTick(() => {
        calcPosition();
      });
    } else {
      openSecondaryDropdown.value = undefined;
      maxHeight.value = "";
    }
  }
);

const select = (opt, secOpt) => {
  if (opt) {
    if (opt.disabled) return;
    if (props.type == "date") {
      if (opt.value == "custom") selectCustomButton();
      else selectRelativeDate(opt.timeframe);
    } else if (opt?.options?.length) {
      if (
        (appStore.a11y && focusedSecondaryOption.value) ||
        (!appStore.a11y && secOpt)
      ) {
        emit("update:selected", opt, secOpt);
        closeSecondaryDropdown();
        emit("update:visible", false);
      } else if (openSecondaryDropdown.value == opt.value) {
        closeSecondaryDropdown();
      } else openSecondaryDropdown.value = opt.value;
    } else {
      if (props.type == "multiple" && opt.value == "all") {
        emit("update:selected", props.options); //Select all
      } else emit("update:selected", opt);

      if (props.type != "multiple") {
        closeSecondaryDropdown();
        emit("update:visible", false);
      }
    }
  }
};

const moveUp = (evt) => {
  const focusedEl = document.getElementsByClassName(
    "m-dropdown__option--focused"
  )[0];
  const idx = parseInt(focusedEl?.attributes?.["data-idx"]?.value ?? 0);

  if (idx == 0) return;
  if (props.secondary) {
    if (focusedEl?.attributes["data-secondary"].value != "true") return;
    if (props.visible && evt.altKey && idx > -1) {
      // alt + up
      select(filteredOptions.value[idx]);
      if (props.type == "multiple") focus(idx);
    } else if (idx > 0) {
      focus(undefined);
      let focuson;
      if (filteredOptions.value[idx - 1]?.value != "divider") {
        focuson = idx - 1;
      } else focuson = idx - 2;
      focus(focuson);
    } else focus(undefined);
  } else if (!secondaryDropdownFocused.value) {
    if (props.visible && evt.altKey && idx > -1) {
      // alt + up
      select(filteredOptions.value[idx]);
      if (props.type == "multiple") focus(idx);
    } else if (idx > 0) {
      focus(undefined);
      let focuson;
      if (filteredOptions.value[idx - 1]?.value != "divider") {
        focuson = idx - 1;
      } else focuson = idx - 2;
      focus(focuson);
    } else focus(0);
  }

  if (maxHeight.value) {
    document
      .querySelectorAll(`[data-idx="${focusedIdx.value}"]`)[0]
      ?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }
};

const moveDown = (evt) => {
  const focusedEl = document.getElementsByClassName(
    "m-dropdown__option--focused"
  )[0];
  const idx = parseInt(focusedEl?.attributes?.["data-idx"]?.value ?? 0);

  if (idx === filteredOptions.value?.length - 1) return;
  if (props.secondary) {
    if (focusedEl?.attributes["data-secondary"].value != "true") return;
    if (idx > -1) {
      focus(undefined);
      let focuson;
      if (filteredOptions.value[idx + 1]?.value != "divider") {
        focuson = idx + 1;
      } else focuson = idx + 2;
      focus(focuson);
    } else focus(undefined);
  } else if (!secondaryDropdownFocused.value) {
    if (idx > -1) {
      focus(undefined);
      let focuson;
      if (filteredOptions.value[idx + 1]?.value != "divider") {
        focuson = idx + 1;
      } else focuson = idx + 2;
      focus(focuson);
    } else focus(0);
  }

  if (maxHeight.value) {
    document
      .querySelectorAll(`[data-idx="${focusedIdx.value}"]`)[0]
      ?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }
};

const getElement = (val) => {
  return document.getElementById(`${props.id}_option_${val}`);
};

watch(
  () => props.keyup,
  (evt) => {
    const focusedEl = document.getElementsByClassName(
      "m-dropdown__option--focused"
    )[0];
    const idx = parseInt(focusedEl?.attributes?.["data-idx"]?.value ?? -1);
    const opt = filteredOptions.value[idx];

    switch (evt?.keyCode) {
      case 27: // escape
        emit("update:visible", false);
        break;
      case 13: // enter
      case 32: // space
        if (props.visible && idx > -1) select(opt);
        if (props.secondary) focus(0);
        else if (opt?.options?.length) {
          lastFocusedIdx.value = idx;
          secondaryDropdownFocused.value = true;
          focus(undefined);
        }
        break;
      case 33: // page up
        if (props.visible) {
          if (idx - 10 > 0) {
            let focuson;
            if (filteredOptions.value[idx - 10].value != "divider") {
              focuson = idx - 10;
            } else focuson = idx - 11;
            focus(focuson);
          } else if (props.secondary) focus(undefined);
          else focus(0);
        }
        break;
      case 34: // page down
        if (props.visible) {
          if (idx + 10 < filteredOptions.value.length - 1) {
            let focuson;
            if (filteredOptions.value[idx + 10].value != "divider") {
              focuson = idx + 10;
            } else focuson = idx + 11;
            focus(focuson);
          } else focus(filteredOptions.value.length - 1);
        }
        break;
      case 35: // end
        focus(filteredOptions.value.length - 1);
        break;
      case 36: // home
        if (props.secondary) focus(undefined);
        else focus(0);
        break;
      case 37: // left
        if (props.secondary) focus(undefined);
        else {
          secondaryDropdownFocused.value = false;
          focus(lastFocusedIdx.value);
        }
        lastFocusedIdx.value = null;
        break;
      case 38: // up
        moveUp(evt);
        break;
      case 39: // right
        if (opt?.options?.length) {
          if (props.visible && idx > -1) select(opt);
          if (props.secondary) focus(0);
          else {
            lastFocusedIdx.value = idx;
            secondaryDropdownFocused.value = true;
            focus(undefined);
          }
        }
        break;
      case 40: // down
        moveDown(evt);
        break;
    }
  },
  { immediate: true }
);

watch(
  () => props.keydown,
  (evt) => {
    if (evt?.keyCode == 9) {
      const focusedEl = document.getElementsByClassName(
        "m-dropdown__option--focused"
      )[0];
      const idx = parseInt(focusedEl?.attributes?.["data-idx"]?.value ?? 0);
      if (props.visible && idx > -1) {
        select(filteredOptions.value[idx]);
      }
      emit("update:visible", false);
    }
  },
  { immediate: true }
);

const inputKeyup = (evt) => {
  if (evt?.keyCode == 38) moveUp();
  if (evt?.keyCode == 40) moveDown();
};

watchEffect(() => {
  if (!props.visible) {
    openSecondaryDropdown.value = undefined;
    maxHeight.value = "";
    focus(undefined);
  }
});

watch(
  () => props.query,
  (val, oldVal) => {
    if (val != "" && val != oldVal) {
      const idx = filteredOptions.value.findIndex((f) =>
        f.label.toLowerCase().startsWith(val)
      );

      if (idx > -1) focus(idx);
      else focus(undefined);
    }
  },
  { immediate: true }
);

onBeforeUnmount(() => {
  if (props.floating && dropdownRef.value?.parentElement?.nodeName == "BODY") {
    document.body.removeChild(dropdownRef.value);
  }
  document
    .getElementById("scrollable")
    ?.removeEventListener("scroll", closeDropdown);
  if (props.parent == "pointer") {
    document.removeEventListener("mousemove", setPointerCoordinates);
  }
});

defineExpose({
  dropdown: dropdownRef,
  focusIdx,
  focus: focus,
});
</script>

<style lang="scss">
.m-dropdown__simple-date-picker__wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: $spacing-1 $spacing-3;
}

.m-max-cluster-age {
  &__text {
    color: $text-light;
  }
}
</style>
