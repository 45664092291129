<template>
  <div
    ref="htmlElement"
    :id="`m_headline_item_${id}`"
    class="m-container__item"
  >
    <div v-if="props.idx < 2 || showInDOM">
      <div v-if="requestCompleted">
        <div v-if="!responseHasData">
          <h6>
            {{ $t("views.headlines.noArticles") }}
          </h6>
        </div>
        <div v-else>
          <div class="m-headline-item__header">
            <div class="m-header__details">
              <div class="mb-2 m-details__iptcs">
                <h4 class="mr-1 type--small">
                  {{ translatedAggregator }}
                </h4>
                <h6
                  v-for="(topic, i) in sortedIptc.show"
                  :key="i"
                  class="ml-2 type--small"
                >
                  {{ topic }}
                  <span
                    v-if="
                      i < sortedIptc.show.length - 1 ||
                      sortedIptc.hide.length != 0
                    "
                    class="ml-2 m-details__divider"
                    >|
                  </span>
                </h6>
                <h6
                  v-if="sortedIptc.hide.length != 0"
                  :id="`m_headline_item_${id}_other`"
                  :data-tooltip-content="sortedIptc.hide.join(', ')"
                  data-tooltip-position="dynamic"
                  class="ml-2 type--small m-details__divider"
                >
                  {{ `+ ${sortedIptc.hide.length}` }}
                </h6>
              </div>
              <h6 class="ml-1 type--xsmall">
                <!-- TODO DGF missing feeds -->
                {{
                  $t("views.headlines.articlesCount", {
                    articles: cluster?.totalNumOfDocs,
                    feeds: undefined,
                    languages: cluster?.metadata?.available_languages?.length,
                    feeds: feeds.length,
                  })
                }}
              </h6>
            </div>
            <div class="m-headline-item__actions">
              <m-icon
                id="headline_create_view"
                :tooltip="createViewTooltip"
                icon="view-filter"
                variant="secondary"
                hover="elevate"
                @click="createView"
              />
              <m-icon
                id="headline_open_story"
                :tooltip="openStoryTooltip"
                icon="story"
                variant="secondary"
                hover="elevate"
                @click="gotoClusterPage(cluster.metadata.id)"
              />
            </div>
          </div>
          <m-top-cluster
            v-if="requestCompleted"
            :cluster="cluster.documents"
            @update:cluster="updateCluster"
          />
        </div>
      </div>
      <m-headline-skeleton v-else />
    </div>
    <div v-else :class="`placeholder i${props.idx}`"></div>
  </div>
</template>

<script setup>
import { ref, unref, computed, onMounted } from "vue";
import { useI18n } from "vue-i18n";

import { useRouter, useRoute } from "vue-router";
import { useViewFilters } from "@hooks/useViewFilters";
import { useApi } from "@api/api";
import useWindowSize from "@hooks/useWindowSize";
import MIcon from "@components/MIcon.vue";
import MTopCluster from "@components/MTopCluster.vue";
import MHeadlineSkeleton from "@components/skeletons/MHeadlineSkeleton.vue";
import useCreateViewModal from "@components/modals/MCreateView/useCreateViewModal";
import { useViewsStore } from "@root/store/modules/views";
import { useClustersStore } from "@root/store/modules/clusters";

const props = defineProps({
  id: String,
  clusterMetadata: Object,
  idx: Number, //Outside index, for lazy lading reasons
});

const emit = defineEmits(["request-completed"]);

const { t, te } = useI18n();
const viewsStore = useViewsStore();
const clustersStore = useClustersStore();
const { api } = useApi();
const router = useRouter();
const { windowWidth } = useWindowSize();
const route = useRoute();
const {
  queryObject,
  dateRestriction,
  sortBy,
  aggregator,
  aggregatorOpts,
  navigateWithQueryObject,
} = useViewFilters(router, route);

const viewId = computed(() => route.params.viewId);
const translatedAggregator = computed(() => {
  if (te(`general.facets.${aggregator.value}`))
    return t(`general.facets.${aggregator.value}`);
  else {
    return aggregatorOpts.value.find((x) => x.value == aggregator.value)?.label;
  }
});

const requestCompleted = ref(false);

const responseHasData = ref(false);

const { open: openCreateViewModal } = useCreateViewModal();

const gotoClusterPage = (clusterId) => {
  const query = { ...queryObject.value }; //Avoid structuredClone() here! It removes needed functions!
  query.contextualFilters = [
    {
      value: "crossClusterId",
      query: [
        {
          label: clustersStore.getClusterNameById(clusterId),
          value: clusterId,
          propertyType: "crossClusterId",
        },
      ],
    },
  ];
  navigateWithQueryObject(query, "headlines-cluster", {
    viewId: viewId.value,
    clusterId: clusterId,
  });
};

const feeds = ref([]);
const cluster = ref({
  metadata: props.clusterMetadata,
  info: null, // Populated in getClusterData()
  documents: null, // Populated in getClusterData()
  totalNumOfDocs: null, // Populated in getClusterData()
});

const updateCluster = (id, key, val) => {
  const idx = cluster.value.documents.findIndex((f) => f.id == id);
  cluster.value.documents[idx][key] = val;
};

const getClusterData = async (metadata) => {
  const filters = structuredClone(queryObject.value.filters ?? []);

  const response = await api.search.cluster(
    unref(viewId),
    metadata.id,
    dateRestriction.value,
    queryObject.value.aggregator ?? aggregator.value,
    queryObject.value.sortBy ?? sortBy.value,
    0,
    5,
    filters
  );
  if (response.status != 200) return;
  if (!response.data.cluster) return;

  if (response.data.cluster == null || response.data.cluster == undefined) {
    responseHasData.value = false;
  } else {
    responseHasData.value = true;
    /**
     * There's ALOT of data on this API call
     * The structure of the data that returns is somehtings like:
     * {
     *  cluster: Information about the cluster itself
     *  dates:
     *  performace:
     *  properties:
     *  result: {
     *    documents: An array with the documents themselves
     *    numDocuments: A Dictionary with information about the number of documents per search engine
     *  },
     *  summary: {
     *    api_response: {
     *      highlights: An array with the highlight of the cluster
     *    }
     *  }
     * }
     * ATTENTION: This object is not complete per any mwans this is just a reference
     */

    cluster.value.data = response.data.cluster;
    cluster.value.documents = response.data.result?.documents;
    feeds.value = response.data.properties.filter(
      (x) => x.type == "sourceItemFeedNames"
    );

    // update the available languages of the cluster here.
    cluster.value.metadata.available_languages =
      response.data.properties.reduce((p, c) => {
        if (c.type == "sourceLanguage") p.push(c.key);
        return p;
      }, []);

    cluster.value.totalNumOfDocs = response.data.result?.numDocuments;
    requestCompleted.value = true;
    emit("request-completed", true);
  }

  requestCompleted.value = true;
};

/**
 * IntersectionObserver code
 * It decides if the element is showing on screen or not.
 * If it is we do an API call to get the needed data, if not stay hidden and do nothing
 */
const htmlElement = ref(null);
const isOnScreen = ref(false);
const showInDOM = ref(false);

let observerWasInitiated = false;
const handleIntersection = (entries) => {
  const info = entries[0];

  isOnScreen.value = info.isIntersecting;

  if (info.isIntersecting) {
    showInDOM.value = true;
    if (!cluster.value.documents) getClusterData(props.clusterMetadata);
  }
};
const observer = new IntersectionObserver(handleIntersection, {
  rootMargin: "20% 0%",
  threshold: [0, 0.75],
});

const sortedIptc = computed(() => {
  const sorted = cluster.value?.metadata?.sorted_topics.map((m) => m.topic);
  let max;
  if (windowWidth.value < 1280) max = 2;
  else if (windowWidth.value < 1440) max = 3;
  else max = 4;

  return {
    show: sorted?.slice(0, max),
    hide: sorted?.slice(max, sorted.length - 1) || [],
  };
});

onMounted(() => {
  /** Initialize an intersectionObserver for each row to fetch the data on demand */
  if (!observerWasInitiated) {
    observer.observe(htmlElement.value);
    observerWasInitiated = true;
  }
});

const openStoryTooltip = computed(() => {
  return {
    content: t("views.headlines.openCluster"),
    position: "bottom-left",
  };
});

const createViewTooltip = computed(() => {
  return {
    content: t("views.headlines.createView"),
    position: "bottom-left",
  };
});

const createView = () => {
  const filters = [];
  const newFilter = {
    facets: [
      {
        value: "crossClusterId",
        query: [
          {
            label: cluster.value.data.title,
            thumbnail: cluster.value.documents[0].thumbnail,
            value: cluster.value.data.id,
            propertyType: "crossClusterId",
          },
        ],
      },
    ],
  };
  filters.push(newFilter);
  viewsStore.setFilters(filters);
  openCreateViewModal("fromStory");
};
</script>

<style scoped lang="scss">
.m-headline-item {
  .m-container {
    &__item {
      padding-bottom: $spacing-6;
      margin-bottom: $spacing-6;

      &.rendered {
        padding-bottom: $spacing-7;
        margin-bottom: $spacing-7;
      }
    }
  }

  &__header {
    margin-bottom: $spacing-2;
    @include flex(space-between, flex-end, row);

    .m-header__details {
      @include flex(flex-start, flex-start, column);

      .m-details {
        &__iptcs {
          padding: math.div($spacing-1, 2) $spacing-2;
          margin-bottom: $spacing-1;
          border: 1px solid color($sec-300);
          @include round-corners($spacing-1);
          @include flex(flex-start, center, row);
          background-color: color($sec-100);

          * {
            @include flex(flex-start, center, row);
            white-space: nowrap;
          }
        }

        &__divider {
          color: color($sec-600);
          @include opacity-disabled;
        }
      }
    }
  }

  &__actions {
    margin-right: $spacing-1;
    @include flex(flex-start, flex-start, row);
    gap: $spacing-2;
  }
}

.placeholder {
  height: 2px;

  &.i0,
  &.i1,
  &.i2,
  &.i3 {
    height: 700px;
  }
}
</style>
