/**
 * Enum class to represent the diferent relatives dates that exist in Monitio
 * Avoid using random magic strings in the code. Use this instead
 */

export default class TimeFrame {
  static Latest = new TimeFrame("latest");

  static SinceSnapshot = new TimeFrame("sinceSnapshot");

  static LastHour = new TimeFrame("lastHour");
  static Today = new TimeFrame("today");
  static Last24Hours = new TimeFrame("last24Hours");

  static Yesterday = new TimeFrame("yesterday");
  static Last3days = new TimeFrame("last3Days");

  static ThisWeek = new TimeFrame("thisWeek");
  static Last7Days = new TimeFrame("last7Days");
  static LastWeek = new TimeFrame("lastWeek");

  static ThisMonth = new TimeFrame("thisMonth");
  static Last30Days = new TimeFrame("last30Days");
  static LastMonth = new TimeFrame("lastMonth");
  static Last6Months = new TimeFrame("last6Months");

  static ThisYear = new TimeFrame("thisYear");
  static Last12Months = new TimeFrame("last12Months");

  static All = new TimeFrame("all");

  constructor(value) {
    this.value = value;
  }

  static valueOf() {
    return this.value;
  }

  /**
   * REturns a array of strings with the values
   * @returns {string[]}
   */
  static ToStringArray() {
    const props = Object.getOwnPropertyNames(TimeFrame);
    return this.FilterProperties(props);
  }

  /**
   * Returns an array of all the available TimeFrames Enums
   * @returns {TimeFrame[]}
   */
  static ToArray() {
    return this.FilterProperties(Object.getOwnPropertyNames(TimeFrame)).map(
      (key) => TimeFrame[key]
    );
  }

  /**
   * Try to create a new instance of Timeframe based on a given string
   * @param {string} string
   * @returns {TimeFrame | undefined}
   */
  static TryParseFromString(string) {
    const normalizedStr = string
      .replaceAll(" ", "")
      .replaceAll("_", "")
      .toLowerCase();
    switch (normalizedStr) {
      case "sincesnapshot":
        return this.SinceSnapshot;
      case "latest":
        return this.Latest;
      case "today":
        return this.Today;
      case "yesterday":
        return this.Yesterday;
      case "lasthour":
        return this.LastHour;
      case "last24hours":
        return this.Last24Hours;
      case "last3days":
        return this.Last3days;
      case "last7days":
        return this.Last7Days;
      case "last30days":
        return this.Last30Days;
      case "thisweek":
        return this.ThisWeek;
      case "lastweek":
        return this.LastWeek;
      case "thismonth":
        return this.ThisMonth;
      case "lastmonth":
        return this.LastMonth;
      case "last6months":
        return this.Last6Months;
      case "last12months":
        return this.Last12Months;
      case "thisyear":
        return this.ThisYear;
      case "all":
        return this.All;
      default:
        return undefined;
    }
  }

  /**
   * Removes the properties of the class that are not needed
   * like FilterProperties, ToArray, prototype... etc
   * @param {string[] | TimeFrame[]} array
   * @returns
   */
  static FilterProperties(array) {
    return array.filter(
      (x) =>
        x != "TryParseFromString" &&
        x != "FilterProperties" &&
        x != "ToStringArray" &&
        x != "ToArray" &&
        x != "prototype" &&
        x != "valueOf" &&
        x != "length" &&
        x != "name"
    );
  }
}
