<template>
  <div
    :id="id"
    ref="iconRef"
    :data-test="status == 'inactive' ? `test_${id}` : undefined"
    :tabindex="tabIndex"
    :role="status == 'inactive' ? 'button' : ''"
    class="m-icon"
    :class="[
      classList,
      { 'm-icon--disabled': disabled, '--a11y': appStore.a11y },
    ]"
    @mouseenter="onHover = true"
    @mouseleave="onHover = false"
    @keyup.enter.stop.prevent="click"
    @click.stop.prevent="click"
  >
    <svg
      v-if="icon_list_regular[icon] || icon_list_other[icon]"
      xmlns="http://www.w3.org/2000/svg"
      :height="getHeight"
      :viewBox="getViewBox"
      role="presentation"
      class="m-icon__content"
    >
      <g v-html="content"></g>
    </svg>
    <span
      v-else
      class="material-icons-round m-icon__content"
      :style="{ fontSize: `${getSpanSize}rem` }"
    >
      {{ icon }}
    </span>
    <div
      :id="`m_icon_${id}_mask`"
      :data-tooltip-content="tooltip?.content ?? tooltip"
      :data-tooltip-template="tooltip?.template ?? null"
      :data-tooltip-position="tooltip?.position ?? 'bottom-center'"
      class="m-icon--mask"
    ></div>
  </div>
</template>

<script setup>
/*
 * Monitio Icon component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons
 */

import { ref, computed, watch, onMounted } from "vue";

import icon_list_chart from "@assets/icons/icon_list_chart";
import icon_list_other from "@assets/icons/icon_list_other";
import icon_list_xsmall from "@assets/icons/icon_list_xsmall";
import icon_list_small from "@assets/icons/icon_list_small";
import icon_list_regular from "@assets/icons/icon_list_regular";
import icon_list_large from "@assets/icons/icon_list_large";
import { useAppStore } from "@root/store/app";

const props = defineProps({
  id: {
    type: String,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  tabindex: { type: [Number, String] },
  icon: { type: String, required: true },
  weight: { type: String, default: "regular" },
  variant: { type: String, default: "primary" },
  status: { type: String, default: "inactive" },
  size: { type: String, default: "default" },
  direction: { type: String, default: "up" },
  hover: { type: String, default: "default" },
  tooltip: { type: [String, Object] },
  type: { type: String },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits(["click"]);

// Props validation
watch(
  () => props,
  () => {
    // weight validation
    if (!["regular", "light", "bold"].includes(props.weight)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "weight": expected string with value "regular", "light" or "bold" and got "${props.weight}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }

    // variant validation
    if (
      ![
        "primary",
        "secondary",
        "terciary",
        "white",
        "success",
        "warning",
        "error",
        "placeholder",
        "sidenav",
      ].includes(props.variant)
    ) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "variant": expected string with value "primary", "secondary", "terciary", "white", "success", "warning", "error", "placeholder" or "sidenav" and got "${props.variant}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }

    // status validation
    if (!["active", "inactive"].includes(props.status)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "status": expected string with value "active" or "inactive" and got "${props.status}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }

    // size validation
    if (!["default", "large", "small", "xsmall"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size": expected string with value "default", "large", "small" or "xsmall" and got ${props.size}. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }

    // direction validation
    if (!["up", "left", "down", "right"].includes(props.direction)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "direction": expected string with value "up", "left", "down" or "right" and got "${props.direction}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }

    // hover validator
    if (!["default", "highlight", "elevate", "weight"].includes(props.hover)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "hover": expected string with value "default", "highlight", "elevate" or "weight" and got "${props.hover}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }

    // tooltip validator
    if (typeof props.tooltip == "object") {
      if (
        props.tooltip?.content === undefined ||
        props.tooltip?.content === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.'
        );
      }
      if (
        props.tooltip?.position === undefined ||
        props.tooltip?.position === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "position" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.'
        );
      } else if (
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(props.tooltip?.position)
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "tooltip": expected attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${props.tooltip?.position}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
        );
      }
    }
  }
);

const appStore = useAppStore();

const iconRef = ref(null);
const onHover = ref(false);

const tabIndex = computed(() => {
  if (props.tabindex) return props.tabindex;
  else return props.status == "inactive" ? 0 : -1;
});

const getVariant = computed(() => {
  return props.disabled ? "terciary" : props.variant;
});

const classList = computed(() => {
  const w = props.hover == "weight" && onHover.value ? "bold" : props.weight;
  return `m-icon--${props.icon} m-icon--${getVariant.value} m-icon--${props.direction} m-icon--${props.status} m-icon--${w} m-icon--${props.size} m-icon--${props.hover}`;
});

watch(
  () => props.icon,
  (val) => {
    if (props.type == "chart" && !icon_list_chart[val]) {
      console.error(
        `Invalid prop "icon": expected string with value "bar", "pie", "line", "doughnut", "bubble" or "choropleth" and got "${val}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    } else if (!icon_list_regular[val] && !icon_list_other[val]) {
      console.debug(
        `Prop "icon" fallback: "${val}" is not a native Monitio icon, a Google Icon fallback is being used. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/602374150/Icons for instructions on how to use the MIcon component.`
      );
    }
  }
);

watch(
  () => props.status,
  (val) => {
    if (val == "inactive") {
      if (!props.id) {
        console.error('Missing required attribute "id"');
      }
    }
  }
);

const list = computed(() => ({
  xsmall: icon_list_xsmall,
  small: icon_list_small,
  default: icon_list_regular,
  large: icon_list_large,
}));

const content = computed(() => {
  if (!list.value[props.size][props.icon]) {
    if (window._env_.DEPLOYMENT_MODE == "DEVELOPMENT") {
      console.warn(
        `The icon with the name ${props.icon} does not exist on list ${props.size}.`
      );
    }
    return null;
  } else {
    let weight = props.weight == "light" ? "regular" : props.weight;
    if (onHover.value) weight = "bold";
    const content = list.value[props.size][props.icon][weight];
    if (content) return content;
    else return list.value[props.size][props.icon].regular; //fallback for icons that have not been created yet
  }
});
const getHeight = computed(() => {
  const h = props.size;
  if (icon_list_other[props.icon]) {
    return "24";
  } else if (h == "large") {
    return "31";
  } else if (h == "small") {
    return "18";
  } else if (h == "xsmall") {
    return "14";
  } else return "20";
});

const getSpanSize = computed(() => {
  const h = props.size;
  if (h == "large") {
    return "1.5";
  } else if (h == "small") {
    return "0.875";
  } else if (h == "xsmall") {
    return "0.75";
  } else return "1";
});

const getViewBox = computed(() => {
  return `0 0 ${getHeight.value} ${getHeight.value}`;
});

const click = (evt) => {
  if (props.disabled) return;
  else emit("click", evt);
};

defineExpose({
  ref: iconRef,
});
</script>
