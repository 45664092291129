import type { AxiosInstance } from "axios";

function anonymousDecorator(fn: any) {
  fn._anonymous = true;
  return fn;
}

export default (axios: AxiosInstance) => ({
  login(username: string, hashedPassword: string, userid: string) {
    return anonymousDecorator(
      axios.post(
        "/v1/auth",
        { userid },
        { auth: { username, password: hashedPassword } }
      )
    );
  },
  info() {
    return axios.get("/v1/auth/info");
  },
  refreshToken(token: string) {
    return anonymousDecorator(axios.get(`/v1/auth/refresh/${token}`));
  },
  logout(token: string) {
    return anonymousDecorator(axios.delete(`/v1/auth/refresh/${token}`));
  },
  loginFromAuthProvider() {
    console.log("loginFromAuthProvider");
    return axios.get("/v1/auth/loginFromAuthProvider");
  },
});
