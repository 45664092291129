import { useApi } from "@api/api";
import DateTimeUtils from "@utils/dateTime";
import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";

interface DashboardsStoreState {
  moduleHasInit: boolean;
  list: MonitioAPI.DashboardDTO[];
  shared: MonitioAPI.DashboardDTO[];
  drafts: MonitioAPI.DashboardDTO[];
}

export const useDashboardsStore = defineStore("dashboards", {
  state: (): DashboardsStoreState => ({
    moduleHasInit: false,
    list: [],
    shared: [],
    drafts: [],
  }),

  getters: {
    getById() {
      return (id: string) => {
        const allDashboards = [...this.list, ...this.shared, ...this.drafts];
        return allDashboards.find((x) => x.id == id);
      };
    },
    getListByViewId() {
      return (id: string | null | undefined): MonitioAPI.DashboardDTO[] => {
        if (!id) return [];
        const dashboards = this.list.filter((x) => x.showedIn?.includes(id));
        if (dashboards) return dashboards;
        return [];
      };
    },
    getDraftsByViewId() {
      return (id: string | null | undefined): MonitioAPI.DashboardDTO[] => {
        if (!id) return [];
        const dashboards = this.drafts.filter((x) => x.showedIn?.includes(id));
        if (dashboards) return dashboards;
        return [];
      };
    },
    getAllAndDrafts: (state) => [
      ...state.list,
      ...state.drafts,
      ...state.shared,
    ],
    getAll: (state) => [...state.list, ...state.shared],
    getAllAndDraftsByViewId() {
      return (
        id: string | null | undefined
      ): MonitioAPI.DashboardDTO[] | null => {
        if (!id) return null;
        const dashboards = this.getListByViewId(id);
        const drafts = this.getDraftsByViewId(id);
        return dashboards?.concat(drafts);
      };
    },
  },

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Dashboard module already initialized");
        return;
      }
      this.load();
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.list = this.shared = this.drafts = [];
      this.moduleHasInit = false;
    },
    async update(dashboard: MonitioAPI.DashboardDTO) {
      const allDashboards = [...this.list, ...this.shared, ...this.drafts];
      let localDashboard = allDashboards.find((x) => x.id == dashboard.id);
      localDashboard = dashboard;
      //this.current = localDashboard || {};
    },
    async load() {
      const { api } = useApi();
      try {
        const { data } = await api.analytics.getDashboards();
        if (!data) throw new Error();
        //Convert normal ISo formated string into a Luxon DateTime
        data.forEach((dashboard: MonitioAPI.DashboardDTO) => {
          dashboard.elements?.forEach((element) => {
            if (element.customSettings) {
              if (element.customDate) {
                if (element.customDate.start)
                  element.customDate.start = DateTimeUtils.parseFromISO(
                    element.customDate.start as any
                  );
                if (element.customDate.end)
                  element.customDate.end = DateTimeUtils.parseFromISO(
                    element.customDate.end as any
                  );
              }
            }
          });
        });
        this.list = data;
      } catch (e) {
        this.list = this.shared = [];
        console.warn(e);
      }
    },
    async save(dashboard: MonitioAPI.DashboardDTO, viewId: string) {
      const { api } = useApi();
      const { request, data } = await api.analytics.saveDashboard(
        dashboard.id,
        dashboard,
        viewId,
        dashboard.showedIn,
        dashboard.showInAllViews
      );
      if (request.status != 200) return null;
      const idx = this.list?.findIndex((f) => f.id === dashboard.id);
      if (idx < 0) {
        const draftIdx = this.drafts.findIndex((x) => x.id == dashboard.id);
        this.drafts.splice(draftIdx, 1);
        this.list?.push(dashboard);
      } else {
        this.list[idx] = { ...this.list[idx], ...dashboard };
      }
      return data;
    },
    async createDraft(dashboard: MonitioAPI.DashboardDTO) {
      this.drafts.push(dashboard);
    },
    async deleteDraft(dashboardId: string) {
      const idx = this.drafts.findIndex((f) => f.id === dashboardId);
      if (idx >= 0) this.drafts.splice(idx, 1);
    },
    async select(dashboardId: string) {
      const allDashboards = [...this.list, ...this.shared, ...this.drafts];
      //this.current = allDashboards.find((x) => x.id == dashboardId) || {};
    },
    async delete(dashboardId: string) {
      const { api } = useApi();
      await api.analytics.delete(dashboardId);
      [this.drafts, this.list, this.shared].forEach((list) => {
        const idx = list.findIndex((f) => f.id === dashboardId);
        if (idx >= 0) list.splice(idx, 1);
      });
    },
  },
});
