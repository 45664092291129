import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  resolveFBUrl(url: string) {
    return axios.get(`/v1/documents/resolve-fb-url?url=${url}`);
  },
  logDocumentRedirect(viewId: string, documentId: string) {
    return axios.get(`/v1/documents/log-redirect/${viewId}/${documentId}`);
  },
});
