<template>
  <div id="capture_dashboard" class="m-dashboard" data-tour="tour_m_dashboard">
    <grid-layout
      v-if="layout?.length"
      :layout="layout"
      :col-num="columnN"
      :row-height="rowHeight"
      :margin="margin"
      :is-draggable="type == 'editor'"
      :is-resizable="type == 'editor'"
      :vertical-compact="false"
      :prevent-collision="false"
      :use-css-transforms="true"
    >
      <grid-item
        v-for="el in layout"
        :key="el.i"
        class="m-dashboard__el"
        :class="{
          'm-dashboard__el--selected': selectedElementId == el.element?.id,
        }"
        :x="el.x"
        :y="el.y"
        :w="el.w"
        :h="el.h"
        :i="el.i"
        :static="type != 'editor' || el.i == 'addCard'"
        data-tour="tour_m_dashboard_types"
        @moved="movedEvent"
        @resized="resizedEvent"
      >
        <m-hint
          v-if="
            displayHints ||
            (userStore.ongoingTour.currentStep == 2 &&
              el.i == 'addCard' &&
              type == 'editor')
          "
          id="m_dashboard_item"
        />
        <div
          v-if="el.i == 'addCard' && type == 'editor'"
          :class="hoverAdd ? 'm-dashboard__types' : 'm-dashboard__add'"
          @mouseenter="hoverAdd = true"
          @mouseleave="hoverAdd = false"
        >
          <m-icon
            v-if="!hoverAdd"
            icon="add"
            status="active"
            variant="sidenav"
            size="large"
          />
          <div
            v-show="hoverAdd"
            v-for="(tp, i) in types"
            :key="i"
            id="m_dashboard_add_card"
            :data-tooltip-content="
              t(`views.dashboards.toolbox.${tp.value}_description`)
            "
            data-tooltip-position="dynamic"
            class="m-dashboard__type"
            data-tour="tour_m_dashboard__type"
            @mouseenter="hoverT = tp.value"
            @mouseleave="hoverT = tp.value"
            @click="addToLayout(tp)"
          >
            <m-toolbox-icon
              property="element"
              :icon="tp.value"
              variant="sidenav"
              status="active"
            />
            <h5 class="type--xsmall">
              {{ tp.label }}
            </h5>
          </div>
        </div>
        <m-dashboard-element
          v-else-if="el.i != 'addCard'"
          :type="type"
          :viewId="reportViewId ?? dashboard.viewId"
          :element="el.element"
          :width="el.w"
          :height="el.h"
          :date-restriction-override="dateRestrictionOverride"
          :row-height="rowHeight"
          :selectedElementId="selectedElementId"
          @create-subchart="createSubChart"
          @update-layout="(val) => emit('update-layout', val)"
          @update-element-layout="(val) => updateElementLayout(val, el.i)"
          @select="selectElement"
          @remove="removeElement"
          @chart-data-changed="
            (data, id, parentId) =>
              emit('chart-data-changed', data, id, parentId)
          "
          @select-chart-item="(item, id) => emit('select-chart-item', item, id)"
          @unselect-chart-item="
            (item, id) => emit('unselect-chart-item', item, id)
          "
          @reset-advanced-filters="(id) => emit('reset-advanced-filters', id)"
          @click="() => emit('click')"
        />
      </grid-item>
    </grid-layout>
  </div>
</template>

<script setup>
import { ref, computed, reactive, watchEffect, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { recalculateLayoutPositions } from "@utils/utils";
import useWindowSize from "@hooks/useWindowSize";
import useDashboards from "@hooks/useDashboards";
import TimeFrames from "@utils/enums/timeFrames";
import { useRoute, onBeforeRouteLeave } from "vue-router";

import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { getRandomInt } from "@utils/math";
import MHint from "@components/MHint.vue";
import MDashboardElement from "@components/dashboard/MDashboardElement.vue";
import MIcon from "@components/MIcon.vue";
import MToolboxIcon from "@components/MToolboxIcon.vue";
import structuredClone from "@utils/structuredClone";
import Guid from "@utils/guid";
import { useUserStore } from "@root/store/modules/user";
import { useWorkspacesStore } from "@root/store/modules/workspaces";

const props = defineProps({
  dashboard: { type: Object },
  type: { type: String, default: "preview" },
  columns: { type: Number, default: undefined },
  dateRestrictionOverride: { type: Object, default: null },
  selectedElementId: { type: [Number, String] },
  reportViewId: { Type: String, required: false }, //If this is defined, this takes priority
});

const emit = defineEmits([
  "select-element",
  "add-element",
  "add-child-element",
  "remove-element",
  "resized-element",
  "update-layout",
  "chart-data-changed",
  "select-chart-item",
  "unselect-chart-item",
  "moved-element",
  "reset-advanced-filters",
]);

const { t } = useI18n();
const { windowWidth } = useWindowSize();
const route = useRoute();
const userStore = useUserStore();
const workspacesStore = useWorkspacesStore();
const hoverAdd = ref(true);
const hoverT = ref(false);
const margin = ref([16, 16]);
const viewId = computed(() => route.params.viewId);
const workspaceId = workspacesStore.id;
const { getAddElementCoords } = useDashboards();
const { open, close: closeModel, cancel: cancelModel } = useModal("m-dialog");

const rowHeight = ref(228);
const columnN = computed(() => {
  if (props.columns) return props.columns;
  else {
    const w = windowWidth.value;
    let n;

    if (w < 1280) {
      n = 1;
    } else if (w >= 1280 && w < 1440) {
      n = 2;
    } else if (w >= 1440 && w < 1920) {
      n = 3;
    } else {
      n = 4;
    }

    return n;
  }
});

const layout = ref(
  props.dashboard.elements
    ? props.dashboard.elements.map((m) => ({
        ...m.layout,
        element: m,
      }))
    : []
);
watch(
  () => layout.value,
  (layout) => {
    emit("update-layout", layout);
  },
  { deep: true }
);

const updateElementLayout = (elLayout, i) => {
  const idx = layout.value.findIndex((x) => x.i == i);
  const clone = structuredClone(layout.value[idx]);
  clone.h = elLayout.h;
  clone.w = elLayout.w;
  clone.x = elLayout.x;
  clone.y = elLayout.y;

  layout.value[idx] = clone;
  recalculateLayoutPositions(layout.value, columnN.value);
};

const workspaceSupportsMapChart = computed(() => {
  const workspace = workspacesStore.currentWorkspaceConfig;
  const userConfig = userStore.config;
  return userConfig.propertyTypeSettings.facets?.[workspace.id]
    .filter((x) => x.active)
    .some((x) => x.mapEnabled);
});

watch(
  () => props.dashboard?.elements,
  (val, oldVal) => {
    if (val.length === oldVal.length) {
      // No changes in layout. So update only the elements inside
      val.forEach((element) => {
        const idx = layout.value.findIndex((x) => x.element?.id == element.id);
        layout.value[idx].element = element;
      });
      return;
    }
    const addCardIdx = layout.value.findIndex((x) => x.i == "addCard");
    const clone = structuredClone(addElement.value);
    if (val.length > oldVal.length) {
      //Insert an item
      layout.value[addCardIdx].x = clone.x;
      layout.value[addCardIdx].y = clone.y;
      const item = val.find(
        (x) => !oldVal.map((m) => m.layout.i).includes(x.layout.i)
      );
      layout.value.push({
        ...item.layout,
        element: item,
      });
    } else {
      //Remove an item

      const item = oldVal.find(
        (x) => !val.map((m) => m.layout.i).includes(x.layout.i)
      );
      const currentIdx = layout.value.findIndex((x) => item.layout.i == x.i);
      if (
        layout.value[currentIdx].y == layout.value[addCardIdx].y &&
        layout.value[addCardIdx].x > layout.value[currentIdx].x
      ) {
        layout.value[addCardIdx].x = layout.value[currentIdx].x;
      } else if (layout.value[currentIdx].y < layout.value[addCardIdx].y) {
        layout.value[addCardIdx].y = layout.value[currentIdx].y;
        layout.value[addCardIdx].x = layout.value[currentIdx].x;
      }
      layout.value.splice(currentIdx, 1);
    }
  },
  { deep: true }
);

const types = computed(() => {
  const items = [
    // { value: "count", label: t("views.dashboards.toolbox.count") },
    { value: "bar", label: t("views.dashboards.toolbox.bar") },
    { value: "line", label: t("views.dashboards.toolbox.line") },
    { value: "pie", label: t("views.dashboards.toolbox.pie") },
    { value: "doughnut", label: t("views.dashboards.toolbox.doughnut") },
    { value: "bubble", label: t("views.dashboards.toolbox.bubble") },
    /* { value: "overview", label: t("views.dashboards.toolbox.overview") } */
  ];

  if (workspaceSupportsMapChart.value)
    items.push({
      value: "choropleth",
      label: t("views.dashboards.toolbox.choropleth"),
    });

  return items;
});

const addElement = computed(() => {
  const { x, y } = getAddElementCoords(layout.value, columnN.value);
  return {
    x,
    y,
    w: 1,
    h: 1,
  };
});

onMounted(() => {
  if (props.type == "editor") {
    layout.value.push({
      i: "addCard",
      ...getAddElementCoords(layout.value, columnN.value),
      w: 1,
      h: 1,
    });
  }
  const maxX = Math.max(...layout.value.map((x) => x.x)) + 1;
  if (maxX < columnN.value || maxX > columnN.value)
    recalculateLayoutPositions(layout.value, columnN.value);
});

watch(
  () => columnN.value,
  (colN) => {
    recalculateLayoutPositions(layout.value, colN);
  }
);

const movedEvent = () => {
  // layout.value = reorderLayout(layout.value);
  //recalculateLayoutPositions(layout.value, columnN.value);
  emit("moved-element");
};

const resizedEvent = (i, newH, newW, newHPx, newWPx) => {
  //recalculateLayoutPositions(layout.value, columnN.value);
  emit("resized-element", i, newH, newW);
};

const createSubChart = (elementId, config) => {
  emit("add-child-element", elementId, config);
  recalculateLayoutPositions(layout.value, columnN.value);
};
const selectElement = (val) => {
  //selectedElement.value = val;
  emit("select-element", val);
};

const removeElement = (val) => {
  emit("remove-element", val);
};

const addToLayout = (val) => {
  const userConfig = userStore.config;

  let availablePropertyTypes =
    userConfig.propertyTypeSettings?.facets?.[workspaceId] ?? [];
  if (val.value == "choropleth") {
    availablePropertyTypes = availablePropertyTypes
      .filter((x) => x.mapEnabled && x.active && x.selected)
      .map((x) => x.searchKey);
  } else {
    availablePropertyTypes = availablePropertyTypes
      .filter((x) => x.active && x.selected)
      .map((x) => x.searchKey || x.uiKey);
  }

  // If for some reason there's no availablePropertyTypes after the filtering, we need to warn the user that its not possible to add element
  // Possibly report this error to dev team?
  if (!availablePropertyTypes?.length) {
    open("dialog", {
      type: "warning",
      heading: t("views.dashboards.cannot_add_element.heading"),
      messages: [
        t("views.dashboards.cannot_add_element.message1"),
        t("views.dashboards.cannot_add_element.message2"),
      ],
      buttons: ["resolve"],
      resolveLabel: t("views.dashboards.cannot_add_element.buttons_resolve"),
    }).then(() => closeModel());
    return;
  }

  let max;
  switch (val.value) {
    case "pie":
    case "doughnut":
      max = 5;
      break;
    case "choropleth":
      max = 999;
      break;
    default:
      max = 8;
      break;
  }
  /** Get th position of the card this way. Because addElement.value is the position where the card WILL BE after inserting a new chart */
  const addCardIdx = layout.value.findIndex((x) => x.i == "addCard");
  const newDashboardId = Guid.NewGuid();
  const newElement = {
    type: val.value,
    title: t("templates.new"),
    id: newDashboardId,
    layout: {
      x: layout.value[addCardIdx].x,
      y: layout.value[addCardIdx].y,
      w: 1,
      h: 1,
      i: newDashboardId,
      static: false,
    },
    filterBy:
      availablePropertyTypes[
        getRandomInt(0, availablePropertyTypes.length - 1)
      ],
    maxResults: max,
    customSettings: false,
    customDate: {
      isRelative: true,
      timeFrame: TimeFrames.ThisMonth.value,
    },
    customViewId: viewId.value,
  };
  /** Add the defaults to the overview chart */
  if (val.value === "bar" || val.value === "line") {
    newElement.averageDays = 7;
    newElement.showMovingAverage = false;
    newElement.isOverviewChart = false;
  }

  emit("add-element", newElement);
  const interval = setInterval(() => {
    /** Hack to select element right after creating it. Think the vue-grid takes some time to register the element */
    if (layout.value.find((x) => x.element?.id == newElement.id)) {
      emit("select-element", newElement.id);
      clearInterval(interval);
    }
  }, 50);
};
const displayHints = computed(() => {
  return (
    !userStore.checkCompletedTour("dashboard") &&
    userStore.ongoingTour.currentStep == 2
  );
});
</script>

<style lang="scss">
@import "@stylesheets/scss/components/dashboard";
</style>
