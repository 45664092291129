import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";

export default () => {
  const { t } = useI18n();
  const { open, close, cancel } = useModal("m-modal");

  const handleModal = (reportId, recurrencyInfo) => {
    open("m_modal_automate_report", {
      heading: t("modals.automateReport.title"),
      description: t("modals.automateReport.description"),
      width: 5,
      resolve: { label: t("general.buttons.create") },
      disabled: false,
      reportId,
      recurrencyInfo,
      buttons: ["custom"],
      close,
      cancel,
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
