import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  autoComplete(searchParams: object) {
    return axios.put(`/v1/entity/auto-complete`, searchParams);
  },
  putEntityMentionRule(rule: object) {
    return axios.put(`/v1/entity/mention-rule`, rule);
  },
  updateActiveEntityMentionRule(id: string, bool: string) {
    return axios.post(`/v1/entity/mention-rule/change-active/${id}/${bool}`);
  },
  deleteEntityMentionRule(id: string) {
    return axios.delete(`/v1/entity/mention-rule/${id}`);
  },
});
