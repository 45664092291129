/**
 * Re-maps a number from one range to another.
 * Please refer to {@link https://p5js.org/reference/#/p5/map | p5js docs}  on
 * this function.
 */

const map = (n, start1, stop1, start2, stop2) => {
  return ((n - start1) / (stop1 - start1)) * (stop2 - start2) + start2;
};

export { map };
