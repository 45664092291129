import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";

export default () => {
  const { open, close, cancel } = useModal("m-modal");
  const { t } = useI18n();

  const handleModal = (mention, startPos, endPos, article) => {
    return new Promise((res, rej) => {
      const title = t("createMentionLink.modalTitle");
      const description = t("createMentionLink.modalDescription");
      open("m_modal_create_entity", {
        heading: title,
        description: description,
        width: 4,
        disabled: false,
        buttons: ["custom"],
        close,
        cancel,

        mention,
        startPos,
        endPos,
        article,
      }).then(
        ({ reason }) => {
          // fulfilled
          res(reason);
        },
        (reason) => {
          // rejected
          rej(reason);
        }
      );
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
