<template>
  <div
    v-if="appStore.chat != 'close'"
    id="m_chat"
    class="m-chat"
    :class="`m-chat--${appStore.chat}`"
    v-click-outside="clickOutside"
  >
    <div class="m-chat__heading">
      <h6 class="h5">{{ t("chat.heading") }}</h6>
      <div class="m-heading__actions">
        <m-icon
          v-if="conversation?.length"
          id="m_chat_reset"
          icon="refresh"
          size="small"
          :tooltip="t('chat.reset')"
          variant="secondary"
          class="pa-1 m-clickable"
          @click="reset"
        />
        <m-icon
          v-if="appStore.chat == 'pin'"
          id="m_chat_close"
          icon="close"
          :tooltip="t('chat.close')"
          variant="secondary"
          class="m-clickable"
          @click="close"
        />
        <m-options
          v-else
          id="m_chat_options"
          :position="['left', 'bottom']"
          :options="moreOptions"
          size="small"
          class="m-clickable"
          @select="selectMoreOpt"
        />
      </div>
    </div>
    <div class="m-chat__box">
      <div>
        <div
          v-for="(message, i) in conversation"
          :key="i"
          :id="`m_chat_message_${i}`"
          class="m-message"
          :class="[
            {
              'd-none': hideMessage(message.type),
            },
            `m-message--${message.type}`,
          ]"
        >
          <m-image
            v-if="message.type.startsWith('assistant')"
            height="24"
            :src="monitio_assistant"
            :alt="t('chat.assistant_image')"
          />
          <div class="m-balloon">
            <span class="p type--small" v-html="message.text"></span>
            <div class="m-balloon__actions">
              <m-icon
                v-for="(action, idx) in messageActions"
                :key="idx"
                v-show="message.type.startsWith('assistant')"
                :id="`m_chat_balloon_${snakeCase(action)}`"
                :icon="action"
                size="xsmall"
                :hover="action != 'check' ? 'highlight' : ''"
                :tooltip="t(`chat.actions_${action}`)"
                :variant="actionVariant(action, message)"
                @mouseenter="hover = action"
                @mouseleave="hover = ''"
                @click="takeAction(action, message)"
              />
            </div>
          </div>
          <m-icon
            v-show="message.type.startsWith('assistant') && i != 0"
            id="m_chat_balloon_refresh"
            icon="refresh"
            size="small"
            :tooltip="t('chat.actions_refresh')"
            variant="secondary"
            class="mr-2"
            @click="refresh(message)"
          />
        </div>
        <div
          v-if="isLoading"
          id="m_chat_message_loading"
          class="m-message m-message--assistant"
        >
          <m-image
            height="24"
            :src="monitio_assistant"
            :alt="t('chat.assistant_image')"
          />
          <div class="m-balloon">
            <div class="m-loading--bubble">
              <div class="m-bubble m-bubble--small m-bubble--1"></div>
              <div class="m-bubble m-bubble--small m-bubble--2"></div>
              <div class="m-bubble m-bubble--small m-bubble--3"></div>
            </div>
          </div>
        </div>
      </div>
      <div id="m_chat_last_message"></div>
    </div>
    <div class="m-chat__disclaimer">
      <span class="h6 type--xsmall">{{ t("chat.disclaimer") }}</span>
    </div>
    <div class="m-chat__input">
      <m-input
        id="m_chat_input"
        v-model="newMessage"
        :placeholder="t('chat.input_placeholder')"
        @resolve="sendMessage"
      />
      <m-icon
        id="m_chat_send"
        icon="send"
        :tooltip="t('chat.sendMessage')"
        variant="secondary"
        class="pa-2 mr-1"
        @click="sendMessage"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from "vue";
import { useI18n } from "vue-i18n";

import { useViewFilters } from "@hooks/useViewFilters";
import { useApi } from "@api/api";
import { snakeCase } from "lodash-es";
import { useRouter, useRoute } from "vue-router";
import MIcon from "@components/MIcon.vue";
import MImage from "@components/MImage.vue";
import MInput from "@components/MInput.vue";
import MOptions from "@components/MOptions.vue";
import mockdata from "@components/mockdata/conversation";
import monitio_assistant from "@assets/illustrations/monitio_assistant.svg";
import { delay } from "lodash-es";
import { useAppStore } from "@root/store/app";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { useSessionStore } from "@root/store/modules/session";

const { t } = useI18n();
const appStore = useAppStore();
const workspacesStore = useWorkspacesStore();
const sessionStore = useSessionStore();
const { api } = useApi();
const router = useRouter();
const route = useRoute();
const { queryObject, dateRestriction } = useViewFilters(router, route);

const workspaceId = ref(workspacesStore.id);

const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);

const initialChatMessage = {
  text: t("chat.initialMessage"),
  type: "assistantContextIgnored",
  functionName: null,
};

const newMessage = ref("");
const isLoading = ref(false);
const conversation = ref([]);
const hover = ref("");

const moreOptions = computed(() => {
  const arr = ["pin", "close"];

  return arr.map((m) => ({ value: m, label: t(`chat.${m}`) }));
});

onMounted(() => {
  if (sessionStore.chatHistory) {
    conversation.value = sessionStore.chatHistory;
  } else {
    conversation.value = [initialChatMessage];
  }

  nextTick(() => {
    document.getElementById("m_chat_last_message")?.scrollIntoView({
      block: "end",
      inline: "nearest",
    });
  });
});

watch(
  () => conversation.value?.length,
  (val, oldVal) => {
    if (val != oldVal) {
      delay(
        () =>
          document.getElementById("m_chat_last_message")?.scrollIntoView({
            block: "end",
            inline: "nearest",
          }),
        100
      );
    }
  }
);

const close = () => {
  appStore.setChat("close");
};

const reset = () => {
  conversation.value = [initialChatMessage];
  sessionStore.chatHistorySet(null);
};

const clickOutside = (evt) => {
  if (
    evt.target?.attributes?.id?.value != "open_chat" &&
    appStore.chat == "open"
  ) {
    close();
  }
};

const selectMoreOpt = (val) => {
  appStore.setChat(val);
};

const messageActions = ref(["duplicates-on", "thumbs-up", "thumbs-down"]);

const hideMessage = (type) => {
  return (
    type != "user" && type != "assistant" && type != "assistantContextIgnored"
  );
};

const actionVariant = (val, message) => {
  if (
    val == "check" ||
    (val == "thumbs-up" &&
      (message.feedback == "positive" || val == hover.value))
  ) {
    return "success";
  } else if (
    val == "thumbs-down" &&
    (message.feedback == "negative" || val == hover.value)
  ) {
    return "error";
  } else return "secondary";
};

const takeAction = (val, message) => {
  const idx = messageActions.value?.findIndex((f) => f == "duplicates-on");

  switch (val) {
    case "duplicates-on":
      messageActions.value[idx] = "check";
      setTimeout(() => {
        messageActions.value[idx] = "duplicates-on";
      }, 1000);
      navigator.clipboard.writeText(message.text);
      break;
    case "thumbs-up":
      console.log("thumbs-up", message);
      break;
    case "thumbs-down":
      console.log("thumbs-down", message);
      break;
  }
};

const refresh = (message) => {
  console.log("refresh", message);
};

const sendMessage = async () => {
  if (newMessage.value.trim().length > 1 && newMessage.value != " ") {
    isLoading.value = true;
    if (conversation.value.length == 1) {
      /**
       * @type {import("@root/types.api.local").MonitioAPI.StartConversationDTO}
       */
      const params = {
        viewId: viewId.value,
        filters: queryObject.value.filters,
        dateRestriction: dateRestriction.value,
        userInput: newMessage.value,
      };
      conversation.value.push({
        type: "user",
        text: newMessage.value,
      });

      newMessage.value = "";
      const data = await api.chat.startConversation(params);
      if (!data?.data?.history) throw new Error();
      conversation.value = [initialChatMessage, ...data.data.history];
    } else {
      /**
       * @type {import("@root/types.api.local").MonitioAPI.ContinueConversationDTO}
       */
      const params = {
        viewId: viewId.value,
        filters: queryObject.value.filters,
        dateRestriction: dateRestriction.value,
        userInput: newMessage.value,
        context: { history: conversation.value },
      };
      conversation.value.push({
        type: "user",
        text: newMessage.value,
      });
      newMessage.value = "";
      const data = await api.chat.continueConversation(params);
      conversation.value = data?.data?.history;
    }

    sessionStore.chatHistorySet(conversation.value);

    isLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
.m-chat {
  width: $spacing-9 * 10;
  height: $spacing-14 * 7;
  @include round-corners($spacing-1);
  @include flex(flex-start, stretch, column);
  position: absolute;
  background-color: color($white);
  @include elevate-popover();

  &--open {
    right: $spacing-0;
  }

  &--pin {
    bottom: $spacing-2;
  }

  &__heading {
    padding: $spacing-1 $spacing-1 $spacing-1 $spacing-3;
    @include flex(space-between, center, row);
    @include elevate-button;

    .m-heading__actions {
      @include flex(flex-end, center, row);
      gap: $spacing-1;
    }
  }

  &__box {
    max-height: ($spacing-14) * 5;
    flex-grow: 1;
    overflow-y: auto;

    > div {
      padding: $spacing-0 $spacing-3;
      @include flex(flex-end, center, column);
    }
  }

  .m-message {
    max-width: 90%;
    padding-top: $spacing-4;

    &--assistant,
    &--assistantContextIgnored {
      @include flex(flex-start, center, row);
      align-self: flex-start;
      gap: $spacing-2;

      .m-image {
        width: unset;
        align-self: flex-end;
      }

      .m-balloon {
        padding: $spacing-2 $spacing-3;
        border: 1px solid color($pri-action-light, 0.4);
        @include round-corners($spacing-1);

        &--loading {
          border: unset;
          gap: $spacing-1;
          background-color: color($pri-action-light, 0.2);
        }

        &__actions {
          margin-top: $spacing-2;
          @include flex(flex-end, center, row);
          gap: $spacing-2;
        }
      }
    }

    &--user {
      @include flex(flex-end, center, row);
      align-self: flex-end;

      .m-balloon {
        padding: $spacing-2 $spacing-3;
        background-color: color($pri-action-light, 0.4);
        @include round-corners($spacing-1);
      }
    }
  }

  &__disclaimer {
    padding: $spacing-1 $spacing-2;
    margin-top: $spacing-3;
    border: 1px solid color($sec-200);
    @include round-corners($spacing-1);
    align-self: center;
    background-color: color($sec-100);
    @include elevate-button;
  }

  &__input {
    padding: $spacing-2;
    @include flex(flex-start, center, row);
    gap: $spacing-1;

    .m-input {
      flex-grow: 1;
    }
  }
}

.--pinned-pane {
  .m-chat--pin {
    right: calc($toolbox-width + $spacing-4);
  }
}

.--unpinned-pane {
  .m-chat--pin {
    right: $spacing-2;
  }
}
</style>
