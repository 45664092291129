<template>
  <div
    id="m_header_assistant"
    class="m-header-assistant"
    @mouseleave="closeDropdown"
  >
    <label id="m_header_account_label" class="visually-hidden">
      {{ t("navigation.header.assistant") }}
    </label>
    <m-image
      :src="assistantSrc"
      :tooltip="t('navigation.header.assistant')"
      height="32"
      :alt="t('chat.assistant_image')"
      class="m-clickable"
      @mouseenter="hoverAssistant = true"
      @mouseleave="hoverAssistant = false"
      @click="toggleChat"
    />
    <m-chat v-if="appStore.chat == 'open'" />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { useI18n } from "vue-i18n";

import MImage from "@components/MImage.vue";
import MChat from "@components/MChat.vue";

import monitio_assistant from "@assets/illustrations/monitio_assistant.svg";
import monitio_assistant_hover from "@assets/illustrations/monitio_assistant_hover.svg";
import { useAppStore } from "@root/store/app";

const props = defineProps({ closeOthers: { type: String } });

const { t } = useI18n();
const appStore = useAppStore();

const hoverAssistant = ref(false);

const assistantSrc = computed(() => {
  if (hoverAssistant.value) return monitio_assistant_hover;
  else return monitio_assistant;
});

const toggleChat = () => {
  if (appStore.chat == "close") appStore.setChat("open");
  else if (appStore.chat == "open") appStore.setChat("close");
};
</script>

<style scoped lang="scss">
.m-header-assistant {
  position: relative;

  :deep(.m-clickable.m-image) {
    padding: $spacing-2 - math.div($spacing-1, 2);
    transform: translateY(-1.5px);
  }
}
</style>
