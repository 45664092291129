import Shepherd from "shepherd.js";
import useUtils from "@root/hooks/useTour/useUtils";
import { useI18n } from "vue-i18n";
import { useUserStore } from "@root/store/modules/user";

export default () => {
  const {
    cardContent,
    getCurrentStepIndex,
    clearArray,
    configTourOptions,
    waitForElementToDisplay,
    removeClass,
    createIcon,
    deleteIcon,
    moreIcon,
    editIcon,
  } = useUtils();

  const { t } = useI18n();
  const userStore = useUserStore();

  // CREATE REPORT TOUR
  const reportTour = new Shepherd.Tour({
    useModalOverlay: true,

    canClickTarget: false,

    defaultStepOptions: {
      when: {
        show() {
          // Get index of currentStep
          const currentStepIndex = getCurrentStepIndex();
          //send current step to store
          userStore.updateStepTour(
            Shepherd.activeTour.steps.indexOf(Shepherd.activeTour.currentStep)
          );

          // click on browser back button
          const stepCurrent = Shepherd.activeTour.getCurrentStep();
          window.addEventListener("popstate", function (event) {
            stepCurrent.hide();
            document
              .querySelectorAll(".m-tour__mask")
              .forEach((e) => e.remove());
            reportTour.show(Shepherd.activeTour.currentStep);
          });

          //click on browser refresh button
          window.onbeforeunload = function (event) {
            reportTour.show(Shepherd.activeTour.currentStep);
          };

          // remove steps after trigger a cancel/ complete event
          [("cancel", "complete")].forEach((event) =>
            reportTour.on(event, (param) => {
              const { tour } = param;
              reportTour.steps.forEach((step) => {
                step.destroy();
              });

              clearArray(tour.steps);
            })
          );
          ["close", "cancel", "complete"].forEach((event) =>
            reportTour.on(event, () => {
              removeClass("m-hint");

              Array.from(
                document.querySelectorAll("div#m_tour_overlay")
              ).forEach((el) => el.remove());

              userStore.completeTour("reports");
              clearArray(reportTour.steps);
            })
          );

          // configurations per step
          if (currentStepIndex != 2 || currentStepIndex != 5) {
            const body = document.querySelector("body");
            const maskElement = document.createElement("div");
            maskElement.setAttribute("id", "m_tour_overlay");
            maskElement.className = "m-tour__mask";
            body.appendChild(maskElement);
          }
          if (currentStepIndex == 2 || currentStepIndex == 5) {
            Array.from(document.querySelectorAll("div#m_tour_overlay")).forEach(
              (el) => el.remove()
            );

            const screenToBlock = document.getElementsByClassName("m-main");
            screenToBlock[0].style["pointer-events"] = "none";

            if (currentStepIndex == 2) {
              const targetToUnlock = document.getElementsByClassName(
                "m-toolbox__draggable"
              );
              targetToUnlock[0].style["pointer-events"] = "visible";

              const clickableEl =
                document.getElementsByClassName("m-toolbox__type");

              clickableEl.forEach((el) => {
                ["click", "drag"].forEach((evt) =>
                  el.addEventListener(
                    evt,
                    function () {
                      if (currentStepIndex == 2) {
                        reportTour.next();
                      }
                    },
                    false
                  )
                );
              });
            }

            if (currentStepIndex == 5) {
              const buttonToUnlock = document.querySelector(
                "#m_button_m_toolbox_save"
              );
              buttonToUnlock.style["pointer-events"] = "visible";

              buttonToUnlock.onclick = () => {
                reportTour.show("m_step_7_white_reports_4");
              };

              const screenToUnlock =
                document.getElementsByClassName("m-container");
              screenToUnlock[0].style["pointer-events"] = "visible";
            }
          }

          /* ***** end of hint implementation  ******/

          // CARDS CONFIGS
          configTourOptions(reportTour, "report", "reports", t);
        },
      },
    },
  });

  //Report step 1 -toolbox- white
  reportTour.addStep({
    id: "m_step_1_white_reports_0",

    title: "",

    text: cardContent(
      "white",
      t("tour.reports.toolbox_content"),
      t("tour.reports.toolbox_title"),
      t("tour.reports.title")
    ),

    attachTo: {
      element: '[data-tour="tour_m_report_toolbox"]',

      on: "left",
    },
    classes: "m-tour-white mr-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: reportTour.next,
      },
    ],
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = '[data-tour="tour_m_report_toolbox"]';

        waitForElementToDisplay(selector).then(() => {
          resolve();
        });
      });
    },
  });

  //Report step 2 -preview- white
  reportTour.addStep({
    id: "m_step_2_white_reports_1",
    title: "",

    text: cardContent(
      "white",
      t("tour.reports.preview_content"),
      t("tour.reports.preview_title"),
      t("tour.reports.title")
    ),

    attachTo: {
      element: '[data-tour="tour_m_report_draggable"]',

      on: "left",
    },
    classes: "m-tour-white mr-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: reportTour.next,
      },
    ],
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = ".m-report__draggable";
        waitForElementToDisplay(selector).then(() => {
          resolve();
        });
      });
    },
  });

  //report step 3 - blue - add element

  reportTour.addStep({
    id: "m_step_3_blue_reports",
    title: "",
    text: cardContent("blue", t("tour.reports.addElement")),
    attachTo: {
      element: '[data-tour="tour_report_m_toolbox_draggable"]',

      on: "left",
    },
    classes: "m-tour-blue mr-6",
  });
  //Report step 4 -Element Settings- white
  reportTour.addStep({
    id: "m_step_4_white_reports_2",
    title: "",

    text: cardContent(
      "white",
      t("tour.reports.elementSettings_content"),
      t("tour.reports.elementSettings_title"),
      t("tour.reports.title")
    ),

    attachTo: {
      element: '[data-tour="tour_m_toolbox_element"]',

      on: "left",
    },
    classes: "m-tour-white mr-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: reportTour.next,
      },
    ],
    scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = '[data-tour="tour_m_toolbox_element"]';

        waitForElementToDisplay(selector).then(() => {
          resolve();
        });
      });
    },
  });
  //Report step 5 -Element options- white
  reportTour.addStep({
    id: "m_step_5_white_reports_3",
    title: "",

    text: cardContent(
      "white",
      t("tour.reports.elementOptions_content", {
        deleteIcon: deleteIcon,
        editIcon: editIcon,
      }),
      t("tour.reports.elementOptions_title"),
      t("tour.reports.title")
    ),

    attachTo: {
      element: '[data-tour="tour_report_m_module"]',

      on: "left",
    },
    classes: "m-tour-white mr-3",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.next")}</h5>`,
        action: reportTour.next,
      },
    ],
    scrollTo: true,
    beforeShowPromise: function () {
      return new Promise(function (resolve) {
        const selector = '[data-tour="tour_report_m_module"]';

        waitForElementToDisplay(selector).then(() => {
          resolve();
        });
      });
    },
  });
  //report step 6 - blue - save report
  reportTour.addStep({
    id: "m_step_6_blue_reports",
    title: "",
    text: cardContent("blue", t("tour.reports.saveReport")),
    attachTo: {
      element: '[data-tour="tour_m_button_m_toolbox_save"]',

      on: "bottom",
    },
    classes: "m-tour-blue mt-3",
  });
  //Report step 7 -Element options- white
  reportTour.addStep({
    id: "m_step_7_white_reports_4",
    title: "",

    text: cardContent(
      "white",
      t("tour.reports.editAndSendReport_content"),
      t("tour.reports.editAndSendReport_title"),
      t("tour.reports.title")
    ),

    attachTo: {
      element: ".m-header-filters",

      on: "bottom",
    },
    classes: "m-tour-white mt-6",

    buttons: [
      {
        classes: "m-button m-button--outlined--primary m-button--small",
        text: `<h5 class="type--small">${t("tour.buttons.exploreReport")}</h5>`,
        action: reportTour.cancel,
      },
    ],
  });

  return reportTour;
  //export { reportTour };
};
