<template>
  <div
    v-if="cluster"
    class="m-top-cluster m-articles__wrapper"
    :class="`m-top-cluster--${clusterLength}`"
  >
    <div :class="clusterClasses('large')">
      <m-article
        v-for="(article, idx) in large"
        :key="idx"
        :article="article"
        :disableActions="view.isShared && !view.isBaseView"
        @update:article="
          (key, val) => emit('update:cluster', article.id, key, val)
        "
        @change-read-status="(status) => changeReadStatus(article, status)"
      />
    </div>
    <div
      v-if="medium || small || xsmall"
      class="m-top-cluster__side"
      :class="{
        'm-top-cluster__side--column': side.find((f) => f.size == 'medium'),
      }"
    >
      <div v-for="(c, i) in side" :key="i" :class="clusterClasses(c.size)">
        <m-article
          v-for="(article, idx) in c.value"
          :key="idx"
          :article="article"
          :disableActions="view.isShared && !view.isBaseView"
          @update:article="
            (key, val) => emit('update:cluster', article.id, key, val)
          "
          @change-read-status="(status) => changeReadStatus(article, status)"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useUserStore } from "@root/store/modules/user";
import { useViewsStore } from "@root/store/modules/views";

import useArticle from "@hooks/useArticle";
import MArticle from "@components/MArticle.vue";

const props = defineProps({
  cluster: { type: Object },
});

const emit = defineEmits("update:cluster");

const { currentRoute } = useRouter();
const route = useRoute();
const userStore = useUserStore();
const viewsStore = useViewsStore();

const { changeReadStatus } = useArticle(route.params.viewId);

// 1 large
// 2 large
// 3 1 large + 2 medium
// 4 1 large + 1 medium + 2 small
// 5 1 large + 4 small - m-cluster termina aqui
// 6 1 large + 2 small + 3 xsmall

const view = computed(() => viewsStore.getViewById(route.params.viewId));
const clusterLength = computed(() => props.cluster?.length);

const clusterClasses = (size) => {
  let str = `m-top-cluster__${size}`;

  if (
    (size == "large" &&
      (clusterLength.value == 1 || large.value.length == 2)) ||
    (size == "small" && small.value.length == 4)
  ) {
    str += ` m-top-cluster__${size}--full-width`;
  }

  return str;
};

const large = computed(() => {
  if (clusterLength.value < 3) return props.cluster;
  else return [props.cluster[0]];
});

const medium = computed(() => {
  if (userStore.pinnedPane && clusterLength.value > 2) {
    return props.cluster.slice(1, 3);
  } else {
    if (clusterLength.value == 3) {
      return props.cluster.slice(1, 3);
    } else if (clusterLength.value == 4) {
      return [props.cluster[1]];
    } else return null;
  }
});

const small = computed(() => {
  if (userStore.pinnedPane) return null;
  else {
    if (clusterLength.value == 4) {
      return props.cluster.slice(2, 4);
    } else if (clusterLength.value == 5) {
      return props.cluster.slice(1, userStore.pinnedPane ? 3 : 5);
    } else if (clusterLength.value > 5) return props.cluster.slice(1, 3);
    else return null;
  }
});

const xsmall = computed(() => {
  if (!userStore.pinnedPane && clusterLength.value > 5) {
    return props.cluster.slice(3, 6);
  } else return null;
});

const side = computed(() => {
  const arr = [];

  if (large.value.length > 1) {
    arr.push({ size: "large", value: large.value[1] });
  }

  if (medium.value) {
    arr.push({ size: "medium", value: medium.value });
  }

  if (small.value) {
    arr.push({ size: "small", value: small.value });
  }

  if (
    !["tiles-cluster", "top-cluster-cluster"].includes(
      currentRoute.value?.name
    ) &&
    xsmall.value
  ) {
    arr.push({ size: "xsmall", value: xsmall.value });
  }

  return arr;
});
</script>
