<template>
  <div
    class="m-simple-date-picker"
    :class="`m-simple-date-picker--${orientation}`"
  >
    <m-input
      ref="startInputRef"
      id="m_simple_date_picker_start"
      type="date"
      name="startDate"
      :size="size"
      :label="t('general.date.start')"
      v-model:modelValue="computedStart"
      @resolve="updateModalValue"
      @blur="updateModalValue(!hideButton)"
    />
    <m-icon
      v-if="orientation == 'horizontal'"
      icon="arrow"
      direction="right"
      variant="terciary"
      status="active"
      class="mt-6 mx-3"
    />
    <m-input
      ref="endInputRef"
      id="m_simple_date_picker_end"
      type="date"
      name="endDate"
      :size="size"
      :label="t('general.date.end')"
      v-model:modelValue="computedEnd"
      @resolve="updateModalValue"
      @blur="updateModalValue(!hideButton)"
    />
    <m-button
      v-if="!hideButton"
      id="m_simple_date_picker_button"
      type="contained"
      variant="primary"
      size="small"
      :label="t('general.buttons.save')"
      @click="updateModalValue"
    />
  </div>
</template>

<script setup>
import { computed, ref } from "vue";
import { DateTime } from "luxon";
import MIcon from "@components/MIcon.vue";
import MButton from "@components/MButton.vue";
import MInput from "@components/MInput.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  modelValue: { type: Object },
  size: { type: String, default: "default" },
  orientation: {
    type: String,
    default: "horizontal",
  },
  hideButton: { type: Boolean, default: false },
});

const emits = defineEmits(["update:modelValue"]);
const { t } = useI18n();

const userClicked = ref(false);

const updateModalValue = (val) => {
  if (!val) {
    userClicked.value = true;
    emits("update:modelValue", {
      start:
        DateTime.fromISO(start.value).toJSDate?.() ??
        DateTime.fromISO(start.value),
      end:
        DateTime.fromISO(end.value).toJSDate?.() ?? DateTime.fromISO(end.value),
    });
    setTimeout(() => {
      userClicked.value = false;
    }, 3000);
  }
};

const startInputRef = ref(null);
const endInputRef = ref(null);
const start = ref(props.modelValue.start);
const end = ref(props.modelValue.end);

const computedStart = computed({
  get() {
    if (typeof start.value == "string") {
      //Play it safe and possibly remove the time from the date
      return start.value.split("T")[0];
    }
    if (start.value.isLuxonDateTime) return start.value.toISODate();
    else
      return `${start.value.getFullYear()}-${(
        "0" +
        (start.value.getMonth() + 1)
      ).slice(-2)}-${("0" + start.value.getDate()).slice(-2)}`;
  },
  set(val) {
    if (start.value != val) start.value = val;
  },
});

const computedEnd = computed({
  get() {
    if (typeof end.value == "string") {
      //Play it safe and possibly remove the time from the date
      return end.value.split("T")[0];
    }
    if (end.value.isLuxonDateTime) return end.value.toISODate();
    else
      return `${end.value.getFullYear()}-${(
        "0" +
        (end.value.getMonth() + 1)
      ).slice(-2)}-${("0" + end.value.getDate()).slice(-2)}`;
  },
  set(val) {
    if (end.value != val) end.value = val;
  },
});
</script>

<style lang="scss">
.m-simple-date-picker {
  height: 100%;
  width: 100%;

  &--vertical {
    @include flex(center, center, column);
    flex-wrap: wrap;
    gap: $spacing-5;

    .m-input {
      width: 100%;
    }
  }

  &--horizontal {
    @include flex(center, center, row);

    .m-input {
      flex-grow: 1;
    }
  }

  .m-button {
    position: absolute;
    right: $spacing-6;
    bottom: $spacing-6;
  }
}
</style>
