import reportTour from "@hooks/useTour/report.tour.js";
import { useI18n } from "vue-i18n";

import createViewTour from "./createView.tour";

//import { createViewTour } from "@hooks/useTour/createView.tour.js";
//import { dashboardTour } from "@hooks/useTour/dashboard.tour.js";

export default () => {
  return {
    reportTour: reportTour(),
    createViewTour: createViewTour(),
    // dashboardTour : dashboardTour(t, store),
  };
};
