<template>
  <div
    class="m-loading"
    :class="[
      {
        'm-loading--overlay': overlay,
        'm-loading--blured': blured,
      },
      `m-loading--${type} m-loading--${base}`,
    ]"
  >
    <m-logo
      v-if="type == 'intro'"
      direction="horizontal"
      height="6vh"
      class="mt-6 mb-3"
    />
    <svg
      v-else-if="type == 'spinner'"
      class="m-loading__svg"
      :class="`m-loading__svg--${props.size}`"
    >
      <circle
        fill="transparent"
        :cx="circle"
        :cy="circle"
        :r="radius"
        :stroke-width="strokeWidth"
        :stroke-dasharray="dasharray"
        stroke-dashoffset="0"
        class="m-loading__still"
      />
      <circle
        fill="transparent"
        :cx="circle"
        :cy="circle"
        :r="radius"
        :stroke-width="strokeWidth"
        class="m-loading__moving"
      />
    </svg>
    <div
      v-else-if="type == 'progressive'"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      @click="toggleCancel"
    >
      <svg class="m-loading__svg" :class="`m-loading__svg--${props.size}`">
        <circle
          fill="transparent"
          :cx="circle"
          :cy="circle"
          :r="radius"
          :stroke-width="strokeWidth"
          :stroke-dasharray="dasharray"
          stroke-dashoffset="0"
          class="m-loading__still pointer"
          :class="{ 'm-loading__still--disabled': hover }"
        />
        <circle
          class="m-loading__moving pointer"
          :class="{ 'm-loading__moving--disabled': hover }"
          fill="transparent"
          :cx="circle"
          :cy="circle"
          :r="radius"
          :stroke-width="strokeWidth"
          :stroke-dashoffset="loadingPercentage"
        />
      </svg>
      <m-icon
        v-if="hover"
        icon="close"
        :tooltip="cancelButtonTooltip"
        variant="secondary"
      />
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from "vue";
import { useI18n } from "vue-i18n";

import MLogo from "@components/MLogo.vue";
import MIcon from "@components/MIcon.vue";

const props = defineProps({
  id: {
    type: String,
  },
  type: {
    type: String,
    default: "spinner",
    validator: function (value) {
      if (!["spinner", "progress", "intro"].includes(value)) {
        console.error(
          `Invalid prop "type": expected a string with value "spinner", "progress" or "intro" and got "${value}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component`
        );
      }
      return true;
    },
  },
  size: {
    type: String,
    required: false,
    default: "default",
    validator(size) {
      if (!["default", "small", "xsmall", "large"].includes(size)) {
        console.error(
          `Invalid prop "size": expected "large", "default", "small" or "xsmall" and got "${size}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component `
        );
      }
      return true;
    },
  },
  base: {
    type: [Number, String],
    default: "light",
    validator(value) {
      if (!["light", "dark"].includes(value)) {
        console.error(`Invalid prop "base": expected a string with value "light", "dark"
          and got "${value}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component`);
      }
      return true;
    },
  },
  overlay: {
    type: Boolean,
    default: false,
  },
  blured: {
    type: Boolean,
    default: false,
  },
  //required if type is progressive
  total: {
    type: [Number, String],
    required: false,
    validator(value) {
      if (typeof value == "string") {
        const regex = /^((100)|(\d{1,2}(\.\d*)?))%?$/; //? regex para %
        if (!value.match(regex)) {
          console.error(
            `Invalid prop "progress": expected a string or a positive number with a valid value and got "${value}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component`
          );
        }
      }
      return true;
    },
  },
  //todo: required if type is progressive
  progress: {
    type: [Number, String],
    required: false,
    validator(value) {
      if (typeof value == "string") {
        const regex = /^((100)|(\d{1,2}(\.\d*)?))%?$/; //? regex para %
        if (!value.match(regex)) {
          console.error(
            `Invalid prop "progress": expected a string or a positive number with a valid value and got "${value}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component`
          );
        }
      }
      return true;
    },
  },
  enableCancel: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["cancel"]);

const { t } = useI18n();

const hover = ref(false);

const toggleCancel = () => {
  emit("cancel");
};

// PROGRESSIVE CASE: TOTAL AND PROGRESSIVE
const loadingPercentage = computed(() => {
  return (props.progress * 100) / props.total;
});

const radius = computed(() => {
  if (props.size == "large") {
    return 20;
  } else if (props.size == "default") {
    return 16;
  } else if (props.size == "small") {
    return 12;
  } else if (props.size == "xsmall") {
    return 8;
  }
  return true;
});

// set cx and cy
const circle = computed(() => radius.value + 2);

// set dasharray
const dasharray = computed(() => {
  // regular size = default size
  if (props.size == "large") {
    return "250";
  } else if (props.size == "default") {
    return "125";
  } else if (props.size == "small") {
    return "100";
  } else if (props.size == "xsmall") {
    return "90";
  }
  return true;
});

// set stroke width
const strokeWidth = computed(() => {
  // regular size = default size
  if (props.size == "large") {
    return 4.5;
  } else if (props.size == "default") {
    return 4;
  } else if (props.size == "small") {
    return 3.5;
  } else if (props.size == "xsmall") {
    return 2.5;
  }
  return true;
});

const cancelButtonTooltip = computed(() => {
  return {
    content: t("general.buttons.cancel"),
    position: "dynamic",
  };
});

watch(
  () => props.type,
  (val) => {
    if (val === "progressive") {
      if (props.total == undefined) {
        console.error(`Invalid prop "total": expected a number or a valid string, make sure the value is passed
          and got "${props.total}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component`);
      } else if (props.progress == undefined) {
        console.error(`Invalid prop "progress": expected a number or a valid string, make sure the value is passed
          and got "${props.progress}". \n\n Go to the https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/589398072/Loadings for instruction on how to use MLoading component`);
      }
    }
  },
  {
    immediate: true,
  }
);
</script>
