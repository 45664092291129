import { computed, ref } from "vue";

import { useI18n } from "vue-i18n";
import { DateTime } from "luxon";
import DateTimeUtils from "@root/utils/dateTime";
import { useUserStore } from "@root/store/modules/user";

export default function (showTime = false) {
  const userStore = useUserStore();
  const { t } = useI18n();
  const userTimezone = userStore.timeZone;

  /**
   *
   * @param {DateTime} now
   * @param {DateTime} date
   */
  const isYesterday = (now, date) => {
    const yesterday = now.minus({ days: 1 });
    if (
      yesterday.day == date.day &&
      yesterday.month == date.month &&
      yesterday.year == date.year
    )
      return true;
    else return false;
  };

  const dateFormat = (val, type) => {
    const today = DateTime.utc().setZone(userTimezone);
    const parsedRawDate =
      val instanceof Date
        ? DateTime.fromJSDate(val, { zone: "utc" })
        : DateTimeUtils.parseFromISO(val, { zone: "utc" });
    const date = parsedRawDate > today ? today : parsedRawDate;
    const dateLocale = date;
    // const dateLocale = date.setLocale(locale.value);
    if (type == "dynamic") {
      if (today.diff(date, "days").days < 1 && today.day == date.day) {
        if (today.diff(date, "minutes").minutes < 1) {
          return t("general.date.justNow");
        } else if (today.diff(date, "minutes").minutes < 60) {
          return t(
            "general.date.minutesAgo",
            parseInt(today.diff(date, "minutes").minutes),
            {
              time: parseInt(today.diff(date, "minutes").minutes),
            }
          );
        } else if (today.diff(date, "hours").hours < 3) {
          return t(
            "general.date.hoursAgo",
            parseInt(today.diff(date, "hours").hours),
            {
              time: parseInt(today.diff(date, "hours").hours),
            }
          );
        } else {
          return t("general.date.todayAt", {
            time: dateLocale.toLocaleString(DateTime.TIME_SIMPLE),
          });
        }
      } else if (isYesterday(today, date)) {
        //Check if it is yesterday
        return t("general.date.yesterdayAt", {
          time: dateLocale.toLocaleString(DateTime.TIME_SIMPLE),
        });
      } else {
        if (showTime) {
          return t("general.date.dateAtTime", {
            date: dateLocale.toLocaleString(DateTime.DATE_SHORT),
            time: dateLocale.toLocaleString(DateTime.TIME_SIMPLE),
          });
        } else {
          return t("general.date.date", {
            date: dateLocale.toLocaleString(DateTime.DATE_SHORT),
          });
        }
      }
    } else if (type == "static") {
      if (showTime) {
        return (
          dateLocale.toLocaleString(DateTime.DATE_SHORT) +
          " - " +
          dateLocale.toLocaleString(DateTime.TIME_SIMPLE)
        );
      } else {
        return dateLocale.toLocaleString(DateTime.DATE_SHORT);
      }
    } else {
      console.error("missing type");
      return "";
    }
  };

  return {
    dateFormat,
  };
}
