import Shepherd from "shepherd.js";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import icon_list_small from "@assets/icons/icon_list_small";
import { useUserStore } from "@root/store/modules/user";
import { useAlertsStore } from "@root/store/modules/alerts";

export default function () {
  const userStore = useUserStore();
  const alertsStore = useAlertsStore();
  const { open, close, cancel } = useModal("m-dialog");

  /**
   *
   * @param {String} type
   * @param {String} balloonContent
   * @param {String} balloonTitle
   * @param {String } tourTitle
   * @returns a HTML content
   */

  const cardContent = (type, balloonContent, balloonTitle, tourTitle) => {
    if (type == "blue") {
      return `<h5 class="type--small"> ${balloonContent}</h5>`;
    } else {
      return `
       <div class="m-tour-white__content">
          <h6 class="mx-0 mb-1 m-toolbox__title">${tourTitle}</h6>
          <h4 class="mb-1">${balloonTitle}</h4>
          <h6 class="type--small">${balloonContent}</h6>
        </div>
       `;
    }
  };

  const tour = Shepherd.activeTour;

  // check the current step index
  const getCurrentStepIndex = () => {
    return Shepherd.activeTour.steps.indexOf(Shepherd.activeTour.currentStep);
  };

  // clear all steps array
  const clearArray = (array) => {
    array = [];
  };

  /**
   * Method to apply default configs  on each tour
   * @param {object} tourName the object of your tour
   * @param {Sring} dialogName the name used on dialog registration (e.g create_view / report)
   * @param {Sring} translateName the name used on en.json (e.g  createView / reports)
   */
  const configTourOptions = (tourName, dialogName, translateName, t) => {
    const currentStepElement = tourName.currentStep.el;
    const filterWord = "white";
    const whiteStepsList = tourName.steps.filter((val) =>
      val.id.includes(filterWord)
    );
    const classList = document.documentElement.classList;

    // BLUE card specifications - remove overlay
    if (tourName.currentStep.id.includes("blue")) {
      classList.add("blue-overlay");
      // create close icon - blue cards
      const headerBlue = currentStepElement.querySelector(".shepherd-header");

      const closeIcon = document.createElement("div");
      closeIcon.setAttribute("id", "m_tour_skip_icon");
      closeIcon.className =
        "m-icon m-icon--xsmall m-icon--white m-icon--inactive m-tour__close";
      closeIcon.innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" height="16" viewBox="0 0 14 14" role="presentation"><path d="M5.707,8.878l1.292-1.292,.018,.019,1.274,1.274c.398,.372,.964-.185,.585-.585h0l-1.292-1.292,1.292-1.292c.373-.413-.186-.954-.585-.585l-1.293,1.293-.019-.019-1.274-1.274c-.392-.372-.962,.175-.585,.585l1.293,1.292-1.293,1.292c-.365,.389,.163,.96,.585,.585Z"/></svg>`;

      headerBlue.appendChild(closeIcon);

      closeIcon.onclick = () => {
        const resolveDialog = () => {
          removeClass("m-hint");

          const shepherdOverlay = document.getElementsByClassName(
            "shepherd-modal-is-visible shepherd-modal-overlay-container"
          );
          shepherdOverlay[0].parentNode.removeChild(shepherdOverlay[0]);

          const screenToBlock = document.getElementsByClassName("m-main");
          screenToBlock[0].removeAttribute("style");

          classList.remove("blue-overlay");

          tourName.cancel();
          clearArray(tourName.steps);
        };

        alertsStore.add({
          type: "dialog",
          variant: "warning",
          heading: t("general.alerts.dialog.leaveTour.heading"),
          message: [
            t(
              "general.alerts.dialog.leaveTour." +
                translateName +
                "_description"
            ),
            t("general.alerts.dialog.leaveTour.retake_description"),
          ],
          resolve: {
            label: t("general.buttons.ok"),
            action: () => resolveDialog(),
          },
          cancel: true,
        });
      };
    }

    //White card specifications
    else if (tourName.currentStep.id.includes(filterWord)) {
      classList.remove("blue-overlay");

      const screenUnlock = document.getElementsByClassName("m-main");
      screenUnlock[0].removeAttribute("style");

      //create close button
      const header = currentStepElement.querySelector(".shepherd-header");
      const buttonSkip = document.createElement("div");
      buttonSkip.setAttribute("id", "m_tour_skip_button");
      buttonSkip.className =
        "mt-1 m-button m-button--xsmall m-button--text--secondary";
      buttonSkip.innerHTML = `<h6 class="type--xsmall">${t(
        "tour.buttons.skipTour"
      )}</h6>`;

      header.appendChild(buttonSkip);

      buttonSkip.onclick = () => {
        const resolveDialog = () => {
          removeClass("m-hint");
          const selector = document.getElementsByClassName("m-tour__mask");

          selector[0].parentNode.removeChild(selector[0]);
          const shepherdOverlay = document.getElementsByClassName(
            "shepherd-modal-is-visible shepherd-modal-overlay-container"
          );
          shepherdOverlay[0].parentNode.removeChild(shepherdOverlay[0]);

          const screenUnlock = document.getElementsByClassName("m-main");
          screenUnlock[0].removeAttribute("style");

          if (tourName == tour) {
            if (getCurrentStepIndex() >= 8) {
              const button = document.querySelector(
                "#m_button_m_manage_filters_create_view"
              );
              button.style["pointer-events"] = "visible";
              const articlesToBlock = document.querySelectorAll(
                '[data-tour="tour_manageFilters_preview"]'
              );
              articlesToBlock[0].style["pointer-events"] = "visible";
              const headerToBlock =
                document.getElementsByClassName("m-header__left");

              headerToBlock[0].style["pointer-events"] = "visible";
            }
          }
          tourName.cancel();
          clearArray(tourName.steps);
          userStore.completeTour(translateName);
        };

        alertsStore.add({
          type: "dialog",
          variant: "warning",
          heading: t("general.alerts.dialog.leaveTour.heading"),
          message: [
            t(
              "general.alerts.dialog.leaveTour." +
                translateName +
                "_description"
            ),
            t("general.alerts.dialog.leaveTour.retake_description"),
          ],
          resolve: {
            label: t("general.buttons.ok"),
            action: () => resolveDialog(),
          },
          cancel: true,
        });
      };

      // progress bar status
      const target = currentStepElement.querySelector(".shepherd-footer");

      const progressWrapper = document.createElement("div");
      progressWrapper.className = "m-tour__progress";

      const idOfStepSplit = tourName.currentStep.id.split("_");
      const indexStep = parseInt(idOfStepSplit[idOfStepSplit.length - 1]);

      for (let i = 0; i < whiteStepsList.length; i++) {
        const progress = document.createElement("button");
        progress.id = "r" + i;
        if (i <= indexStep) {
          progress.className = "m-tour__step m-tour__step--blue";
        } else {
          progress.className = "m-tour__step m-tour__step--grey";
        }
        progressWrapper.appendChild(progress);
      }
      target.insertBefore(
        progressWrapper,
        currentStepElement.querySelector(".shepherd-button")
      );

      progressWrapper.onclick = () => {
        tourName.back();
      };
    }
  };

  const waitForElementToDisplay = (selector) => {
    return new Promise(function (resolve) {
      (function checkIfElementExists() {
        if (document.querySelector(selector) !== null) {
          resolve();
        } else {
          setTimeout(checkIfElementExists, 300);
        }
      })();
    });
  };
  //remove all classes with a specific name
  const removeClass = (nameClass) => {
    Array.from(document.querySelectorAll("." + nameClass)).forEach((el) =>
      el.classList.remove(nameClass)
    );
  };

  // ICON CONSTRUCTION
  const createIcon = (type, variant, size) => {
    return `<svg     
      xmlns="http://www.w3.org/2000/svg"
      height=${size}
      viewBox="0 0 ${size} ${size}"
      role="presentation"
      ${type == "more" ? "direction=“vertical”" : ""} 
      class="m-icon__content">
      
      <g fill="#60769f">${icon_list_small[type][variant]}</g>
      </svg>
      `;
  };
  const deleteIcon = createIcon("delete", "regular", 18);
  const editIcon = createIcon("edit", "regular", 18);
  const moreIcon = createIcon("more", "regular", 18);

  return {
    cardContent,
    getCurrentStepIndex,
    configTourOptions,
    waitForElementToDisplay,
    removeClass,
    createIcon,
    clearArray,
    deleteIcon,
    editIcon,
    moreIcon,
  };
}
