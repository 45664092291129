import { create } from "@root/api/instance";
import * as Sentry from "@sentry/vue";
import axios from "axios";
import storage from "@utils/tokenStorage";

/* Modules */
import tags from "@api/modules/tagmodule";
import chat from "@api/modules/chatmodule";
import auth from "@api/modules/authmodule";
import user from "@api/modules/usermodule";
import views from "@api/modules/viewmodule";
import admin from "@api/modules/adminmodule";
import panel from "@api/modules/panelmodule";
import entity from "@api/modules/entitymodule";
import search from "@api/modules/searchmodule";
import timeZones from "@api/modules/timeZones";
import reports from "@api/modules/reportsmodule";
import documents from "@api/modules/documentsmodule";
import analytics from "@api/modules/analyticsmodule";
import workspaces from "@api/modules/workspacesmodule";

import { DateTime } from "luxon";
import type {
  IInterceptors,
  IRefreshTokenState,
  MAxiosRequestConfig,
  Req,
} from "./types";
import guid from "@root/utils/guid";

const config = {
  baseURL: (<any>window)._env_.VUE_APP_apiUrl || "",
  timeout: 60 * 1000, // Timeout,
  crossDomain: true,
  withCredentials: false, // Check cross-site Access-Control,
  headers: { "Cache-Control": "no-cache" },
  // cache will be enabled by default
  // adapter: cacheAdapterEnhancer(axios.defaults.adapter)
};

const unnotified = "_unnotified_";

const factory = create(config);

factory.registModule("tags", tags);
factory.registModule("chat", chat);
factory.registModule("auth", auth);
factory.registModule("user", user);
factory.registModule("views", views);
factory.registModule("admin", admin);
factory.registModule("panel", panel);
factory.registModule("entity", entity);
factory.registModule("search", search);
factory.registModule("timeZones", timeZones);
factory.registModule("reports", reports);
factory.registModule("documents", documents);
factory.registModule("analytics", analytics);
factory.registModule("workspaces", workspaces);

const api = factory.request(0, unnotified);
const named = (name: string) => factory.request(0, unnotified, name);
const prio = (prio: number) => factory.request(prio, unnotified);
const notify = (notify: string) => factory.request(0, notify);
const full = (prio: number, notify: string, name: string) =>
  factory.request(prio, notify, name);

const bareAxios = axios.create(config);

const _interceptors: IInterceptors = {
  responseResolve: null,
  responseReject: null,
  request: null,
};

const refreshTokenState: IRefreshTokenState = {
  awaiter: new Promise(() => {
    null;
  }),
  completeRefresh: null,
  cancelRefresh: null,
  isRefreshing: false,
  resetState: function () {
    this.awaiter = new Promise(() => {
      null;
    });
    this.completeRefresh = null;
    this.cancelRefresh = null;
    this.isRefreshing = false;
  },
};

factory.interceptors.request.use(async (config: MAxiosRequestConfig<any>) => {
  if (_interceptors.request) _interceptors.request(config);
  if (config._anonymous) return config;

  /**
   * Check if the exprire token is about to exprire and preemptively refresh be4
   * getting 401's
   */

  /**
   * @description Calculated by the diference between now and the token expire time.
   * If the token has more than 30 seconds to live is considered valid, if it has less
   * than 30 seconds to live is considered expired.
   * @type {boolean}
   */
  const hasExpired =
    DateTime.utc().diff(
      DateTime.fromISO(storage?.jwtToken?.localExpires, { zone: "UTC" })
    ).milliseconds >
    30 * -1000;

  if (!hasExpired) {
    if (config?.headers) {
      config.headers.Authorization = `Bearer ${storage.jwtToken?.token}`;
      config.headers["X-Transaction-ID"] = guid.NewGuid();
      Sentry.setTag("transaction_id", config.headers["X-Transaction-ID"]);
    }
    return config;
  }

  /**
   * If the token has exprired start refreshing before sending any request
   * While refreshing all the request get caught here and await for the token to be
   * refreshed be4 continuing (they await refreshTokenState.awaiter)
   */
  const rt = storage.refreshToken;
  if (rt) {
    try {
      if (!refreshTokenState.isRefreshing) {
        refreshTokenState.isRefreshing = true;
        refreshTokenState.awaiter = new Promise((res, rej) => {
          refreshTokenState.cancelRefresh = rej;
          refreshTokenState.completeRefresh = res;
        });
        /// direct call to axios - skips queue, notify and this interceptor
        const { data } = await bareAxios.request(
          api.auth.refreshToken.config(rt)
        );

        // update the token
        storage.jwtToken = data;
        if (config?.headers)
          config.headers.Authorization = `Bearer ${data.token}`;
        if (refreshTokenState.completeRefresh)
          refreshTokenState.completeRefresh();
        refreshTokenState.isRefreshing = false;
        return config;
      } else {
        await refreshTokenState.awaiter;
        if (config.headers) {
          config.headers.Authorization = `Bearer ${storage.jwtToken.token}`;
          config.headers["X-Transaction-ID"] = guid.NewGuid();
          Sentry.setTag("transaction_id", config.headers["X-Transaction-ID"]);
        }
        return config;
      }
    } catch (e) {
      // We assume there's a problem with the refreshToken so force relog
      refreshTokenState.resetState();
      storage.jwtToken = storage.refreshToken = null;
      location.assign(location.origin);
      throw new Error("Invalid refreshToken please relog");
    }
  }
  //If for some reason there's no refresh token, we need to force relogging
  storage.jwtToken = storage.refreshToken = null;
  location.assign(location.origin);
  throw new Error("Invalid refreshToken please relog");
});

let refreshingToken = false;
const waitingForRefresh: Req[] = [];

factory.interceptors.response.use(
  (response) => {
    if (_interceptors.responseResolve)
      return _interceptors.responseResolve(response);
    else return response;
  },
  async (error) => {
    let response = Promise.reject(error);
    if (
      error.response &&
      error.response.status === 401 &&
      !error.config._anonymous
    ) {
      const rt = storage.refreshToken;
      if (rt) {
        try {
          if (!refreshingToken) {
            refreshingToken = true;
            /// direct call to axios - skips queue, notify and this interceptor
            const { data } = await bareAxios.request(
              api.auth.refreshToken.config(rt)
            );
            storage.jwtToken = data;
            // update the token
            error.config.headers.Authorization = `Bearer ${data.token}`;
            response = bareAxios.request(error.config);
            while (waitingForRefresh.length) {
              const item = waitingForRefresh.pop();
              if (item) {
                const { res, rej, error } = item;
                if (error.config.headers)
                  error.config.headers.Authorization = `Bearer ${data.token}`;
                bareAxios.request(error.config).then(res, rej);
              }
            }
            refreshingToken = false;
          } else {
            response = new Promise((res, rej) => {
              waitingForRefresh.push({
                error,
                res,
                rej,
              });
            });
          }
        } catch {
          while (waitingForRefresh.length) {
            const item = waitingForRefresh.pop();
            if (item) {
              const { rej, error } = item;
              rej(error);
            }
          }
        }
      }
    }

    return response.then(
      _interceptors.responseResolve,
      _interceptors.responseReject
    );
  }
);

export const interceptors = {
  request: (fn: any) => {
    _interceptors.request = fn;
  },
  response: (res: any, rej: any) => {
    if (res) _interceptors.responseResolve = res;
    if (rej) _interceptors.responseReject = rej;
  },
};

export function setLocale(locale: string) {
  factory.defaults.headers.common["Accept-Language"] = locale;
}

const exports = {
  api,
  prio,
  notify,
  full,
  named,
};

export const useApi = () => exports;
