/** Jwt and refresh token management */

import { inject, reactive, readonly } from "vue";
import { defineStore } from "pinia";

const sharelistcachetime = 10000;
const jwtName = "_jwt";
const refreshName = "_rft";
const impersonatorRefreshName = "_irft";
const workspaceIdName = "_wspid";
const redirectName = "_redirect";
const chatHistoryName = "_chathistory";
const mainNavStateName = "_mainnavstate";
const secNavStateName = "_secnavstate";
const activeEntityName = "_activeentity";
const modalDataName = "_modaldata";

interface SessionStoreState {
  moduleHasInit: boolean;
  jwtToken: string | null | undefined;
  refreshToken: string | null | undefined;
  impersonatorRefreshToken: string | null | undefined;
  workspaceId: string | null;
  user: object;
  sharelist: { list: null; updated: 0 };
  chatHistory: any[] | null;
  mainNavState: "expanded" | "collapsed";
  secNavState: "expanded" | "collapsed";
  activeEntity: object;
  modalData: string | null;
}

export const useSessionStore = defineStore("store", {
  state: (): SessionStoreState => ({
    moduleHasInit: false,
    jwtToken: sessionStorage.getItem(jwtName) || null,
    refreshToken: localStorage.getItem(refreshName) || null,
    impersonatorRefreshToken:
      sessionStorage.getItem(impersonatorRefreshName) || null,
    workspaceId: localStorage.getItem(workspaceIdName) || null,
    user: {},
    sharelist: { list: null, updated: 0 },
    chatHistory:
      JSON.parse(localStorage.getItem(chatHistoryName) ?? "[]") || null,
    mainNavState:
      (localStorage.getItem(mainNavStateName) as "expanded" | "collapsed") ||
      "expanded",
    secNavState:
      (localStorage.getItem(secNavStateName) as "expanded" | "collapsed") ||
      "expanded",
    activeEntity:
      JSON.parse(localStorage.getItem(activeEntityName) ?? "{}") || null,
    modalData: localStorage.getItem(modalDataName) || null,
  }),

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Session module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async jwtToken_set(token?: string) {
      this.jwtToken = token;
      if (token) {
        sessionStorage.setItem(jwtName, token);
      } else {
        sessionStorage.removeItem(jwtName);
      }
    },
    async jwtToken_remove() {
      this.jwtToken_set();
    },
    async refreshToken_set(token?: string) {
      this.refreshToken = token;
      if (token) {
        localStorage.setItem(refreshName, token);
      } else {
        localStorage.removeItem(refreshName);
      }
    },
    async refreshToken_remove() {
      this.refreshToken_set();
    },
    async impersonatorRefreshToken_set(token?: string) {
      this.impersonatorRefreshToken = token;
      if (token) {
        sessionStorage.setItem(impersonatorRefreshName, token);
      } else {
        sessionStorage.removeItem(impersonatorRefreshName);
      }
    },
    async impersonatorRefreshToken_remove() {
      this.impersonatorRefreshToken_set();
    },
    async workspaceId_set(id: string) {
      this.workspaceId = id;
      if (id) {
        localStorage.setItem(workspaceIdName, id);
      } else {
        localStorage.removeItem(workspaceIdName);
      }
    },
    async chatHistorySet(conversation: any[]) {
      this.chatHistory = conversation;
      if (conversation) {
        localStorage.setItem(chatHistoryName, JSON.stringify(conversation));
      } else {
        localStorage.removeItem(chatHistoryName);
      }
    },
    async mainNavStateSet(val: "expanded" | "collapsed") {
      this.mainNavState = val;
      if (val) {
        localStorage.setItem(mainNavStateName, val);
      } else {
        localStorage.removeItem(mainNavStateName);
      }
    },
    async secNavStateSet(val: "expanded" | "collapsed") {
      this.secNavState = val;
      if (val) {
        localStorage.setItem(secNavStateName, val);
      } else {
        localStorage.removeItem(secNavStateName);
      }
    },
    async setModalData(data: string) {
      this.modalData = data;
      if (data) {
        localStorage.setItem(modalDataName, data);
      } else localStorage.removeItem(modalDataName);
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async logout(): Promise<void> {
      // considerar passar isto para a o index porque
      // poderá ser necessário mexer nas views
      return new Promise((res, rej) => {
        this.refreshToken_remove();
        res();
      });
    },
    /*   async getShareList({ state, commit }) {
    if (
      this.sharelist.list === null ||
      new Date().getTime() > this.sharelist.update
    ) {
      try {
        let response = await api.unnotified.viewsmodule.getShareList();
        commit("set_sharelist", response.data);
      } catch (e) {
        return [];
      }
    }
    return this.sharelist.list;
  }, */
  },
});
