<template>
  <div :id="id" class="m-badge">
    <div class="m-badge__image">
      <m-image
        v-if="filter.image || !isLoading"
        :id="`image_${id}`"
        :src="filter.image"
      />
      <m-loading v-else-if="isLoading" type="spinner" size="xsmall" />
    </div>

    <div class="m-badge__details" :dir="checkForArabicLanguage(filter.label)">
      <p class="h5 type--small" :class="{ 'type--error': filter.negated }">
        <span v-if="filter.negated">-</span>
        {{ filter.label }}
      </p>
      <p class="h6 type--xsmall type--empty">
        <template v-if="t(`general.facets.${filter.type}`)?.includes('.')">
          {{ filter.type }}
        </template>
        <template v-else>
          {{ t(`general.facets.${filter.type}`) }}
        </template>
      </p>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import MImage from "@components/MImage.vue";
import MLoading from "@components/MLoading.vue";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  filter: { type: Object, required: true },
});

const { t } = useI18n();

const isLoading = ref(!props.filter?.image);

const checkForArabicLanguage = (txt) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(txt) ? "rtl" : null;
};

onMounted(() => {
  if (!props.filter?.image) {
    isLoading.value = true;
    setTimeout(() => {
      isLoading.value = false;
    }, 500);
  }
});
</script>

<style scoped lang="scss">
.m-badge {
  &__image {
    min-width: $spacing-7;
    max-width: $spacing-7;

    img {
      width: 100%;
      height: 100%;
      @include round-corners;
      object-fit: cover;
    }
  }

  &__details {
    max-width: calc(100% - $spacing-10);

    .h5 {
      @include ellipsis;
    }
  }
}

.m-loading {
  transform: scale(0.6);
}
</style>
