import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  getUserTags() {
    return axios.get<MonitioAPI.SmartTagTypeDTO[]>(`/v1/smartTags/user`);
  },
  createSmartTag(params: MonitioAPI.CreateSmartTagDTO) {
    return axios.post<void>(`/v1/smartTags/create-smart-tag`, { ...params });
  },
  addDocumenttoTag(
    document: MonitioAPI.SearchDocumentDTO,
    influence: boolean,
    tagId: number
  ) {
    return axios.post<void>(`/v1/smartTags/add-to-smart-tag`, document, {
      params: {
        influence,
        tagId,
      },
    });
  },
  /*   createTagAndDocumentTag(name: string, document: object) {
    return axios.post(`/v1/tag/tagAndDocTag?name=${name}`, { document });
  },
  addDocTag(tagId: string, documentId: string, document: object) {
    return axios.post(
      `/v1/tag/docTag?tagId=${tagId}&documentId=${documentId}`,
      {
        document,
      }
    );
  },
  removeDocTag(tagId: string, documentId: string, document: object) {
    return axios.post(
      `/v1/tag/untagDocument?tagId=${tagId}&documentId=${documentId}`,
      { document }
    );
  },
  getUserTagsSettingsPage() {
    return axios.get(`/v1/tag/settings`);
  },
  getUserDocumentTags(documentId: string) {
    return axios.get(`/v1/tag/docTag?documentId=${documentId}`);
  },
  updateActiveField(tagId: string) {
    return axios.put(`/v1/tag/updateActiveField?tagId=${tagId}`);
  }, */
});
