import { ref } from "vue";

export default function (disabled = false) {
  const openDropdown = ref(false);
  let timeout = undefined;

  const toggleDropdown = (toggleDropdown, readonly) => {
    if (readonly) return;
    if (toggleDropdown > -1) {
      if (toggleDropdown === openDropdown.value) {
        openDropdown.value = undefined;
      } else openDropdown.value = toggleDropdown;
    } else {
      if (!disabled) openDropdown.value = !openDropdown.value;
      clearTimeout(timeout);
    }
  };

  const keepDropdownOpen = () => {
    clearTimeout(timeout);
  };

  const closeDropdown = (callback) => {
    return new Promise((res) => {
      if (!disabled) {
        timeout = setTimeout(() => {
          openDropdown.value = false;
          if (typeof callback == "function") callback();
          res();
        }, 300);
      } else {
        res();
      }
    });
  };

  return {
    openDropdown,
    toggleDropdown,
    keepDropdownOpen,
    closeDropdown,
  };
}
