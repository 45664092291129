export default function (num, digits) {
  const unitConverter = (num, digits) => {
    if (num != undefined) {
      let units = ["", "k", "M", "B", "T", "P", "E", "Z", "Y"];
      let numDigits = Math.abs(num).toString().length;
      let unitAt = 0;
      if (numDigits <= 3) {
        return num.toString();
      } else {
        unitAt = Math.floor((numDigits - 1) / 3);
      }
      let value = +(num / Math.pow(1000, unitAt));
      return value.toFixed(value >= 1 ? digits : 2) + "|" + units[unitAt];
    }
  };

  return {
    unitConverter,
  };
}
