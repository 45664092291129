<template>
  <div
    id="m_header_account"
    class="m-header-account"
    @mouseleave="closeDropdown"
  >
    <label id="m_header_account_label" class="visually-hidden">
      {{ userName }}
    </label>
    <m-user-badge
      ref="accountRef"
      data-test="test_m_header_account_menu"
      tabindex="0"
      aria-controls="m_header_account_dropdown"
      :aria-expanded="openDropdown"
      aria-haspopup="listbox"
      aria-labelledby="m_header_account_label"
      :user="account"
      :tooltip="{ position: 'bottom-left' }"
      type="badge"
      show="image"
      class="pa-1 m-header-account__thumbnail"
      :class="{ '--a11y': appStore.a11y }"
      @click="toggleDropdown"
      @keyup.up="openDropdown = true"
      @keyup.down.exact="openDropdown = true"
      @keyup.alt.down="openDropdown = true"
      @keyup.home="openDropdown = true"
      @keyup.end="openDropdown = true"
      @keyup.space="openDropdown = true"
      @keyup.enter="openDropdown = true"
      @keyup.esc="closeDropdown"
    />
    <m-dropdown
      id="m_header_account_dropdown"
      labelledBy="m_header_workspace"
      v-model:visible="openDropdown"
      :options="options"
      class="m-header-account__dropdown"
      :position="['left', 'bottom']"
      size="small"
      :query="query"
      :keyup="keyup"
      :keydown="keydown"
      disableSort
      @update:selected="select"
      @keep-dropdown-open="keepDropdownOpen"
      @mouseenter="keepDropdownOpen"
    />
    <m-retake-tour ref="retakeTourModal" :retakeTour="retakeTour" />
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

import useDropdown from "@hooks/useDropdown";
import useAboutModal from "@components/modals/MAbout/useAboutModal";
import useOnboardingModal from "@root/components/modals/MOnboarding/useOnboardingModal";
import MDropdown from "@components/MDropdown.vue";
import MUserBadge from "@components/MUserBadge.vue";
import MRetakeTour from "@components/modals/MRetakeTour.vue";
import { useAppStore } from "@root/store/app";
import { useUserStore } from "@root/store/modules/user";
import { useMonitioStore } from "@root/store/store";

const props = defineProps({ closeOthers: { type: String } });

const { t } = useI18n();
const router = useRouter();

const monitioStore = useMonitioStore();
const appStore = useAppStore();
const userStore = useUserStore();
const { openDropdown, toggleDropdown, keepDropdownOpen, closeDropdown } =
  useDropdown();
const { open: openAboutModal } = useAboutModal();
const { open: openOnboardingModal } = useOnboardingModal();

const accountRef = ref(null);

watch(
  () => props.closeOthers,
  (val) => {
    if (val != "account") closeDropdown();
  }
);

const account = computed(() => {
  return userStore.details;
});

const retakeTourModal = ref(null);
const retakeTour = ref("");

const themeOpts = computed(() => {
  const opts = ["light", "dark"];

  return opts.map((m) => ({
    value: m,
    label: t(`navigation.header.account_theme_${m}`),
  }));
});

const helpOpts = computed(() => {
  // Add in future: "createView", "createReport", "createDashboard"
  const opts = ["about", "onboarding"];

  return opts.map((m) => ({
    value: m,
    label: t(`navigation.header.account_help_${m}`),
  }));
});

const options = computed(() => {
  let arr = [/*"theme",*/ "settings", "help", "logout"];

  arr = arr.map((m) => ({
    value: m,
    label: t(`navigation.header.account_${m}`),
  }));

  // arr.find((f) => f.value == "theme").selected = userStore.theme;
  // arr.find((f) => f.value == "theme").options = themeOpts.value;
  arr.find((f) => f.value == "help").options = helpOpts.value;
  return arr;
});

const select = async (val, secVal) => {
  switch (val.value) {
    case "theme":
      userStore.setTheme(secVal);
      break;
    case "settings":
      router.push({ name: "account" });
      break;
    case "logout":
      router.push("/login");
      monitioStore.shutdown();
      break;
    case "help":
      if (secVal.value == "about") openAboutModal();
      if (secVal.value == "onboarding") openOnboardingModal();
      // else if (secVal.value) {
      //   retakeTour.value = secVal.value;
      //   retakeTourModal.value.open();
      // }
      break;
  }

  closeDropdown();
};

const userName = computed(() => {
  return `${account.value?.accountDetails?.firstName}  ${account.value?.accountDetails?.lastName}`;
});

const query = ref("");
const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;

  if (evt.code.startsWith("Key")) {
    if (!openDropdown.value) openDropdown.value = true;
    query.value += evt.key;
    setTimeout(() => {
      query.value = "";
    }, 500);
  }
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  accountRef.value.ref.addEventListener("keyup", keyUpMovement);
  accountRef.value.ref.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  accountRef.value.ref.removeEventListener("keyup", keyUpMovement);
  accountRef.value.ref.removeEventListener("keydown", keyDownMovement);
});
</script>

<style scoped lang="scss">
.m-header-account {
  position: relative;

  &__thumbnail,
  &__thumbnail * {
    cursor: pointer;
  }

  &__dropdown {
    top: 40px;
    transform: translate($spacing-0, -($spacing-2));
    z-index: $z-popover;
  }
}
</style>
