import { useUserStore } from "@root/store/modules/user";
import { ref, reactive, computed } from "vue";
import { useI18n } from "vue-i18n";

export default function () {
  const { t } = useI18n();
  const userStore = useUserStore();
  const i18nLanguage = computed(() => userStore?.i18nLanguage.split("-")[0]);

  const isoLanguages = reactive([]);
  /**
   *
   * @param {string} code
   * @returns
   */
  const localizeCountryCode = (code) => {
    let lang;
    if (i18nLanguage.value == "original") lang = "en";
    else lang = i18nLanguage.value;

    const regionNames = new Intl.DisplayNames([lang], {
      type: "region",
    });

    try {
      return regionNames.of(code);
    } catch (error) {
      return code;
    }
  };

  const regionTranslators = reactive({});
  const localizeLanguageCode = (code) => {
    if (i18nLanguage.value == "original") i18nLanguage.value = "en";
    if (!(i18nLanguage.value in regionTranslators)) {
      regionTranslators[i18nLanguage.value] = new Intl.DisplayNames(
        [i18nLanguage.value],
        {
          type: "language",
        }
      );
    }
    try {
      return regionTranslators[i18nLanguage.value].of(code);
    } catch (error) {
      return code;
    }
  };

  const keysToTranslate = ref([
    "/customMetadata/sourceItemTypedScopes/$ISO_3166_alpha-2_country_code",
    "sourceLanguage",
  ]);

  const complexTranslate = (totranslate, key) => {
    if (totranslate != undefined)
      if (keysToTranslate.value.indexOf(key) >= 0) {
        if (
          key ==
          "/customMetadata/sourceItemTypedScopes/$ISO_3166_alpha-2_country_code"
        )
          return localizeCountryCode(totranslate);
        if (key == "sourceLanguage") return localizeLanguageCode(totranslate);
        return t(totranslate);
      }
    return totranslate;
  };

  return { localizeCountryCode, localizeLanguageCode, complexTranslate };
}
