import axios from "axios";
import { useApi } from "@api/api";
import { IPTCDescriptionToCode } from "../iptcs";

import useUnitConverter from "@hooks/useUnitConverter";
const { api } = useApi();

const negUNC = "__N__";
const groupUNC = "__G__";
const keyUNC = "__K__";

const { unitConverter } = useUnitConverter();

const toLocalISOString = (dt, tz) => {
  return dt;
};

const fromLocalISOString = (datestr) => {
  const regex = new RegExp(
    "(?<year>[0-9]{4})-(?<month>[0-9]{1,2})-(?<day>[0-9]{1,2})T(?<hour>[0-9]{1,2}):(?<minute>[0-9]{1,2}):(?<second>[0-9]{1,2}).(?<millisecond>[0-9]+)"
  );
  const match = regex.exec(datestr);
  if (match)
    return new Date(
      match.groups["year"],
      +match.groups["month"] - 1,
      match.groups["day"],
      match.groups["hour"],
      match.groups["minute"],
      match.groups["second"],
      match.groups["millisecond"]
    );
  else return null;
};

const createFilterParam = (filters) => {
  const changed = Object.keys(filters).reduce((p, c) => {
    const group = filters[c];
    const selected =
      group.filters &&
      Object.keys(group.filters)
        .map((m) => group.filters[m])
        .filter((f) => f.selected);
    if (selected.length) p[c] = selected;
    return p;
  }, {});

  const param = encodeURIComponent(
    Object.keys(changed)
      .map((f) => {
        const keys = changed[f]
          .map((k) => `${!k.negated ? "" : negUNC}${k.id}`)
          .join(keyUNC);
        return `${f}${groupUNC}${keys}`;
      })
      .join(groupUNC)
  );

  return param;
};

const createUUID = () => {
  let dt = new Date().getTime();
  const uuid = "xxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (dt + Math.random() * 16) % 16 | 0;
    dt = Math.floor(dt / 16);
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
  return uuid;
};

const queryRestrictionToFilters = (restriction) => {
  const filters = {};
  if (restriction) {
    const groups = restriction.split(groupUNC);
    for (let i = 0; i < groups.length / 2; i++) {
      const type = groups[i * 2];
      const keys = groups[i * 2 + 1];

      if (type && keys) {
        if (!filters[type]) filters[type] = [];
        const ksplit = keys.split(keyUNC);
        ksplit.forEach((f) => {
          filters[type].push({ key: f });
        });
      }
    }
  }
  return filters;
};

const checkWiki = async (language, baseForm, baseLanguage, params) => {
  let response = await axios.get(
    `https://${language}.wikipedia.org/w/api.php`,
    {
      params,
    }
  );
  if (response.status == 404 || !response.data?.query) {
    params.titles = baseForm;
    response = await axios.get(
      `https://${baseLanguage}.wikipedia.org/w/api.php`,
      {
        params,
      }
    );
  }
  return response;
};
const checkForWikiImage = async (
  language,
  baseForm,
  currlangForm,
  baseLanguage
) => {
  const params = {
    action: "query",
    prop: "pageimages|info",
    inprop: "url",
    format: "json",
    /**@type {"original"|"thumbnail"} */
    piprop: "thumbnail",
    titles: currlangForm,
    pithumbsize: 50,
    origin: "*",
  };

  const response = await checkWiki(language, baseForm, baseLanguage, params);

  const pages =
    (response.data && response.data.query && response.data.query.pages) || {};

  const result = { page: null, image: null, fullurl: null };
  for (const key in pages) {
    if (key < 0) continue;
    const page = pages[key];
    if (page.fullurl) result.page = page.fullurl;
    if (page.original?.source) result.image = page.original.source;
    if (page.thumbnail?.source) result.image = page.thumbnail.source;

    if (result.page && result.image && result.fullurl) break;
  }
  return result;
};

const computePMI = (item, corpora_total, results_total) => {
  console.log(item, corpora_total, results_total);
  let corpora_frequency = item.corpora_frequency;
  const mincap = 0.0005 * corpora_total;
  if (corpora_frequency < mincap) corpora_frequency = mincap;

  const p_selection = results_total / corpora_total;
  const key_posterior = item.selection_frequency / results_total;
  const key_prior = corpora_frequency / corpora_total;

  let pmi_norm = Math.log(key_posterior / key_prior) / -Math.log(p_selection);
  let pmi_cap = (pmi_norm + 1) / 2;

  /* if (p_selection > 0.1) {
    pmi_norm = item.selection_frequency / 25; // 🔨🔨🔨🔨🔨🔨🔨🔨🔨
    pmi_cap = item.selection_frequency / 25; // 🔨🔨🔨🔨🔨🔨🔨🔨🔨
  } */

  const pmi_cap_pure = pmi_cap;

  return {
    corpora_frequency: item.corpora_frequency,
    cap_corpora_frequency: corpora_frequency,
    selection_frequency: item.selection_frequency,

    corpora_total: corpora_total,
    selection_total: results_total,

    p_selection: p_selection,
    key_posterior: key_posterior,
    key_prior: key_prior,
    pmi_norm: pmi_norm,
    pmi_cap: pmi_cap,
    pmi_cap_pure: pmi_cap_pure,
  };
};

const computePMI2 = (item, corpora_total, results_total) => {
  let corpora_frequency = item.corpora_frequency;
  const mincap = 0.0005 * corpora_total;
  if (corpora_frequency < mincap) corpora_frequency = mincap;

  const pxy = item.selection_frequency / (corpora_total + 0.0000000001);
  const px = item.selection_frequency / (results_total + 0.0000000001);
  const py = item.corpora_frequency / (corpora_total + 0.0000000001);

  const pmi = Math.log2(pxy / (px * py + 0.0000000001));
  const normalized_pmi = pmi / -Math.log2(pxy + 0.0000000001);

  const x = (normalized_pmi + 1) / 2;

  return {
    corpora_frequency: item.corpora_frequency,
    cap_corpora_frequency: corpora_frequency,
    selection_frequency: item.selection_frequency,

    corpora_total: corpora_total,
    selection_total: results_total,

    pmi_norm: x,
    pmi_cap: x,
    pmi_cap_pure: x,
  };
};

const rainbow = (numOfSteps, step) => {
  // This function generates vibrant, "evenly spaced" colours (i.e. no
  // clustering). This is ideal for creating easily distinguishable vibrant
  // markers in Google Maps and other apps. Adam Cole, 2011-Sept-14 HSV to
  // RBG adapted from:
  // http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript
  let r, g, b;
  const h = step / numOfSteps;
  const i = ~~(h * 6);
  const f = h * 6 - i;
  const q = 1 - f;
  switch (i % 6) {
    case 0:
      r = 1;
      g = f;
      b = 0;
      break;
    case 1:
      r = q;
      g = 1;
      b = 0;
      break;
    case 2:
      r = 0;
      g = 1;
      b = f;
      break;
    case 3:
      r = 0;
      g = q;
      b = 1;
      break;
    case 4:
      r = f;
      g = 0;
      b = 1;
      break;
    case 5:
      r = 1;
      g = 0;
      b = q;
      break;
  }
  const c =
    "#" +
    ("00" + (~~(r * 255)).toString(16)).slice(-2) +
    ("00" + (~~(g * 255)).toString(16)).slice(-2) +
    ("00" + (~~(b * 255)).toString(16)).slice(-2);
  return c;
};

const cleanViewEditorEntry = (entry) => {
  const newentry = { ...entry, filters: [] };
  if (entry && entry.filters) {
    entry.filters.forEach((s) => {
      const filters = Object.keys(s.filters).reduce((p, c) => {
        let keys = s.filters[c];
        if (Array.isArray(keys)) {
          keys = keys.filter((f, i) => {
            return f.key && keys.findIndex((e) => e.key === f.key) === i;
          });

          if (keys.length) p[c] = keys;
        }
        return p;
      }, {});

      if (s.query || Object.keys(filters).length) {
        newentry.filters.push({
          query: s.query,
          filters: filters,
        });
      }
    });
  }
  return newentry;
};

const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const dayoftheweek = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Out",
  "Nov",
  "Dec",
];

const isElementChildren = (elem, container) => {
  while (elem) {
    if (elem === container) return true;
    elem = elem.parentElement;
  }
  return false;
};

const getDisplaySettingElement = (ds, name) => {
  if (Array.isArray(ds)) {
    for (let i = 0; i < ds.length; i++) {
      let el = null;
      if (ds[i].name === name) el = ds[i];
      else if (Array.isArray(ds[i].children))
        el = getDisplaySettingElement(ds[i].children, name);
      if (el !== null) return el;
    }
    return null;
  }
};
const getIPTCCode = (iptc_tag) => {
  if (Object.prototype.hasOwnProperty.call(IPTCDescriptionToCode, iptc_tag)) {
    const rawLevels = IPTCDescriptionToCode[iptc_tag];
    const el = {
      level: 0,
      l: rawLevels,
      l0: rawLevels.slice(0, 2),
      l1: rawLevels.slice(2, 5),
      l2: rawLevels.slice(5, 8),
    };
    if (el.l.slice(2) == "000000") {
      el.level = 0;
    } else {
      if (el.l.slice(5) == "000") {
        el.level = 1;
      } else {
        el.level = 2;
      }
    }
    return el;
  } else {
    return {
      level: 0,
      l: "00000000",
      l0: "00",
      l1: "00",
      l2: "00",
    };
  }
};

const dateValid = (date) => {
  return date.getTime && date.getTime() === date.getTime();
};
const validateViewFilters = (view) => {
  const errors = [];
  if (
    !view.newViewDetails ||
    !Array.isArray(view.newViewDetails.filters) ||
    view.newViewDetails.filters.length == 0
  ) {
    errors.push({
      id: "filters",
      index: -1, // generic error
      code: "empty",
    });
  } else {
    view.newViewDetails.filters.forEach((f, i) => {
      const hasfilters = Object.keys(f.filters).reduce(
        (p, c) =>
          p ||
          (Array.isArray(f.filters[c] && f.filters[c].filters) &&
            f.filters[c].filters.length),
        false
      );
      if (!f.query && !hasfilters) {
        errors.push({
          id: "filters",
          index: i,
          code: "no-query-no-filters",
        });
      }
    });
  }
  return errors;
};

const validateView = (view) => {
  let errors = [];
  if (typeof view.newName !== "string" || view.newName.trim().length === 0) {
    errors.push({
      id: "newName",
      code: "newname-empty",
    });
  }

  errors = errors.concat(validateViewFilters(view));

  return {
    valid: errors.length === 0,
    errors: errors,
  };
};

const validatePanel = (panel) => {
  const errors = [];
  if (typeof panel.newName !== "string" || panel.newName.trim().length === 0) {
    errors.push({
      id: "newName",
      code: "newpanelname-empty",
    });
  }
  // TODO: Add more panel validation
  return {
    valid: errors.length === 0,
    errors: errors,
  };
};

const validateDashboard = (dashboard, existingDashboards) => {
  const errors = [];
  if (
    typeof dashboard.name !== "string" ||
    dashboard.name.trim().length === 0
  ) {
    errors.push({
      id: "emptyName",
      code: "newdashboardname-empty",
    });
  }
  // else if (
  //   dashboard.name !== "" &&
  //   existingDashboards.find(
  //     (f) => f.name.toLowerCase() === dashboard.name.toLowerCase()
  //   )
  // ) {
  //   errors.push({
  //     id: "dupName",
  //     code: "dashboardname-exists",
  //   });
  // }
  return {
    valid: errors.length === 0,
    errors: errors,
  };
};

const isMouseOnTop = (evt, elem) => {
  const rect = elem.getBoundingClientRect();
  const x = evt.clientX;
  const y = evt.clientY;

  return rect.left < x && rect.right > x && rect.top < y && rect.bottom > y;
};

const checkImage = (imageSrc, good, bad) => {
  const img = new Image();
  img.onload = () => {
    good(img);
  };
  img.onerror = () => {
    bad(img);
  };
  img.src = imageSrc;
};

const hasClass = (el, className) => {
  if (el.classList) return el.classList.contains(className);
  return !!el.className.match(new RegExp("(\\s|^)" + className + "(\\s|$)"));
};

const addClass = (el, className) => {
  if (el) {
    if (el.classList) el.classList.add(className);
    else if (!hasClass(el, className)) el.className += " " + className;
  }
};

const removeClass = (el, className) => {
  if (el) {
    if (el.classList) el.classList.remove(className);
    else if (hasClass(el, className)) {
      const reg = new RegExp("(\\s|^)" + className + "(\\s|$)");
      el.className = el.className.replace(reg, " ");
    }
  }
};

const resizeTextLite = (clusteringcell) => {
  if (clusteringcell) {
    const reduceby = 0.9; // percent
    const height = clusteringcell.clientHeight * reduceby;
    const width = clusteringcell.clientWidth * reduceby;
    const textHeight = 0;
    const maxFontSize = 16; //parseInt(this.maxFontSize, 10);
    const minFontSize = 7; //parseInt(this.minFontSize, 10);
    /* let unit = "px"; //this.maxFontSize.replace(maxFontSize.toString(), ""); */

    const fontSize = 0.001 * (height * width);
    const realFontSize = Math.min(
      Math.max(fontSize - 1, minFontSize),
      maxFontSize
    );
    clusteringcell.setAttribute("font-size", realFontSize);
    clusteringcell.style["font-size"] = realFontSize + "pt";
    if (fontSize < 8) {
      clusteringcell.style["font-weight"] = "400";
      clusteringcell.style["line-height"] = "130%";
    } else if (fontSize < 10) {
      clusteringcell.style["font-weight"] = "600";
      clusteringcell.style["line-height"] = "140%";
    } else {
      clusteringcell.style["font-weight"] = "700";
      clusteringcell.style["line-height"] = "125%";
    }
  }

  // possible model (put also in the other place where this is decided)
  /*           let fontSize = heigth / 22 + width / 30;
  clusteringcell.style["font-size"] =
    Math.min(Math.max(fontSize - 1, minFontSize), maxFontSize) + "pt"; */
};

const tryGet = (obj, ...params) => {
  if (obj) {
    const p = params.shift();
    if (p !== undefined) {
      if (obj[p]) {
        return tryGet(obj[p], ...params);
      } else return null;
    } else return obj;
  } else return null;
};

const readFeedNameFromHeader = (header) => {
  if (!header) return "";

  return (
    (header.customMetadata &&
      header.customMetadata.sourceItemFeedNames &&
      header.customMetadata.sourceItemFeedNames.length > 0 &&
      header.customMetadata.sourceItemFeedNames[0]) ||
    header.sourceItemOriginFeedName ||
    ""
  );
};

const dateDiffInDays = (dt1, dt2) => {
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
  );
};

const isTypeMindMapColor = (type) => {
  const filtered = type?.replace("_qid", "").replace("qid_", "").toLowerCase();
  if (!filtered) return false;
  return (
    filtered == "places" ||
    filtered == "organization" ||
    filtered == "people" ||
    filtered == "iptc"
  );
};

const removeFiltersAndPositions = (dash) => {
  dash.forEach((v) => {
    delete v.x;
    delete v.y;
    v.charts.forEach((c) => {
      // delete c.x;
      // delete c.y;
      delete c.filters;
    });
  });
  return dash;
};

const reorderLayout = (layout) => {
  return layout.sort((a, b) => {
    return a.y * 10 + a.x - (b.y * 10 + b.x);
  });
};
const recalculateLayoutPositions = (layout, colNum, addCard = null) => {
  let positionMatrix = [];
  function addEmptyRows(matrix, col, numberofRows) {
    for (let r = 1; r <= numberofRows; r++) {
      const row = [];
      for (let i = 0; i < col; i++) row.push(0);
      matrix.push(row);
    }
    return matrix;
  }

  function spaceInRow(row, width) {
    let space = 0;
    let index = -1;
    for (let x = 0; x < row.length; x++) {
      if (row[x] == 0) {
        if (index == -1) index = x;
        space += 1;
      } else {
        space = 0;
        index = -1;
      }
      if (space >= width) break;
    }
    if (space < width) return -1;
    return index;
  }

  function fillMatrix(matrix, x, y, w, h) {
    while (h > 0) {
      h -= 1;
      for (let i = 0; i < w; i++) {
        matrix[y + h][x + i] = 1;
      }
    }
    return matrix;
  }

  // The previous algorithm consider the addCard as an element outside
  // the matrix array. In the new grid layout, addCard its in the matrix
  // array. This functions just finds it and pushes it to the last index
  function popAndPushAddCard(matrix) {
    const idx = matrix.findIndex((el) => el.i === "addCard");
    if (idx == -1) return matrix;
    const card = matrix[idx];
    matrix.splice(idx, 1);
    matrix.push(card);
    return matrix;
  }

  positionMatrix = addEmptyRows(positionMatrix, colNum, 1);
  let activeRow = 0;
  if (addCard) layout?.push(addCard);

  popAndPushAddCard(layout);

  layout?.forEach((item) => {
    console.log(item);
    while (positionMatrix[activeRow].every((p) => p == 1)) {
      activeRow += 1;
      if (activeRow >= positionMatrix.length) {
        positionMatrix = addEmptyRows(positionMatrix, colNum, 1);
      }
    }
    const tempWidth = item.w > colNum ? colNum : item.w;
    let space = spaceInRow(positionMatrix[activeRow], tempWidth);
    if (space < 0) {
      space = 0;
      positionMatrix = addEmptyRows(positionMatrix, colNum, 1);
      activeRow = positionMatrix.length - 1;
    }
    item.x = space;
    item.y = activeRow;
    const additionalRows = item.y + item.h - positionMatrix.length;
    if (additionalRows > 0) {
      positionMatrix = addEmptyRows(positionMatrix, colNum, additionalRows);
    }
    positionMatrix = fillMatrix(positionMatrix, item.x, item.y, item.w, item.h);
  });
  const response = {};

  if (addCard) response.addCard = layout?.pop();

  response.layout = layout;
  return response;
};
const estimatedCount = (effCount) => {
  const str_key_count = effCount.toString();
  const first_digits = Math.round(
    parseInt(str_key_count.substring(0, 2)) / 10.0
  );
  const orderOfMagnitude = Math.pow(10, Math.ceil(Math.log10(effCount) - 1));
  return Math.round(orderOfMagnitude * first_digits);
};

const sanitizedValue = (value, wasExtrapolated) => {
  if (typeof value == "number") {
    let effCount = value;
    let displayDigits = 1;
    if (wasExtrapolated) {
      effCount = estimatedCount(effCount);
      displayDigits = 0;
    }
    let count = unitConverter(effCount, displayDigits);
    count = count.split("|");
    return count;
  } else {
    return [];
  }
};
const encodeCSVEntry = (entry) => {
  let overload = false;
  const maxSize = 16000;
  if (entry.length > maxSize) overload = true;
  let ret = `"${entry
    .substring(0, maxSize)
    .replaceAll('"', '""')
    .replaceAll("\n", "\\n")
    .replaceAll("\t", "\\t")}"`;
  if (overload) ret += `(<truncated to ${maxSize} chars>)`;
  return ret;
};

const sanitizeToCSV = (str) => {
  console.log(str);
  str = str.trim();

  // Remove accent marks using Unicode normalization
  str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

  // Escape double quotes with double quotes
  str = str.replace(/"/g, '""');

  // Quote the string if needed
  if (/[,"'\n]/.test(str)) {
    return `"${str}"`;
  }

  return str;
};

const doNotInclude = ["dashboard", "granularity", "chartSearch"];

const flattenKeys = (obj, keys, flatMap, path = []) => {
  if (obj) {
    for (const k of Object.keys(obj)) {
      if (typeof obj[k] === "object" && obj[k] != null) {
        return flattenKeys(obj[k], keys, flatMap, path.concat(k));
      } else {
        keys.push(obj[k]);
        const tpath = path.concat(k);
        flatMap[tpath.join(".")] = obj[k];
      }
    }
  }
};

const colors = [
  "#c73880",
  "#c738c7",
  "#7343db",
  "#316fd3",
  "#2cbcc9",
  "#22c997",
  "#8dc526",
  "#c9a722",
  "#db8229",
];

const stringToColor = (str) => {
  if (!str) return;
  const color =
    str
      ?.split(" ")
      .map((a) => a.charCodeAt(0))
      .reduce((a, b) => a + b, 0) % 10 || 0;
  return colors[color];
};

export {
  flattenKeys,
  dateValid,
  toLocalISOString,
  fromLocalISOString,
  validatePanel,
  negUNC,
  groupUNC,
  keyUNC,
  createFilterParam,
  checkWiki,
  checkForWikiImage,
  computePMI,
  computePMI2,
  rainbow,
  queryRestrictionToFilters,
  cleanViewEditorEntry,
  deepCopy,
  dayoftheweek,
  months,
  isElementChildren,
  getDisplaySettingElement,
  validateView,
  validateViewFilters,
  validateDashboard,
  isMouseOnTop,
  checkImage,
  hasClass,
  addClass,
  removeClass,
  resizeTextLite,
  tryGet,
  readFeedNameFromHeader,
  dateDiffInDays,
  isTypeMindMapColor,
  doNotInclude,
  createUUID,
  getIPTCCode,
  removeFiltersAndPositions,
  estimatedCount,
  sanitizedValue,
  encodeCSVEntry,
  sanitizeToCSV,
  reorderLayout,
  recalculateLayoutPositions,
  stringToColor,
};
