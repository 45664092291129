import type { MonitioAPI } from "@root/types.api.local";
import { defineStore } from "pinia";
import { inject, reactive, readonly } from "vue";

interface ViewPropertiesStoreState {
  moduleHasInit: boolean;
  sessionViewProperties: { [key: string]: any };
  baseViewProperties: { [key: string]: any };
}

export const useViewPropertiesStore = defineStore("viewProperties", {
  state: (): ViewPropertiesStoreState => ({
    moduleHasInit: false,
    sessionViewProperties: {},
    baseViewProperties: {},
  }),

  getters: {
    getViewSessionFilters() {
      return (viewId: string) => this.sessionViewProperties[viewId]?.filters;
    },
    getViewSessionQuery() {
      return (viewId: string) => this.sessionViewProperties[viewId]?.query;
    },
  },
  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("ViewProperties module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async setViewFilters(filters: any, viewId: any) {
      if (viewId) this.sessionViewProperties[viewId] = { filters: filters };
      if (!viewId) this.baseViewProperties = { filters: filters };
    },
    async setViewQuery(query: any, viewId: any) {
      if (viewId) this.sessionViewProperties[viewId] = { query: query };
      if (!viewId) this.baseViewProperties = { query: query };
    },
  },
});
