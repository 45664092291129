// View and Panel composer
import { defineStore } from "pinia";

const storageId = "composer";

interface ViewStoreState {
  moduleHasInit: boolean;
  history: string[];
  from: string;
}

export const useComposerStore = defineStore("composer", {
  state: (): ViewStoreState => ({
    moduleHasInit: false,
    history: sessionStorage.getItem(storageId)
      ? JSON.parse(sessionStorage.getItem(storageId) ?? "{}")
      : [],
    from: "",
  }),

  getters: {
    current: (state) => state.history[state.history.length - 1] || {},
    length: (state) => state.history.length,
  },

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Composer module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async reset() {
      sessionStorage.removeItem(storageId);
      this.history = [];
    },
    async pop() {
      this.history.pop();
      sessionStorage.setItem(storageId, JSON.stringify(this.history));
    },
    async push(payload: string) {
      this.history.push(payload);
      sessionStorage.setItem(storageId, JSON.stringify(this.history));
    },
  },
});
