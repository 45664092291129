<template>
  <div v-if="showHeaderFilters" class="m-header-filters">
    <div v-if="options.includes('sort')" class="m-header-filters--sort">
      <m-selector
        id="header_sort"
        :tooltip="t('navigation.header.filters.sort')"
        :selected="sort"
        :options="sortOpts"
        size="small"
        variant="simple"
        class="m-clickable"
        @update:selected="changeSort"
      />
    </div>
    <div v-if="options.includes('show')" class="m-header-filters--show">
      <m-selector
        id="header_show"
        :tooltip="t('navigation.header.filters.show')"
        :selected="show"
        :options="showOpts"
        size="small"
        variant="simple"
        class="m-clickable"
        @update:selected="changeShow"
      />
    </div>
    <div
      v-if="
        windowWidth >= 1366 &&
        options.includes('aggregator') &&
        aggregatorOpts.length > 1
      "
      class="m-header-filters--aggregator"
    >
      <m-selector
        id="header_aggregator"
        :tooltip="t('navigation.header.filters.aggregator')"
        :selected="aggregator"
        :options="aggregatorOpts"
        size="small"
        variant="simple"
        class="m-clickable"
        @update:selected="
          (opt) => {
            updateAggregator(opt.value);
          }
        "
      />
    </div>
    <div
      v-if="windowWidth >= 1366 && options.includes('granularity')"
      class="m-header-filters--granularity"
    >
      <m-selector
        id="header_granularity"
        :tooltip="t('navigation.header.filters.granularity')"
        :selected="granularity"
        :options="granularityOpts"
        :disable-sort="true"
        size="small"
        variant="simple"
        class="m-clickable"
        @update:selected="(opt) => updateGranularity(opt.value)"
      />
    </div>
    <div v-if="options.includes('filters')" class="m-header-filters--filters">
      <m-selector
        id="header_filters"
        :tooltip="t('navigation.header.filters.filters')"
        :selected="selectedRefineBy"
        :options="refineByOpts"
        size="small"
        variant="simple"
        canSelectAll
        class="m-clickable"
        type="multiple"
        :selectedLabel="getRefineBySelectedLabel"
        @update:selected="changeRefineBy"
      />
    </div>
    <div
      v-if="windowWidth >= 1366 && options.includes('graph-type')"
      class="m-header-filters--graph-type"
    >
      <m-selector
        id="header_graphType"
        :tooltip="t('navigation.header.filters.graph-type')"
        :selected="graphType"
        :options="graphTypeOpts"
        @update:selected="(val) => updateGraphType(val.value)"
        size="small"
        variant="simple"
        class="m-clickable"
      />
    </div>
    <div
      v-if="windowWidth >= 1366 && options.includes('preference')"
      class="m-header-filters--preference"
    >
      <m-selector
        id="header_preference"
        :tooltip="t('navigation.header.filters.preference')"
        :selected="preference"
        :options="preferenceOpts"
        size="small"
        variant="simple"
        class="m-clickable"
        @update:selected="(val) => updatePreference(val.value)"
      />
    </div>
    <div v-if="options.includes('type')" class="m-header-filters--type">
      <m-selector
        id="header_type"
        :tooltip="t('navigation.header.filters.type')"
        v-model:selected="type"
        :options="typeOpts"
        size="small"
        variant="simple"
        class="m-clickable"
        @update:selected="changeType"
      />
    </div>
    <!-- <div v-if="options.includes('mode')" class="m-header-filters--mode">
      <m-selector
        id="header_mode"
        :title="t('navigation.header.filters.mode')"
        v-model:selected="mode"
        :options="modeOpts"
        size="small" 
        variant="simple"
        class="m-clickable"
        @update:selected="changeMode"
      />
    </div> -->

    <div v-if="options.includes('send')" class="m-header-filters--send">
      <m-icon
        id="send"
        icon="send"
        :tooltip="t('navigation.header.filters.send')"
        variant="secondary"
        size="small"
        class="pa-2 m-clickable"
        @click="triggerSend"
      />
    </div>

    <div v-if="options.includes('automate')" class="m-header-filters--automate">
      <m-icon
        id="automate"
        icon="report-automate"
        :tooltip="t('navigation.header.filters.automate')"
        variant="secondary"
        size="small"
        class="pa-2 m-clickable"
        @click="
          openAutomateReportModal(
            route.params.reportId,
            structuredClone(reportRecurrencyInfo)
          )
        "
      />
    </div>

    <div
      v-if="options.includes('edit-template') && report.template.id"
      class="m-header-filters--send"
    >
      <m-icon
        id="send"
        icon="edit"
        :tooltip="t('navigation.header.filters.edit')"
        variant="secondary"
        size="small"
        class="pa-2 m-clickable"
        @click="
          router.push({
            name: 'reportBuilder',
            params: {
              templateId: report.template.id,
            },
          })
        "
      />
    </div>
    <!--  <div
      v-if="!editor && options.includes('csv')"
      class="m-header-filters--csv"
    >
      <m-button
        id="csv"
        leadingIcon="file_download"
        :label="t('navigation.header.filters.csv')"
        type="outlined"
        variant="primary"
        size="small"
        @click="triggerCSVExport"
      />
    </div> -->
    <div v-if="moreOptions.length">
      <m-options
        id="header_more"
        :position="['left', 'bottom']"
        :options="moreOptions"
        size="small"
        disableSort
        class="py-1 m-clickable"
        @select="selectMoreOpt"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { debounce } from "lodash-es";
import { useRouter, useRoute } from "vue-router";
import { useViewFilters } from "@hooks/useViewFilters";
import useWindowSize from "@hooks/useWindowSize";
import MSelector from "@components/MSelector.vue";
import MIcon from "@components/MIcon.vue";
import MOptions from "@components/MOptions.vue";
import { useReportsStore } from "@root/store/modules/reports";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";
import { useDashboardsStore } from "@root/store/modules/dashboards";
import { useAppStore } from "@root/store/app";
import { useClustersStore } from "@root/store/modules/clusters";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import useAutomateReportModal from "@components/modals/MAutomateReport/useAutomateReportModal";
import { eventBus } from "@root/utils/eventBus";
import structuredClone from "@utils/structuredClone";
import TimeFrame from "@root/utils/enums/timeFrames";

const { t } = useI18n();
const reportsStore = useReportsStore();
const viewsStore = useViewsStore();
const userStore = useUserStore();
const clustersStore = useClustersStore();
const appStore = useAppStore();
const dashboardsStore = useDashboardsStore();
const workspacesStore = useWorkspacesStore();
const route = useRoute();
const router = useRouter();
const { windowWidth } = useWindowSize();
const {
  changeShow,
  sortBy,
  show,
  queryObject,
  dateRestriction,
  refineBy,
  refineByTrendingOpts,
  refineByMindmapOpts,
  graphType,
  updateGraphType,
  granularity,
  granularityOpts,
  updateGranularity,
  preference,
  updatePreference,
  aggregator,
  updateAggregator,
  showSnippet,
  aggregatorOpts,
  aggregateDuplicates,
} = useViewFilters(router, route);
const backendDetails = computed(
  () => viewsStore.getViewById(route.params.viewId)?.backend?.details
);
const { open: openAutomateReport } = useAutomateReportModal();

const openAutomateReportModal = (reportId, info) => {
  if (!info.dateRestriction) dateRestriction.value = TimeFrame.SinceSnapshot;
  else dateRestriction.value = info.dateRestriction;
  openAutomateReport(reportId, info);
};

const viewId = computed(() => {
  const currentRoute = router.currentRoute.value;
  if (currentRoute?.meta?.breadcrumbs[0] == "dashboards") {
    return dashboardsStore.getById(currentRoute?.params?.dashboardId).viewId;
  } else return route.params.viewId;
});
const view = computed(() => viewsStore.getViewById(viewId.value));
const workspaceId = ref(workspacesStore.id);
const showHeaderFilters = computed(() => {
  return !["home", "manageFilters", "createManageFilters"].includes(
    router.currentRoute.value?.name
  );
});

const refineByOpts = computed(() => {
  if (route.name == "trending-entities") return refineByTrendingOpts.value;
  else return refineByMindmapOpts.value;
});

const options = computed(() => {
  const current = router.currentRoute.value;

  let result = [];
  switch (current.name) {
    case "explore":
    case "explorer":
    case "tiles-cluster":
    case "headlines-cluster":
      result = [
        "sort",
        /*"show",*/ "duplicates",
        "snippet",
        "layout",
        "mark-all-as-read",
      ];
      break;
    case "articles":
      result = [
        "sort",
        /*"show",*/ "duplicates",
        "snippet",
        "layout",
        "mark-all-as-read",
      ];
      if (userStore.details.featureAccess?.["massArticlesExport"])
        result.push("export-csv");
      break;
    case "saved":
      result = ["layout"];
      if (userStore.details.featureAccess?.["massArticlesExport"])
        result.push("export-csv");
      break;
    case "tiles":
      result = ["aggregator", "viewsNavOpt", "export-csv", "fullscreen"];
      break;
    case "headlines":
      result = ["aggregator", "viewsNavOpt"];
      break;
    case "trending-entities":
      result = ["granularity", "filters", "viewsNavOpt"];
      break;
    case "entity-network":
      result = ["graph-type", "filters", "preference", "viewsNavOpt"];
      break;
    case "report":
      result = ["send", "automate", "csv", "edit-template"];
      break;
    case "dashboardsTemplates":
    case "dashboards":
    case "reportsTemplates":
    case "reports":
    case "summary":
    case "manageFilters":
    case "createManageFilters":
    case "dashboard":
    default:
      return [];
  }

  //Remove what we dont want here:
  result = result.filter(
    (x) => !backendDetails.value?.orchestrationServicesDisabled?.includes(x)
  ); //Remove some services that are inavtive in certain workspaces
  return result;
});

const sort = computed(() => {
  if (
    router.currentRoute.value.name == "articles" ||
    router.currentRoute.value.name.includes("-cluster")
  ) {
    return sortOpts.value.find((x) => x.value == sortBy.value)?.value;
  } else return viewsStore.sort;
});

const sortOpts = computed(() => {
  let opts;

  if (
    router.currentRoute.value.name == "articles" ||
    router.currentRoute.value.name.includes("-cluster")
  ) {
    opts = ["date", "relevance"];
  } else opts = ["name", "latest", "oldest"];

  return opts.map((m) => ({
    value: m,
    label: t(`navigation.header.filters.sort_${m}`),
  }));
});

const changeSort = (val) => {
  if (
    router.currentRoute.value.name == "articles" ||
    router.currentRoute.value.name.includes("-cluster")
  )
    sortBy.value = val.value;
  else viewsStore.setSort(val.value);
};

const showOpts = ref([
  { value: "all", label: t("navigation.header.filters.show_all") },
  { value: "unread", label: t("navigation.header.filters.show_unread") },
  { value: "read", label: t("navigation.header.filters.show_read") },
]);

const selectedRefineBy = ref(refineByOpts.value.filter((x) => x.selected));

const changeRefineBy = (val) => {
  if (Array.isArray(val)) {
    if (selectedRefineBy.value.length == refineByOpts.value.length)
      selectedRefineBy.value = [];
    else selectedRefineBy.value = refineByOpts.value;
  } else {
    if (!selectedRefineBy.value.find((x) => x.value == val.value)) {
      selectedRefineBy.value.push(val);
    } else {
      selectedRefineBy.value = selectedRefineBy.value.filter(
        (x) => x.value != val.value
      );
    }
  }
  debounceUpdateRefineBy();
};

watch(
  () => route.name,
  (val, oldVal) => {
    if (val != oldVal) {
      selectedRefineBy.value = refineByOpts.value.filter((x) => x.selected);
    }
  },
  { immediate: true }
);

const getRefineBySelectedLabel = computed(() => {
  if (selectedRefineBy.value.length == refineByOpts.value.length) {
    return t("navigation.header.filters.allFilters");
  } else
    return t("navigation.header.filters.nFilters", {
      n: selectedRefineBy.value.length,
    });
});

const updateRefineBy = () => {
  refineBy.value = selectedRefineBy.value.map((x) => x.value);
};

const debounceUpdateRefineBy = debounce(updateRefineBy, 750);

const graphTypeOpts = computed(() => {
  return [
    { value: "star", label: t("navigation.header.filters.graphType_star") },
    { value: "mst", label: t("navigation.header.filters.graphType_mst") },
  ];
});

const preferenceOpts = computed(() => {
  return [
    {
      value: "pmi",
      label: t("navigation.header.filters.preference_relevance"),
    },
    {
      value: "frequency",
      label: t("navigation.header.filters.preference_frequency"),
    },
  ];
});

const changeType = (val) => {
  appStore.setGraphType(val);
};

const report = computed(() => reportsStore.getById(route.params.reportId));
const reportRecurrencyInfo = computed(() => {
  const recurrencyInfo = structuredClone(report.value.recurrencyInfo);

  // Fix time in order for the input to doesn't show seconds
  const time = recurrencyInfo.deliveryTime?.split(":");
  time.pop();
  recurrencyInfo.deliveryTime = time.join(":");
  return recurrencyInfo;
});

const openEdit = () => {
  switch (router.currentRoute.value.name) {
    case "report":
      router.push({
        name: "reportBuilder",
        params: {
          reportId: route.params.reportId,
          viewId: route.params.viewId,
        },
      });
      break;
    case "dashboard":
      router.push({
        name: "dashboardBuilder",
        params: { dashboardId: route.params.dashboardId },
      });
      break;
  }
};

const duplicate = () => {
  alert("TODO");
};

const deleteEl = () => {
  alert("TODO");
};

const triggerSend = () => {
  if (route.name == "report") eventBus.emit("send-report");
};

const snippet = computed(() => {
  if (showSnippet.value) return "find-in-text-off";
  else return "find-in-text-on";
});

const duplicates = computed(() => {
  if (aggregateDuplicates.value) return "duplicates-off";
  else return "duplicates-on";
});

const fullscreen = computed(() => {
  if (appStore.fullscreen) return "collapse-screen";
  else return "expand-screen";
});

const layoutOpts = computed(() => {
  const opts = ["list", "details", "card"];

  return opts.map((m) => ({
    value: m,
    icon: m,
    label: t(`navigation.header.filters.layout_${m}`),
  }));
});

const moreOptions = computed(() => {
  let arr = [];
  const opts = options.value;

  if (
    windowWidth.value < 1366 &&
    (opts.includes("aggregator") ||
      opts.includes("graph-type") ||
      opts.includes("preference") ||
      opts.includes("granularity"))
  ) {
    if (opts.includes("aggregator")) arr.push({ value: "aggregator" });
    if (opts.includes("graph-type")) arr.push({ value: "graph-type" });
    if (opts.includes("preference")) arr.push({ value: "preference" });
    if (opts.includes("granularity")) arr.push({ value: "granularity" });
  }

  if (opts.includes("mark-all-as-read")) {
    arr.push({
      icon: "read",
      value: "mark-all-as-read",
      disabled: viewsStore?.allArticlesRead,
    });
  }

  if (opts.includes("snippet")) {
    arr.push({ icon: "find-in-text", value: snippet.value });
  }

  if (opts.includes("duplicates")) {
    arr.push({ value: duplicates.value });
  }

  if (opts.includes("layout")) {
    const current = router.currentRoute.value;
    if (current.name != "saved") arr.push({ value: "divider" });
    arr.push({ value: "layout" });
  }

  if (opts.includes("export-csv")) {
    arr.push({
      icon: "download",
      value: "export-csv",
      disabled: appStore.isExporting,
    });
  }
  if (opts.includes("fullscreen")) arr.push({ value: fullscreen.value });

  if (opts.includes("edit")) {
    arr.push({ value: "edit" });
  }

  arr = arr.map((m) => ({
    ...m,
    icon: m.icon ?? m.value,
    value: m.value,
    label: t(`navigation.header.filters.${m.value}`),
  }));

  for (let i = 0; i < arr.length; i++) {
    if (arr[i]?.value == "layout") {
      delete arr[i].icon;

      arr[i].selected = viewsStore.layout;
      arr[i].options = layoutOpts.value;
    }

    if (arr[i]?.value == "aggregator") {
      delete arr[i].icon;

      arr[i].selected = aggregator.value;
      arr[i].options = aggregatorOpts.value;
    }

    if (arr[i]?.value == "granularity") {
      if (granularityOpts.value?.length) {
        delete arr[i].icon;
        arr[i].selected = granularity.value;
        arr[i].options = granularityOpts.value;
      } else arr.splice(i, 1);
    }

    if (arr[i]?.value == "graph-type") {
      if (graphTypeOpts.value?.length) {
        delete arr[i].icon;
        arr[i].selected = graphType.value;
        arr[i].options = graphTypeOpts.value;
      } else arr.splice(i, 1);
    }

    if (arr[i]?.value == "preference") {
      if (preferenceOpts.value?.length) {
        delete arr[i].icon;
        arr[i].selected = preference.value;
        arr[i].options = preferenceOpts.value;
      } else arr.splice(i, 1);
    }
  }

  return arr;
});

const moreOptionsSecondarySelected = ref(undefined);

onMounted(() => {
  if (options.value.includes("layout")) {
    moreOptionsSecondarySelected.value = { layout: viewsStore.layout };
  }
});

const selectMoreOpt = (opt, secOpt) => {
  switch (opt) {
    case "aggregator":
      updateAggregator(secOpt);
      return;
    case "graph-type":
      updateGraphType(secOpt);
      return;
    case "preference":
      updatePreference(secOpt);
      return;
    case "granularity":
      updateGranularity(secOpt);
      return;
    case "find-in-text-on":
      viewsStore.setShowSnippet(true);
      return;
    case "find-in-text-off":
      viewsStore.setShowSnippet(false);
      return;
    case "duplicates-on":
      aggregateDuplicates.value = true;
      return;
    case "duplicates-off":
      aggregateDuplicates.value = false;
      return;
    case "layout":
      if (secOpt) viewsStore.setLayout(secOpt);
      return;
    case "mark-all-as-read":
      viewsStore.markAllAsRead(
        t,
        viewId.value,
        dateRestriction.value,
        queryObject.value.filters
      );
      return;
    case "export-csv":
      if (route.name == "articles") eventBus.emit("export-csv");
      if (route.name == "saved") eventBus.emit("export-saved-csv");
      if (route.name == "tiles" || route.name == "headlines")
        clustersStore.exportCSV(t, dateRestriction.value, aggregator.value);
      return;
    case "expand-screen":
      appStore.setFullscreen(true);
      return;
    case "edit":
      openEdit();
      return;
    case "duplicate":
      duplicate();
      return;
    case "delete":
      deleteEl();
      return;
    default:
      return;
  }
};
</script>
