import { inject, reactive, readonly } from "vue";
import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";
import type { Monitio } from "@root/types";

interface FilterStoreState {
  moduleHasInit: boolean;
  latestApiCallParams: Monitio.FilterQueryParams | null;
  latestResult: MonitioAPI.FilterNavigator | null;
}

export const useFiltersStore = defineStore("filters", {
  state: (): FilterStoreState => ({
    moduleHasInit: false,
    /** This is the most recent filter data, its retrieved directly from search */
    latestApiCallParams: null,
    latestResult: null,
  }),

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Filter module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async updateLatestApiCallParams(params: Monitio.FilterQueryParams) {
      this.latestApiCallParams = params;
    },
    async updateLatestResult(result: MonitioAPI.FilterNavigator) {
      this.latestResult = result;
    },
  },
});
