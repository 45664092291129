export default function structuredClone(obj) {
  let clone;
  try {
    if (Array.isArray(obj)) clone = window.structuredClone([...obj]);
    else clone = window.structuredClone({ ...obj });
  } catch (error) {
    //console.error(error);
    console.debug(error.message + ". Falling backend to JSON stringify/parse.");
    clone = JSON.parse(JSON.stringify(obj));
  }
  return clone;
}
