import { defineStore } from "pinia";
import { useWorkspacesStore } from "./modules/workspaces";
import { useAppStore } from "./app";
import { useViewsStore } from "./modules/views";
import { useUserStore } from "./modules/user";
import { useViewPropertiesStore } from "./modules/viewProperties";
import { useClustersStore } from "./modules/clusters";
import { useAlertsStore } from "./modules/alerts";
import { useComposerStore } from "./modules/composer";
import { useDashboardsStore } from "./modules/dashboards";
import { useFiltersStore } from "./modules/filters";
import { useNavigationStore } from "./modules/navigation";
import { useReportsStore } from "./modules/reports";
import { useSessionStore } from "./modules/session";

export const useMonitioStore = defineStore("monitio", {
  state: () => ({
    hasInit: false,
    initMessage: "",
    progressMessage: "",
  }),
  actions: {
    async appInit() {
      const modules = {
        app: useAppStore(),
        user: useUserStore(),
        views: useViewsStore(),
        alerts: useAlertsStore(),
        session: useSessionStore(),
        filters: useFiltersStore(),
        reports: useReportsStore(),
        clusters: useClustersStore(),
        composer: useComposerStore(),
        navigation: useNavigationStore(),
        dashboards: useDashboardsStore(),
        workspaces: useWorkspacesStore(),
        viewProperties: useViewPropertiesStore(),
      };

      console.group("App initialization");
      console.log(
        "%c Monitio <store> %c Initializing store...",
        "background:rgb(28, 67, 140) ; padding: 1px; border-radius: 2px; color: #fff; font-weight: 700;",
        "background:transparent"
      );
      for (const module in modules) {
        try {
          this.progressMessage = `🌩 Initializing ${module} module...`;
          await modules[module as keyof typeof modules].init();
          console.debug(`🆗 Initialized ${module} module`);
        } catch (error: unknown) {
          console.debug(
            `🆘 Unable to initialize ${module} module. Details: ${error}`
          );
        }
      }
      console.groupEnd();
      this.progressMessage = "";
      this.hasInit = true;
    },
    async shutdown() {
      const modules = {
        app: useAppStore(),
        user: useUserStore(),
        views: useViewsStore(),
        alerts: useAlertsStore(),
        session: useSessionStore(),
        filters: useFiltersStore(),
        reports: useReportsStore(),
        clusters: useClustersStore(),
        composer: useComposerStore(),
        navigation: useNavigationStore(),
        dashboards: useDashboardsStore(),
        workspaces: useWorkspacesStore(),
        viewProperties: useViewPropertiesStore(),
      };

      this.hasInit = false;
      console.group("Monitio is shutting down");
      console.log(
        "%c Monitio <store> %c Shutting down store...",
        "background:rgb(140, 67, 28) ; padding: 1px; border-radius: 2px; color: #fff; font-weight: 700;",
        "background:transparent"
      );
      for (const module in modules) {
        try {
          await modules[module as keyof typeof modules].shutdown();
          console.debug(`🛑 Shutdown ${module} module`);
        } catch (error: unknown) {
          console.debug(
            `🆘 Unable to shutdown ${module} module. Details: ${error}`
          );
        }
      }
      console.groupEnd();
    },
  },
});
