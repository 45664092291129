<template>
  <div
    v-if="showImage"
    ref="userBadgeRef"
    :id="`m_user_badge_${user.id}`"
    class="m-badge"
    :class="{ 'm-badge--tag': type === 'tag' }"
    :data-tooltip-content="details?.firstName"
    data-tooltip-position="dynamic"
  >
    <div
      v-if="!imgError && user?.accountPersonalization?.pictureUrl"
      class="m-badge__image"
    >
      <m-image
        :src="user.accountPersonalization?.pictureUrl"
        :alt="$t('navigation.header.account_userThumbnail')"
        @error="imgError = true"
      />
    </div>
    <div v-else class="m-badge__image">
      <div
        class="m-image__base"
        :style="{
          backgroundColor: `${stringToColor(username)}26`,
        }"
      ></div>
      <span
        :style="{
          color: stringToColor(username),
        }"
      >
        {{ initials }}
      </span>
    </div>
    <div v-if="showDetails" class="m-badge__details">
      <h5 :class="type === 'tag' ? 'type--xsmall' : 'type--small'">
        {{ `${details?.firstName} ${details?.lastName}` }}
        <span
          v-if="user?.state && type !== 'tag'"
          class="h6 type--error type--xsmall"
        >
          {{ user.state }}</span
        >
      </h5>
      <h6 v-if="type !== 'tag'" class="type--xsmall type--empty">
        {{ user?.email }}
      </h6>
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio User Badge component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881918101/User+Badge
 */

import { ref, computed, defineExpose, onMounted } from "vue";
import { stringToColor } from "@utils/utils";
import MImage from "@components/MImage.vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: "badge",
    validator(type) {
      if (!["badge", "tag"].includes(type)) {
        console.error(
          `Invalid prop "type": expected string with value "badge" or "tag" and got "${type}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881918101/User+Badge for instructions on how to use the MUserBadge component.`
        );
      }
      return true;
    },
  },
  show: {
    type: [Array, String],
    default: () => ["image", "details"],
    validator(show) {
      if (
        Array.isArray(show) &&
        show.find((f) => f != "image" && f != "details") &&
        !["image", "details"].includes(show)
      ) {
        console.error(
          `Invalid prop "show": expected either a array with value ["image", "details"] or a string with value "image" or "details" and got "${show}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881918101/User+Badge for instructions on how to use the MUserBadge component.`
        );
      }
      return true;
    },
  },
  tooltip: {
    type: Object,
    validator(tooltip) {
      if (tooltip.content) {
        console.warn(
          `Prop "tooltip": attribute "content" is going to be ignored since MUserBadge displays the user name on the tooltip. \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881918101/User+Badge for instructions on how to use the MUserBadge component.`
        );
      }
      if (
        tooltip.position &&
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(tooltip.position)
      ) {
        console.error(
          `Invalid prop "tooltip": expected string attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${tooltip.position}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/881918101/User+Badge for instructions on how to use the MUserBadge component.`
        );
      }
      return true;
    },
  },
});

const { t } = useI18n();

const userBadgeRef = ref(null);
const imgError = ref(null);

const showImage = computed(() => {
  return (
    props.show == "image" ||
    (Array.isArray(props.show) && props.show.find((f) => f == "image"))
  );
});

const showDetails = computed(() => {
  return (
    props.show == "details" ||
    (Array.isArray(props.show) && props.show.find((f) => f == "details"))
  );
});

const details = computed(() => props.user?.accountDetails);

const username = computed(() => {
  return details.value?.firstName + details.value?.lastName;
});

const initials = computed(() => {
  return (
    details.value?.firstName?.charAt(0) + details.value?.lastName?.charAt(0)
  );
});

defineExpose({
  ref: userBadgeRef,
});
</script>

<style scoped>
:deep(.m-image) {
  max-height: 100%;
}
</style>
