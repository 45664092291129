<template>
  <div class="m-container__item">
    <div class="m-headline-item__header">
      <div>
        <skeleton-loader
          type="image"
          width="300"
          height="22"
          :lines="1"
          class="mb-2"
        />
        <m-type-skeleton type="h6" size="xsmall" width="200" :lines="1" />
      </div>
      <skeleton-loader type="button" width="81" height="26" :corner="4" />
    </div>
    <div class="m-top-cluster m-articles__wrapper">
      <div class="m-top-cluster__large m-top-cluster-skeleton">
        <m-article-skeleton />
      </div>
      <div class="m-top-cluster__small">
        <m-article-skeleton v-for="i in 2" :key="i" />
      </div>
      <div v-if="!userStore.pinnedPane" class="m-top-cluster__xsmall">
        <m-article-skeleton v-for="i in 3" :key="i" :titleLines="titleLines" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import SkeletonLoader from "@priberam/skeleton-loader";
import MTypeSkeleton from "@components/skeletons/MTypeSkeleton.vue";
import MArticleSkeleton from "@components/skeletons/MArticleSkeleton.vue";
import { useUserStore } from "@root/store/modules/user";
import useWindowSize from "@hooks/useWindowSize";

const userStore = useUserStore();
const { windowWidth, windowHeight } = useWindowSize();

const titleLines = computed(() => {
  if (windowWidth.value >= 1024 && windowWidth.value < 1280) {
    return 1;
  } else if (windowWidth.value >= 1280 && windowWidth.value < 1536) {
    return 3;
  } else return 2;
});
</script>

<style scoped lang="scss">
.m-headline-item {
  .m-container {
    &__item {
      padding-bottom: $spacing-6;
      margin-bottom: $spacing-6;
    }
  }

  &__header {
    margin-bottom: $spacing-2;
    @include flex(space-between, flex-end, row);
  }
}
</style>
