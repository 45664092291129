<template>
  <div
    v-if="showImage"
    :id="`m_team_badge_${team.id}`"
    class="m-badge"
    :class="{ 'm-badge--tag': type === 'tag' }"
  >
    <div class="m-badge__image">
      <span
        :style="{
          color: stringToColor(team.name),
        }"
      >
        {{ initials }}
      </span>
      <div
        class="m-image__base"
        :style="{
          backgroundColor: `${stringToColor(team.name)}26`,
        }"
      ></div>
    </div>
    <div class="m-badge__details">
      <h5 :class="type === 'tag' ? 'type--xsmall' : 'type--small'">
        {{ team.name }}
      </h5>
      <h6 v-if="type !== 'tag'" class="type--xsmall type--empty">
        {{ t("general.forms.team") }}
      </h6>
    </div>
    <div v-if="type !== 'tag'" class="m-badge__users">
      <div
        v-if="orderedMembers.length >= 5"
        :id="`m_team_badge_${team.id}_more`"
        class="m-badge m-users--more"
        :data-tooltip-content="otherMembers"
        data-tooltip-position="dynamic"
      >
        <span>{{ `+${orderedMembers.length - 4}` }}</span>
      </div>
      <m-user-badge
        v-for="(user, idx) in orderedMembers?.slice(0, 4)"
        :key="idx"
        :id="`m_team_badge_${team.id}_user_${user.id}`"
        :user="user"
        type="badge"
        show="image"
        class="m-users"
        :class="{ 'm-users--first': idx == 0 && orderedMembers.length < 5 }"
        :data-tooltip-content="`${user.accountDetails.firstName} ${user.accountDetails.lastName}`"
        data-tooltip-position="dynamic"
        :style="{
          transform: `scale(0.6) translateX(${72 * (idx + 1)}px)`,
          zIndex: 6 - idx,
        }"
      />
    </div>
  </div>
</template>

<script setup>
/*
 * Monitio Team Badge component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882344162/Team+Badge
 */

import { computed } from "vue";
import { stringToColor } from "@utils/utils";
import { useI18n } from "vue-i18n";
import MUserBadge from "@components/MUserBadge.vue";

const props = defineProps({
  team: {
    type: Object,
    required: true,
  },
  type: {
    type: String,
    default: "badge",
    validator(type) {
      if (!["badge", "tag"].includes(type)) {
        console.error(
          `Invalid prop "type": expected string with value "badge" or "tag" and got "${type}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882344162/Team+Badge for instructions on how to use the MTeamBadge component.`
        );
      }
      return true;
    },
  },
  show: {
    type: [Array, String],
    default: () => ["image", "details"],
    validator(show) {
      if (
        Array.isArray(show) &&
        show.find((f) => f != "image" && f != "details") &&
        !["image", "details"].includes(show)
      ) {
        console.error(
          `Invalid prop "show": expected either a array with value ["image", "details"] or a string with value "image" or "details" and got "${show}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/882344162/Team+Badge for instructions on how to use the MTeamBadge component.`
        );
      }
      return true;
    },
  },
});

const { t } = useI18n();

const showImage = computed(() => {
  return (
    props.show == "image" ||
    (Array.isArray(props.show) && props.show.find((f) => f == "image"))
  );
});

const showDetails = computed(() => {
  return (
    props.show == "details" ||
    (Array.isArray(props.show) && props.show.find((f) => f == "details"))
  );
});

const orderedMembers = computed(() => {
  const membersList = [...props.team.members];
  return membersList.sort((a, b) => {
    const nameA =
      `${a.accountDetails.firstName} ${a.accountDetails.lastName}`.toLowerCase();
    const nameB =
      `${b.accountDetails.firstName} ${b.accountDetails.lastName}`.toLowerCase();
    //reverse order because we use reverse in template iteration
    return nameB.localeCompare(nameA);
  });
});

const reducedName = computed(() => {
  const firstWord = props.team.name.split(" ").splice(0)[0];
  const lastWord = props.team.name.split(" ").splice(-1)[0];
  return `${firstWord} ${lastWord}`;
});

const initials = computed(() => {
  const name = reducedName.value;
  return name
    ?.split(" ")
    .map((m) => m.charAt(0))
    .join("");
});

const otherMembers = computed(() => {
  return orderedMembers.value
    .slice(4)
    .map((m) => `${m.accountDetails.firstName} ${m.accountDetails.lastName}`)
    .join(", ");
});
</script>

<style scoped lang="scss">
.m-badge {
  width: 100%;
  position: relative;

  &__users {
    @include flex(flex-end, center, row-reverse);
    position: absolute;
    right: $spacing-0;
    bottom: $spacing-0;
    transform: translateY(math.div($spacing-3, 2));

    .m-users {
      :deep(.m-badge__image) {
        width: $spacing-7;
        height: $spacing-7;
        border: $spacing-1 solid white;
      }

      &--more {
        min-width: $spacing-7;
        width: $spacing-7;
        height: $spacing-7;
        border: $spacing-1 solid color($white);
        @include round-corners;
        @include flex(flex-start, center, row);
        color: color($pri-action-inactive);
        background-color: color($pri-light);
        transform: scale(0.6);
        transform-origin: right;
        z-index: 7;

        span {
          font-size: 0.675rem;
          font-weight: 900;
        }
      }

      &--first {
        margin-right: $spacing-5;
      }
    }
  }
}
</style>
