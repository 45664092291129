<template>
  <ul class="m-iptcs">
    <li
      v-for="(level0, i0) in iptcs"
      :key="i0"
      :class="{ 'mb-2': i0 != iptcs.length - 1 }"
    >
      <h5
        :data-tooltip-content="entitySearchTooltip(level0)?.content ?? tooltip"
        :data-tooltip-position="
          entitySearchTooltip(level0)?.position ?? 'bottom-center'
        "
        @click="searchInMonitio(level0)"
        class="pb-1 type--small c-pointer"
      >
        {{ level0.label }}
      </h5>
      <ul
        v-if="level0.children?.length != 0"
        class="ma-0 py-0 px-0 m-iptcs__level1"
      >
        <li
          v-for="(level1, i1) in level0.children"
          :key="i1"
          :class="{
            'm-iptcs__level1--multiple': level0.children != 1,
          }"
        >
          <div>
            <div class="m-iptcs__indentation"></div>
            <h5
              :data-tooltip-content="
                entitySearchTooltip(level1)?.content ?? tooltip
              "
              :data-tooltip-position="
                entitySearchTooltip(level1)?.position ?? 'bottom-center'
              "
              @click="searchInMonitio(level1)"
              class="ml-1 type--small c-pointer"
            >
              {{ level1.label }}
            </h5>
          </div>
          <ul
            v-if="level1.children?.length != 0"
            class="my-0 pl-3 py-0 m-iptcs__level2"
          >
            <li
              v-for="(level2, i2) in level1.children"
              :key="i2"
              :class="{
                'm-iptcs__level1--multiple': level1.children != 1,
              }"
            >
              <div>
                <div class="m-iptcs__indentation"></div>
                <h5
                  :data-tooltip-content="
                    entitySearchTooltip(level2)?.content ?? tooltip
                  "
                  :data-tooltip-position="
                    entitySearchTooltip(level2)?.position ?? 'bottom-center'
                  "
                  @click="searchInMonitio(level2)"
                  class="ml-1 type--small c-pointer"
                >
                  {{ level2.label }}
                </h5>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </li>
  </ul>
</template>
<script setup>
import MIcon from "@components/MIcon.vue";
import { useViewFilters } from "@root/hooks/useViewFilters";
import { useWorkspacesStore } from "@root/store/modules/workspaces";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";

const route = useRoute();
const router = useRouter();
const { t } = useI18n();
const workspacesStore = useWorkspacesStore();
const workspaceConfig = ref(workspacesStore.currentWorkspaceConfig);
const viewId = computed(
  () => route.params.viewId ?? workspaceConfig.value.baseViewId
);

const { navigateWithQueryObject } = useViewFilters(router, route);

const props = defineProps({
  iptcs: { type: Array, required: true },
});

const searchInMonitio = (iptc) => {
  /** @type {import("@root/types").Monitio.URLQueryObject]} */
  const queryObj = {
    filters: [
      {
        facets: [
          {
            value: "IPTC",
            query: [
              {
                label: iptc.label,
                value: iptc.sourceLabel,
                propertyType: "IPTC",
                negated: false,
              },
            ],
          },
        ],
      },
    ],
  };
  navigateWithQueryObject(queryObj, "articles", { viewId: viewId.value });
};

const entitySearchTooltip = (iptc) => {
  return {
    content: t("article.side_entity_search", {
      find: iptc.label,
    }),
    position: "dynamic",
  };
};
</script>
