import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";
import { computed } from "vue";
import { useViewsStore } from "@root/store/modules/views";
import { useUserStore } from "@root/store/modules/user";

export default () => {
  const { open, close, cancel } = useModal("m-modal");

  const viewsStore = useViewsStore();
  const userStore = useUserStore();
  const { t } = useI18n();

  //tour hints
  const displayHints = computed(() => {
    return userStore.ongoingTour.name == "createView";
  });

  const setTitle = (type) => {
    if (type === "fromFilter" || type === "fromStory") {
      return t(`createView.modalTitle_${type}`);
    } else {
      return t("createView.modalTitle");
    }
  };

  const handleModal = (type) => {
    return new Promise((res, rej) => {
      open("m_modal_create_view", {
        heading: setTitle(type),
        description: t("createView.modalDescription"),
        type: type ?? "default",
        width: 4,
        buttons: ["custom"],
        close,
        cancel,
      }).then(
        ({ reason }) => {
          // fulfilled
          res(reason);
        },
        (reason) => {
          // rejected
          viewsStore.setFilters([]);
          rej(reason);
        }
      );
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
