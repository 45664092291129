// @ts-ignore
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import type { Monitio } from "@root/types";
import Guid from "@utils/guid";
import { defineStore } from "pinia";

interface AlertstoreState {
  moduleHasInit: boolean;
  banner: any[];
  toast: any[];
  dialog: any;
  resolve: any;
}

export const useAlertsStore = defineStore("alerts", {
  state: (): AlertstoreState => ({
    moduleHasInit: false,
    banner: [],
    toast: [],
    dialog: null,
    resolve: undefined,
  }),

  getters: {
    getType() {
      return (id: string) => {
        if (this.banner.find((f: any) => f.id == id)) return "banner";
        else if (this.toast.find((f: any) => f.id == id)) return "toast";
        else if (this.dialog?.id === id) return "dialog";
      };
    },
  },

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Alerts module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async resolve(id: string) {
      this.resolve = id;
    },
    async remove(id: string) {
      const type = this.getType(id);

      switch (type) {
        case "dialog":
          this.dialog = null;
          break;
        case "banner":
        case "toast":
          setTimeout(() => {
            this[type] = this[type].filter((n) => n.id != id);
          }, 400);
          break;
      }
    },
    /** @description Function to add alerts to the queue */
    async add(alert: Monitio.PlatformAlert): Promise<string | void> {
      const { open, close, cancel } = useModal("m-dialog");

      alert.id = alert.id ?? Guid.NewGuid();

      if (alert.type === "dialog") {
        this.dialog = { ...alert };

        return new Promise((res, rej) => {
          open("dialog", {
            id: alert.id,
            variant: alert.variant,
            heading: alert.heading,
            message: alert.message,
            resolve: alert.resolve,
            reject: alert.reject,
            cancel: alert.cancel,
          })
            .then(() => res())
            .catch(() => rej());
        });
      } else if (!this[alert.type].find((f) => f.id == alert.id)) {
        if (alert.type == "toast" && !alert.keepOnScreen) {
          setTimeout(() => {
            this.remove(alert.id);
          }, 10000);
        }

        this[alert.type].push({ ...alert });
      }
      return new Promise((res) => res(alert.id));
    },
  },
});
