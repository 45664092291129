<template>
  <svg xmlns="http://www.w3.org/2000/svg" :viewBox="viewBox" :height="height">
    <g v-if="type == 'horizontal'" id="horizontal">
      <g id="icon" fill="#185484">
        <path
          d="M73.616,44.158c-5.606,15.02,1.687,31.782,16.497,37.92,21.714,11,42.691,19.68,45.806,32.2,2.749,11.217,.291,9.406-.571,12.154-.36,1.011,1.811,1.88,3.331,3.549,2.768,3.063,4.6,6.854,5.28,10.926,.434,2.246,.434,4.571,1.714,3.331,3.537-3.462,5.913-7.936,6.8-12.806,.011-2.012-.462-3.997-1.377-5.789-.217-.726,1.377-2.171,1.88-3.114,1.303-2.463,12.571-20.263,7.166-32.783-5.714-13.24-16.646-13.891-32.634-16.931-16.429-3.114-37.92-8.177-49.783-25.977-2.823-4.194-2.749-5.931-4.126-2.68"
        />
        <path
          d="M102.491,60.061c-2.897-1.811-3.183-16.063-2.606-20.623,.571-4.126,1.229-2.606,3.76,.943,1.88,2.606,17.714,20.571,32.2,26.629,4.571,1.88,5.646,2.966,.726,2.171-5.497-.869-28.509-5.646-34.08-9.143"
        />
        <path
          d="M83.679,86.255c-15.56-.143-57.143,16.646-59.629,56.949-2.314,38.137,26.726,58.251,54.56,63.314,8.537,1.594,4.343-.726,2.029-1.446-26.367-9.603-42.91-35.815-40.234-63.749,3.331-37.92,40.16-52.103,43.777-53.549,3.331-1.303,3.04-1.52-.509-1.52"
        />
        <path
          d="M63.851,109.478c-3.257,4.126-22.577,31.697-6.583,58.469,14.257,23.88,35.383,32.783,66.354,27.857,5.211-.794,5.931-1.88,1.594-2.097-16.571-.651-42.766-16.931-51.669-32.926-8.209-15.802-10.634-33.976-6.857-51.377,1.011-4.703-1.086-2.171-2.823,.074"
        />
        <path
          d="M102.782,137.633c2.606,4.126,14.474,22.217,33.143,21.634,22.937-.726,34.857-23.663,38.423-30.177,3.566-6.514,28.291-63.606,40.16-78.657,11.577-14.691,19.103-20.623,35.891-25.04,9.714-2.606,5.211-.291,3.429,.943-12.189,7.469-22.341,17.835-29.554,30.177-9.861,17.08-17.382,35.408-22.36,54.491-1.086,4.92-1.143,6.006-.291,5.28,1.097-1.14,2.089-2.375,2.966-3.691,2.246-3.474,31.114-43.429,35.674-49.857,11.651-16.429,34.154-31.48,55.789-31.114,7.743,.143,6.223,.509,3.691,1.377-20.253,7.745-37.72,21.381-50.149,39.149-12.819,18.58-24.259,38.074-34.229,58.326-2.029,4.846-.726,4.051,1.011,2.749,11.073-8.919,23.351-16.229,36.469-21.714,16.373-5.167,33.816-5.941,50.583-2.246,8.903,2.606,6.657,2.897,1.446,3.114-17.631-.387-35.068,3.744-50.651,12-15.151,7.726-29.016,17.749-41.103,29.714-8.611,8.686-30.103,31.914-58.686,35.749-13.675,1.347-27.37-2.361-38.497-10.423-8.686-6.657-16.137-18.234-15.846-40.234,.143-7.669,.869-4.486,2.68-1.594"
        />
      </g>
      <g id="lettering">
        <path
          d="M580.034,172.33c-6.477-3.54-11.849-8.802-15.52-15.206-7.46-13.706-7.46-30.26,0-43.966,3.659-6.393,9.035-11.632,15.52-15.126,14.009-7.249,30.665-7.249,44.674,0,6.445,3.515,11.787,8.751,15.429,15.126,7.458,13.706,7.458,30.259,0,43.966-3.653,6.386-8.99,11.646-15.429,15.206-13.984,7.352-30.69,7.352-44.674,0m36.794-11.429c4.245-2.459,7.713-6.063,10.006-10.4,4.832-9.672,4.832-21.054,0-30.726-2.283-4.324-5.754-7.905-10.006-10.32-9.004-4.832-19.83-4.832-28.834,0-4.264,2.425-7.759,6.002-10.086,10.32-4.938,9.647-4.938,21.079,0,30.726,2.337,4.332,5.828,7.931,10.086,10.4,8.978,4.936,19.857,4.936,28.834,0"
        />
        <path
          d="M763.388,74.478c-1.904-1.797-2.962-4.314-2.914-6.931-.038-2.639,1.017-5.177,2.914-7.011,1.936-1.94,4.586-2.995,7.326-2.914,2.711-.085,5.345,.907,7.326,2.76,1.91,1.762,2.972,4.259,2.914,6.857,.06,2.69-.995,5.286-2.914,7.171-1.935,1.941-4.586,2.995-7.326,2.914-2.728,.079-5.371-.952-7.326-2.857m-.234,18.909h15.126v83.526h-15.126V93.375Z"
        />
        <path
          d="M844.999,172.033c-2.402,1.996-5.192,3.471-8.194,4.331-3.269,1-6.668,1.504-10.086,1.497-7.159,.45-14.199-1.992-19.543-6.777-4.893-5.224-7.402-12.241-6.932-19.383V75.13h15.126v18.286h23.949v12.446h-23.949v45.2c-.255,3.774,.959,7.5,3.389,10.4,2.568,2.545,6.106,3.864,9.714,3.623,4.279,.115,8.463-1.278,11.817-3.937l4.709,10.886Z"
        />
        <path
          d="M857.788,74.478c-1.904-1.797-2.962-4.314-2.914-6.931-.038-2.639,1.016-5.177,2.914-7.011,1.936-1.94,4.586-2.995,7.326-2.914,2.71-.082,5.343,.91,7.326,2.76,1.91,1.762,2.972,4.259,2.914,6.857,.059,2.69-.995,5.285-2.914,7.171-1.936,1.94-4.586,2.995-7.326,2.914-2.728,.079-5.371-.952-7.326-2.857m-.234,18.909h15.126v83.526h-15.114l-.012-83.526Z"
        />
        <path
          d="M910.011,172.33c-6.477-3.54-11.848-8.802-15.52-15.206-7.459-13.706-7.459-30.26,0-43.966,3.659-6.393,9.035-11.632,15.52-15.126,14.009-7.249,30.665-7.249,44.674,0,6.445,3.514,11.787,8.751,15.428,15.126,7.458,13.706,7.458,30.259,0,43.966-3.652,6.386-8.989,11.646-15.428,15.206-13.984,7.352-30.691,7.352-44.674,0m36.794-11.429c4.245-2.459,7.713-6.063,10.006-10.4,4.832-9.672,4.832-21.054,0-30.726-2.282-4.324-5.754-7.905-10.006-10.32-9.006-4.834-19.834-4.834-28.84,0-4.264,2.425-7.759,6.002-10.086,10.32-4.938,9.647-4.938,21.079,0,30.726,2.337,4.332,5.828,7.931,10.086,10.4,8.979,4.938,19.86,4.938,28.84,0"
        />
        <path
          d="M703.794,106.153c-6.185-.406-12.494,1.591-16.703,6.143-4.507,4.874-5.593,13.493-5.829,18.286-.569,11.567,0,46.326,0,46.326h-15.131s-.574-36.061,0-48.063c.342-7.143,1.958-20.412,9.377-27.023,15.545-13.852,41.027-13.852,56.572,0,7.419,6.611,9.035,19.88,9.377,27.023,.574,12.002,0,48.063,0,48.063h-15.126s.569-34.758,0-46.326c-.236-4.792-1.322-13.412-5.829-18.286-4.208-4.551-10.517-6.548-16.703-6.143"
        />
        <path
          d="M533.554,101.77c-15.977-13.896-40.426-12.169-57.754,0-.707,.685-1.376,1.408-2.006,2.166-.63-.757-1.299-1.48-2.006-2.166-17.328-12.169-41.773-13.891-57.754,0-7.527,6.542-9.215,19.865-9.571,27.023-.598,12.001,0,48.063,0,48.063h15.429s-.516-34.769,0-46.343c.214-4.803,1.215-13.606,5.954-18.286,9.141-9.026,24.968-9.027,34.109,0,4.739,4.68,5.74,13.483,5.954,18.286,.516,11.57,0,46.326,0,46.326h15.76s-.516-34.756,0-46.326c.214-4.803,1.215-13.606,5.954-18.286,9.139-9.024,24.964-9.024,34.103,0,4.739,4.679,5.74,13.483,5.954,18.286,.516,11.57,0,46.326,0,46.326h15.429s.593-36.045,0-48.04c-.354-7.158-2.03-20.484-9.554-27.029Z"
        />
      </g>
    </g>
    <g v-else-if="type == 'vertical'" id="vertical">
      <g id="icon" fill="#185484">
        <path
          d="M259.421,106.913c-7.702,20.638,2.318,43.669,22.667,52.102,29.835,15.114,58.658,27.04,62.937,44.243,3.777,15.412,.4,12.923-.785,16.7-.495,1.39,2.489,2.583,4.577,4.876,3.803,4.208,6.321,9.418,7.255,15.012,.597,3.086,.597,6.281,2.355,4.577,4.86-4.757,8.125-10.905,9.343-17.595,.015-2.764-.634-5.492-1.892-7.954-.298-.997,1.892-2.984,2.583-4.279,1.79-3.384,17.273-27.841,9.846-45.044-7.851-18.192-22.871-19.087-44.84-23.264-22.573-4.279-52.102-11.235-68.402-35.693-3.879-5.763-3.777-8.15-5.669-3.682"
        />
        <path
          d="M299.095,128.764c-3.981-2.489-4.373-22.07-3.58-28.336,.785-5.669,1.688-3.58,5.166,1.295,2.583,3.58,24.339,28.265,44.243,36.588,6.281,2.583,7.757,4.075,.997,2.984-7.553-1.193-39.171-7.757-46.826-12.562"
        />
        <path
          d="M273.248,164.755c-21.379-.196-78.514,22.871-81.93,78.247-3.18,52.401,36.721,80.038,74.966,86.994,11.73,2.191,5.967-.997,2.787-1.986-36.229-13.195-58.959-49.21-55.282-87.591,4.577-52.102,55.18-71.589,60.15-73.576,4.577-1.79,4.177-2.088-.699-2.088"
        />
        <path
          d="M246.003,196.663c-4.475,5.669-31.021,43.552-9.045,80.336,19.589,32.811,48.616,45.044,91.171,38.276,7.161-1.091,8.15-2.583,2.191-2.882-22.769-.895-58.76-23.264-70.993-45.24-11.279-21.711-14.612-46.683-9.422-70.592,1.39-6.462-1.492-2.984-3.879,.102"
        />
        <path
          d="M299.495,235.347c3.58,5.669,19.888,30.526,45.538,29.726,31.516-.997,47.894-32.513,52.793-41.463,4.899-8.951,38.873-87.394,55.18-108.075,15.907-20.186,26.247-28.336,49.315-34.405,13.347-3.58,7.161-.4,4.711,1.295-16.747,10.262-30.697,24.506-40.608,41.463-13.549,23.468-23.882,48.65-30.723,74.871-1.492,6.76-1.57,8.252-.4,7.255,1.507-1.566,2.87-3.263,4.075-5.072,3.086-4.774,42.751-59.671,49.017-68.504,16.009-22.573,46.928-43.254,76.654-42.751,10.639,.196,8.55,.699,5.072,1.892-27.827,10.642-51.827,29.377-68.904,53.79-17.613,25.529-33.332,52.314-47.03,80.14-2.787,6.658-.997,5.567,1.39,3.777,15.215-12.255,32.084-22.299,50.108-29.835,22.497-7.1,46.464-8.164,69.501-3.086,12.233,3.58,9.147,3.981,1.986,4.279-24.225-.532-48.184,5.144-69.595,16.488-20.818,10.615-39.869,24.387-56.475,40.827-11.832,11.934-41.361,43.85-80.634,49.119-18.789,1.851-37.606-3.244-52.895-14.321-11.934-9.147-22.172-25.054-21.772-55.282,.196-10.537,1.193-6.163,3.682-2.191"
        />
      </g>
      <g id="lettering">
        <path
          d="M277.379,494.109c-6.477-3.54-11.849-8.802-15.52-15.206-7.46-13.706-7.46-30.26,0-43.966,3.659-6.393,9.035-11.632,15.52-15.126,14.009-7.249,30.665-7.249,44.674,0,6.445,3.515,11.787,8.751,15.429,15.126,7.458,13.706,7.458,30.259,0,43.966-3.653,6.386-8.99,11.646-15.429,15.206-13.984,7.352-30.69,7.352-44.674,0m36.794-11.429c4.245-2.459,7.713-6.063,10.006-10.4,4.832-9.672,4.832-21.054,0-30.726-2.283-4.324-5.754-7.905-10.006-10.32-9.004-4.832-19.83-4.832-28.834,0-4.264,2.425-7.759,6.002-10.086,10.32-4.938,9.647-4.938,21.079,0,30.726,2.337,4.332,5.828,7.931,10.086,10.4,8.978,4.936,19.857,4.936,28.834,0"
        />
        <path
          d="M460.733,396.257c-1.904-1.797-2.962-4.314-2.914-6.931-.038-2.639,1.017-5.177,2.914-7.011,1.936-1.94,4.586-2.995,7.326-2.914,2.711-.085,5.345,.907,7.326,2.76,1.91,1.762,2.972,4.259,2.914,6.857,.06,2.69-.995,5.286-2.914,7.171-1.935,1.941-4.586,2.995-7.326,2.914-2.728,.079-5.371-.952-7.326-2.857m-.234,18.909h15.126v83.526h-15.126v-83.526Z"
        />
        <path
          d="M542.344,493.812c-2.402,1.996-5.192,3.471-8.194,4.331-3.269,1-6.668,1.504-10.086,1.497-7.159,.45-14.199-1.992-19.543-6.777-4.893-5.224-7.402-12.241-6.932-19.383v-76.571h15.126v18.286h23.949v12.446h-23.949v45.2c-.255,3.774,.959,7.5,3.389,10.4,2.568,2.545,6.106,3.864,9.714,3.623,4.279,.115,8.463-1.278,11.817-3.937l4.709,10.886Z"
        />
        <path
          d="M555.133,396.257c-1.904-1.797-2.962-4.314-2.914-6.931-.038-2.639,1.016-5.177,2.914-7.011,1.936-1.94,4.586-2.995,7.326-2.914,2.71-.082,5.343,.91,7.326,2.76,1.91,1.762,2.972,4.259,2.914,6.857,.059,2.69-.995,5.285-2.914,7.171-1.936,1.94-4.586,2.995-7.326,2.914-2.728,.079-5.371-.952-7.326-2.857m-.234,18.909h15.126v83.526h-15.114l-.012-83.526Z"
        />
        <path
          d="M607.356,494.109c-6.477-3.54-11.848-8.802-15.52-15.206-7.459-13.706-7.459-30.26,0-43.966,3.659-6.393,9.035-11.632,15.52-15.126,14.009-7.249,30.665-7.249,44.674,0,6.445,3.514,11.787,8.751,15.428,15.126,7.458,13.706,7.458,30.259,0,43.966-3.652,6.386-8.989,11.646-15.428,15.206-13.984,7.352-30.691,7.352-44.674,0m36.794-11.429c4.245-2.459,7.713-6.063,10.006-10.4,4.832-9.672,4.832-21.054,0-30.726-2.282-4.324-5.754-7.905-10.006-10.32-9.006-4.834-19.834-4.834-28.84,0-4.264,2.425-7.759,6.002-10.086,10.32-4.938,9.647-4.938,21.079,0,30.726,2.337,4.332,5.828,7.931,10.086,10.4,8.979,4.938,19.86,4.938,28.84,0"
        />
        <path
          d="M401.139,427.932c-6.185-.406-12.494,1.591-16.703,6.143-4.507,4.874-5.593,13.493-5.829,18.286-.569,11.567,0,46.326,0,46.326h-15.131s-.574-36.061,0-48.063c.342-7.143,1.958-20.412,9.377-27.023,15.545-13.852,41.027-13.852,56.572,0,7.419,6.611,9.035,19.88,9.377,27.023,.574,12.002,0,48.063,0,48.063h-15.126s.569-34.758,0-46.326c-.236-4.792-1.322-13.412-5.829-18.286-4.208-4.551-10.517-6.548-16.703-6.143"
        />
        <path
          d="M230.899,423.549c-15.977-13.896-40.426-12.169-57.754,0-.707,.685-1.376,1.408-2.006,2.166-.63-.757-1.299-1.48-2.006-2.166-17.328-12.169-41.773-13.891-57.754,0-7.527,6.542-9.215,19.865-9.571,27.023-.598,12.001,0,48.063,0,48.063h15.429s-.516-34.769,0-46.343c.214-4.803,1.215-13.606,5.954-18.286,9.141-9.026,24.968-9.027,34.109,0,4.739,4.68,5.74,13.483,5.954,18.286,.516,11.57,0,46.326,0,46.326h15.76s-.516-34.756,0-46.326c.214-4.803,1.215-13.606,5.954-18.286,9.139-9.024,24.964-9.024,34.103,0,4.739,4.679,5.74,13.483,5.954,18.286,.516,11.57,0,46.326,0,46.326h15.429s.593-36.045,0-48.04c-.354-7.158-2.03-20.484-9.554-27.029Z"
        />
      </g>
    </g>
    <g v-else-if="type == 'ico'" id="ico">
      <g id="icon" fill="#185484">
        <path
          d="M86.5,38.1c-2,4.7-11.6,37.2,24,55.3c31.7,16,62.3,28.7,66.8,47c4,16.3,0.5,13.7-0.8,17.7c-0.5,1.5,2.6,2.7,4.9,5.2c3,3.2,6.1,8,7.7,16c0.6,3.2,0.7,6.6,2.5,4.9c4.1-3.7,9.9-13.4,9.9-18.7c0.1-4.7-1.7-7.4-2-8.4s2-3.1,2.7-4.5c1.9-3.6,18.3-29.6,10.4-47.8c-8.4-19.2-24.3-20.3-47.6-24.7c-23.9-4.6-55.2-11.9-72.6-37.9C88.5,35.9,88.6,33.4,86.5,38.1z"
        />
        <path
          d="M128.6,61.3c-4.2-2.6-4.7-23.5-3.8-30.1c0.8-6,1.8-3.8,5.5,1.3c2.8,3.8,25.9,30,46.9,38.9c6.6,2.8,8.2,4.3,1,3.2C170.3,73.4,136.7,66.4,128.6,61.3z"
        />
        <path
          d="M101.2,99.5c-22.7-0.2-83.4,24.2-86.9,83c-3.3,55.6,38.9,84.9,79.5,92.3c12.4,2.3,6.3-1.1,3-2.1c-10.4-3.1-64.3-28.8-58.7-93c4.9-55.3,58.6-76,63.9-78.1C106.8,99.9,106.3,99.6,101.2,99.5z"
        />
        <path
          d="M72.3,133.4c-4.7,6.1-32.8,46.2-9.6,85.2c20.8,34.8,51.6,47.8,96.7,40.6c7.6-1.2,8.6-2.8,2.3-3c-24.2-1-62.3-24.6-75.3-48c-14.8-26.5-14-57.5-10.1-74.9C77.9,126.5,74.8,130.2,72.3,133.4z"
        />
        <path
          d="M129,174.4c3.8,6.1,21.1,32.4,48.3,31.5c33.4-1,50.9-34.5,56-44s41.3-92.7,58.5-114.6c16.9-21.4,27.8-30,52.3-36.6c14.2-3.8,7.6-0.4,4.9,1.3c-1.4,0.9-26.7,15.3-43,44c-20,35.2-30.1,68.2-32.6,79.4c-1.6,7.2-1.7,8.8-0.4,7.7c1.6-1.6,3.1-3.5,4.3-5.4c3.2-5.1,45.3-63.2,52-72.6c17-23.9,49.8-45.9,81.3-45.4c11.3,0.2,9.1,0.8,5.4,2C389.5,31,363.3,50.9,343,78.8c-21,28.8-47,78-49.9,85c-2.9,7-1,5.9,1.5,4c5-3.8,29.8-23.4,53.2-31.6c27.4-9.7,61.6-6.8,73.7-3.3c13,3.7,9.7,4.2,2.1,4.5c-14.3,0.6-39.8-0.4-73.9,17.5c-17.6,9.2-38.7,22.2-59.9,43.4c-12.6,12.6-43.9,46.5-85.6,52.1c-20.1,2.7-42.7-4.9-56.1-15.1c-12.6-9.7-23.5-26.6-23.1-58.7C125.3,165.5,126.4,170.2,129,174.4z"
        />
      </g>
    </g>
  </svg>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = defineProps({
  id: {
    type: String,
    validator(id: string) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  type: {
    type: String,
    default: "horizontal",
    validator(type: "vertical" | "horizontal" | "ico") {
      if (!["vertical", "horizontal", "ico"].includes(type)) {
        console.error(
          `Invalid prop "type": expected string with value "vertical", "horizontal" or "ico" and got "${type}". \n\n There is no documentation for this component.`
        );
      }
      return true;
    },
  },
  height: {
    type: [String, Number],
    default: "48",
    validator(height: string) {
      if (height.match(/[.,]/g)) {
        console.error(
          'Invalid prop "height": string must be a round number. \n\n There is no documentation for this component.'
        );
      }
      return true;
    },
  },
});

const viewBox = computed(() => {
  if (props.type == "horizontal") {
    return "0 0 1000 228.571";
  } else if (props.type == "vertical") {
    return "0 0 774.594 579.19";
  } else if (props.type == "ico") {
    return "0 0 439.1 284.7";
  } else {
    return "0 0 0 0";
  }
});
</script>
