import { useI18n } from "vue-i18n";
import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";

export default () => {
  const { t } = useI18n();
  const { open, close, cancel } = useModal("m-modal");

  const handleModal = (article, selectedTagType = null) => {
    return new Promise((res, rej) => {
      open("m_modal_create_smarttag", {
        heading: t("createSmartTagModal.title"),
        description: t("createSmartTagModal.description"),
        width: 8,
        buttons: ["custom"],
        article,
        selectedTagType,
        close,
        cancel,
      }).then(
        ({ reason }) => {
          // fulfilled
          res(reason);
        },
        (reason) => {
          // rejected
          rej(reason);
        }
      );
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
