const IPTCDescriptionToCode = {
  "arts, culture and entertainment": "01000000",
  archaeology: "01001000",
  architecture: "01002000",
  bullfighting: "01003000",
  "festive event (including carnival)": "01004000",
  cinema: "01005000",
  "film festival": "01005001",
  dance: "01006000",
  fashion: "01007000",
  jewelry: "01007001",
  language: "01008000",
  "library and museum": "01009000",
  literature: "01010000",
  fiction: "01010001",
  poetry: "01010002",
  music: "01011000",
  "classical music": "01011001",
  "folk music": "01011002",
  "jazz music": "01011003",
  "popular music": "01011004",
  "country music": "01011005",
  "rock and roll music": "01011006",
  "hip-hop": "01011007",
  painting: "01012000",
  photography: "01013000",
  radio: "01014000",
  sculpture: "01015000",
  "plastic art": "01015001",
  television: "01016000",
  "soap opera": "01016001",
  theatre: "01017000",
  "music theatre": "01017001",
  "monument and heritage site": "01018000",
  "institution-DEPRECATED": "01018001",
  "customs and tradition": "01019000",
  "arts (general)": "01020000",
  "entertainment (general)": "01021000",
  "entertainment award": "01021001",
  "culture (general)": "01022000",
  "cultural development": "01022001",
  nightclub: "01023000",
  cartoon: "01024000",
  animation: "01025000",
  "mass media": "01026000",
  periodicals: "01026001",
  "news media": "01026002",
  newspapers: "01026003",
  reviews: "01026004",
  internet: "01027000",
  history: "13003002",
  "crime, law and justice": "02000000",
  crime: "02001000",
  homicide: "02001001",
  "computer crime": "02001002",
  theft: "02001003",
  "drug trafficking": "02001004",
  "sexual assault": "02001005",
  "assault (general)": "02001006",
  kidnapping: "02001007",
  arson: "02001008",
  "gang activity": "02001009",
  terrorism: "02001010",
  "judiciary (system of justice)": "02002000",
  lawyer: "02002001",
  judge: "02002002",
  "court administration": "02002003",
  police: "02003000",
  "law enforcement": "02003001",
  investigation: "02003002",
  arrest: "02003003",
  punishment: "02004000",
  fine: "02004001",
  execution: "02004002",
  prison: "02005000",
  laws: "02006000",
  criminal: "02006001",
  civil: "02006002",
  "justice and rights": "02007000",
  "civil rights": "02007001",
  trials: "02008000",
  litigation: "02008001",
  arbitration: "02008002",
  "court preliminary": "02008003",
  prosecution: "02009000",
  defendant: "02009001",
  witness: "02009002",
  "organized crime": "02010000",
  "international law": "02011000",
  "international court or tribunal": "02011001",
  extradition: "02011002",
  "corporate crime": "02012000",
  fraud: "02012001",
  embezzlement: "02012002",
  "restraint of trade": "02012003",
  "breach of contract": "02012004",
  "anti-trust crime": "02012005",
  corruption: "02012006",
  bribery: "02012007",
  "war crime": "02013000",
  inquest: "02014000",
  inquiry: "02015000",
  tribunal: "02016000",
  "disaster and accident": "03000000",
  drought: "03001000",
  earthquake: "03002000",
  famine: "03003000",
  fire: "03004000",
  flood: "03005000",
  "industrial accident": "03006000",
  "structural failures": "03006001",
  "meteorological disaster": "03007000",
  windstorms: "03007001",
  "nuclear accident": "03008000",
  pollution: "03009000",
  "transport accident": "03010000",
  "road accident": "03010001",
  "railway accident": "03010002",
  "air and space accident": "03010003",
  "maritime accident": "03010004",
  "volcanic eruption": "03011000",
  "relief and aid organisation": "03012000",
  "accident (general)": "03013000",
  "emergency incident": "03014000",
  explosion: "03014001",
  "disaster (general)": "03015000",
  "natural disasters": "03015001",
  "avalanche/landslide": "03015002",
  "emergency planning": "03016000",
  rescue: "03017000",
  "economy, business and finance": "04000000",
  agriculture: "04001000",
  "arable farming": "04001001",
  "fishing industry": "04001002",
  "forestry and timber": "04001003",
  "livestock farming": "04001004",
  viniculture: "04001005",
  aquaculture: "04001006",
  chemicals: "04002000",
  biotechnology: "13019000",
  fertiliser: "04002002",
  "health and beauty product": "04002003",
  "inorganic chemical": "04002004",
  "organic chemical": "04002005",
  pharmaceutical: "04002006",
  "synthetic and plastic": "04002007",
  "computing and information technology": "04003000",
  hardware: "04003001",
  networking: "04003002",
  "satellite technology": "04003003",
  "semiconductors and active components": "04003004",
  software: "04003005",
  "telecommunication equipment": "04003006",
  "telecommunication service": "04003007",
  security: "04003008",
  "wireless technology": "04003009",
  "construction and property": "04004000",
  "heavy construction": "04004001",
  "house building": "04004002",
  "real estate": "04004003",
  farms: "04004004",
  "land price": "04004005",
  renovation: "04004006",
  "design and engineering": "04004007",
  "energy and resource": "04005000",
  "alternative energy": "04005001",
  coal: "04005002",
  "oil and gas - downstream activities": "04005003",
  "oil and gas - upstream activities": "04005004",
  "nuclear power": "04005005",
  "electricity production and distribution": "04005006",
  "waste management and pollution control": "04005007",
  "water supply": "04005008",
  "natural resources (general)": "04005009",
  "energy (general)": "04005010",
  "natural gas": "04005011",
  petrol: "04005012",
  "diesel fuel": "04005013",
  "kerosene/paraffin": "04005014",
  "financial and business service": "04006000",
  "accountancy and auditing": "04006001",
  banking: "04006002",
  "consultancy service": "04006003",
  "employment agency": "04006004",
  "healthcare provider": "04006005",
  insurance: "04006006",
  "legal service": "04006007",
  "market research": "04006008",
  "stock broking": "04006009",
  "personal investing": "04006010",
  "market trend": "04006011",
  "shipping service": "04006012",
  "personal service": "04006013",
  "janitorial service": "04006014",
  "funeral parlour and crematorium": "04006015",
  "rental service": "04006016",
  "wedding service": "04006017",
  "personal finance": "04006018",
  "personal income": "04006019",
  "auction service": "04006020",
  "printing/promotional service": "04006021",
  "investment service": "04006022",
  "consumer goods": "04007000",
  clothing: "04007001",
  "department store": "04007002",
  food: "04013002",
  "mail order": "04007004",
  retail: "04007005",
  "speciality store": "04007006",
  wholesale: "04007007",
  beverage: "04007008",
  "electronic commerce": "04007009",
  "luxury good": "04007010",
  "non-durable good": "04007011",
  toy: "04007012",
  "macro economics": "04008000",
  "central bank": "04008001",
  "consumer issue": "10017000",
  "debt market": "04008003",
  "economic indicator": "04008004",
  "emerging market": "04008005",
  "foreign exchange market": "04008006",
  "government aid": "04008007",
  "government debt": "04008008",
  "interest rate": "04008009",
  "international economic institution": "04008010",
  "international (foreign) trade": "04008011",
  "loan market": "04008012",
  "economic organization": "04008013",
  "consumer confidence": "04008014",
  "trade dispute": "04008015",
  "inflation and deflation": "04008016",
  prices: "04008017",
  "currency values": "04008018",
  "budgets and budgeting": "04008019",
  "credit and debt": "04008020",
  loans: "04008021",
  mortgages: "04008022",
  "financial markets": "04008023",
  "commodity markets": "04008024",
  investments: "04008025",
  stocks: "04008026",
  bonds: "04008027",
  "mutual funds": "04008028",
  "derivative securities": "04008029",
  imports: "04008030",
  exports: "04008031",
  "trade agreements": "04008032",
  "trade policy": "04008033",
  "business enterprises": "04008034",
  tariff: "04008035",
  "trade balance": "04008036",
  "market and exchange": "04009000",
  energy: "04009001",
  metal: "04009002",
  securities: "04009003",
  "soft commodity": "04009004",
  media: "04010000",
  advertising: "04010001",
  book: "04010002",
  "cinema industry": "04010003",
  "news agency": "04010004",
  "newspaper and magazine": "04010005",
  online: "04010006",
  "public relation": "04010007",
  "radio industry": "04010008",
  "satellite and cable service": "04010009",
  "television industry": "04010010",
  "music industry": "04010011",
  "manufacturing and engineering": "04011000",
  aerospace: "04011001",
  "automotive equipment": "04011002",
  "defence equipment": "04011003",
  "electrical appliance": "04011004",
  "heavy engineering": "04011005",
  "industrial component": "04011006",
  "instrument engineering": "04011007",
  shipbuilding: "04011008",
  "machine manufacturing": "04011009",
  "metal and mineral": "04012000",
  "building material": "04012001",
  "gold and precious material": "04012002",
  "iron and steel": "04012003",
  "non ferrous metal": "04012004",
  mining: "04012005",
  "process industry": "04013000",
  "distiller and brewer": "04013001",
  "furnishings and furniture": "04013003",
  "paper and packaging product": "04013004",
  "rubber product": "04013005",
  "soft drinks": "04013006",
  "textile and clothing": "04013007",
  tobacco: "04013008",
  "tourism and leisure": "04014000",
  "casino and gambling": "04014001",
  "hotel and accommodation": "04014002",
  "recreational and sporting goods": "04014003",
  "restaurant and catering": "04014004",
  "tour operator": "04014005",
  transport: "04015000",
  "air transport": "04015001",
  railway: "04015002",
  "road transport": "04015003",
  "waterway and maritime transport": "04015004",
  "company information": "04016000",
  "accounting and audit": "04016001",
  "annual and special corporate meeting": "04016002",
  "annual report": "04016003",
  "antitrust issue": "04016004",
  "merger, acquisition and takeover": "04016005",
  "analysts' comment": "04016006",
  bankruptcy: "04016007",
  "board of directors (appointment and change)": "04016008",
  buyback: "04016009",
  "C.E.O. interview": "04016010",
  "corporate officer": "04016011",
  "corporate profile": "04016012",
  contract: "04016013",
  "defence contract": "04016014",
  "dividend announcement": "04016015",
  "earnings forecast": "04016016",
  "financially distressed company": "04016017",
  earnings: "04016018",
  "financing and stock offering": "04016019",
  "government contract": "04016020",
  "global expansion": "04016021",
  "insider trading": "04016022",
  "joint venture": "04016023",
  "leveraged buyout": "04016024",
  "layoffs and downsizing": "04016025",
  "licensing agreement": "04016026",
  "litigation and regulation": "04016027",
  "management change": "04016028",
  marketing: "04016029",
  "new product": "04016030",
  "patent, copyright and trademark": "04016031",
  "plant closing": "04016032",
  "plant opening": "04016033",
  privatisation: "11006011",
  "proxy filing": "04016035",
  rating: "04016036",
  "research and development": "04016037",
  "quarterly or semiannual financial statement": "04016038",
  "restructuring and recapitalisation": "04016039",
  "spin-off": "04016040",
  "stock activity": "04016041",
  "industrial production": "04016042",
  productivity: "04016043",
  inventories: "04016044",
  sales: "04016045",
  corporations: "04016046",
  shareholders: "04016047",
  "corporate performance": "04016048",
  losses: "04016049",
  "credit ratings": "04016050",
  "stock splits": "04016051",
  "stock options": "04016052",
  "recalls (products)": "04016053",
  globalization: "04016054",
  consumers: "04016055",
  purchase: "04016056",
  "new service": "04016057",
  "economy (general)": "04017000",
  "economic policy": "04017001",
  "business (general)": "04018000",
  institution: "04018001",
  "finance (general)": "04019000",
  "money and monetary policy": "04019001",
  education: "05000000",
  "adult education": "05001000",
  "further education": "05002000",
  "parent organisation": "05003000",
  preschool: "05004000",
  school: "05005000",
  "elementary schools": "05005001",
  "middle schools": "05005002",
  "high schools": "05005003",
  "teachers union": "05006000",
  university: "05007000",
  upbringing: "05008000",
  "entrance examination": "05009000",
  "teaching and learning": "05010000",
  students: "05010001",
  teachers: "05010002",
  curriculum: "05010003",
  "test/examination": "05010004",
  "religious education": "05011000",
  "parochial school": "05011001",
  seminary: "05011002",
  yeshiva: "05011003",
  madrasa: "05011004",
  "environmental issue": "06000000",
  "renewable energy": "06001000",
  conservation: "06002000",
  "endangered species": "06002001",
  ecosystem: "06002002",
  "energy saving": "06003000",
  "environmental politics": "06004000",
  "environmental pollution": "06005000",
  "air pollution": "06005001",
  "water pollution": "06005002",
  "natural resources": "06006000",
  "land resources": "06006001",
  parks: "06006002",
  forests: "06006003",
  wetlands: "06006004",
  mountains: "06006005",
  rivers: "06006006",
  oceans: "06006007",
  wildlife: "06006008",
  "energy resources": "06006009",
  nature: "06007000",
  "invasive species": "06007001",
  population: "06008000",
  waste: "06009000",
  water: "06010000",
  "global warming": "06011000",
  "hazardous materials": "06012000",
  "environmental cleanup": "06013000",
  health: "07000000",
  disease: "07001000",
  "communicable diseases": "07001001",
  "virus diseases": "07001002",
  AIDS: "07001003",
  cancer: "07001004",
  "heart disease": "07001005",
  "alzheimer's disease": "07001006",
  "animal diseases": "07001007",
  "plant diseases": "07001008",
  retrovirus: "07001009",
  "epidemic and plague": "07002000",
  "health treatment": "07003000",
  "prescription drugs": "07003001",
  "dietary supplements": "07003002",
  diet: "07003003",
  "medical procedure/test": "07003004",
  therapy: "07003005",
  "health organisations": "07004000",
  "medical research": "07005000",
  "medical staff": "07006000",
  "primary care physician": "07006001",
  "health-workers union": "07006002",
  medicine: "07007000",
  herbal: "07007001",
  holistic: "07007002",
  western: "07007003",
  "traditional Chinese": "07007004",
  "preventative medicine": "07008000",
  vaccines: "07008001",
  injury: "07009000",
  "hospital and clinic": "07010000",
  "government health care": "07011000",
  medicare: "07011001",
  medicaid: "07011002",
  "private health care": "07012000",
  "healthcare policy": "07013000",
  "food safety": "07013001",
  "medical specialisation": "07014000",
  geriatric: "07014001",
  pediatrics: "07014002",
  reproduction: "07014003",
  genetics: "07014004",
  "obstetrics/gynecology": "07014005",
  "medical service": "07015000",
  "physical fitness": "07016000",
  illness: "07017000",
  "mental illness": "07017001",
  "eating disorder": "07017002",
  obesity: "07017003",
  "medical conditions": "07018000",
  patient: "07019000",
  "human interest": "08000000",
  animal: "08001000",
  curiosity: "08002000",
  people: "14024000",
  advice: "08003001",
  celebrity: "08003002",
  accomplishment: "08003003",
  "human mishap": "08003004",
  "fortune-telling": "08003005",
  mystery: "08004000",
  society: "08005000",
  ceremony: "08005001",
  death: "08005002",
  funeral: "08005003",
  "estate bestowal": "08005004",
  memorial: "08005005",
  "award and prize": "08006000",
  record: "08006001",
  "imperial and royal matters": "08007000",
  plant: "08008000",
  labour: "09000000",
  apprentices: "09001000",
  "collective contract": "09002000",
  "contract issue-wages": "09002001",
  "contract issue-healthcare": "09002002",
  "contract issue-work rules": "09002003",
  employment: "09003000",
  "labor market": "09003001",
  "job layoffs": "09003002",
  "child labor": "09003003",
  occupations: "09003004",
  "labour dispute": "09004000",
  "labour legislation": "09005000",
  retirement: "09006000",
  retraining: "09007000",
  strike: "09008000",
  unemployment: "09009000",
  unions: "09010000",
  "wage and pension": "09011000",
  "employee benefits": "09011001",
  "social security": "09011002",
  "work relations": "09012000",
  "health and safety at work": "09013000",
  "advanced training": "09014000",
  employer: "09015000",
  employee: "09016000",
  "lifestyle and leisure": "10000000",
  game: "10001000",
  Go: "10001001",
  chess: "10001002",
  bridge: "10001003",
  shogi: "10001004",
  "gaming and lottery": "10002000",
  gastronomy: "10003000",
  "organic foods": "10003001",
  hobby: "10004000",
  DIY: "10004001",
  shopping: "10004002",
  gardening: "10004003",
  "holiday or vacation": "10005000",
  tourism: "10006000",
  "travel and commuting": "10007000",
  traffic: "10007001",
  "club and association": "10008000",
  "lifestyle (house and home)": "10009000",
  "leisure (general)": "10010000",
  "public holiday": "10011000",
  hunting: "10012000",
  fishing: "10013000",
  "auto trends": "10014000",
  adventure: "10015000",
  beauty: "10016000",
  wedding: "10018000",
  politics: "11000000",
  defence: "11001000",
  "veterans affairs": "11001001",
  "national security": "11001002",
  "security measures": "11001003",
  "armed Forces": "11001004",
  "military equipment": "11001005",
  firearms: "11001006",
  "biological and chemical weapons": "11001007",
  "missile systems": "11001008",
  "nuclear weapons": "11001009",
  diplomacy: "11002000",
  summit: "11002001",
  "international relations": "11002002",
  "peace negotiations": "11002003",
  alliances: "11002004",
  election: "11003000",
  "political candidates": "11003001",
  "political campaigns": "11003002",
  "campaign finance": "11003003",
  "national elections": "11003004",
  "regional elections": "11003005",
  "local elections": "11003006",
  voting: "11003007",
  poll: "11003008",
  "european elections": "11003009",
  primary: "11003010",
  "espionage and intelligence": "11004000",
  "foreign aid": "11005000",
  "economic sanction": "11005001",
  government: "11006000",
  "civil and public service": "11006001",
  "safety of citizens": "11006002",
  "think tank": "11006003",
  "national government": "11006004",
  "executive (government)": "11006005",
  "heads of state": "11006006",
  "government departments": "11006007",
  "public officials": "11006008",
  "ministers (government)": "11006009",
  "public employees": "11006010",
  nationalisation: "11006012",
  impeachment: "11006013",
  "human rights": "11007000",
  "local authority": "11008000",
  parliament: "11009000",
  "upper house": "11009001",
  "lower house": "11009002",
  "parties and movements": "11010000",
  "non government organizations (NGO)": "11010001",
  refugee: "11011000",
  "regional authority": "11012000",
  "state budget and tax": "11013000",
  "public finance": "11013001",
  "treaty and international organisation-DEPRECATED": "11014000",
  "international relations-DEPRECATED": "11014001",
  "peace negotiations-DEPRECATED": "11014002",
  "alliances-DEPRECATED": "11014003",
  constitution: "11015000",
  "interior policy": "11016000",
  "data protection": "11016001",
  "housing and urban planning": "11016002",
  "pension and welfare": "11016003",
  "personal weapon control": "11016004",
  "indigenous people": "11016005",
  "personal data collection": "11016006",
  "planning inquiries": "11016007",
  migration: "11017000",
  "citizens initiative and recall": "11018000",
  referenda: "11019000",
  "nuclear policy": "11020000",
  lobbying: "11021000",
  "regulatory policy and organisation": "11022000",
  censorship: "11023000",
  "politics (general)": "11024000",
  "political systems": "11024001",
  democracy: "11024002",
  "political development": "11024003",
  "freedom of the press": "11025000",
  "freedom of religion": "11026000",
  treaty: "11027000",
  "international organisation": "11028000",
  "religion and belief": "12000000",
  "cult and sect": "12001000",
  "belief (faith)": "12002000",
  unificationism: "12002001",
  scientology: "12002002",
  freemasonry: "12003000",
  "religion-DEPRECATED": "12004000",
  "christianity-DEPRECATED": "12004001",
  "islam-DEPRECATED": "12004002",
  "judaism-DEPRECATED": "12004003",
  "buddhism-DEPRECATED": "12004004",
  "hinduism-DEPRECATED": "12004005",
  "church (organisation)-DEPRECATED": "12005000",
  "religious facilities-DEPRECATED": "12005001",
  values: "12006000",
  ethics: "12006001",
  "corrupt practices": "12006002",
  "church and state relations": "12007000",
  philosophy: "12008000",
  christianity: "12009000",
  protestant: "12009001",
  lutheran: "12009002",
  reformed: "12009003",
  anglican: "12009004",
  methodist: "12009005",
  baptist: "12009006",
  mennonite: "12009007",
  mormon: "12009009",
  "roman catholic": "12009010",
  "old catholic": "12009011",
  orthodoxy: "12009012",
  "salvation army": "12009013",
  islam: "12010000",
  judaism: "12011000",
  buddhism: "12012000",
  hinduism: "12013000",
  "religious festival or holiday": "12014000",
  christmas: "12014001",
  easter: "12014002",
  pentecost: "12014003",
  ramadan: "12014004",
  "yom kippur": "12014005",
  "religious leader": "12015000",
  pope: "12015001",
  "nature religion": "12016000",
  taoism: "12017000",
  shintoism: "12018000",
  sikhism: "12019000",
  jainism: "12020000",
  parsasm: "12021000",
  confucianism: "12022000",
  "religious text": "12023000",
  bible: "12023001",
  "qur'an": "12023002",
  torah: "12023003",
  "interreligious dialogue": "12024000",
  "religious event": "12025000",
  "catholic convention": "12025001",
  "protestant convention": "12025002",
  ritual: "12025004",
  concordat: "12026000",
  ecumenism: "12027000",
  "science and technology": "13000000",
  "applied science": "13001000",
  physics: "13001001",
  chemistry: "13001002",
  cosmology: "13001003",
  "particle physics": "13001004",
  engineering: "13002000",
  "material science": "13002001",
  "human science": "13003000",
  "social sciences": "13003001",
  psychology: "13003003",
  sociology: "13003004",
  anthropology: "13003005",
  "natural science": "13004000",
  geology: "13004001",
  paleontology: "13004002",
  geography: "13004003",
  botany: "13004004",
  zoology: "13004005",
  physiology: "13004006",
  astronomy: "13004007",
  biology: "13004008",
  "philosophical science": "13005000",
  research: "13006000",
  survey: "13006001",
  "scientific exploration": "13007000",
  "space programme": "13008000",
  "science (general)": "13009000",
  "technology (general)": "13010000",
  rocketry: "13010001",
  laser: "13010002",
  standards: "13011000",
  "animal science": "13012000",
  "micro science": "13013000",
  "marine science": "13014000",
  "weather science": "13015000",
  electronics: "13016000",
  "identification technology": "13017000",
  mathematics: "13018000",
  "agricultural research and technology": "13020000",
  nanotechnology: "13021000",
  "IT/computer sciences": "13022000",
  "scientific institutions": "13023000",
  "social issue": "14000000",
  addiction: "14001000",
  charity: "14002000",
  demographics: "14003000",
  "population and census": "14003001",
  immigration: "14003002",
  "illegal immigrants": "14003003",
  emigrants: "14003004",
  disabled: "14004000",
  "euthanasia (also includes assisted suicide)": "14005000",
  suicide: "14005001",
  family: "14006000",
  "parent and child": "14006001",
  adoption: "14006002",
  marriage: "14006003",
  divorce: "14006004",
  sex: "14006005",
  courtship: "14006006",
  "family planning": "14007000",
  "health insurance": "14008000",
  homelessness: "14009000",
  "minority group": "14010000",
  "gays and lesbians": "14010001",
  "national or ethnic minority": "14010002",
  pornography: "14011000",
  poverty: "14012000",
  prostitution: "14013000",
  racism: "14014000",
  welfare: "14015000",
  abortion: "14016000",
  "missing person": "14017000",
  "missing due to hostilities": "14017001",
  "long term care": "14018000",
  "juvenile delinquency": "14019000",
  "nuclear radiation victims": "14020000",
  slavery: "14021000",
  "abusive behaviour": "14022000",
  "death and dying": "14023000",
  children: "14024001",
  infants: "14024002",
  "teen-agers": "14024003",
  adults: "14024004",
  "senior citizens": "14024005",
  "social issues (general)": "14025000",
  "social conditions": "14025001",
  "social problems": "14025002",
  discrimination: "14025003",
  "social services": "14025004",
  "death penalty policies": "14025005",
  "ordnance clearance": "14026000",
  reconstruction: "14027000",
  sport: "15000000",
  "aero and aviation sport": "15001000",
  parachuting: "15001001",
  "sky diving": "15001002",
  "alpine skiing": "15002000",
  downhill: "15002001",
  "giant slalom": "15053001",
  "super G": "15002003",
  slalom: "15069001",
  combined: "15069004",
  "American football": "15003000",
  "(US) National Football League (NFL) (North American)": "15003001",
  CFL: "15003002",
  "AFL-DEPRECATED": "15003003",
  archery: "15004000",
  "FITA / Outdoor target archery": "15004001",
  "crossbow shooting": "15004002",
  "athletics, track and field": "15005000",
  "100 m": "15005001",
  "200 m": "15015002",
  "400 m": "15005003",
  "800 m": "15005004",
  "1000 m": "15056002",
  "1500 m": "15056003",
  mile: "15005007",
  "2000 m": "15005008",
  "3000 m": "15056004",
  "5000 m": "15056005",
  "10,000 m": "15005011",
  "20 km": "15009004",
  "one hour": "15019010",
  25000: "15005014",
  30000: "15005015",
  "110 m hurdles": "15005016",
  "400 m hurdles": "15005017",
  "3000 m steeplechase": "15005018",
  "high jump": "15005019",
  "pole vault": "15005020",
  "long jump": "15005021",
  "triple jump": "15005022",
  "shot put": "15005023",
  "discus throw": "15005024",
  "hammer throw": "15005025",
  "javelin throw": "15005026",
  decathlon: "15005027",
  "4x100 m": "15005028",
  "4x200 m": "15005029",
  "4x400 m": "15005030",
  "4x800 m": "15005031",
  "4x1500 m": "15005032",
  "walk 1 h": "15005033",
  "walk 2 h": "15005034",
  "10 km walk": "15005035",
  "15 km walk": "15005036",
  "20 km walk": "15005037",
  "30 km walk": "15005038",
  "50 km walk": "15005039",
  "100 m hurdles": "15005040",
  "5 km walk": "15005041",
  heptathlon: "15005042",
  "1500 m walk": "15005043",
  "2000 m walk": "15005044",
  "3000 m walk": "15005045",
  "50 m": "15005046",
  "50 m hurdles": "15005047",
  "50 yards": "15005048",
  "50 yard hurdles": "15005049",
  "60 m": "15005050",
  "60 m hurdles": "15005051",
  "60 yards": "15005052",
  "60 yard hurdles": "15005053",
  "100 yards": "15005054",
  "100 yard hurdles": "15005055",
  "300 m": "15005056",
  "300 yards": "15005057",
  "440 yards": "15005058",
  "500 m": "15056001",
  "500 yards": "15005060",
  "600 m": "15005061",
  "600 yards": "15005062",
  "880 yards": "15005063",
  "1000 yards": "15005064",
  "2 miles": "15005065",
  "3 miles": "15005066",
  "6 miles": "15005067",
  "4x1 mile": "15005068",
  pentathlon: "15005069",
  badminton: "15006000",
  baseball: "15007000",
  "Major League Baseball (North American Professional) - American League":
    "15007001",
  "Major League Baseball (North American Professional) - National League":
    "15007002",
  "Major League Baseball (North American Professional) - Special (e.g. All-Star, World Series)":
    "15007003",
  "rubberball baseball": "15007004",
  "Major League Baseball Playoffs": "15007005",
  "World Series": "15007006",
  basketball: "15008000",
  "National Basketball Association (North American Professional)": "15008001",
  "professional - Women general": "15008002",
  "Swiss netball": "15008003",
  "German netball": "15008004",
  "Dutch netball": "15008005",
  biathlon: "15009000",
  "7.5 km": "15009001",
  "10 km": "15009002",
  "15 km": "15009003",
  "4x7.5 km relay": "15009005",
  "12.5 km pursuit": "15009006",
  "billiards, snooker and pool": "15010000",
  "8 ball": "15010001",
  "9 ball": "15010002",
  14.1: "15010003",
  "continuous-DEPRECATED": "15010004",
  "other-DEPRECATED": "15010005",
  snooker: "15010006",
  bobsleigh: "15011000",
  "two-man sled": "15011001",
  "four-man sled": "15011002",
  bowling: "15012000",
  "bowls and petanque": "15013000",
  boxing: "15014000",
  "super-heavyweight": "15014001",
  heavyweight: "15033001",
  cruiserweight: "15014003",
  "light-heavyweight": "15014004",
  "super-middleweight": "15014005",
  middleweight: "15033003",
  "light-middleweight": "15014007",
  welterweight: "15014008",
  "light-welterweight": "15014009",
  lightweight: "15047007",
  "super-featherweight": "15014011",
  featherweight: "15014012",
  "super-bantamweight": "15014013",
  bantamweight: "15014014",
  "super-flyweight": "15014015",
  flyweight: "15014016",
  "light flyweight": "15014017",
  straw: "15014018",
  IBF: "15014019",
  WBA: "15014020",
  WBC: "15014021",
  WBO: "15014022",
  "French boxing": "15014023",
  "Thai boxing": "15014024",
  "canoeing and kayaking": "15015000",
  Slalom: "15015001",
  K1: "15015005",
  K2: "15015006",
  K4: "15015007",
  C1: "15015008",
  C2: "15015009",
  C4: "15015010",
  "canoe sailing": "15015011",
  pontoniering: "15015012",
  climbing: "15016000",
  mountaineering: "15016001",
  "sport climbing": "15016002",
  cricket: "15017000",
  curling: "15018000",
  "icestock sport": "15018001",
  cycling: "15019000",
  track: "15019001",
  pursuit: "15040002",
  "Olympic sprint": "15019003",
  sprint: "15019004",
  Keirin: "15019005",
  "points race": "15019006",
  "Madison race": "15019007",
  "500 m time trial": "15019008",
  "1 km time trial": "15019009",
  "road race": "15019011",
  "road time trial": "15019012",
  "staging race": "15050011",
  "cyclo-cross": "15019014",
  Vtt: "15019015",
  "Vtt-cross": "15019016",
  "Vtt-downhill": "15019017",
  "bi-crossing": "15019018",
  trial: "15041007",
  "artistic cycling": "15019020",
  "cycle ball": "15019021",
  dancing: "15020000",
  diving: "15021000",
  "10 m platform": "15021001",
  "10 m platform synchronised": "15021002",
  "3 m springboard": "15021003",
  "3 m springboard synchronised": "15021004",
  subaquatics: "15021005",
  "scuba diving": "15021006",
  equestrian: "15022000",
  "three-day event": "15022001",
  dressage: "15022002",
  jumping: "15022003",
  "cross country": "15030004",
  fencing: "15038004",
  epee: "15023001",
  foil: "15023002",
  sabre: "15023003",
  "field Hockey": "15024000",
  "roll hockey": "15024001",
  "figure Skating": "15025000",
  singles: "15036001",
  pairs: "15025002",
  "ice dance": "15025003",
  "freestyle Skiing": "15026000",
  moguls: "15026001",
  aerials: "15026002",
  "artistic skiing": "15026003",
  golf: "15027000",
  gymnastics: "15028000",
  "floor exercise": "15028001",
  vault: "15028002",
  "pommel horse": "15028003",
  "uneven bars": "15028004",
  "parallel bars": "15028005",
  "horizontal bar": "15028006",
  rings: "15028007",
  beam: "15028008",
  rhythmic: "15028009",
  clubs: "15028010",
  hoop: "15028011",
  ribbon: "15028012",
  rope: "15028013",
  ball: "15028014",
  trampoline: "15028015",
  "handball (team)": "15029000",
  "horse racing, harness racing": "15030000",
  "flat racing": "15030001",
  "steeple chase": "15030002",
  trotting: "15030003",
  "ice hockey": "15031000",
  "National Hockey League (North American)": "15031001",
  "sledge hockey": "15031002",
  "Jai Alai (Pelota)": "15032000",
  fronton: "15032001",
  "jai-alai": "15032002",
  "left wall": "15032003",
  trinquet: "15032004",
  rebot: "15032005",
  "chistera ancha": "15032006",
  "chistera corta": "15032007",
  "bare hand": "15032008",
  "pala-ancha": "15032009",
  "pala-corta": "15032010",
  pasaka: "15032011",
  xare: "15032012",
  judo: "15033000",
  "half-heavyweight": "15033002",
  "half-middleweight": "15033004",
  "half-lightweight": "15033005",
  "extra lightweight": "15033007",
  karate: "15034000",
  sparring: "15034001",
  "formal exercise-DEPRECATED": "15034002",
  lacrosse: "15035000",
  luge: "15036000",
  doubles: "15036002",
  marathon: "15037000",
  "modern pentathlon": "15038000",
  running: "15038001",
  shooting: "15051000",
  swimming: "15062000",
  showjumping: "15038005",
  "motor racing": "15039000",
  "Formula One": "15039001",
  F3000: "15039002",
  endurance: "15041008",
  Indy: "15039004",
  CART: "15039005",
  NHRA: "15039006",
  NASCAR: "15039007",
  TRUCKI: "15039008",
  "motor rallying": "15040000",
  rallying: "15041006",
  rallycross: "15040003",
  motorcycling: "15041000",
  "speed-Grand-Prix": "15041001",
  enduro: "15041002",
  "grass-track": "15041003",
  "moto-ball": "15041004",
  "moto-cross": "15041005",
  superbike: "15041009",
  "125 cm3": "15041010",
  "250 cm3": "15041011",
  "500 cm3": "15041012",
  "side-cars": "15041013",
  motoGP: "15041014",
  netball: "15042000",
  "nordic skiing": "15043000",
  "cross-country": "15043001",
  "5 km classical time": "15043002",
  "10 km classical style": "15043003",
  "10 km pursuit free style": "15043004",
  "15 km classical style": "15043005",
  "15 km pursuit free style": "15043006",
  "10 km + 15 km combined": "15043007",
  "30 km classical style": "15043008",
  "30km free style": "15043009",
  "50 km free style": "15043010",
  "4x5 km relay": "15043011",
  "4x10 km relay": "15043012",
  "nordic combined": "15043013",
  raid: "15043014",
  "5 km pursuit free style": "15043015",
  "1.5 km sprint free": "15043016",
  "50 km classic style": "15043017",
  orienteering: "15044000",
  "ski orienteering": "15044001",
  polo: "15045000",
  "power boating": "15046000",
  F1: "15046001",
  F2: "15046002",
  rowing: "15047000",
  "single sculls": "15047001",
  "double sculls": "15047002",
  "quadruple sculls": "15047003",
  "coxless pair": "15047004",
  "coxless four": "15047005",
  eight: "15047006",
  "rugby league": "15048000",
  "rugby union": "15049000",
  "rugby 7": "15049001",
  sailing: "15050000",
  Tornado: "15050001",
  soling: "15050002",
  "49er": "15050003",
  Europe: "15050004",
  Laser: "15050005",
  470: "15050006",
  Finn: "15050007",
  Star: "15050008",
  "flying dutchmann": "15050009",
  505: "15050010",
  "around the world": "15050012",
  monohull: "15050013",
  multihulls: "15050014",
  yngling: "15050015",
  mistral: "15050016",
  "10 m air rifle": "15051001",
  "10 m air pistol": "15051002",
  "10 m running target": "15051003",
  "25 m rapid fire pistol": "15051004",
  "25 m sport pistol": "15051005",
  "50 m free pistol": "15051006",
  "50 m free rifle prone": "15051007",
  "50 m free rifle 3x40": "15051008",
  "50 m sport rifle 3x20": "15051009",
  trap: "15051010",
  "double trap": "15051011",
  skeet: "15051012",
  "ski jumping": "15052000",
  "K90 jump": "15052001",
  "K120 jump": "15052002",
  "K180 (flying jump)": "15052003",
  "snow boarding": "15053000",
  "half-pipe": "15053002",
  soccer: "15054000",
  softball: "15055000",
  "speed skating": "15056000",
  "10000 m": "15056006",
  "Short-track": "15056007",
  "st 500 m": "15056008",
  "st 1000m": "15056009",
  "st 1500m": "15056010",
  "st 3000m": "15056011",
  "st 3000m relay": "15056012",
  "st 5000m": "15056013",
  "st 5000m relay": "15056014",
  speedway: "15057000",
  "sports organisations": "15058000",
  IOC: "15058001",
  "international federation": "15058002",
  "continental federation": "15058003",
  "national federation": "15058004",
  GAISF: "15058005",
  squash: "15059000",
  "sumo wrestling": "15060000",
  surfing: "15061000",
  "50 m freestyle": "15062001",
  "100 m freestyle": "15062002",
  "200 m freestyle": "15062003",
  "400 m freestyle": "15062004",
  "800 m freestyle": "15062005",
  "1500 m freestyle": "15062006",
  "relay 4x50 m freestyle": "15062007",
  "relay 4x100 m freestyle": "15062008",
  "relay 4x200 m freestyle": "15062009",
  "50 m backstroke": "15062010",
  "100 m backstroke": "15062011",
  "200 m backstroke": "15062012",
  "50 m breaststroke": "15062013",
  "100 m breaststroke": "15062014",
  "200 m breaststroke": "15062015",
  "50 m butterfly": "15062016",
  "100 m butterfly": "15062017",
  "200 m butterfly": "15062018",
  "100 m medley": "15062019",
  "200 m medley": "15062020",
  "400 m medley": "15062021",
  "relay 4x50 m medlay": "15062022",
  "relay4x100 m medley": "15062023",
  "short course": "15062024",
  "synchronised technical routine": "15062025",
  "synchronised free routine": "15062026",
  "table tennis": "15063000",
  "Taekwon-Do": "15064000",
  "under 49 kg": "15064001",
  "under 58 kg": "15064002",
  "49-57 kg": "15064003",
  "58-68 kg": "15064004",
  "57-67 kg": "15064005",
  "68-80 kg": "15064006",
  "over 67 kg": "15064007",
  "over 80 kg": "15064008",
  tennis: "15065000",
  "soft tennis": "15065001",
  triathlon: "15066000",
  "triathlon swimming": "15066001",
  "triathlon cycling": "15066002",
  "triathlon run": "15066003",
  volleyball: "15067000",
  "beach volleyball": "15067001",
  "water polo": "15068000",
  "water skiing": "15069000",
  trick: "15069002",
  jump: "15069003",
  weightlifting: "15070000",
  snatch: "15070001",
  "clean and jerk": "15070002",
  "48 kg": "15070003",
  "53 kg": "15070004",
  "63 kg": "15072009",
  "75 kg": "15070006",
  "over 75 kg": "15070007",
  "56 kg": "15070008",
  "62 kg": "15070009",
  "69 kg": "15072008",
  "77 kg": "15070011",
  "85 kg": "15072006",
  "94 kg": "15070013",
  "105 kg": "15070014",
  "over 105 kg": "15070015",
  powerlifting: "15070016",
  windsurfing: "15071000",
  ocean: "15071001",
  lake: "15071002",
  river: "15071003",
  land: "15071004",
  wrestling: "15072000",
  freestyle: "15077008",
  "greco-roman": "15072002",
  "over 130 kg": "15072003",
  "130 kg": "15072004",
  "97 kg": "15072005",
  "76 kg": "15072007",
  "58 kg": "15072010",
  "54 kg": "15072011",
  "Swiss wrestling": "15072012",
  "sports event": "15073000",
  "Summer Olympics": "15073001",
  "Winter Olympics": "15073002",
  "Summer universiade": "15073003",
  "Winter Universiade": "15073004",
  "Commonwealth Games": "15073005",
  "Winter Goodwill Games": "15073006",
  "Summer Asian Games": "15073007",
  "Winter Asian Games": "15073008",
  "Panamerican Games": "15073009",
  "African Games": "15073010",
  "Mediterranean Games": "15073011",
  "SouthEast Asiatic Games": "15073012",
  "PanPacific Games": "15073013",
  "SouthPacific Games": "15073014",
  "PanArabic Games": "15073015",
  "Summer Goodwill Games": "15073016",
  "World games": "15073017",
  "World Cup": "15073018",
  "intercontinental cup": "15073019",
  "continental cup": "15073020",
  "international cup": "15073021",
  "National Cup": "15073022",
  "interregional cup": "15073023",
  "regional cup": "15073024",
  "league cup": "15073025",
  "world championship": "15073026",
  "intercontinental championship": "15073027",
  "continental championship 1st level": "15073028",
  "continental championship 2nd level": "15073029",
  "continental championship 3rd level": "15073030",
  "national championship 1st level": "15073031",
  "national championship 2nd level": "15073032",
  "national championship3rdlevel": "15073033",
  "national championship 4th level": "15073034",
  "regional championship": "15073035",
  "Grand Prix": "15073036",
  "intercontinental tournament": "15073037",
  "continental tournament": "15073038",
  "international tournament": "15073039",
  "national tournament": "15073040",
  "inter-nations competition": "15073041",
  "inter-clubs competition": "15073042",
  "friendly competition": "15073043",
  "all-stars competition": "15073044",
  exhibition: "15073045",
  "Super Bowl": "15073046",
  "paralympic games": "15073047",
  rodeo: "15074000",
  "barrel racing": "15074001",
  "calf roping": "15074002",
  "bull riding": "15074003",
  bulldogging: "15074004",
  "saddle bronc": "15074005",
  bareback: "15074006",
  "goat roping": "15074007",
  "mini golf sport": "15075000",
  bandy: "15076000",
  "flying disc": "15077000",
  ultimate: "15077001",
  guts: "15077002",
  overall: "15077003",
  distance: "15077004",
  discathon: "15077005",
  DDC: "15077006",
  SCF: "15077007",
  accuracy: "15077009",
  "disc golf": "15077010",
  floorball: "15078000",
  casting: "15079000",
  "tug-of-war": "15080000",
  croquette: "15081000",
  "dog racing": "15082000",
  sled: "15082001",
  "oval track": "15082002",
  skeleton: "15083000",
  "Australian rules football": "15084000",
  "Canadian football": "15085000",
  duathlon: "15086000",
  hornuss: "15087000",
  "fist ball": "15088000",
  "inline skating": "15089000",
  "grass ski": "15090000",
  snowbiking: "15091000",
  twirling: "15092000",
  kendo: "15093000",
  jukendo: "15094000",
  naginata: "15095000",
  kyudo: "15096000",
  kabaddi: "15097000",
  "sepak takraw": "15098000",
  wushu: "15099000",
  darts: "15100000",
  bodybuilding: "15101000",
  "sports disciplinary action": "15102000",
  "sports awards": "15103000",
  "unrest, conflicts and war": "16000000",
  "act of terror": "16001000",
  "armed conflict": "16002000",
  "civil unrest": "16003000",
  revolutions: "16003001",
  rebellions: "16003002",
  "political dissent": "16003003",
  "religious conflict": "16003004",
  "social conflict": "16003005",
  "coup d'etat": "16004000",
  "guerrilla activity": "16005000",
  bioterrorism: "16005001",
  bombings: "16005002",
  massacre: "16006000",
  genocide: "16006001",
  riots: "16007000",
  demonstration: "16008000",
  war: "16009000",
  "civil war": "16009001",
  "international military intervention": "16009002",
  "prisoners and detainees": "16009003",
  "conflict (general)": "16010000",
  "peacekeeping force": "16010001",
  crisis: "16011000",
  weaponry: "16012000",
  weather: "17000000",
  forecast: "17001000",
  "global change": "17002000",
  report: "17003000",
  "weather news": "17003001",
  statistic: "17004000",
  warning: "17005000",
};

const IPTCLayer0Labels = [
  "arts, culture and entertainment",
  "crime, law and justice",
  "disaster and accident",
  "economy, business and finance",
  "education",
  "environmental issue",
  "health",
  "human interest",
  "labour",
  "lifestyle and leisure",
  "politics",
  "religion and belief",
  "science and technology",
  "social issue",
  "sport",
  "unrest, conflicts and war",
  "weather",
];

export { IPTCDescriptionToCode, IPTCLayer0Labels };
