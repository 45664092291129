<template>
  <div
    :id="`m_filter_entry_${id}_${firstEntryState.type}`"
    :data-tooltip-content="firstEntryState.tooltip"
    data-tooltip-position="dynamic"
    class="m-filter-entry m-clickable"
    :class="{ 'm-filter-entry--inactive': !entry.selected }"
    @click="updateState(!firstEntryState.value, firstEntryState.type)"
  >
    <div @mouseenter="setHover(true)" @mouseleave="setHover(false)">
      <div
        class="m-filter-entry__wrapper"
        @mouseenter="hover = true"
        @mouseleave="hover = false"
      >
        <!-- todo: delete this -->
        <!--  :class="
          hover ? `m-filter-entry__checkbox--${firstEntryState.type}` : ''
        " -->
        <m-checkbox
          ref="firstEntryCheckboxRef"
          :id="`m_filter_entry_${id}_${firstEntryState.type}_checkbox`"
          :modelValue="firstEntryState.value"
          :type="firstEntryState.type"
          :tooltip="firstEntryState.tooltip"
          @update:modelValue="updateState"
        />
        <div
          v-if="hover"
          class="ml-1 m-filter-entry__mask"
          @mouseenter="setHover(false)"
          @mouseleave="setHover(true)"
        >
          <m-checkbox
            :id="`m_filter_entry_${id}_${secondEntryState.type}`"
            :modelValue="true"
            :type="secondEntryState.type"
            :hover="false"
            :tooltip="secondEntryState.tooltip"
            :class="`m-filter-entry__checkbox--${secondEntryState.type}`"
            @update:modelValue="(val, type) => updateState(true, type)"
          />
        </div>
      </div>
      <label
        class="m-filter-entry__label"
        :class="firstEntryState.value ? 'h5' : 'p'"
        :dir="checkForArabicLanguage(label)"
      >
        {{ label }}
      </label>

      <span
        v-if="entry.count != 0"
        :id="`m_filter_entry_${id}_${firstEntryState.type}_count`"
        class="ml-3"
        :class="entry.selected ? 'p' : 'h6 type--empty'"
        :data-tooltip-content="filterCount(entry)"
        data-tooltip-position="dynamic"
      >
        {{ count[1] ? count[0] + count[1] : count[0] }}
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import { sanitizedValue } from "@utils/utils";

import MCheckbox from "@components/MCheckbox.vue";
import { snakeCase } from "lodash-es";

const props = defineProps({
  entry: Object,
});

const emit = defineEmits(["update:entry"]);

const { t } = useI18n();

const firstEntryCheckboxRef = ref(null);
const hover = ref(false);

const id = computed(() => {
  const id = snakeCase(props.entry.value);
  let out = 0;
  for (let i = 0; i < id?.length; i++) {
    out += Math.abs((id.charCodeAt(i) - 64) * Math.pow(26, id?.length - i - 1));
  }
  return out || "";
});

const count = computed(() => {
  return sanitizedValue(props.entry?.count, props.entry?.wasExtrapolated);
});

const label = computed(() => {
  return props.entry.label;
});

const firstEntryState = computed(() => {
  const checkbox = { type: null, value: null, tooltip: null };

  if (props.entry.selected && props.entry.negated) {
    checkbox.type = "negative";
    checkbox.value = true;
    checkbox.tooltip = t(
      "components.filterPane.tooltipCheckbox_unchecked_negative",
      {
        label: props.entry.label,
      }
    );
  } else {
    checkbox.type = "positive";
    checkbox.value = props.entry.selected;
    if (checkbox.value) {
      checkbox.tooltip = t("components.filterPane.tooltipCheckbox_checked", {
        label: props.entry.label,
      });
    } else {
      checkbox.tooltip = t(
        "components.filterPane.tooltipCheckbox_unchecked_positive",
        {
          label: props.entry.label,
        }
      );
    }
  }

  return checkbox;
});

const secondEntryState = computed(() => {
  const checkbox = { type: null, value: null, tooltip: null };

  if (props.entry.selected && props.entry.negated) {
    checkbox.type = "positive";
    checkbox.tooltip = t(
      "components.filterPane.tooltipCheckbox_unchecked_positive",
      { label: label.value }
    );
  } else {
    checkbox.type = "negative";
    checkbox.tooltip = t(
      "components.filterPane.tooltipCheckbox_unchecked_negative",
      { label: label.value }
    );
  }

  return checkbox;
});

const setHover = (val) => {
  firstEntryCheckboxRef.value.setHover(val);
};

const updateState = (val, type) => {
  const v = { ...props.entry };

  if (val) {
    v.selected = true;

    if (type == "positive") {
      v.negated = false;
    } else if (type == "negative") {
      v.negated = true;
    }
  } else {
    v.selected = false;
    v.negated = false;
  }
  firstEntryState.value.value = v.selected;
  firstEntryState.value.type = type;
  emit("update:entry", v);
};

const checkForArabicLanguage = (txt) => {
  const arabicRegex = /[\u0600-\u06FF]/;
  return arabicRegex.test(txt) ? "rtl" : null;
};

const filterCount = (val) => {
  if (val.count) {
    const count = val.count.toString();
    // NOTE: This was wrong for small strings; FIXME: Generalize.
    /*count =
      count.substr(0, count.length - 3) +
      " " +
      count.substr(count.length - 3, count.length);*/
    return `${count} ${val.wasExtrapolated ? "results (estimate)" : ""}`;
  }
};
</script>

<style scoped lang="scss">
.m-filter-entry {
  padding: math.div($spacing-1, 2) $spacing-1;
  margin: math.div($spacing-1, 2) $spacing-0;

  > div {
    @include flex(space-between, center, row);
  }

  &--inactive {
    @include opacity-inactive;

    &:hover {
      @include opacity-hover;
    }
  }

  &__wrapper {
    @include flex(space-between, center, row);
  }

  &__mask {
    @include round-corners($spacing-1);
    background-color: color($white);
  }

  &__checkbox {
    border: none;

    &--negative {
      :deep(.m-checkbox__box) {
        background-color: color($error, 0.1) !important;

        &:hover {
          background-color: color($error-light);
        }
      }

      :deep(.m-icon) {
        color: color($error, 0.4);
        fill: color($error, 0.4);
        cursor: pointer;

        &:hover {
          color: color($error);
          fill: color($error);
        }
      }
    }

    &--positive {
      :deep(.m-checkbox__box) {
        background-color: color($pri-action-light, 0.2) !important;

        &:hover {
          background-color: color($pri-action-light);
        }
      }

      :deep(.m-icon) {
        color: color($pri-action, 0.4);
        fill: color($pri-action, 0.4);
        cursor: pointer;

        &:hover {
          color: color($pri-action);
          fill: color($pri-action);
        }
      }
    }
  }

  &__label {
    margin-left: $spacing-3;
    flex-grow: 1;
    @include ellipsis;
  }
}
</style>
@root/utils/
