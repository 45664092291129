import type { AxiosInstance } from "axios";
import type { MonitioAPI } from "@root/types.api.local";

function anonymousDecorator(fn: any) {
  fn._anonymous = true;
  return fn;
}

export default (axios: AxiosInstance) => ({
  getAll() {
    return axios.get(`/v1/reports`);
  },
  getAllTemplates() {
    return axios.get(`/v1/reports/templates`);
  },
  getReportById(id: string) {
    return axios.get(`/v1/reports/${id}`);
  },
  getReporTemplatetById(id: string) {
    return axios.get(`/v1/reports/template/${id}`);
  },
  create(report: MonitioAPI.ReportDTO) {
    return axios.post(`/v1/reports/create`, report);
  },
  update(report: MonitioAPI.ReportDTO) {
    return axios.post(`/v1/reports/update`, report);
  },
  createTemplate(template: MonitioAPI.ReportTemplateDTO) {
    return axios.post(`/v1/reports/create-template`, template);
  },
  updateTemplate(template: MonitioAPI.ReportTemplateDTO) {
    return axios.post(`/v1/reports/update-template`, template);
  },
  delete(id: string) {
    return axios.delete(`/v1/reports/${id}`);
  },
  deleteTemplate(id: string) {
    return axios.delete(`/v1/reports/template/${id}`);
  },
  snapshots(templateid: string) {
    return axios.get(`/v1/reports/snapshot/${templateid}`);
  },
  snapshot(templateid: string, id: string) {
    return axios.get(`/v1/reports/snapshot/${templateid}/${id}`);
  },
  automate(settings: MonitioAPI.ReportAutomateSettings, reportId: string) {
    return axios.post(`/v1/reports/${reportId}/automate-report`, settings);
  },
  createSnapshot(snapshot: object, send: boolean) {
    return axios.post(
      `/v1/reports/snapshot?send=${send ? "true" : "false"}`,
      snapshot
    );
  },
  openSnapshot(f: string, snapshotId: string) {
    const format = f == "html" ? "" : `/${f}`;
    return anonymousDecorator(
      axios.get(`/v1/reports/open-snapshot/${snapshotId}` + format)
    );
  },
  unsubscribe(email: string, reportId: string) {
    return anonymousDecorator(
      axios.post(`/v1/reports/unsubscribe/${reportId}?email=${email}`)
    );
  },
  resubscribe(email: string, reportId: string) {
    return anonymousDecorator(
      axios.post(`/v1/reports/resubscribe/${reportId}?email=${email}`)
    );
  },
});
