// User state
import { saveAs } from "file-saver";
import { useApi } from "@api/api";
import DateTimeUtils from "@root/utils/dateTime";
import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";
import { useAlertsStore } from "./alerts";
import guid from "@root/utils/guid";
import type TimeFrame from "@root/utils/enums/timeFrames";
import type { useI18n } from "vue-i18n";
import { useAppStore } from "@root/store/app";
import { useUserStore } from "./user";

interface ClusterStoreState {
  moduleHasInit: boolean;
  currentView: MonitioAPI.ViewDTO | null;
  currentClusters: any;
  names: Record<string, string>;
}

export const useClustersStore = defineStore("clusters", {
  //const { api } = useApi();
  state: (): ClusterStoreState => ({
    moduleHasInit: false,
    currentView: null,
    currentClusters: {},
    names: localStorage.getItem("clusterNames")
      ? JSON.parse(localStorage.getItem("clusterNames") ?? "{}")
      : {},
  }),

  getters: {
    getClusterNameById() {
      return (id: string) => {
        return this.names[id] ?? id;
      };
    },
  },

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.debug("Tiles module already initialized");
        return;
      }
      this.moduleHasInit = true;
    },
    async shutdown() {
      this.moduleHasInit = false;
    },
    async updateCurrentClusters(clusters: any, view: MonitioAPI.ViewDTO) {
      this.currentClusters = clusters;
      this.currentView = view;
    },
    async exportCSV(
      t: ReturnType<typeof useI18n>,
      dateRestriction: MonitioAPI.DateRestriction,
      aggregator: string
    ) {
      const { api } = useApi();
      const appStore = useAppStore();
      const userStore = useUserStore();
      const alertsStore = useAlertsStore();
      const id = guid.NewGuid();

      alertsStore.add({
        id: id,
        type: "toast",
        variant: "load",
        message: t("general.alerts.toast.downloadingCsv", {
          type: t("views.tiles.tiles"),
        }),
      });

      appStore.isExporting = true;
      const clusterIds = this.currentClusters.map((x: any) => x.id);

      try {
        const response = await api.search.clustersFeedCount(
          this.currentView?.id,
          clusterIds,
          dateRestriction,
          aggregator
        );

        if (response.status != 200) return;

        const feedsCount = response.data;

        for (const clusterId in feedsCount) {
          if (Object.hasOwnProperty.call(feedsCount, clusterId)) {
            const count = feedsCount[clusterId];
            const cluster = this.currentClusters.find(
              (x: any) => x.id == clusterId
            );
            if (cluster) cluster.numberOfFeeds = count;
          }
        }

        const csvHead = [
          "title",
          "topic",
          "corpora_freq",
          "selection_freq",
          "number_of_feeds",
        ];
        const csvData = [csvHead].concat(
          this.currentClusters.map((line: any) => [
            `"${line.title}"`,
            line.topic ? `"${line.topic}"` : `""`,
            line.corpora_frequency,
            line.selection_frequency,
            line.numberOfFeeds,
          ])
        );

        //TODO: ADD SUMMARY TO CSV

        //create csv file
        //let universalBOM = "\uFEFF";
        let csvContent = "";
        csvContent += csvData.map((e) => e.join(",")).join("\n");

        let fileName = "";
        if (dateRestriction.isRelative) {
          const timeframe = DateTimeUtils.getTimeFrame(
            dateRestriction.timeFrame as unknown as TimeFrame,
            userStore.timeZone
          );
          fileName += timeframe.start.toISODate();
          fileName += `-${timeframe.end.toISODate()}`;
        } else {
          fileName += dateRestriction.start?.toISODate();
          if (dateRestriction.end)
            fileName += `-${dateRestriction.end.toISODate()}`;
        }

        fileName += ` Clusters of ${this.currentView?.name}`;

        const BOM = "\uFEFF";
        const csvFixedSpecialChar = BOM + csvContent;
        saveAs(
          new Blob([csvFixedSpecialChar], {
            type: "text/csv;charset=utf-8",
          }),
          fileName + ".csv"
        );
      } catch (error) {
        console.log(error);
      }
      appStore.isExporting = false;
      alertsStore.remove(id);
      alertsStore.add({
        id: guid.NewGuid(),
        type: "toast",
        variant: "success",
        message: t("general.alerts.toast.downloadedCsv", {
          type: t("views.tiles.tiles"),
        }),
      });
    },
    async addClusterName(id: string, name: string) {
      this.names[id] = name;
      localStorage.setItem("clusterNames", JSON.stringify(this.names));
    },
  },
});
