<template>
  <div
    :id="`m_options_${id}`"
    ref="wrapperRef"
    class="m-options"
    :class="{ 'm-options--disabled': disabled || options?.length == 0 }"
    v-click-outside="closeOptions"
  >
    <label :id="`m_options_label_${id}`" class="visually-hidden">
      {{ t("general.options") }}
    </label>
    <m-icon
      :id="`m_options_more_${id}`"
      ref="optionRef"
      :data-test="`test_m_options_more_${id}`"
      :tabindex="disabled ? -1 : 0"
      :aria-controls="`m_options_dropdown_${id}`"
      :aria-expanded="openDropdown"
      aria-haspopup="listbox"
      :aria-labelledby="`m_options_label_${id}`"
      role="combobox"
      :icon="icon"
      :variant="variant"
      :hover="hover"
      :tooltip="tooltip"
      :direction="direction == 'horizontal' ? 'right' : 'up'"
      :class="{ '--a11y': appStore.a11y }"
      @click="toggleDropdown"
      @keyup.up="openDropdown = true"
      @keyup.down.exact="openDropdown = true"
      @keyup.alt.down="openDropdown = true"
      @keyup.home="openDropdown = true"
      @keyup.end="openDropdown = true"
      @keyup.space="openDropdown = true"
      @keyup.enter="openDropdown = true"
      @keyup.esc="closeOptions"
    />
    <m-dropdown
      v-if="options?.length != 0"
      :id="`m_options_dropdown_${id}`"
      data-tour="tour_m_dash_dropdown_more_options"
      :labelledBy="`m_options_${id}`"
      :options="options"
      v-model:visible="openDropdown"
      :position="position"
      :size="size"
      :floating="floating"
      :focused="focused"
      :keyup="keyup"
      :keydown="keydown"
      :query="query"
      :parent="wrapperRef"
      :disableSort="disableSort"
      class="m-options__dropdown"
      @update:selected="select"
      @keep-dropdown-open="keepDropdownOpen"
      @mouseenter="keepDropdownOpen"
      @mouseleave="closeOptions"
    />
  </div>
</template>

<script setup>
/*
 * Monitio options component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/608862209/options
 */

import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";

import useDropdown from "@hooks/useDropdown";
import MDropdown from "@components/MDropdown.vue";
import MIcon from "@components/MIcon.vue";
import { useAppStore } from "@root/store/app";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  size: { type: String, default: "default" },
  icon: { type: String, default: "more" },
  options: { type: Array, required: true },
  variant: { type: String, default: "primary" },
  direction: { type: String, default: "vertical" },
  position: { type: Array, default: () => ["left", "bottom"] },
  disableSort: { type: Boolean, default: false },
  hover: { type: String, default: "default" },
  floating: { type: Boolean, default: false },
  focused: { type: String },
  disabled: { type: Boolean, default: false },
});

const emit = defineEmits(["select", "close"]);

// Props validation
watch(
  () => props,
  () => {
    // size validator
    if (!["default", "small"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size": expected string with value "default" or "small" and got "${props.size}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component`
      );
    }

    // options validator
    props.options?.forEach((opt) => {
      if (typeof opt != "object") {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "options": all array elements have to be objects. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component'
        );
      } else {
        if (opt.value === undefined || opt.value === null) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "value" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component'
          );
        }
        if (opt.label === undefined || opt.label === null) {
          console.error(
            `%cComponent id: ${props.id}`,
            "font-weight:bold",
            '\n Invalid prop "options": all object entries of the array should have the "label" attribute defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component'
          );
        }
      }
    });

    // variant validator
    if (
      ![
        "primary",
        "secondary",
        "terciary",
        "success",
        "warning",
        "error",
        "sidenav",
      ].includes(props.variant)
    ) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "variant": expected string with value "primary", "secondary", "terciary", "success", "warning", "error" or "sidenav" and got "${props.variant}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component.`
      );
    }

    // direction validator
    if (!["vertical", "horizontal"].includes(props.direction)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "direction": expected string with value "vertical" or "horizontal" and got "${props.direction}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component.`
      );
    }

    // position validator
    if (props.position?.length != 2) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "position": expected an array with two children defining the [ x, y ] positioning. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component`
      );
    } else {
      if (!["left", "right"].includes(props.position[0])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the first element of the array to be string with value "left" or "right" and got "${props.position[0]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component`
        );
      }
      if (!["top", "bottom"].includes(props.position[1])) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "position": expected the second element of the array to be string with value "top" or "bottom" and got "${props.position[1]}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component`
        );
      }
    }

    // hover validation
    if (!["default", "highlight", "elevate", "weight"].includes(props.hover)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "hover": expected string with value "default", "highlight", "elevate" or "weight" and got "${props.hover}". \n\n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/612565123/Options for instructions on how to use the MOptions component.`
      );
    }
  },
  { immediate: true }
);

const { t } = useI18n();
const appStore = useAppStore();

const { openDropdown, toggleDropdown, keepDropdownOpen, closeDropdown } =
  useDropdown();

const wrapperRef = ref(null);
const optionRef = ref(null);

const select = (opt, secOpt) => {
  emit("select", opt.value, secOpt ? secOpt.value : null);
};

const closeOptions = () => {
  closeDropdown();
};

const query = ref("");
const keyup = ref(null);
const keydown = ref(null);

const keyUpMovement = (evt) => {
  keyup.value = evt;
  if (evt.code.startsWith("Key")) {
    if (!openDropdown.value) openDropdown.value = true;
    query.value += evt.key;
    setTimeout(() => {
      query.value = "";
    }, 500);
  }
};

const keyDownMovement = (evt) => {
  keydown.value = evt;
};

onMounted(() => {
  optionRef.value?.ref?.addEventListener("keyup", keyUpMovement);
  optionRef.value?.ref?.addEventListener("keydown", keyDownMovement);
});

onBeforeUnmount(() => {
  optionRef.value?.ref?.removeEventListener("keyup", keyUpMovement);
  optionRef.value?.ref?.removeEventListener("keydown", keyDownMovement);
});

const tooltip = computed(() => {
  return {
    content: t("general.options"),
    position: "bottom-left",
  };
});
</script>

<style scoped lang="scss">
.m-options {
  position: relative;

  .m-icon {
    padding: $spacing-1 $spacing-0;
  }

  &__dropdown {
    &--top {
      bottom: $spacing-4;
    }

    &--left {
      left: $spacing-4;
    }

    &--bottom {
      top: $spacing-4;
    }

    &--right {
      right: $spacing-4;
    }
  }
}
</style>
