// Page navigation state
import structuredClone from "@utils/structuredClone";
import { defineStore } from "pinia";

interface NavigationStoreState {
  moduleHasInit: boolean;
  path: { label: string; value: string }[];
  from: string;
}

export const useNavigationStore = defineStore("navigation", {
  state: (): NavigationStoreState => ({
    moduleHasInit: false,
    path: localStorage.getItem("navigationPath")
      ? JSON.parse(localStorage.getItem("navigationPath") ?? "[]")
      : [],
    from: "",
  }),

  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Navigation module already initialized");
        return;
      }
      const storedNavigationPath = localStorage.getItem("navigationPath");
      if (storedNavigationPath) {
        this.path = JSON.parse(storedNavigationPath);
      }

      this.moduleHasInit = true;
    },
    async shutdown() {
      this.path = [];
      this.moduleHasInit = false;
    },
    async setPath(path: { label: string; value: string }[] = []) {
      localStorage.setItem("navigationPath", JSON.stringify(path));
      this.path = path;
    },
    async setPageTitle(path: { label: string; value: string }[]) {
      let title: string;
      if (path.length == 1) {
        title = path[0].label;
      } else {
        title = `${path[1].label}: ${path[path.length - 1].label}`;
      }

      document.title = `${title} - Monitio`;
    },
    async setArticleTitle(title: string) {
      const idx = this.path?.findIndex?.((x) => x.value == "article");
      if (idx < 0 || !this.path[idx]) return;
      this.path[idx].value = "article-title";
      this.path[idx].label = title;
      this.setPath(this.path);
      this.setPageTitle(this.path);
    },
    async setClusterTitle(title: string) {
      const idx = this.path?.findIndex?.((x) => x.value == "cluster");
      if (idx < 0 || !this.path[idx]) return;
      this.path[idx].value = "cluster-title";
      this.path[idx].label = title;
      this.setPath(this.path);
      this.setPageTitle(this.path);
    },
    async setFrom(path: string) {
      this.from = path;
    },
  },
});
