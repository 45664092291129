import type { MonitioAPI } from "@root/types.api.local";
import type { AxiosInstance } from "axios";

export default (axios: AxiosInstance) => ({
  getBarChart(
    element: any,
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    selectedProperty: string,
    numberOfResults: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    reportId = null
  ) {
    // eslint-disable-next-line prefer-const
    let { type, showMovingAverage, averageDays, isOverviewChart } = element;
    isOverviewChart ??= selectedProperty == "overview";
    return axios.post("/v1/analytics/trending-bar-chart", filters || [], {
      params: {
        type,
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame,
        "dateRestriction.start": dateRestriction.start?.toISO(),
        "dateRestriction.end": dateRestriction.end?.toISO(),
        "dateRestriction.reportId": dateRestriction.reportId,
        selectedProperty,
        numberOfResults,
        reportId,
        showMovingAverage,
        averageDays,
        isOverviewChart,
        variant: type,
      },
    });
  },
  getPieChart(
    type: string,
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    selectedProperty: string,
    numberOfResults: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    reportId = null
  ) {
    return axios.post("/v1/analytics/trending-pie-chart", filters || [], {
      params: {
        type,
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame,
        "dateRestriction.start": dateRestriction.start?.toISO(),
        "dateRestriction.end": dateRestriction.end?.toISO(),
        "dateRestriction.reportId": dateRestriction.reportId,
        selectedProperty,
        numberOfResults,
        reportId,
      },
    });
  },
  getBubblePlot(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    selectedProperty: string,
    numberOfResults: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    reportId = null
  ) {
    return axios.post("/v1/analytics/trending-bubble-plot", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame,
        "dateRestriction.start": dateRestriction.start?.toISO(),
        "dateRestriction.end": dateRestriction.end?.toISO(),
        "dateRestriction.reportId": dateRestriction.reportId,
        selectedProperty,
        numberOfResults,
        reportId,
      },
    });
  },
  getMapChart(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    selectedProperty: string,
    numberOfResults: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    reportId = null
  ) {
    return axios.post("/v1/analytics/trending-map-chart", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame,
        "dateRestriction.start": dateRestriction.start?.toISO(),
        "dateRestriction.end": dateRestriction.end?.toISO(),
        "dateRestriction.reportId": dateRestriction.reportId,
        selectedProperty,
        numberOfResults,
        reportId,
      },
    });
  },
  getMapChartData(
    viewId: string,
    dateRestriction: MonitioAPI.DateRestriction,
    selectedProperty: string,
    numberOfResults: number,
    filters: MonitioAPI.FrontendFiltersGroup[],
    reportId = null
  ) {
    return axios.post("/v1/analytics/map-chart-trending-data", filters || [], {
      params: {
        viewId,
        "dateRestriction.isRelative": dateRestriction.isRelative,
        "dateRestriction.timeFrame": dateRestriction.timeFrame,
        "dateRestriction.start": dateRestriction.start?.toISO(),
        "dateRestriction.end": dateRestriction.end?.toISO(),
        "dateRestriction.reportId": dateRestriction.reportId,
        selectedProperty,
        numberOfResults,
        reportId,
      },
    });
  },
  saveDashboard(
    dashboardId: string,
    dashboard: any,
    viewId: string,
    showedIn = [],
    showInAllViews = true
  ) {
    return axios.post("/v1/dashboards/" + dashboardId, {
      id: dashboard.id,
      name: dashboard.name,
      config: dashboard,
      viewId,
      showedIn,
      showInAllViews,
    });
  },
  delete(dashboardId: string) {
    return axios.delete("/v1/dashboards/" + dashboardId);
  },
  createDashboard(dashboard: any) {
    return axios.put("/v1/dashboards", dashboard);
  },
  getDashboard(dashboardId: string) {
    return axios.get("/v1/dashboards/" + dashboardId);
  },
  getDashboards() {
    return axios.get("/v1/dashboards");
  },
  getMapChartTopoJson(data: any) {
    // Request the chartAPI directly or load here the topojson
    return axios.post(
      (<any>window)._env_.VUE_APP_chartApiUrl + "/chartjs/ui",
      data,
      {
        baseURL: "/",
      }
    );
  },
});
