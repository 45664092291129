import storage from "@utils/tokenStorage";
import { useApi } from "@api/api";
import { defineStore } from "pinia";
import type { MonitioAPI } from "@root/types.api.local";
import DateTimeUtils from "@root/utils/dateTime";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useWorkspacesStore = defineStore("workspaces", {
  state: () => ({
    moduleHasInit: false,
    id: null as string | null,
    user: [] as MonitioAPI.WorkspaceDTO[],
    switching: false,
    languages: [] as MonitioAPI.Language[],
    dateRangeIntervalId: -1,
  }),
  getters: {
    currentWorkspaceConfig: (state) => {
      return (state.user.find((x) => x.id == state.id) ??
        state.user[0]) as MonitioAPI.WorkspaceDTO;
    },
    getWorkspaceConfig() {
      return (id: string) =>
        this.user.find((x) => x.id == id) as MonitioAPI.WorkspaceDTO;
    },
    getUserById() {
      return (id: string): MonitioAPI.WorkspaceUserDTO | null | undefined =>
        this.currentWorkspaceConfig.workspaceUsers?.find((f) => f.id == id);
    },
    getTeamById() {
      return (id: string): MonitioAPI.TeamDTO | undefined =>
        this.currentWorkspaceConfig?.teams?.find((f) => f.id == id);
    },
  },
  actions: {
    async init() {
      if (this.moduleHasInit) {
        console.warn("Workspaces module already initialized");
        return;
      }
      await this.getAccountWorkspaces();
      await this.getWorkspaceLanguages();
      const savedid = storage.workspaceId;

      if (savedid && this.user.some((x) => x.id == savedid)) {
        this.id = savedid;
      } else if (!savedid) {
        this.id = this.user[0].id ?? null;
      }

      // Workspce comes with unacurate dateRange by default, so we need to ask for updated values
      if (this.id) await this.updateWorkspaceDateRange(this.id);
      this.dateRangeIntervalId = setInterval(() => {
        if (this.id) this.updateWorkspaceDateRange(this.id);
      }, 60 * 60 * 1000); //Run hourly by default

      this.moduleHasInit = true;
    },
    async shutdown() {
      this.id = null;
      this.user = [];
      this.moduleHasInit = false;
      if (this.dateRangeIntervalId >= 0) {
        clearInterval(this.dateRangeIntervalId);
        this.dateRangeIntervalId = -1;
      }
    },
    async getAccountWorkspaces() {
      const { api } = useApi();
      const { data } = await api.workspaces.getWorkspacesForAccount();
      if (!data) return;
      this.user = data;
    },
    /**
     * @descripton this function retrieves a new token with the new workspace
     * context, all the requests after this will be made against the new workspace!
     * When using this function one should realy reboot the store afterwards
     */
    async switchWorkspace(id: string, redirectUrl: string) {
      const { api } = useApi();
      this.switching = true;

      const { data } = await api.workspaces.switchWorkspace(id);
      if (!data) return;
      // Change the token, this is what indentfies the user and its workspace in the backend
      storage.refreshToken = data.token;
      storage.jwtToken = data.sessionToken; // 🚑 It's important that we send the entire object { token, expires }
      storage.workspaceId = data.workspaceId;

      this.id = id;
      /** After that we need to reboot the app */
      if (redirectUrl) history.pushState(null, "", redirectUrl);
      location.reload();
    },
    async getWorkspaceLanguages() {
      const { api } = useApi();
      const data = await api.workspaces.getWorkspaceLanguages();
      if (!data) return;
      this.languages = data.data;
    },
    async updateWorkspaceDateRange(id: string) {
      const { api } = useApi();
      const { data } = await api.workspaces.getWorkspaceDateRange(id);
      const idx = this.user.findIndex((x) => x.id == id);

      this.user[idx].documentDateRange = {
        newest: DateTimeUtils.parseFromISO(data.newest),
        oldest: DateTimeUtils.parseFromISO(data.oldest),
      };
    },
  },
});
