<template>
  <!-- ?add focus for a11y ? -->
  <div
    :id="`m_checkbox_${id}`"
    :data-test="`test_m_checkbox_${id}`"
    class="m-checkbox"
    :class="[
      `m-checkbox--${variant} m-checkbox--${size} m-checkbox--${type}`,
      {
        'm-checkbox--hover': onhover,
        'm-checkbox--checked': modelValue,
        'm-checkbox--unchecked': !modelValue,
        'm-checkbox--disabled': disabled,
        '--a11y': appStore.a11y,
      },
    ]"
    :data-tooltip-content="tooltipContent"
    data-tooltip-position="dynamic"
    role="checkbox"
    :aria-checked="modelValue"
    :aria-disabled="disabled"
    @keyup.enter.stop="update"
    @keyup.space.stop="update"
    @click.stop="update"
  >
    <div
      tabindex="0"
      role="button"
      class="m-checkbox__box"
      @mouseenter="setHover(true)"
      @mouseleave="setHover(false)"
    >
      <div
        v-if="variant == 'round' && modelValue"
        class="m-checkbox__radio"
      ></div>
      <m-icon
        v-if="variant == 'square'"
        :id="`m_checkbox_icon_${id}`"
        :icon="setIcon"
        :variant="setIconVariant"
        status="active"
        :size="size"
        @click.stop="update"
      />
    </div>
    <label
      v-if="label"
      :id="`m_checkbox_${id}`"
      :data-tooltip-content="label"
      :data-tooltip-position="'dynamic'"
      class="ml-2"
      :class="[`type--${size}`, modelValue ? 'h5' : 'p']"
      :dir="dir"
    >
      {{ label }}
    </label>
  </div>
</template>

<script setup>
/*
 * Monitio Checkbox component.
 * For more details of please refer to the docs at:
 * https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/617840767/Forms#Setup.2
 */

import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

import MIcon from "@components/MIcon.vue";
import { useAppStore } from "@root/store/app";

const props = defineProps({
  id: {
    type: String,
    required: true,
    validator(id) {
      if (id.match(/[\s-]/g)) {
        console.error(
          `Invalid attribute "id": string "${id}" has to be in snake_case.`
        );
      }
      return true;
    },
  },
  modelValue: { type: Boolean },
  size: {
    type: String,
    default: "default",
  },
  label: { type: String },
  variant: {
    type: String,
    default: "square",
  },
  type: {
    type: String,
    default: "positive",
  },
  tooltip: {
    type: [String, Object],
  },
  disabled: { type: Boolean, default: false },
  dir: {
    type: String,
    default: "ltr",
  },
  hover: {
    type: Boolean,
    default: true,
  },
});

// Props validation
watch(
  () => props,
  () => {
    // size validator
    if (!["default", "small", "xsmall"].includes(props.size)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "size":expected string with value "default", "small" or "xsmall" and got "${props.size}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/630652992/Checkbox for instructions on how to use the MCheckbox component.`
      );
    }

    // variant validator
    if (!["square", "round"].includes(props.variant)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "variant":expected string with value "square" or "round" and got "${props.variant}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/630652992/Checkbox for instructions on how to use the MCheckbox component.`
      );
    }
    // tooltip validator
    if (typeof props.tooltip == "object") {
      if (
        props.tooltip?.content === undefined ||
        props.tooltip?.content === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "content" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/630652992/Checkbox for instructions on how to use the MCheckbox component.'
        );
      }
      if (
        props.tooltip?.position === undefined ||
        props.tooltip?.position === null
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          '\n Invalid prop "tooltip": tooltip should have the attribute "position" defined. \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/630652992/Checkbox for instructions on how to use the MCheckbox component.'
        );
      } else if (
        ![
          "dynamic",
          "top-left",
          "top-center",
          "top-right",
          "left",
          "right",
          "bottom-left",
          "bottom-center",
          "bottom-right",
        ].includes(props.tooltip?.position)
      ) {
        console.error(
          `%cComponent id: ${props.id}`,
          "font-weight:bold",
          `\n Invalid prop "tooltip": expected attribute "position" with value "dynamic", "top-left", "top-center", "top-right", "left", "right", "bottom-left", "bottom-center" or "bottom-right", and got "${props.tooltip?.position}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/630652992/Checkbox for instructions on how to use the MCheckbox component.`
        );
      }
    }

    // type validator
    if (!["positive", "negative"].includes(props.type)) {
      console.error(
        `%cComponent id: ${props.id}`,
        "font-weight:bold",
        `\n Invalid prop "variant":expected string with value "square" or "round" and got "${props.type}". \n Go to https://priberam.atlassian.net/wiki/spaces/INSIGHT/pages/630652992/Checkbox  for instructions on how to use the MCheckbox component.`
      );
    }
  },
  { immediate: true }
);

const emit = defineEmits(["update:modelValue"]);
const { t } = useI18n();

const appStore = useAppStore();

const onhover = ref(undefined);

const tooltipContent = computed(() => {
  if (props.tooltip?.content) return props.tooltip.content;
  else if (props.tooltip) return props.tooltip;
  else if (!props.modelValue) {
    return t("components.checkbox.tooltip_unchecked", {
      label: props.label,
    });
  } else {
    return t("components.checkbox.tooltip_checked", {
      label: props.label,
    });
  }
});

const setHover = (val) => {
  if (props.hover) onhover.value = val;
};

const setIcon = computed(() => {
  if (props.hover) {
    if (
      (onhover.value && !props.modelValue) ||
      (!onhover.value && props.modelValue)
    ) {
      return props.type == "positive" ? "check" : "line";
    } else return "none";
  } else {
    if (props.modelValue) return props.type == "positive" ? "check" : "line";
    else return "none";
  }
});

const setIconVariant = computed(() => {
  if (props.modelValue) {
    return "white";
  } else {
    if (setIcon.value == "check") {
      return "primary";
    } else if (setIcon.value == "line") {
      return "error";
    } else {
      return "default";
    }
  }
});

const update = () => {
  emit("update:modelValue", !props.modelValue, props.type);
};

defineExpose({
  setHover,
});
</script>

<style scoped lang="scss">
.m-checkbox {
  width: 100%;
  @include flex(flex-start, center, row);
  cursor: pointer;

  * {
    cursor: pointer;
  }

  :deep(.m-icon:hover *) {
    cursor: pointer !important;
  }

  &__box {
    width: $spacing-4 + math.div($spacing-1, 2);
    height: $spacing-4 + math.div($spacing-1, 2);
    @include flex(center, center, row);
  }

  label {
    flex-grow: 1;
  }

  &--positive {
    &.m-checkbox--checked .m-checkbox__box {
      background-color: color($pri-action);
    }

    &.m-checkbox--unchecked .m-checkbox__box {
      border: 2px solid color($pri-action-inactive);
    }

    &.m-checkbox--hover .m-checkbox__box {
      border: none;
      background-color: color($pri-action-light);
    }
  }

  &--negative {
    &.m-checkbox--checked .m-checkbox__box {
      background-color: color($error);
    }
    &.m-checkbox--unchecked .m-checkbox__box {
      border: none;
    }

    &.m-checkbox--hover .m-checkbox__box {
      border: none;
      background-color: color($error-light);
    }
  }

  &--disabled {
    @include opacity-disabled;
  }

  &__radio {
    min-width: $spacing-2;
    width: $spacing-2;
    height: $spacing-2;
    @include round-corners;
    background-color: color($white);
  }

  label {
    flex-grow: 1;
    @include ellipsis;
  }

  &--small {
    .m-checkbox__box {
      min-width: $spacing-3 + math.div($spacing-1, 2);
      width: $spacing-3 + math.div($spacing-1, 2);
      height: $spacing-3 + math.div($spacing-1, 2);

      &--label {
        margin-right: $spacing-2;
      }
    }

    .m-checkbox__radio {
      width: $spacing-1 + math.div($spacing-1, 2);
      height: $spacing-1 + math.div($spacing-1, 2);
    }

    :deep(svg) {
      transform: scale(0.9);
    }
  }

  &--xsmall {
    .m-checkbox__box {
      min-width: $spacing-3;
      width: $spacing-3;
      height: $spacing-3;

      &--label {
        flex-grow: 1;
        margin-right: $spacing-1;
      }
    }

    &.m-checkbox--disabled {
      border: 1px solid color($pri-action-inactive);
    }

    .m-checkbox__radio {
      min-width: $spacing-1 + math.div($spacing-1, 2);
      width: $spacing-1 + math.div($spacing-1, 2);
      height: $spacing-1 + math.div($spacing-1, 2);
    }

    :deep(svg) {
      transform: scale(0.9);
    }
  }

  &--square .m-checkbox__box {
    @include round-corners($spacing-1);
  }

  &--round .m-checkbox__box {
    @include round-corners;
  }
}
</style>
