/**
 * File with common code for sharing between article related views and components
 */

import { computed, ref, unref } from "vue";

import { useApi } from "@api/api";
import structuredClone from "@utils/structuredClone";

export default () => {
  const { api } = useApi();

  /**
   * @param {[]} elements The array of elements in a dashboard
   * @param {string} id The id of the chart we want to find
   * @returns The indexes we must follow to find the chart within this potentialy infinit nested array
   */
  const deepSearchChart = (elements, id) => {
    let result = [];
    const chartIdx = elements?.findIndex((x) => x.id == id);
    if (chartIdx < 0) {
      elements.forEach((element, i) => {
        if (element.children?.length > 0) {
          const idx = deepSearchChart(element.children, id);
          if (idx?.length) {
            result = [...result, i, ...idx];
          }
        }
      });
      return result;
    } else {
      return [chartIdx];
    }
  };

  /**
   * @description Retrieves a matrix Array(Array) in wich the values of the cells are either 1 if there's a chart there or 0 if not.
   * @param {[]} elements
   * @returns {[number[]]}
   */
  const getDashboardMatrix = (elements, numberOfCols) => {
    const maxX = Math.max(...elements.map((x) => x.x + (x.w - 1)));
    const maxY = Math.max(...elements.map((x) => x.y + (x.h - 1)));
    const gridMaxtrix = Array.from({ length: maxY + 1 }, () =>
      Array.from({ length: numberOfCols }, () => 0)
    );

    //Check for items in the last "row" that are bigger than 1 in height because we need to add those rows tot he matrix
    const lastRowItems = elements.filter((x) => x.y == maxY);
    if (lastRowItems.find((x) => x.h > 1)) {
      const maxHofRow = Math.max(...lastRowItems.map((x) => x.h));
      for (let i = 0; i < maxHofRow; i++) {
        gridMaxtrix.push(Array.from({ length: numberOfCols }, () => 0));
      }
    }

    for (let rowIdx = 0; rowIdx < gridMaxtrix.length; rowIdx++) {
      for (let colIdx = 0; colIdx < gridMaxtrix[rowIdx].length; colIdx++) {
        const element = elements.find((x) => x.y == rowIdx && x.x == colIdx);
        if (element) {
          gridMaxtrix[rowIdx][colIdx] = 1;
          for (let index = 0; index <= element.w - 1; index++) {
            gridMaxtrix[rowIdx][colIdx + index] = 1;
            for (let indexJ = 0; indexJ <= element.h - 1; indexJ++) {
              gridMaxtrix[rowIdx + indexJ][colIdx + index] = 1;
            }
          }
        }
      }
    }
    return gridMaxtrix;
  };

  /** Function that retrieves the position that the new element will be */
  const getAddElementCoords = (layout, columnN) => {
    if (!layout) return { x: 0, y: 0 };
    const matrix = getDashboardMatrix(layout, columnN);
    let x;
    let y;
    let lazyBreak = false;

    for (let row = 0; row < matrix.length; row++) {
      for (let col = 0; col < matrix[row].length; col++) {
        if (matrix[row][col] === 0) {
          x = col;
          y = row;
          lazyBreak = true;
          break;
        }
      }
      if (lazyBreak) break;
    }

    if (!x && !y) {
      // If coords are not defined it means theres no space in the matrix so we need to put it in another row
      x = 0;
      y = matrix.length;
    }
    return { x, y };
  };

  return {
    getDashboardMatrix,
    getAddElementCoords,
    deepSearchChart,
  };
};
