import { useModal } from "@npm/@priberam/frontend-utils/dist/modal-factory";
import { useI18n } from "vue-i18n";

export default () => {
  const { open, close, cancel } = useModal("m-modal");
  const { t } = useI18n();

  const handleModal = (mode, value, article, articleIptcs) => {
    return new Promise((res, rej) => {
      const title = t(`${mode}MentionLink.modalTitle`);
      const description = t(`${mode}MentionLink.modalDescription`);
      open("m_modal_edit_entity", {
        heading: title,
        description: description,
        width: 4,
        disabled: false,
        buttons: ["custom"],
        //
        mode,
        value,
        article,
        articleIptcs,
        close,
        cancel,
      }).then(
        ({ reason }) => {
          // fulfilled
          res(reason);
        },
        (reason) => {
          // rejected
          rej(reason);
        }
      );
    });
  };

  return {
    open: handleModal,
    close,
    cancel,
  };
};
